import React from 'react';
import { Card } from '@material-ui/core';

import WorkTab from '../../containers/WorkTab/WorkTab';

const tabList = [
  {
    key: 'scientificResearch',
    title: 'Bài báo/Chương sách'
  },
  {
    key: 'solution',
    title: 'Giải pháp hữu ích'
  },
  {
    key: 'patent',
    title: 'Bằng sáng chế'
  },
  {
    key: 'project',
    title: 'Đề tài nghiên cứu'
  },
  {
    key: 'monograph',
    title: 'Sách'
  },
]

function Work() {
  return (
    <Card style={{ minHeight: 'calc(100vh - 92px)', position: 'relative' }}>
      <WorkTab tabList={tabList} address={window.location.href} />
    </Card>
  );
}

export default Work;
