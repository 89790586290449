import React from 'react';
import { useState } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TextField,
  IconButton,
  Button,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Add, Delete, List } from '@material-ui/icons';
import clsx from 'clsx';
import { FieldArray, FastField, ErrorMessage, useField } from 'formik';

import ResearchProjectModal from './ResearchProjectModal';
import ISIScopusPublishmentModal from './ISIScopusPublishmentModal';
import PublishmentModal from './PublishmentModal';
import DomesticPublishmentModal from './DomesticPublishmentModal';
import PatentsOrSolutionsOrMonographsModal from './PatentsOrSolutionsOrMonographsModal';

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionTitle: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subsectionTitle: {
      fontWeight: 'bold',
    },
    subsectionTitleNote: {
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    tableRoot: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.common.black,
    },
    tableCell: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.common.black,
    },
    alignCenter: {
      textAlign: 'center',
    },
    tableCellHead: {
      fontWeight: 'bold',
    },
  })
);

function Research(props) {
  const {
    researchProps: { researchProjects, patents, monographs, scientificResearchs, solutions, scientificResearchOthers },
  } = props;

  const [researchProjectModalOpen, setResearchProjectModalOpen] = useState(false);
  const [isiScopusPublishmentModalOpen, setIsiScopusPublishmentModalOpen] = useState(false);
  const [publishmentModalOpen, setPublishmentModalOpen] = useState(false);
  const [domesticPublishmentModalOpen, setDomesticPublishmentModalOpen] = useState(false);
  const [patentsOrSolutionsOrMonographsModalOpen, setPatentsOrSolutionsOrMonographsModalOpen] = useState(false);

  const classes = useStyles();
  const [researchAreaInput] = useField('scientificResearchs.researchAreas');
  const [researchProjectInput] = useField('scientificResearchs.researchProjects');
  const [isiScopusPublishmentInput] = useField('scientificResearchs.isiScopusPublishments');
  const [publishmentInput] = useField('scientificResearchs.publishments');
  const [domesticPublishmentInput] = useField('scientificResearchs.domesticPublishments');
  const [patentsOrSolutionsOrMonographsInput] = useField('scientificResearchs.patentsOrSolutionsOrMonographs');

  const renderSortedByYear = (arr) => {
    const sortedData = [].concat(arr)
      .sort((a, b) => a.year < b.year ? 1 : -1)
      .map((item, index) => 
        <li key={index}>{item.title}</li>
    );
    return(sortedData)
  }

  return (
    <>
      <Box py={2}>
        <Box>
          <Typography variant='h6' className={classes.sectionTitle}>
            4. Quá trình nghiên cứu khoa học
          </Typography>
        </Box>
        {/* a. Hướng nghiên cứu chính */}
        <Box>
          <Typography className={classes.subsectionTitle}>a. Hướng nghiên cứu chính</Typography>
          <FieldArray name='scientificResearchs.researchAreas'>
            {(arrayHelpers) => {
              const handleAddResearchArea = () => {
                arrayHelpers.push('');
              };
              const handleDeleteResearchArea = (index) => {
                arrayHelpers.remove(index);
              };

              return (
                <>
                  {researchAreaInput.value.map((_researchAreaElement, researchAreaIndex) => {
                    return (
                      <Box
                        paddingTop={1}
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        key={researchAreaIndex}
                      >
                        <FastField name={`scientificResearchs.researchAreas.${researchAreaIndex}`}>
                          {(fieldProps) => (
                            <TextField
                              {...fieldProps.field}
                              fullWidth
                              label='Hướng nghiên cứu'
                              variant='outlined'
                              size='small'
                              helperText={
                                <ErrorMessage name={`scientificResearchs.researchAreas.${researchAreaIndex}`} />
                              }
                              FormHelperTextProps={{ error: true }}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      color='secondary'
                                      onClick={() => handleDeleteResearchArea(researchAreaIndex)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </FastField>
                      </Box>
                    );
                  })}
                  <Box paddingY={1}>
                    <Button variant='outlined' color='primary' startIcon={<Add />} onClick={handleAddResearchArea}>
                      Thêm
                    </Button>
                  </Box>
                </>
              );
            }}
          </FieldArray>
        </Box>

        {/* b. Các đề tài/dự án nghiên cứu khoa học */}
        <Box>
          <Typography className={classes.subsectionTitle}>b. Các đề tài/dự án nghiên cứu khoa học</Typography>
          <Box py={2}>
            <Table size='small' classes={{ root: classes.tableRoot }}>
              {researchProjectInput.value.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell
                      variant='head'
                      classes={{
                        root: classes.tableCell,
                        head: clsx(classes.tableCellHead, classes.alignCenter),
                      }}
                    >
                      TT
                    </TableCell>
                    <TableCell
                      variant='head'
                      classes={{
                        root: classes.tableCell,
                        head: clsx(classes.tableCellHead, classes.alignCenter),
                      }}
                    >
                      Tên đề tài/dự án
                    </TableCell>
                    <TableCell
                      variant='head'
                      classes={{
                        root: classes.tableCell,
                        head: clsx(classes.tableCellHead, classes.alignCenter),
                      }}
                    >
                      Cơ quan tài trợ
                    </TableCell>
                    <TableCell
                      variant='head'
                      classes={{
                        root: classes.tableCell,
                        head: clsx(classes.tableCellHead, classes.alignCenter),
                      }}
                    >
                      Thời gian thực hiện
                    </TableCell>
                    <TableCell
                      variant='head'
                      classes={{
                        root: classes.tableCell,
                        head: clsx(classes.tableCellHead, classes.alignCenter),
                      }}
                    >
                      Vai trò
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {researchProjectInput.value.map((researchProjectElement, researchProjectIndex) => {
                  return (
                    <TableRow key={researchProjectIndex}>
                      <TableCell className={clsx(classes.tableCell)}>{researchProjectIndex + 1}</TableCell>
                      <TableCell className={clsx(classes.tableCell)}>{researchProjectElement.name}</TableCell>
                      <TableCell className={clsx(classes.tableCell)}>{researchProjectElement.sponsor}</TableCell>
                      <TableCell className={clsx(classes.tableCell)}>{researchProjectElement.duration}</TableCell>
                      <TableCell className={clsx(classes.tableCell)}>{researchProjectElement.role}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box paddingBottom={2}>
            <ResearchProjectModal
              open={researchProjectModalOpen}
              handleClose={() => setResearchProjectModalOpen(false)}
              researchProjects={researchProjects}
            />
            <Button
              variant='outlined'
              color='primary'
              startIcon={<List />}
              onClick={() => setResearchProjectModalOpen(true)}
            >
              Chọn
            </Button>
          </Box>
        </Box>

        {/* c. Công bố ISI/Scopus */}
        <Box>
          <Typography className={classes.subsectionTitle}>c. Công bố ISI/Scopus</Typography>
          <ol>
            {renderSortedByYear(isiScopusPublishmentInput.value)}
            {/* {isiScopusPublishmentInput.value.map((isiScopusPublishmentElement, isiScopusPublishmentIndex) => {
              return <li key={isiScopusPublishmentIndex}>{isiScopusPublishmentElement.title}</li>;
            })} */}
          </ol>
          <Box py={2}>
            <ISIScopusPublishmentModal
              open={isiScopusPublishmentModalOpen}
              handleClose={() => setIsiScopusPublishmentModalOpen(false)}
              scientificResearchs={scientificResearchs}
            />
            <Button
              variant='outlined'
              color='primary'
              startIcon={<List />}
              onClick={() => setIsiScopusPublishmentModalOpen(true)}
            >
              Chọn
            </Button>
          </Box>
        </Box>

        {/* d. Công bố quốc tế khác */}
        <Box>
          <Typography className={classes.subsectionTitle}>d. Công bố quốc tế khác</Typography>
          <ol>
            {publishmentInput.value.map((publishmentElement, publishmentIndex) => {
              return <li key={publishmentIndex}>{publishmentElement.title}</li>;
            })}
          </ol>
          <Box py={2}>
            <PublishmentModal
              open={publishmentModalOpen}
              handleClose={() => setPublishmentModalOpen(false)}
              scientificResearchs={scientificResearchs}
              scientificResearchOthers={scientificResearchOthers}
            />
            <Button
              variant='outlined'
              color='primary'
              startIcon={<List />}
              onClick={() => setPublishmentModalOpen(true)}
            >
              Chọn
            </Button>
          </Box>
        </Box>

        {/* e. Công bố trên các tạp chí khoa học quốc gia */}
        <Box>
          <Typography className={classes.subsectionTitle}>e. Công bố trên các tạp chí khoa học quốc gia</Typography>
          <ol>
            {renderSortedByYear(domesticPublishmentInput.value)}
            {/* {domesticPublishmentInput.value.map((domesticPublishmentElement, domesticPublishmentIndex) => {
              return <li key={domesticPublishmentIndex}>{domesticPublishmentElement.title}</li>;
            })} */}
          </ol>
          <Box py={2}>
            <DomesticPublishmentModal
              open={domesticPublishmentModalOpen}
              handleClose={() => setDomesticPublishmentModalOpen(false)}
              scientificResearchs={scientificResearchs}
            />
            <Button
              variant='outlined'
              color='primary'
              startIcon={<List />}
              onClick={() => setDomesticPublishmentModalOpen(true)}
            >
              Chọn
            </Button>
          </Box>
        </Box>

        {/* f. Bằng sáng chế/Giải pháp hữu ích/sách chuyên khảo */}
        <Box>
          <Typography className={classes.subsectionTitle}>
            f. Bằng sáng chế/Giải pháp hữu ích/Sách chuyên khảo
          </Typography>
          <ol>
            {renderSortedByYear(patentsOrSolutionsOrMonographsInput.value.patents)}
            {/* {patentsOrSolutionsOrMonographsInput.value.patents.map((element, index) => {
              return (
                <li key={index} title='Bằng sáng chế'>
                  {element.title}
                </li>
              );
            })} */}
            {patentsOrSolutionsOrMonographsInput.value.solutions.map((element, index) => {
              return (
                <li key={index} title='Giải pháp hữu ích'>
                  {element.title}
                </li>
              );
            })}
            {renderSortedByYear(patentsOrSolutionsOrMonographsInput.value.monographs)}
            {/* {patentsOrSolutionsOrMonographsInput.value.monographs.map((element, index) => {
              return (
                <li key={index} title='Sách chuyên khảo'>
                  {element.title}
                </li>
              );
            })} */}
          </ol>
          <Box py={2}>
            <PatentsOrSolutionsOrMonographsModal
              open={patentsOrSolutionsOrMonographsModalOpen}
              handleClose={() => setPatentsOrSolutionsOrMonographsModalOpen(false)}
              patents={patents}
              solutions={solutions}
              monographs={monographs}
            />
            <Button
              variant='outlined'
              color='primary'
              startIcon={<List />}
              onClick={() => setPatentsOrSolutionsOrMonographsModalOpen(true)}
            >
              Chọn
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Research;
