import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Chip,
  Grid,
  Button,
  Divider, 
  Popover,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { Replay, AllInclusive } from '@material-ui/icons';

import select from '../../../utils/select';
import KPTable from '../../../components/KPTable';
import EmploymentContractUpdateModal2 from '../../EmploymentContract/EmploymentContractUpdateModal2';

import {
  getEmploymentContractInfoById,
  updateReceiveNotiEmploymentContract,
  getAllEmploymentContractByProfileId,
} from '../../../reducer/employmentContract/action';

function ContractProfile(props) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [checkingRow, setCheckingRow] = useState(null);
  const [detailRow, setDetailRow] = useState(null);
  const [lstContract, setLstContract] = useState([]);

  const profileDetail = useSelector(state => select(state, 'profileReducer', 'detail'));
	const isFetching = useSelector(state => select(state, 'profileReducer', 'isFetching'));
	const profileContracts = useSelector(state => select(state, 'profileReducer', 'contracts'));
	const contractInfo = useSelector(state => select(state, 'employmentContractReducer', 'contractInfo'));

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCheckingRow(id)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCheckingRow(null)

  };

  const handleSubmit = () => {
    dispatch(updateReceiveNotiEmploymentContract(checkingRow?.id, !checkingRow?.receiveNoti));
    handleClose();
  };

  const handleReloadContract = () => {
    if (!props.profileInfo) return;
    dispatch(getAllEmploymentContractByProfileId(props.profileInfo.id)).then((res) => {
      setLstContract(res?.map((e) => e.contract))
    });
  };

  useEffect(() => handleReloadContract(), []);

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        {/* <Grid item lg={6} md={12}>
          <Typography variant='h4'>Hợp đồng cá nhân</Typography>
        </Grid>
        <Grid item lg={6} md={12}>
          <Button
            color='default'
            variant='outlined'
            disabled={true}
            startIcon={<Replay />}
            style={{ float: 'right' }}
          >
            Tải lại
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}
        <Grid item md={12}>
          <KPTable
            shadow="none"
            columns={[
              {
                title: <b>Thời hạn</b>,
                key: 'duration',
                width: '20%',
                sorter: (a, b) => a?.duration - b?.duration,
                render: (value) => <Typography>{value === 1000 ? 'Vô thời hạn' : `${value} năm`}</Typography>
              },
              {
                title: <b>Bắt đầu</b>,
                key: 'startAt',
                width: '20%',
                sorter: (a, b) => dayjs(a?.startAt).diff(b?.startAt),
                render: (value) => <Typography>{dayjs(value ?? '').format('DD/MM/YYYY')}</Typography>
              },
              {
                title: <b>Kết thúc</b>,
                key: 'finishAt',
                width: '20%',
                sorter: (a, b) => dayjs(a?.finishAt).diff(b?.finishAt),
                render: (value, row) => (
                  <Typography>
                    {row?.duration === 1000 ? (
                      <AllInclusive style={{ marginLeft: 30 }} />
                    ) : (
                      dayjs(value ?? '').format('DD/MM/YYYY')
                    )}
                  </Typography>
                )
              },
              {
                title: <b>Tình trạng</b>,
                key: 'active',
                width: '20%',
                render: (value) => (
                  <Chip
                    size='small'
                    variant='outlined'
                    color={value ? 'primary' : 'secondary'}
                    label={value ? 'Đang có hiệu lực' : 'Hết hiệu lực'}
                  />
                )
              },
              {
                title: <b>Nhận thông báo</b>,
                key: 'receiveNoti',
                width: '20%',
                render: (value, row) => (
                  <Checkbox
                    disabled={props?.profileInfo?.id && props.profileInfo.id !== profileDetail?.id}
                    color='primary'
                    checked={value}
                    onChange={(event) => handleClick(event, row)}
                  />
                )
              },
              {
                title: <b>Tác vụ</b>,
                key: 'actions',
                width: '20%',
                render: (_, row) => (
                  <Typography
                    color='primary'
                    variant='caption'
                    className='table-action-buton'
                    onClick={() => setDetailRow(row)}
                  >
                    Xem chi tiết
                  </Typography>
                )
              },
            ]}
            rows={(props.profileInfo ? lstContract : profileContracts)?.sort((a, b) => a.id - b.id)}
          />
        </Grid>
      </Grid>
    
      

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div style={{ padding: 8 }}>
          <div>{checkingRow?.receiveNoti? 'Hủy nhận thông cho báo hợp đồng?' : 'Nhận thông báo cho hợp đồng'}</div>
          <div style={{ float: 'right', padding: '8px 0' }}>
            <Button size='small' variant='outlined' color='secondary' onClick={handleClose} style={{ marginRight: 4 }}>Huỷ</Button>
            <Button size='small' variant='outlined' color='primary'  onClick={() => handleSubmit()}>Xác nhận</Button>
          </div>
        </div>
      </Popover>

      {detailRow !== null && (
        <EmploymentContractUpdateModal2
          updating={{
            contract: detailRow,
            profile: props?.profileInfo || profileDetail,
          }}
          profile={props?.profileInfo || profileDetail}
          lstContract={lstContract}
          setUpdating={setDetailRow}
        />
      )}
    </>
  );
}

export default ContractProfile;
