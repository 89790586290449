import { BorderStyle } from "docx";

export const noneBorderStyle = {
  style: BorderStyle.NONE,
  size: 0,
  color: "FFFFFF",
};

export const commonBorders = {
  top: noneBorderStyle,
  bottom: noneBorderStyle,
  left: noneBorderStyle,
  right: noneBorderStyle,
};
