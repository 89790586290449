import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getEarningsTable = () => async (dispatch) => {
  const api = API_URLS.EARNINGS_TABLE.getEarningsTable();
  dispatch({
    type: TYPE.EARNINGS_TABLE.GET_EARNINGS_TABLE_LOADING,
    meta: { prefix: [PREFIX.EARNINGS_TABLE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EARNINGS_TABLE.GET_EARNINGS_TABLE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EARNINGS_TABLE, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.EARNINGS_TABLE.GET_EARNINGS_TABLE_FAILURE,
      meta: { prefix: [PREFIX.EARNINGS_TABLE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const updateEarningsTable = (id, payload) => async (dispatch) => {
  const api = API_URLS.EARNINGS_TABLE.updateEarningsTable(id);
  dispatch({
    type: TYPE.EARNINGS_TABLE.UPDATE_EARNINGS_TABLE_LOADING,
    meta: { prefix: [PREFIX.EARNINGS_TABLE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EARNINGS_TABLE.UPDATE_EARNINGS_TABLE_SUCCESS,
      id: id,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EARNINGS_TABLE, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.EARNINGS_TABLE.UPDATE_EARNINGS_TABLE_FAILURE,
      meta: { prefix: [PREFIX.EARNINGS_TABLE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};
