import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { PREFIX } from '../../constants/enums';
import { API_URLS } from '../../constants/api';

export const notification = (payload) => async (dispatch) => {
  dispatch({
    type: TYPE.HOME.NOTIFICATION,
    payload,
    meta: { prefix: [PREFIX.HOME, PREFIX.API_CALLING] },
  });
}

export const changePages = (id, newPage) => async (dispatch) => {
  dispatch({
    type: 'CHANGE_PAGES',
    id, newPage,
    meta: { prefix: [PREFIX.HOME, PREFIX.API_CALLING] },
  });
}

export const resetPages = () => async (dispatch) => {
  dispatch({
    type: 'RESET_PAGES',
    meta: { prefix: [PREFIX.HOME, PREFIX.API_CALLING] },
  });
}

export const createHistory = (payload) => async (dispatch) => {
  dispatch({
    type: TYPE.HOME.CREATE_HISTORY,
    payload,
    meta: { prefix: [PREFIX.HOME, PREFIX.API_CALLING] },
  });
}

export const autoRanking = (id) => async (dispatch) => {
  const api = API_URLS.AUTO_RANKING.autoRanking(id);
  dispatch({
    type: TYPE.AUTO_RANKING_LOADING,
    meta: { prefix: [PREFIX.HOME, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.AUTO_RANKING_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.HOME, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.AUTO_RANKING_FAILURE,
      meta: { prefix: [PREFIX.HOME, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thất bại!'
    }));
  }
};

export const getFilteredScientificResearch = (page, pageSize, payload) => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH.getFilteredScientificResearch(page, pageSize);
  dispatch({
    type: TYPE.HOME.GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_LOADING,
    meta: { prefix: [PREFIX.HOME, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.HOME.GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.HOME, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.HOME.GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_FAILURE,
      meta: { prefix: [PREFIX.HOME, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};