import { Grid, IconButton } from "@material-ui/core";
import { GetApp, Publish, Visibility } from "@material-ui/icons";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

import dshdnt from "../../../../assets/files/DeTaiSinhVien/DanhsachHDNT.docx";

import {
  StudentProjectAcceptListDocx,
  StudentProjectAdjustDecisionDocx,
  StudentProjectAdjustListDocx,
  StudentProjectListDocx,
  StudentProjectStopDecisionDocx,
  StudentProjectStopListDocx,
} from "../../../../utils/documentCreator";
import select from "../../../../utils/select";

import { STUDENT_RESEARCH_STATUS } from "../../../../constants/projectStatus";

import styles from "./index.module.css";
import { uploadFile, uploadMultiFile } from "../../../../reducer/file/action";
import { API_URLS } from "../../../../constants/api";
import { apiCall } from "../../../../utils/api";
import { DegreeAbbrv } from "../../../../utils/abbreviation";

// eslint-disable-next-line no-unused-vars
function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function ExportForm(props) {
  const input0 = useRef();
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState(null);
  const [id, setId] = useState(null);

  const profileList = useSelector((state) => select(state, "profileReducer", "list"));
  const faculties = useSelector((state) => select(state, "departmentReducer", "faculties"));
  const departments = useSelector((state) => select(state, "departmentReducer", "departments"));
  const profileDetail = useSelector((state) => select(state, "profileReducer", "detail"));
  const list = useSelector((state) => select(state, "studentResearchReducer", "list")) || [];
   
  const downloadButton = (type, name) => (
    <IconButton size="small" color="primary" title="Tải xuống" disabled={!name} onClick={() => generate(type, name)}>
      <GetApp size="small" />
    </IconButton>
  );

  const getData = async () => {
    const api = API_URLS.YEARLY_DUE_DATE.filterYearlyDueDate();
    const { response, error } = await apiCall({ ...api, payload: {
      type: 'student',
      schoolYear: props.year,
    }});
    if (!error && response.status === 200 && response.data.success) {
      const res = response.data.data[0];
      setFileList(isJsonString(res?.fileList) ? JSON.parse(res?.fileList) : {});
      setId(res?.id);
    }
  }

  useEffect(() => getData(), [props.year]);

  const updateData = async (payload) => {
    props.handleClose();
    const api = API_URLS.YEARLY_DUE_DATE.updateYearlyDueDate();
    await apiCall({ ...api, payload });
  }

  const handleUploadFile = (name, event) => {
    if (event.target.files) {
      dispatch(
        uploadMultiFile(
          profileDetail.id,
          {
            [name]: event.target.files[0]
          }
        )
      ).then((responseUpload) => {
        if (id && responseUpload.success) {
          const _fileList = {
            ...fileList,
            [name]: responseUpload.data[0]
          }
          updateData({ id, fileList: JSON.stringify(_fileList) });
        }
      });
    }
    // 'VB3', event
  }

  const generate = (type, name) => {
    var documentCreator;

    var data = props.rows;

    // Filter ra các list đề tài
    const memberAcceptedFiltered = (data) =>
      data
        .filter((e) => {
          var res = false;
          for (let i = STUDENT_RESEARCH_STATUS.length - 1; i >= 0; i--) {
            if (e.status.find((_status) => _status.value === STUDENT_RESEARCH_STATUS[i])) {
              res = true;
              break;
            }
            if (STUDENT_RESEARCH_STATUS[i] === "member_accepted") break;
          }
          return res;
        })
        .map((e) => ({
          ...e,
          researchMembers: e.researchMembers.map((member) => ({
            ...member,
            department: faculties.find((faculty) => faculty.id === member.departmentId)?.name,
          })),
          instructors: e.instructors.map((instructor) => ({
            ...instructor,
            profile: profileList.find((e) => e.id === instructor.profileId),
          })),
        }));

    // Filter ra các đề tài dừng triển khai
    const reportStopAcceptedFiltered = (data) =>
      data
        .map((e) => {
          const res = !!e.status.find(
            (_status) => _status.value.includes("report_stop_accepted")
          );
          return ({
            ...e,
            isStop: res,
            department: faculties.find((faculty) => faculty.id === e.departmentId)?.name,
          });
        });

    // Filter ra các đề tài sau khi điều chỉnh bổ sung
    const reportEnsureFiltered = (data) =>
      data
        .filter((e) => {
          var res = false;
          for (let i = STUDENT_RESEARCH_STATUS.length - 1; i >= 0; i--) {
            if (e.status.find((_status) => _status.value.includes(STUDENT_RESEARCH_STATUS[i]))) {
              res = true;
              break;
            }
            if (STUDENT_RESEARCH_STATUS[i] === "report_ensure") break;
          }
          res = res && !e.status.find(
            (_status) => _status.value.includes("report_stop_accepted")
          );
          return res;
        })
        // .filter((row) => {
        //   const parent = row?.parentResearch;

        //   const nameChange = parent?.name !== row?.name;
        //   const researchTargetChange = parent?.researchTarget !== row?.researchTarget;
        //   const expectedResultChange = parent?.expectedResult !== row?.expectedResult;
        //   const expectedCompetitionChange = parent?.expectedCompetition !== row?.expectedCompetition;
        //   const instructors = [
        //     ...(row?.instructors?.map((instructor) => ({
        //       ...instructor,
        //       isNew: !!!parent?.instructors?.find((e) => e.name === instructor.name),
        //     })) || []),
        //     ...(parent?.instructors
        //       ?.filter((instructor) => !!!row?.instructors?.find((e) => e.name === instructor.name))
        //       ?.map((e) => ({ ...e, isStop: true })) || []),
        //   ];
        //   const researchMembers = row?.researchMembers?.map((member) => ({
        //     ...member,
        //     isStop: member?.leaveReason !== "",
        //     isNew: !!!parent?.researchMembers?.find((e) => e.name === member.name),
        //     department: faculties.find((faculty) => faculty.id === member.departmentId)?.name,
        //   }));

        //   return (
        //     nameChange ||
        //     researchTargetChange ||
        //     expectedResultChange ||
        //     expectedCompetitionChange ||
        //     instructors.find((e) => e.isNew || e.isStop) ||
        //     researchMembers.find((e) => e.isNew || e.isStop)
        //   );
        // })
        .map((e) => ({
          ...e,
          researchMembers: e.researchMembers
            .filter((member) => member?.leaveReason === "")
            .map((member) => ({
              ...member,
              department: faculties.find((faculty) => faculty.id === member.departmentId)?.name,
            })),
        }));

    switch (type) {
      case 1:
        documentCreator = new StudentProjectStopDecisionDocx();
        data = reportStopAcceptedFiltered(data);
        break;
      case 2:
        documentCreator = new StudentProjectAcceptListDocx();
        data = memberAcceptedFiltered(data);
        break;
      case 3:
        documentCreator = new StudentProjectAdjustDecisionDocx();
        data = reportEnsureFiltered(data);
        break;
      case 4:
        documentCreator = new StudentProjectStopListDocx();
        data = reportStopAcceptedFiltered(data);
        break;
      case 5:
        documentCreator = new StudentProjectAdjustListDocx();
        data = reportEnsureFiltered(data);
        break;
      default:
        documentCreator = new StudentProjectListDocx();
        data = memberAcceptedFiltered(data);
        break;
    }

    const doc = documentCreator.create(data);

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${name}.docx`);
    });
  };

  return (
    <table style={{ borderCollapse: "collapse" }}>
      <thead style={{ borderBottom: "solid 1px #e0e0e0" }}>
        <tr className={styles.head}>
          <th style={{ textAlign: "start", width: 170 }}>Phân loại</th>
          <th style={{ textAlign: "start", width: "calc(100% - 490px)" }}>Văn bản xuất</th>
          <th style={{ textAlign: "start", width: 200 }}>Người xuất</th>
          <th style={{ textAlign: "start", width: 150 }}>Tác vụ</th>
        </tr>
      </thead>
      <tbody>
        <tr className={styles.row}>
          <td rowSpan="2">Quản lý đăng ký</td>
          <td>Danh mục các đề tài sinh viên</td>
          <td>Trưởng khoa</td>
          <td>{downloadButton(0, "Danh mục các đề tài sinh viên")}</td>
        </tr>
        <tr className={styles.row}>
          <td>Danh mục các đề tài sinh viên NCKH</td>
          <td>P. KHCN</td>
          <td>
            <Grid container>
              <Grid item xs={4}>
                {downloadButton(2, "Danh mục các đề tài sinh viên NCKH")}
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  size="small"
                  color="secondary"
                  title="Upload file văn bản kèm chữ ký của hiệu trưởng"
                  onClick={() => input0.current.click()}
                >
                  <Publish size="small" />
                </IconButton>
                <input
                  type='file'
                  ref={input0}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    handleUploadFile('VB3', event);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <IconButton size="small" disabled={!fileList?.VB3} title="Xem file văn bản kèm chữ ký của hiệu trưởng">
                  <a href={`${process.env.REACT_APP_DOMAIN}${fileList?.VB3}`} target="_blank" rel="noreferrer">
                    <Visibility size="small" />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </td>
        </tr>
        <tr className={styles.row}>
          <td rowSpan="4">Báo cáo tiến độ</td>
          <td>Danh mục các đề tài sinh viên dừng triển khai</td>
          <td rowSpan="2">Trưởng khoa</td>
          <td>{downloadButton(4, "Danh mục các đề tài sinh viên dừng triển khai")}</td>
        </tr>
        <tr className={styles.row}>
          <td>Danh mục các đề tài sinh viên sau khi điều chỉnh bổ sung</td>
          <td>{downloadButton(5, "Danh mục các đề tài sinh viên sau khi điều chỉnh bổ sung")}</td>
        </tr>
        <tr className={styles.row}>
          <td>Quyết định phê duyệt các đề tài sinh viên dừng triển khai</td>
          <td rowSpan="2">P. KHCN</td>
          <td>{downloadButton(1, "Quyết định phê duyệt các đề tài sinh viên dừng triển khai")}</td>
        </tr>
        <tr className={styles.row}>
          <td>Quyết định phê duyệt các đề tài sau khi được bổ sung, điều chỉnh</td>
          <td>{downloadButton(3, "Quyết định phê duyệt các đề tài sau khi được bổ sung, điều chỉnh")}</td>
        </tr>
        <tr className={styles.row}>
          <td></td>
          <td>Danh sách giới thiệu hội đồng nghiệm thu đề tài</td>
          <td>P. KHCN</td>
          <td>
          <IconButton size="small" color="primary" title="Tải xuống" onClick={() => {
            const loadFile = (url, callback) => PizZipUtils.getBinaryContent(url, callback);

            loadFile(dshdnt, (error, content) => {
              if (error) { throw error; }

              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, { linebreaks: true, paragraphLoop: true });

              const _list = list.filter((e) => !!e.councilMemberInfo);

              const data = {
                department: departments.find((e) => e.id === props.chosenDepartmentId)?.name,
                hasCouncilInfo: _list.length > 0,
                schoolYear: props.year,
                formCouncilInfo: _list.map((p, id) => ({
                  index: id + 1,
                  mainStudent: p.researchMembers
                    .filter((member) => member?.leaveReason === "")
                    .filter((m) => m.researchRole === "main")
                    .map((m) => ({
                      name: m.name,
                      academicYear: m.academicYear,
                      class: m.class,
                  })),
                  otherMember: p.researchMembers
                    .filter((member) => member?.leaveReason === "")
                    .filter((m) => m.researchRole === "other")
                    .map((m) => ({
                      name: m.name,
                      academicYear: m.academicYear,
                      class: m.class,
                  })),
                  instructor: p.instructors.map((i) => ({
                    degree: i.degree,
                    name: i.name,
                  })),
                  ...p.councilMemberInfo,
                  name: p.name,
                })),
                numberOfCouncils: _list.length,
              }
              data.formCouncilInfo.forEach((info) => {
                Object.keys(info).forEach((key) => {
                  if (key.toLowerCase().includes("degree")) info[key] = DegreeAbbrv(info[key])
                })
                info.instructor.forEach((i) => {
                  Object.keys(i).forEach((key) => {
                    if (key.toLowerCase().includes("degree")) i[key] = DegreeAbbrv(i[key])
                  })
                });
              });
              doc.render(data);

              const blob = doc.getZip().generate({
                type: "blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
          
              saveAs(blob, "Danh sách giới thiệu hội đồng nghiệm thu đề tài.docx");
            });

          }}>
            <GetApp size="small" />
          </IconButton>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ExportForm;
