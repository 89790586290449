import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllSolution = () => async (dispatch) => {
  const api = API_URLS.SOLUTION.getAllSolution();
  dispatch({
    type: TYPE.SOLUTION.GET_ALL_SOLUTION_LOADING,
    meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SOLUTION.GET_ALL_SOLUTION_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.SOLUTION.GET_ALL_SOLUTION_FAILURE,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getAllSolutionById = (id) => async (dispatch) => {
  const api = API_URLS.SOLUTION.getAllSolutionById(id);
  dispatch({
    type: TYPE.SOLUTION.GET_ALL_SOLUTION_BY_ID_LOADING,
    meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SOLUTION.GET_ALL_SOLUTION_BY_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.SOLUTION.GET_ALL_SOLUTION_BY_ID_FAILURE,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getFilteredSolution = (page, pageSize, payload) => async (dispatch) => {
  const api = API_URLS.SOLUTION.getFilteredSolution(page, pageSize);
  dispatch({
    type: TYPE.SOLUTION.GET_FILTERED_SOLUTION_LOADING,
    meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SOLUTION.GET_FILTERED_SOLUTION_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.SOLUTION.GET_FILTERED_SOLUTION_FAILURE,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getSolutionById = (exist, id) => async (dispatch) => {
  if (exist) {
    dispatch({
      type: TYPE.SOLUTION.GET_SOLUTION_BY_ID_SUCCESS,
      payload: id,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
  } else {
    const api = API_URLS.SOLUTION.getSolutionById(id);
    dispatch({
      type: TYPE.SOLUTION.GET_SOLUTION_BY_ID_LOADING,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
    });
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success) {
      dispatch({
        type: TYPE.SOLUTION.GET_SOLUTION_BY_ID_SUCCESS,
        payload: response.data.data,
        meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
      });
    } else {
      dispatch({
        type: TYPE.SOLUTION.GET_SOLUTION_BY_ID_FAILURE,
        meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
      });
    }
  }
};

export const insertSolution = (profileId, payload) => async (dispatch) => {
  const api = API_URLS.SOLUTION.insertSolution();
  dispatch({
    type: TYPE.SOLUTION.INSERT_SOLUTION_LOADING,
    meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SOLUTION.INSERT_SOLUTION_SUCCESS,
      id: profileId,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thêm dữ liệu thành công!'
    }));
    return({success: true, data: response.data.data})
  } else {
    dispatch({
      type: TYPE.SOLUTION.INSERT_SOLUTION_FAILURE,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thêm dữ liệu thất bại!'
    }));
    return({success: false, data: null})
  }
};

export const updateSolution = (id, payload) => async (dispatch) => {
  const api = API_URLS.SOLUTION.updateSolution(id);
  dispatch({
    type: TYPE.SOLUTION.UPDATE_SOLUTION_LOADING,
    meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SOLUTION.UPDATE_SOLUTION_SUCCESS,
      id,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Cập nhật dữ liệu thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.SOLUTION.UPDATE_SOLUTION_FAILURE,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Cập nhật dữ liệu thất bại!'
    }));
    return(false);
  }
};

export const deleteSolution = (id) => async (dispatch) => {
  const api = API_URLS.SOLUTION.deleteSolution(id);
  dispatch({
    type: TYPE.SOLUTION.DELETE_SOLUTION_LOADING,
    meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SOLUTION.DELETE_SOLUTION_SUCCESS,
      id,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xoá dữ liệu thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.SOLUTION.DELETE_SOLUTION_FAILURE,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xoá dữ liệu thất bại!'
    }));
    return(false);
  }
};

export const acceptSolution = (id, accept) => async (dispatch) => {
  const api = API_URLS.SOLUTION.acceptSolution(id, accept);
  dispatch({
    type: TYPE.SOLUTION.ACCEPT_SOLUTION_LOADING,
    meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SOLUTION.ACCEPT_SOLUTION_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xác nhận thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.SOLUTION.ACCEPT_SOLUTION_FAILURE,
      meta: { prefix: [PREFIX.SOLUTION, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xác nhận thất bại!'
    }));
    return(false);
  }
};
