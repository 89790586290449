import React from 'react';
import { Group, RateReview } from '@material-ui/icons';
import { Box, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { KPTextfield, KPTextform } from '../../../../components/KPTComponents';

import theme from '../../../../theme';
import { updateInternalResearch } from '../../../../reducer/internalResearch/action';

function CouncilForm(props) {
  const dispatch = useDispatch();

  const {
    values, setValues,
    council, setCouncil,
    councilApp, setCouncilApp,
    handleClose,
  } = props;

  const handleChangeForm = (key, val) => {
    const keyList = key.split('.');
    const check = keyList[0] === 'council';
    const realKey = keyList[1];
    if (check) {
      setCouncil({ ...council, [realKey]: val });
    } else {
      setCouncilApp({ ...councilApp, [realKey]: val });
    }
  }

  const handleSubmit = () => {
    props.handleClose();
    dispatch(updateInternalResearch({
      ...props.detail,
      internalCouncilInfos: props.detail.researchType === 'basic' ? [
          ...props.detail.internalCouncilInfos?.filter((e) => e?.name !== 'project' && e?.name !== 'application'),
          { ...council, name: 'project' },
        ] : [
          ...props.detail.internalCouncilInfos?.filter((e) => e?.name !== 'project' && e?.name !== 'application'),
          { ...council, name: 'project' },
          { ...councilApp, name: 'application' },
        ],
      status: 'info_accepted,info_council'
    }));
  }

  const textForm = [
    { key: 'box1', xs: 12, type: 'render', render: () => (
      <Box>
        <Box border='solid 1px #bcbcbc' borderRadius={4}>
          <Box
            display='flex'
            bgcolor='#ececec'
            padding='8px 16px'
            alignItems='center'
            borderBottom='solid 1px #bcbcbc' 
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
            <Typography variant='h6' style={{ color: theme.palette.primary.main }}>
              {/* <b>{`DANH SÁCH HỘI ĐỒNG XÉT CHỌN ${values?.researchType === 'applicational' ? 'SẢN PHẨM' : ''}`}</b> */}
              <b>DANH SÁCH HỘI ĐỒNG XÉT CHỌN</b>
            </Typography>
          </Box>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems='center' style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
                  <Grid item xs={4}>
                    <Typography variant='body1'><b>Tên thành viên</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Đơn vị</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1'><b>Vai trò</b></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <KPTextfield id='council.chairmanName' value={council?.chairmanName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.chairmanDegree' value={council?.chairmanDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.chairmanUnit' value={council?.chairmanUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Chủ tịch' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.secretaryName' value={council?.secretaryName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secretaryDegree' value={council?.secretaryDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secretaryUnit' value={council?.secretaryUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Thư ký' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.financeCommissionerName' value={council?.financeCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.financeCommissionerDegree' value={council?.financeCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.financeCommissionerUnit' value={council?.financeCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên thẩm định tài chính' disabled={true} />
                  </Grid>
                  {/* Ủy viên 1 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.firstCommissionerName' value={council?.firstCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstCommissionerDegree' value={council?.firstCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstCommissionerUnit' value={council?.firstCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 1' disabled={true} />
                  </Grid>
                  {/* Ủy viên 2 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.secondCommissionerName' value={council?.secondCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondCommissionerDegree' value={council?.secondCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondCommissionerUnit' value={council?.secondCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 2' disabled={true} />
                  </Grid>
                  {/* Ủy viên 3 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.thirdCommissionerName' value={council?.thirdCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdCommissionerDegree' value={council?.thirdCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdCommissionerUnit' value={council?.thirdCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 3' disabled={true} />
                  </Grid>
                  {/* Ủy viên 4 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.fourthCommissionerName' value={council?.fourthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fourthCommissionerDegree' value={council?.fourthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fourthCommissionerUnit' value={council?.fourthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 4' disabled={true} />
                  </Grid>
                  {/* Ủy viên 5 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.fifthCommissionerName' value={council?.fifthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fifthCommissionerDegree' value={council?.fifthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fifthCommissionerUnit' value={council?.fifthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 5' disabled={true} />
                  </Grid>
                  {/* Ủy viên 6 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.sixthCommissionerName' value={council?.sixthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.sixthCommissionerDegree' value={council?.sixthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.sixthCommissionerUnit' value={council?.sixthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 6' disabled={true} />
                  </Grid>
                  {/* Ủy viên 7 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.seventhCommissionerName' value={council?.seventhCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.seventhCommissionerDegree' value={council?.seventhCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.seventhCommissionerUnit' value={council?.seventhCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 7' disabled={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
        <Box border='solid 1px #bcbcbc' marginTop={4} borderRadius={4}>
          <Box
            display='flex'
            bgcolor='#ececec'
            padding='8px 16px'
            alignItems='center'
            borderBottom='solid 1px #bcbcbc' 
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <RateReview style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
            <Typography variant='h6' style={{ color: theme.palette.primary.main }}>
              {/* <b>{`DANH SÁCH PHẢN BIỆN ${values?.researchType === 'applicational' ? '(HỘI ĐỒNG XÉT CHỌN SẢN PHẨM)' : ''}`}</b> */}
              <b>DANH SÁCH PHẢN BIỆN</b>
            </Typography>
          </Box>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
                  <Grid item xs={4}>
                    <Typography variant='body1'><b>Tên thành viên</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Đơn vị</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1'><b>Vai trò</b></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <KPTextfield id='council.firstReviewerName' value={council?.firstReviewerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstReviewerDegree' value={council?.firstReviewerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstReviewerUnit' value={council?.firstReviewerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 1' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.secondReviewerName' value={council?.secondReviewerName} handleChangeForm={handleChangeForm}/>
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondReviewerDegree' value={council?.secondReviewerDegree} handleChangeForm={handleChangeForm}/>
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondReviewerUnit' value={council?.secondReviewerUnit} handleChangeForm={handleChangeForm}/>
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 2' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.thirdReviewerName' value={council?.thirdReviewerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdReviewerDegree' value={council?.thirdReviewerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdReviewerUnit' value={council?.thirdReviewerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 3' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.fourthReviewerName' value={council?.fourthReviewerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fourthReviewerDegree' value={council?.fourthReviewerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fourthReviewerUnit' value={council?.fourthReviewerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 4' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.fifthReviewerName' value={council?.fifthReviewerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fifthReviewerDegree' value={council?.fifthReviewerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fifthReviewerUnit' value={council?.fifthReviewerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 5' disabled={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    )},
  ];

  // if (values?.researchType === 'applicational') {
  //   textForm.push({ key: 'box3', xs: 12, type: 'render', render: () => (
  //     <Box
  //       maxHeight='calc(100vh - 216px)'
  //     >
  //       <Box border='solid 1px #bcbcbc' borderRadius={4} marginTop={4}>
  //         <Box
  //           display='flex'
  //           bgcolor='#ececec'
  //           padding='8px 16px'
  //           alignItems='center'
  //           borderBottom='solid 1px #bcbcbc' 
  //           style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
  //         >
  //           <Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
  //           <Typography variant='h6' style={{ color: theme.palette.primary.main }}>
  //             <b>DANH SÁCH HỘI ĐỒNG XÉT CHỌN ĐỀ TÀI</b>
  //           </Typography>
  //         </Box>
  //         <Box padding={2}>
  //           <Grid container spacing={2}>
  //             <Grid item xs={12}>
  //               <Grid container spacing={2} alignItems='center' style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
  //                 <Grid item xs={4}>
  //                   <Typography variant='body1'><b>Tên thành viên</b></Typography>
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <Typography variant='body1'><b>Đơn vị</b></Typography>
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <Typography variant='body1'><b>Vai trò</b></Typography>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //             <Grid item xs={12}>
  //               <Grid container spacing={2}>
  //                 <Grid item xs={4}>
  //                   <KPTextfield id='councilApp.chairmanName' value={councilApp?.chairmanName} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.chairmanDegree' value={councilApp?.chairmanDegree} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.chairmanUnit' value={councilApp?.chairmanUnit} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <KPTextfield value='Chủ tịch' disabled={true} />
  //                 </Grid>
  //                 <Grid item xs={4}>
  //                   <KPTextfield id='councilApp.secretaryName' value={councilApp?.secretaryName} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.secretaryDegree' value={councilApp?.secretaryDegree} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.secretaryUnit' value={councilApp?.secretaryUnit} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <KPTextfield value='Thư ký' disabled={true} />
  //                 </Grid>
  //                 <Grid item xs={4}>
  //                   <KPTextfield id='councilApp.ReviewerName' value={councilApp?.ReviewerName} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.commissionerDegree' value={councilApp?.commissionerDegree} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.commissionerUnit' value={councilApp?.commissionerUnit} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <KPTextfield value='Uỷ viên' disabled={true} />
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Box>
  //       </Box>
        
  //       <Box border='solid 1px #bcbcbc' marginTop={4} borderRadius={4}>
  //         <Box
  //           display='flex'
  //           bgcolor='#ececec'
  //           padding='8px 16px'
  //           alignItems='center'
  //           borderBottom='solid 1px #bcbcbc' 
  //           style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
  //         >
  //           <RateReview style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
  //           <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>DANH SÁCH PHẢN BIỆN (HỘI ĐỒNG XÉT CHỌN ĐỀ TÀI)</b></Typography>
  //         </Box>
  //         <Box padding={2}>
  //           <Grid container spacing={2}>
  //             <Grid item xs={12}>
  //               <Grid container spacing={2} style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
  //                 <Grid item xs={4}>
  //                   <Typography variant='body1'><b>Tên thành viên</b></Typography>
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <Typography variant='body1'><b>Đơn vị</b></Typography>
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <Typography variant='body1'><b>Vai trò</b></Typography>
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //             <Grid item xs={12}>
  //               <Grid container spacing={2}>
  //                 <Grid item xs={4}>
  //                   <KPTextfield id='councilApp.firstCommissionerName' value={councilApp?.firstCommissionerName} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.firstCommissionerDegree' value={councilApp?.firstCommissionerDegree} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.firstCommissionerUnit' value={councilApp?.firstCommissionerUnit} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <KPTextfield value='Phản biện 1' disabled={true} />
  //                 </Grid>
  //                 <Grid item xs={4}>
  //                   <KPTextfield id='councilApp.secondCommissionerName' value={councilApp?.secondCommissionerName} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.secondCommissionerDegree' value={councilApp?.secondCommissionerDegree} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.secondCommissionerUnit' value={councilApp?.secondCommissionerUnit} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <KPTextfield value='Phản biện 2' disabled={true} />
  //                 </Grid>
  //                 <Grid item xs={4}>
  //                   <KPTextfield id='councilApp.thirdCommissionerName' value={councilApp?.thirdCommissionerName} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.thirdCommissionerDegree' value={councilApp?.thirdCommissionerDegree} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={3}>
  //                   <KPTextfield id='councilApp.thirdCommissionerUnit' value={councilApp?.thirdCommissionerUnit} handleChangeForm={handleChangeForm} />
  //                 </Grid>
  //                 <Grid item xs={2}>
  //                   <KPTextfield value='Phản biện 3' disabled={true} />
  //                 </Grid>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Box>
  //       </Box>
  //     </Box>
  //   )})
  // }

  return (
    <KPTextform
      id={props.id}
      textForm={textForm}
      values={values}
      setValues={setValues}
      handleChangeForm={handleChangeForm}
      handleSubmit={handleSubmit}
    />
  );
}

export default CouncilForm;
