import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllConference = () => async (dispatch) => {
  const api = API_URLS.CONFERENCE.getAllConference();
  dispatch({
    type: TYPE.CONFERENCE.GET_ALL_CONFERENCE_LOADING,
    meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.CONFERENCE.GET_ALL_CONFERENCE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.CONFERENCE.GET_ALL_CONFERENCE_FAILURE,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const insertConference = (payload) => async (dispatch) => {
  const api = API_URLS.CONFERENCE.insertConference();
  dispatch({
    type: TYPE.CONFERENCE.INSERT_CONFERENCE_LOADING,
    meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.CONFERENCE.INSERT_CONFERENCE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thêm dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.CONFERENCE.INSERT_CONFERENCE_FAILURE,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thêm dữ liệu thất bại!'
    }));
  }
};

export const updateConference = (id, payload) => async (dispatch) => {
  const api = API_URLS.CONFERENCE.updateConference(id);
  dispatch({
    type: TYPE.CONFERENCE.UPDATE_CONFERENCE_LOADING,
    meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.CONFERENCE.UPDATE_CONFERENCE_SUCCESS,
      id,
      payload: response.data.data,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Cập nhật dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.CONFERENCE.UPDATE_CONFERENCE_FAILURE,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Cập nhật dữ liệu thất bại!'
    }));
  }
};

export const deleteConference = (id) => async (dispatch) => {
  const api = API_URLS.CONFERENCE.deleteConference(id);
  dispatch({
    type: TYPE.CONFERENCE.DELETE_CONFERENCE_LOADING,
    meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.CONFERENCE.DELETE_CONFERENCE_SUCCESS,
      id,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xoá dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.CONFERENCE.DELETE_CONFERENCE_FAILURE,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xoá dữ liệu thất bại!'
    }));
  }
};

export const importConferenceByYear = (year, file) => async (dispatch) => {
  const api = API_URLS.CONFERENCE.importConferenceByYear(year);
  dispatch({
    type: TYPE.CONFERENCE.IMPORT_CONFERENCE_BY_YEAR_LOADING,
    meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_CALLING] },
  });
  var formData = new FormData();
  formData.append("conferenceFile", file[0]);
  const { response, error } = await apiCall({ ...api, payload: formData });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.CONFERENCE.IMPORT_CONFERENCE_BY_YEAR_SUCCESS,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Import dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.CONFERENCE.IMPORT_CONFERENCE_BY_YEAR_FAILURE,
      meta: { prefix: [PREFIX.CONFERENCE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: response.data.message
    }));
  }
};