import dayjs from "dayjs";
import React from "react";
import {
  Edit,
  Delete,
  TurnedIn,
  GroupAdd,
  Equalizer,
  Visibility,
  CheckCircle,
  MoreHoriz,
} from "@material-ui/icons";
import { Button, Tooltip, MenuItem, Menu } from "@material-ui/core";

import { compareTwoArray } from "../../../utils/compareTwoArray";

function ActionButton(props) {
  const { actions, row, parent, setOpen, setDetail } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMoreButtons = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const render = (type, index) => {
    let tooltipTitle,
      icon,
      clName,
      color = "primary";

    switch (type) {
      case "detail":
      case "detailCouncil":
      case "detailAdjusted":
        tooltipTitle = "Chi tiết";
        icon = <Visibility fontSize="small" />;
        break;
      case "edit":
        tooltipTitle = "Chỉnh sửa";
        icon = <Edit fontSize="small" />;
        break;
      case "editBasic":
        tooltipTitle = "Chỉnh sửa";
        icon = <Edit fontSize="small" />;
        break;
      case "disabled":
        tooltipTitle = "disabled";
        icon = <Visibility style={{ color: "#ffffff00" }} fontSize="small" />;
        break;
      case "delete":
        clName = "error-button";
        tooltipTitle = "Xóa";
        icon = <Delete fontSize="small" />;
        break;
      case "acceptInfo":
      case "acceptInfoV2":
        tooltipTitle = "Duyệt đề tài";
        icon = <CheckCircle fontSize="small" />;
        break;
      case "acceptInfoAgain":
      case "acceptInfoAgainV2":
        tooltipTitle = "Duyệt đề tài";
        icon = <CheckCircle fontSize="small" />;
        break;
      case "insertReviewer":
        clName = "success-button";
        tooltipTitle = "Thêm phản biện";
        icon = <GroupAdd fontSize="small" />;
        break;
      case "report":
        clName = "success-button";
        tooltipTitle = "Báo cáo tiến độ";
        icon = <Equalizer fontSize="small" />;
        break;
      case "reportStop":
        clName = "error-button";
        tooltipTitle = "Chi tiết đơn xin dừng";
        icon = <Equalizer fontSize="small" />;
        break;
      case "reportAccept":
        clName = "success-button";
        tooltipTitle = "Duyệt báo cáo tiến độ";
        icon = <Equalizer fontSize="small" />;
        break;
      case "reportDetail":
        color = "secondary";
        tooltipTitle = "Chi tiết báo cáo tiến độ";
        icon = <Equalizer fontSize="small" />;
        break;
      case "submit":
        clName = "success-button";
        tooltipTitle = "Nộp báo cáo";
        icon = <TurnedIn style={{ fontSize: 20 }} />;
        break;
      case "detailAdjust":
        tooltipTitle = "Chỉnh sửa, bổ sung";
        icon = <Edit fontSize="small" />;
        break;
      case "submitDetail":
        // case 'submitAccept':
        clName = "success-button";
        tooltipTitle = "Xác nhận";
        icon = <TurnedIn fontSize="small" />;
        break;
      case "insertCouncil":
        clName = "success-button";
        tooltipTitle = "Thêm hội đồng nghiệm thu";
        icon = <GroupAdd fontSize="small" />;
        break;
      case "councilDetail":
        color = "secondary";
        tooltipTitle = "Xem lại hội đồng nghiệm thu";
        icon = <GroupAdd fontSize="small" />;
        break;
      case "submitAccept":
        clName = "success-button";
        tooltipTitle = "Nghiệm thu đề tài";
        icon = <TurnedIn style={{ fontSize: 20 }} />;
        break;
      case "submitDetailEdit":
        clName = "success-button";
        tooltipTitle = "Nộp báo cáo";
        icon = <TurnedIn style={{ fontSize: 20 }} />;
        break;
      case "defaultDetail":
      case "defaultDetailNotCouncil":
        color = "secondary";
        tooltipTitle = "Chi tiết báo cáo tổng kết";
        icon = <TurnedIn style={{ fontSize: 20 }} />;
        break;
      default:
        break;
    }

    const renderButton = () => (
      <Button
        color={color}
        variant="contained"
        className={clName}
        disabled={type === "disabled"}
        style={{
          width: 48,
          minWidth: 48,
          height: 32,
          padding: 0,
          marginLeft: index === 0 ? 0 : 8,
        }}
        onClick={() => {
          setOpen({ [type]: true });
          if (type === "reportDetail" || type === "reportAccept") {
            const days = dayjs(row?.endTime).diff(
              dayjs(parent?.endTime),
              "days"
            );
            const months = dayjs(row?.endTime).diff(
              dayjs(parent?.endTime),
              "months"
            );
            const timeChange = {
              num: months > 0 ? months : days,
              unit: months > 0 ? "month" : "day",
            };

            const memberChange = compareTwoArray(
              parent?.participants,
              row?.participants,
              ["id", "email", "phone"]
            );

            props.setDetail({
              ...row,
              vietnameseNameOld: parent?.vietnameseName,
              englishNameOld: parent?.englishName,
              vietnameseNameChange:
                row?.vietnameseName !== parent?.vietnameseName,
              englishNameChange: row?.englishName !== parent?.englishName,
              timeChange,
              isTimeChange: timeChange?.num > 0,
              nameChange:
                row?.vietnameseName !== parent?.vietnameseName ||
                row?.englishName !== parent?.englishName,
              participantsOld: !memberChange ? parent?.participants : undefined,
            });
          } else if (
            type === "detail" &&
            row.status.find((e) => e === "report_submitted")
          ) {
            const parent = props.rows.find((e) => row.parent === e.id);
            props.setDetail({ ...parent });
          } else {
            props.setDetail({ ...row });
          }
        }}
      >
        {icon}
      </Button>
    );

    const renderButtonCaseMore = (typeItem) => {
      switch (typeItem) {
        case "editBasic":
          tooltipTitle = "Chỉnh sửa";
          icon = <Edit fontSize="small" />;
          break;
        case "delete":
          clName = "error-button";
          tooltipTitle = "Xóa";
          icon = <Delete fontSize="small" />;
          break;
        default:
          break;
      }

      return (
        <Button
          color={color}
          variant="contained"
          className={clName}
          disabled={typeItem === "disabled"}
          style={{
            width: 48,
            minWidth: 48,
            height: 32,
            padding: 0,
            marginLeft: index === 0 ? 0 : 8,
          }}
          onClick={() => {
            setOpen({ [typeItem]: true });
            props.setDetail({ ...row });
          }}
        >
          {icon}
        </Button>
      );
    };

    const arrayButtonsRender = () => {
      return (
        <>
          <Button
            style={{
              width: 48,
              minWidth: 48,
              height: 32,
              padding: 0,
              marginLeft: index === 0 ? 0 : 8,
            }}
            variant="contained"
            onClick={handleOpenMoreButtons}
          >
            <MoreHoriz />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMoreMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {type.map((typeItem) => (
              <MenuItem onClick={handleCloseMoreMenu}>
                {renderButtonCaseMore(typeItem)}
              </MenuItem>
            ))}
          </Menu>
        </>
      );
    };

    const normalButtonRender = () => {
      return (
        <>
          {type === "disabled" ? (
            renderButton()
          ) : (
            <Tooltip title={tooltipTitle}>{renderButton()}</Tooltip>
          )}
        </>
      );
    };

    return (
      <>
        {typeof type === "string" ? normalButtonRender() : arrayButtonsRender()}
      </>
    );
  };

  return (
    <>
      {actions.map((e, id) => (
        <React.Fragment key={`button-${id}`}>{render(e, id)}</React.Fragment>
      ))}
    </>
  );
}

export default ActionButton;
