import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getCTKHStatistic } from '../../../reducer/statistic/action';

function CTKHStatistic(props) {
  const ref = useRef(null);

  const thisDepartmentProfile =
    props.departmentList.find((e) => e?.id === props?.chosenDepartmentId)?.departmentProfile;

  const checkBelongsToDepartment = (profileList) => {
    if (props?.chosenDepartmentId === 0) return true;

    for (var i = 0; i < profileList.length; i++) {
      const check = thisDepartmentProfile?.find((e) => e?.profileId === profileList[i]?.profileId)
      if (check) return true;
    }

    return false;
  }

  useEffect (() => {
    props.getCTKHStatistic(1, -1, { 
      // accepted: true,
      acceptedOne: true,
    })
  }, [])

  const renderStatistic = () => {
    var statisticNumber = {
      labels: [
        'Công bố khoa học',
        'Giải pháp hữu ích',
        'Bằng sáng chế',
        'Sách',
      ],
      datasets: [
        {
          label: 'Thống kê toàn bộ sản phẩm khoa học',
          data: [],
          backgroundColor: [
            '#f4642320',
            '#24347420',
            '#60ae7d20',
            '#F5222220',
          ],
          borderColor: [
            '#f46423',
            '#243474',
            '#60ae7d',
            '#F52222',
          ],
          borderWidth: 1,
        },
      ],
    }

    var cc = null;

    cc = props.dataScientificResearch?.filter((e) => 
      checkBelongsToDepartment(e?.scientificResearch?.scientificResearchProfile)
    );
    statisticNumber.datasets[0].data.push(cc?.length || 0);

    cc = props.dataSolution?.filter((e) => checkBelongsToDepartment(e?.solution?.solutionProfile));
    statisticNumber.datasets[0].data.push(cc?.length || 0);

    cc = props.dataPatent?.filter((e) => checkBelongsToDepartment(e?.patent?.patentProfile));
    statisticNumber.datasets[0].data.push(cc?.length || 0);

    cc = props.dataMonograph?.filter((e) => checkBelongsToDepartment(e?.monograph?.monographProfile));
    statisticNumber.datasets[0].data.push(cc?.length || 0);

    const testOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false, position: 'bottom' },
      title: { display: true, text: 'Số lượng công bố theo năm' },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return(
      <Bar height={128} data={statisticNumber} options={testOptions}/>
    )
  }

  return(
    <div ref={ref} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {props.isFetching ? <CircularProgress /> : <>{renderStatistic()}</>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  profileDetail: select(state, 'profileReducer', 'detail'),
  departmentList: select(state, 'departmentReducer', 'departments'),
  dataScientificResearch: select(state, 'statisticReducer', 'dataScientificResearch'),
  dataSolution: select(state, 'statisticReducer', 'dataSolution'),
  dataPatent: select(state, 'statisticReducer', 'dataPatent'),
  dataMonograph: select(state, 'statisticReducer', 'dataMonograph'),
  isFetching: select(state, 'statisticReducer', 'isFetching'),
});

const mapDispatchToProps = (dispatch) => ({
  getCTKHStatistic: (page, pageSize, payload) => dispatch(getCTKHStatistic(page, pageSize, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(CTKHStatistic));