import React from 'react';
import { connect } from 'react-redux';
import { Button, Popover } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { deletePatent } from '../../../reducer/patent/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';

function PatentDeleteModal(props) {
  const {
    deleting,
    anchorEl,
    setAnchorEl,
    deletePatent,
  } = props;

  const handleSubmit = () => {
    setAnchorEl(null);
    deletePatent(deleting).then((success) => {
      if (success) {
        props.statisticUnaccepted({ departmentId: 0, profileId: props.profile?.id });
        if (props.role === 'sudo-admin') props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
      }
    });
  }

  return (
    <Popover
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div style={{ padding: 8 }}>
        <div>Xác nhận xoá?</div>
        <div style={{ float: 'right', padding: '8px 0' }}>
          <Button size='small' variant='outlined' color='secondary' onClick={() => setAnchorEl(null)} style={{ marginRight: 4 }}>Huỷ</Button>
          <Button size='small' variant='outlined' color='primary' onClick={handleSubmit}>Xác nhận</Button>
        </div>
      </div>
    </Popover>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, 'userReducer', 'role'),
  profile: select(state, 'profileReducer', 'detail'),
});

const mapDispatchToProps = (dispatch) => ({
  deletePatent: (id) => dispatch(deletePatent(id)),
  statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(PatentDeleteModal));
