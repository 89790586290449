import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTable from '../../../components/KPTable';
import {
  insertDepartment,
  getAllProfiles,
} from '../../../reducer/department/action';
import { DepartmentContext } from '../DepartmentContext';
import { Check, Replay, WarningOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    fieldContainer: {
      marginBottom: theme.spacing(2),
    },
    btnAction: {
      cursor: 'pointer',
    },
  })
);

const departmentSchema = yup.object().shape({
  name: yup.string().required('Không được để trống'),
  code: yup.string(),
  location: yup.string(),
  description: yup.string(),
  researchArea: yup.string(),
  website: yup.string(),
  foundedAt: yup.date('Ngày không hợp lệ').required('Không được để trống'),
  profileId: yup
    .number()
    // .min(1, 'Mã hồ sơ phải là số tự nhiên')
    // .required('Không được để trống'),
});

function DepartmentInsertModal(props) {
  const { allProfiles, getAllProfiles, isFetching } = props;

  const classes = useStyles();

  const { insertingDepartment, setInsertingDepartment } = useContext(
    DepartmentContext
  );

  const handleCloseModal = () => {
    setInsertingDepartment(false);
  };

  const handleReloadProfiles = () => {
    getAllProfiles();
  };

  useEffect(() => {
    getAllProfiles();
  }, [getAllProfiles]);

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth='lg'
      open={insertingDepartment}
      onClose={handleCloseModal}
    >
      <DialogTitle>Tạo đơn vị</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: '',
            code: '',
            location: '',
            description: '',
            researchArea: '',
            website: '',
            foundedAt: dayjs().format('YYYY-MM-DD'),
            profileId: 0,
          }}
          validationSchema={departmentSchema}
          onSubmit={async (values, { resetForm }) => {
            const payload = {
              name: values.name,
              code: values.code,
              location: values.location,
              description: values.description,
              researchArea: values.researchArea,
              website: values.website,
              foundedAt: dayjs(values.foundedAt).format(
                'YYYY-MM-DDThh:mm:ss[Z]'
              ),
              departmentProfile: values.profileId ? [
                {
                  profileId: values.profileId,
                  roleInDepartment: 'leader',
                },
              ] : null,
            };

            // await props.insertDepartment(payload, () => {
            //   setInsertingDepartment(false);
            // });

            await props.insertDepartment(payload).then(() => {
              setInsertingDepartment(false);
              resetForm();
            });
          }}
        >
          {(formikProps) => {
            const {
              isSubmitting,
              handleSubmit,
              setFieldValue,
              values,
            } = formikProps;

            const selectedLeaderProfile = allProfiles.find((profileElement) => {
              return profileElement.id === values.profileId;
            });

            return (
              <Form>
                <Grid container>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Typography variant='h6'>Thông tin tổng quan</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Field name='name'>
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            size='small'
                            type='text'
                            variant='outlined'
                            label='Tên'
                            helperText={<ErrorMessage name='name' />}
                            FormHelperTextProps={{ error: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Field name='code'>
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            size='small'
                            type='text'
                            variant='outlined'
                            label='Mã đơn vị'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Field name='location'>
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            size='small'
                            type='text'
                            variant='outlined'
                            label='Địa điểm'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Field name='researchArea'>
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            size='small'
                            type='text'
                            variant='outlined'
                            label='Hướng nghiên cứu chính'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Field name='foundedAt'>
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            size='small'
                            type='date'
                            variant='outlined'
                            label='Ngày thành lập'
                            helperText={<ErrorMessage name='foundedAt' />}
                            FormHelperTextProps={{ error: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Field name='website'>
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            size='small'
                            type='text'
                            variant='outlined'
                            label='Website'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Field name='description'>
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            size='small'
                            type='text'
                            multiline={true}
                            rows={2}
                            variant='outlined'
                            label='Mô tả'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                      alignItems='center'
                      marginBottom={2}
                    >
                      <Typography variant='h6'>Trưởng đơn vị</Typography>
                      <Button
                        variant='outlined'
                        startIcon={
                          isFetching ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Replay />
                          )
                        }
                        onClick={handleReloadProfiles}
                      >
                        Tải lại
                      </Button>
                    </Box>
                    {selectedLeaderProfile ? (
                      <Box marginBottom={2}>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  alignItems='center'
                                >
                                  <strong>Đã chọn</strong>
                                  <Check />
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <strong>ID</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Tên</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Bằng cấp</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Chuyên môn</strong>
                              </TableCell>
                              <TableCell>
                                <strong>Chuyên ngành</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{selectedLeaderProfile.id}</TableCell>
                              <TableCell>
                                {selectedLeaderProfile.name || 'N/A'}
                              </TableCell>
                              <TableCell>
                                {selectedLeaderProfile.degree || 'N/A'}
                              </TableCell>
                              <TableCell>
                                {selectedLeaderProfile.major || 'N/A'}
                              </TableCell>
                              <TableCell>
                                {selectedLeaderProfile.profession || 'N/A'}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    ) : (
                      <Box textAlign='center' marginBottom={2}>
                        <WarningOutlined />
                        <Typography>Chưa chọn trưởng đơn vị</Typography>
                      </Box>
                    )}
                    <KPTable
                      shadow="none"
                      rowsPerPage={10}
                      columns={[
                        // {
                        //   title: <b>ID</b>,
                        //   key: 'id',
                        //   width: '5%',
                        //   render: (value, row) => {
                        //     return <Typography>{row.id}</Typography>;
                        //   },
                        // },
                        {
                          title: <b>Tên</b>,
                          key: 'name',
                          width: '10%',
                          searchable: true,
                          render: (value, row) => {
                            return <Typography>{value}</Typography>;
                          },
                        },
                        {
                          title: <b>Bằng cấp</b>,
                          key: 'degree',
                          width: '10%',
                          render: (value) => {
                            return <Typography>{value ? value : 'N/A'}</Typography>;
                          },
                        },
                        {
                          title: <b>Chuyên ngành</b>,
                          key: 'major',
                          width: '10%',
                          render: (value) => {
                            return <Typography>{value ? value : 'N/A'}</Typography>;
                          },
                        },
                        {
                          title: <b>Chuyên môn</b>,
                          key: 'profession',
                          width: '5%',
                          render: (value) => {
                            return <Typography>{value ? value : 'N/A'}</Typography>;
                          },
                        },
                        {
                          title: <b>Tác vụ</b>,
                          key: 'action',
                          width: '5%',
                          render: (value, row) => {
                            return (
                              <Typography
                                variant='caption'
                                color='primary'
                                className={classes.btnAction}
                                onClick={() =>
                                  setFieldValue('profileId', row.id, false)
                                }
                              >
                                Chọn
                              </Typography>
                            );
                          },
                        },
                      ]}
                      rows={allProfiles}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Box textAlign='right'>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Tạo đơn vị
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  isFetching: select(state, 'departmentReducer', 'isFetching'),
  allProfiles: select(state, 'departmentReducer', 'allProfiles'),
});
const mapDispatchToProps = (dispatch) => ({
  insertDepartment: (payload) => dispatch(insertDepartment(payload)),
  getAllProfiles: () => dispatch(getAllProfiles()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(DepartmentInsertModal));
