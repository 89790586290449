/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Grid,
  Divider,
  MenuItem,
  TextField,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import select from '../../utils/select';
import { getAllDepartments } from '../../reducer/department/action';

import ListPatent from '../../containers/Home/ListPatent';
import CTKHStatistic from '../../containers/Home/CTKHStatistic';
import ListMonograph from '../../containers/Home/ListMonograph';
import ListProject from '../../containers/Home/ListProject/ListProject';
import ListSolution from '../../containers/Home/ListSolution/ListSolution';
import ScientifiResearchStatistic from '../../containers/Home/ScientificResearchStatistic';
import ListScientificResearch from '../../containers/Home/ListScientificResearch/ListScientificResearch';
import ScientifiResearchCoreCollectionStatistic from '../../containers/Home/ScientifiResearchCoreCollectionStatistic'

const useStyles = makeStyles(() =>
  createStyles({
    selectRoot: {
      fontSize: 14,
      paddingTop: 8,
      paddingBottom: 8,
    },
  })
);

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [chosenDepartmentId, setChosenDepartmentId] = useState(0);
  const [haveDepartmentsList, setHaveDepartmentsList] = useState(false);

  const url = window.location.href;
  const isGuestRoute = url.split('/').slice(-1)[0].toLowerCase().includes('guest');
  const isCNTTGuestRoute = isGuestRoute && url.split('/').slice(-1)[0].toLowerCase().includes('cntt');
  const isORlabGuestRoute = isGuestRoute && url.split('/').slice(-1)[0].toLowerCase().includes('orlab');

  const yearRange = [0];
  for (var i = parseInt(moment().format('YYYY')); i >= 2015; i--) yearRange.push(i);

  const departmentList = useSelector(state => select(state, 'departmentReducer', 'departments'));

  // Vừa vào lấy Department luôn
  useEffect(() => dispatch(getAllDepartments()), []);

  // Magic
  useEffect(() => {
    if (departmentList.length > 0) {
      setHaveDepartmentsList(true);

      // department_id của ORlab là 5
      if (isORlabGuestRoute) {
        setYear(0);
        setChosenDepartmentId(5);
      }
      // department_id của khoa CNTT là 6
      else if (isCNTTGuestRoute) {
        setYear(0);
        setChosenDepartmentId(6);
      }
    }
  }, [departmentList]);

  const renderTab = () => {
    if (tab === 0) return (
      <ListScientificResearch
        chosenYear={year}
        chosenDepartmentId={chosenDepartmentId}
        haveDepartmentsList={haveDepartmentsList}
      />
    );

    var renderElm = null;
    switch (tab) {
      case 1:
        renderElm = <ListSolution chosenYear={year} chosenDepartmentId={chosenDepartmentId}/>
        break;
      case 2:
        renderElm = <ListPatent chosenYear={year} chosenDepartmentId={chosenDepartmentId}/>
        break;
      case 3:
        renderElm = <ListMonograph chosenYear={year} chosenDepartmentId={chosenDepartmentId}/>
        break;
      case 4:
        renderElm = <ListProject chosenYear={year} chosenDepartmentId={chosenDepartmentId}/>
        break;
      default:
        return <>Rỗng</>
    }

    return <Grid item xs={12}>{renderElm}</Grid>
  }

  return (
    <Grid container spacing={1} style={{ height: '100%' }}>
      <Grid item xs={12} lg={7}>
        <Card style={{ minHeight: 'calc(100vh - 92px)', maxHeight: 'calc(100vh - 92px)' }}>
          <Grid container justifyContent='center'>
            <Grid item xs={8} sm={9}>
              <Tabs
                value={tab}
                scrollButtons='off'
                variant='scrollable'
                textColor='secondary'
                onChange={(_, value) => setTab(value)}
              >
                <Tab label='Công bố khoa học' />
                <Tab label='Giải pháp hữu ích' />
                <Tab label='Bằng sáng chế' />
                <Tab label='Sách' />
                <Tab label='Đề tài' />
              </Tabs>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3} sm={2}>
              <TextField
                select
                fullWidth
                size='small'
                variant='outlined'
                SelectProps={{
                  classes: { root: classes.selectRoot },
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    transformOrigin: { vertical: 'top', horizontal: 'center' },
                  }
                }}
                style={{ float: 'right', backgroundColor: '#fff', marginTop: 7, marginRight: 16 }}
                label='Năm'
                value={year}
                onChange={(event) => setYear(event.target.value)}
              >
                {yearRange.map((year) => <MenuItem key={`year-${year}`} value={year}>{year === 0 ? 'Tất cả' : year}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
          </Grid>
          <Grid container spacing={2} justifyContent='center' style={{ padding: 16 }}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={departmentList || []}
                getOptionLabel={(option) => option.name}
                disableClearable
                filterSelectedOptions
                disabled={isORlabGuestRoute || isCNTTGuestRoute}
                onChange={(_, val) => setChosenDepartmentId(val?.id)}
                value={departmentList?.find((e) => e.id === chosenDepartmentId) || 'Cả trường'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    label='Đơn vị'
                    variant='outlined'
                    placeholder='Cả trường'
                  />
                )}
              />
            </Grid>
            {(tab !== 0) && <Grid item xs={12}>
              <Divider style={{
                marginLeft: -16,
                backgroundColor: '#fff',
                width: 'calc(100% + 32px)',
                boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 3px',
              }} />
            </Grid>}
            {renderTab()}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={5} style={{ minHeight: 'calc(100vh - 100px)', maxHeight: 'calc(100vh - 100px)' }}>
        <Box height='33.34%'>
          <ScientifiResearchCoreCollectionStatistic chosenYear={year} chosenDepartmentId={chosenDepartmentId} />
        </Box>
        <Card style={{ marginTop: 8, padding: '0 32px', width: 'calc(100% - 64px)', height: '33.33%' }}>
          <ScientifiResearchStatistic chosenDepartmentId={chosenDepartmentId} />
        </Card>
        <Card style={{ marginTop: 8, padding: '0 32px', width: 'calc(100% - 64px)', height: '33.33%' }}>
          <CTKHStatistic chosenDepartmentId={chosenDepartmentId} />
        </Card>
      </Grid>
    </Grid>
  );
}

export default Home;
