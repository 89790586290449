import {
  Box,
  Grid,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import moment from 'moment';
import { Add, Close, Replay, Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

import select from "../../../utils/select";
import InstitutionalProjectModal from '../InstitutionalProjectModal';

import { Autocomplete } from '@material-ui/lab';
import { getAllProfile } from "../../../reducer/profile/action";
import { filterInternalResearchV2 } from '../../../reducer/internalResearch/action';
import { getAllDepartments, getAllFaculties } from '../../../reducer/department/action';
import { API_URLS } from '../../../constants/api';
import { apiCall } from '../../../utils/api';
import dayjs from 'dayjs';



function InstitutionalProjectButton(props) {
  const dispatch = useDispatch();

  const { type, year, setYear, chosenDepartmentId, setChosenDepartmentId } = props;

  const role = useSelector((state) => select(state, "userReducer", "role"));
  // const faculties = useSelector((state) => select(state, "departmentReducer", "faculties"));
  const departments = useSelector((state) => select(state, "departmentReducer", "departments"));
  const profileDetail = useSelector(state => select(state, 'profileReducer', 'detail'));
  const profileDepartments = useSelector((state) => select(state, "profileReducer", "departments"));

  const [open, setOpen] = useState(false);
  const [searchElm, setSearchElm] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [dueTime, setDueTime] = useState(null);

  const handleReload = () => {
    if (props.type === 'personal') {
      if (!isNaN(profileDetail?.id)) dispatch(filterInternalResearchV2({
        profileId: profileDetail?.id.toString(),
        schoolYear: year,
      }));
    } else if (props.type === 'admin' && chosenDepartmentId) {
      if (!isNaN(profileDetail?.id)) dispatch(
        filterInternalResearchV2({
          schoolYear: year,
          departmentId: chosenDepartmentId?.toString(),
        })
      );
    } else {
      // dispatch(getAllInternalResearch());
      if (!isNaN(profileDetail?.id)) dispatch(
        filterInternalResearchV2({ schoolYear: year })
      );
    }

    // Lấy giá trị thời gian
    const api = API_URLS.YEARLY_DUE_DATE.filterYearlyDueDate();
    apiCall({ ...api, payload: { type: 'internal', schoolYear: props.year }})
    .then(({ response, error }) => {
      if (!error && response.status === 200 && response.data.success) {
        setDueTime(response.data.data[0]);
      }
    });
  }

  // Vừa vào lấy hêt profile, lấy hết khoa luôn
  useEffect(() => {
    dispatch(getAllProfile());
    dispatch(getAllDepartments());
  }, []);

  useEffect(() => {
    let newObj;
    if (isSearching) newObj = { ...newObj, search: isSearching ? searchElm : null };
    if (newObj) props.setFilterObj(newObj);
  }, [searchElm, isSearching]);

  useEffect(() => handleReload(), [
    year,
    props.type,
    profileDetail?.id,
    chosenDepartmentId
  ]);

  const yearRange = [];
  for (var i = parseInt(moment().format('YYYY')); i >= 2019; i--) yearRange.push(i);

  // Lấy khoa mà mình làm trưởng khoa
  const personalDepartment = useMemo(() => {
    if (profileDepartments) {
      const faculty = profileDepartments.filter((department) => {
        const matchProfile = department.departmentProfile?.find(
          (item) => item.profileId === profileDetail.id
        );
        return matchProfile.roleInDepartment.toLowerCase() === "leader"
      });
      if (faculty.length > 0) {
        setChosenDepartmentId(faculty[0].id);
        return faculty;
      }
      return null;
    }
  }, [profileDepartments]);

  const handleInsert = () => setOpen({ insert: true });

  const IS_OPEN_REGISTER = dayjs(dueTime?.regStartTime) <= dayjs() && dayjs() <= dayjs(dueTime?.regEndTime);

  return (
    <>
      <Grid item xs={12}>
        <form onSubmit={(e) => { e.preventDefault(); setIsSearching(true); }}>
          <Grid container justifyContent='space-between'>
            <Grid item xs={4}>
              <Typography variant='h5' style={{ lineHeight: '40px' }}>
                <b>Đề tài Nghiên cứu khoa học cấp Cơ sở</b>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Box display='flex'>
                <Box display='flex' flexGrow={1}>
                  <Box
                    marginRight={0.5}
                    borderRadius={4}
                    width='100%'
                    display='flex'
                    alignItems='center'
                    border='solid 1px #bcbcbc'
                  >
                    <InputBase
                      placeholder='Tìm kiếm'
                      value={searchElm || ''}
                      style={{ padding: '0 12px', flexGrow: 1 }}
                      onChange={(e) => {
                        setIsSearching(false);
                        setSearchElm(e.target.value);
                      }}
                      endAdornment={searchElm && (
                        <IconButton size='small' onClick={() => {
                          setIsSearching(true);
                          setSearchElm(null);
                        }}>
                          <Close size='small' />
                        </IconButton>
                      )}
                    />
                    <Divider orientation='vertical' style={{ width: 1, height: 38 }} />
                    <Box padding='0 16px'>
                      <IconButton size='small' type='submit'>
                        <Search size='small' />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <TextField
                  select
                  size='small'
                  variant='outlined'
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                      transformOrigin: { vertical: 'top', horizontal: 'center' },
                    }
                  }}
                  label='Năm'
                  value={year}
                  onChange={(event) => setYear(event.target.value)}
                  style={{ width: 100, minWidth: 100, maxWidth: 100, margin: '0 4px' }}
                >
                  {yearRange.map((year) => <MenuItem key={`year-${year}`} value={year}>{year === 0 ? 'Tất cả' : year}</MenuItem>)}
                </TextField>
                {props.type === "admin" ? (
                  <>
                    {((props.type === "admin" && departments && chosenDepartmentId) || role === "sudo-admin") && (
                      <Autocomplete
                        disableClearable
                        options={role === "sudo-admin" ? departments : personalDepartment}
                        // disabled={role !== "sudo-admin"}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, val) => setChosenDepartmentId(val?.id)}
                        value={(role === "sudo-admin" ? departments : personalDepartment)?.find((e) => e.id === chosenDepartmentId)}
                        getOptionSelected={(option, value) => option.id === value?.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Đơn vị"
                            variant="outlined"
                            placeholder="Cả trường"
                          />
                        )}
                        style={{ width: 260 }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      variant='outlined'
                      style={{ margin: '0 4px', padding: '5px 0', minWidth: 54 }}
                      onClick={handleReload}
                    >
                      <Replay />
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      disabled={!IS_OPEN_REGISTER}
                      style={{ marginLeft: 4, padding: '5px 0', minWidth: 54 }}
                      onClick={handleInsert}
                    >
                      <Add />
                    </Button>
                  </>
                )}
                {/* <Button
                  variant='outlined'
                  style={{ margin: '0 4px', padding: '5px 0', minWidth: 54 }}
                  onClick={handleReload}
                >
                  <Replay />
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  style={{ marginLeft: 4, padding: '5px 0', minWidth: 54 }}
                  onClick={handleInsert}
                >
                  <Add />
                </Button> */}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}><Divider /></Grid>
      <InstitutionalProjectModal open={open} setOpen={setOpen} type={type} year={year} />
    </>
  );
}

export default InstitutionalProjectButton;
