import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Delete,
  DeleteForever,
  DoubleArrow,
  Group,
  HighlightOff,
  Info,
  OfflinePin,
  Replay,
  School,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";

import fileXinDung from "../../../../assets/files/DeTaiSinhVien/BMSVNCKH-08B ĐXDTHĐT.docx";
import fileCongViecHoanThanh from "../../../../assets/files/DeTaiSinhVien/BMSVNCKH-05 BCTĐCNĐT.docx";
import theme from "../../../../theme";
import select from "../../../../utils/select";

import { KPTextfield, KPTextform } from "../../../../components/KPTComponents";
import { uploadMultiFile } from "../../../../reducer/file/action";
import {
  filterStudentResearchV2,
  insertStudentResearch,
  updateStudentResearch,
  updateStudentResearchStatus,
} from "../../../../reducer/studentResearch/action";

const researchRoleInstructorOptions = [
  { key: "main", value: "main", title: "HD chính" },
  { key: "other", value: "other", title: "HD phụ" },
];

const researchRoleMemberOptions = [
  { key: "main", value: "main", title: "Chủ nhiệm" },
  { key: "other", value: "other", title: "Tham gia" },
];

function ReportForm(props) {
  const dispatch = useDispatch();

  const { files, setFiles, values, setValues, members, setMembers, instructors, setInstructors } = props;

  const profileList = useSelector((state) => select(state, "profileReducer", "list"));
  const profileDetail = useSelector((state) => select(state, "profileReducer", "detail"));
  const faculties = useSelector((state) => select(state, "departmentReducer", "faculties"));

  const [isReport, setIsReport] = useState(false);
  const [history, setHistory] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [defaultMembers, setDefaultMembers] = useState(null);

  const fieldList = [
    { key: "name", label: "Tên thành viên", xs: 3, required: true },
    {
      key: "phoneNumber",
      label: "SĐT",
      xs: 3,
      required: "only_main",
      type: "tel",
    },
    {
      key: "departmentId",
      label: "Khoa",
      xs: 6,
      type: "autoComplete",
      options: faculties,
      required: true,
    },
    { key: "class", label: "Lớp", xs: 2, required: true },
    { key: "email", label: "Email", xs: 4, required: "only_main" },
    { key: "academicYear", label: "Khóa", xs: 2, required: true },
    { key: "studentId", label: "MSSV", xs: 2, required: true },
  ];

  const viewHistory = (key) => {
    if (values?.[`${key}Change`])
      setHistory({
        before: values?.[`${key}Old`],
        after: values?.[key],
      });
  };

  const getStateColor = (member) =>
    member?.isStop ? "#b52f2f" : member?.isNew ? theme.palette.secondary.main : theme.palette.primary.main;

  useEffect(() => {
    setDefaultValues(values);
    setDefaultMembers(members);
    setIsReport(!!props.open?.report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReload = () => {
    if (props.type === "personal" && profileDetail?.id) {
      dispatch(
        filterStudentResearchV2({
          schoolYear: props.year,
          profileId: profileDetail.id.toString(),
        })
      );
    } else if (props.type === "mod") {
      dispatch(filterStudentResearchV2({ schoolYear: props.year }));
    }
  };

  const handleChangeForm = (key, val, type) => {
    if (key?.includes("members")) type = "memberField";
    if (key?.includes("instructors")) type = "instructorField";

    if (type === "memberField") {
      const keyList = key.split(".");
      const index = parseInt(keyList[1]);
      const fieldKey = keyList?.[2];
      const value = [...members];

      if (fieldKey !== "leaveFileLink" || (fieldKey === "leaveFileLink" && typeof val === "string")) {
        if (fieldKey === "researchRole") {
          value.forEach((e, id) => (e.researchRole = index === id ? "main" : "other"));
        } else {
          if (fieldKey === "departmentId") {
            value.splice(index, 1, { ...members[index], departmentId: val.id });
          } else value.splice(index, 1, { ...members[index], [fieldKey]: val });
        }
        setMembers(value);
      } else if (fieldKey === "leaveFileLink" && val.length > 0) {
        setFiles({ ...files, [key]: val[0] });
      }
    } else if (type === "instructorField") {
      const keyList = key.split(".");
      const index = parseInt(keyList[1]);
      const fieldKey = keyList?.[2] || "all";
      const value = [...instructors];

      if (fieldKey === "all")
        value.splice(index, 1, {
          ...value[index],
          name: val?.name,
          email: val?.mail,
          profileId: val?.id,
        });
      else if (fieldKey === "isPhenikaa")
        value.splice(index, 1, {
          ...value[index],
          [fieldKey]: val,
          name: undefined,
          email: undefined,
          profileId: undefined,
        });
      else if (fieldKey === "researchRole") {
        value.forEach((e, id) => (e.researchRole = index === id ? "main" : "other"));
        setValues(defaultValues);
        setMembers(defaultMembers);
        setDisabled(true);
        props.setStep(2);
      } else value.splice(index, 1, { ...value[index], [fieldKey]: val });

      setInstructors(value);
    } else if (type === "file") {
      if (val[0]) setFiles({ ...files, [key]: val[0] });
    } else {
      setValues({ ...values, [key]: val });
    }
  };

  const textForm = [
    {
      key: "box1",
      xs: 12,
      type: "render",
      render: () => (
        <Box border="solid 1px #bcbcbc" borderRadius={4}>
          <Box
            display="flex"
            bgcolor="#ececec"
            padding="8px 16px"
            alignItems="center"
            borderBottom="solid 1px #bcbcbc"
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <Info
              style={{
                marginRight: 12,
                marginTop: 2,
                color: theme.palette.primary.main,
              }}
            />
            <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
              <b>THÔNG TIN CƠ BẢN</b>
            </Typography>
          </Box>
          <Box padding={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box position="relative">
                  <KPTextfield
                    id="name"
                    label="Tên đề tài"
                    value={values?.name || ""}
                    handleChangeForm={handleChangeForm}
                    required={true}
                    disabled={
                      !values?.nameChange || props.open?.detail || props.open?.reportDetail || props.open?.reportAccept
                    }
                  />
                  {(isReport || values?.nameChange) && !disabled && (
                    <Box
                      top={2}
                      right={0}
                      height={32}
                      paddingLeft="24px"
                      position="absolute"
                      // bgcolor='#bcbcbc'
                      bgcolor={theme.palette.primary.main}
                      zIndex={1}
                      style={{
                        borderTopRightRadius: 4,
                        borderTopLeftRadius: 4,
                        clipPath: "polygon(5% 0%, 100% 0, 100% 100%, 0% 100%)",
                      }}
                      display="flex"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="default"
                            style={{ color: "#fff" }}
                            checked={values?.nameChange || false}
                            disabled={props.open?.detail || props.open?.reportDetail || props.open?.reportAccept}
                            onClick={(e) => handleChangeForm("nameChange", e.target.checked)}
                          />
                        }
                        style={{ marginTop: -6 }}
                        label={
                          <Typography
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              marginTop: -2,
                            }}
                          >
                            <b>Xin điều chỉnh</b>
                          </Typography>
                        }
                      />
                      {(props.open?.detail || props.open?.reportDetail || props.open?.reportAccept) && (
                        <>
                          <Divider
                            style={{
                              marginTop: 1,
                              width: 1,
                              height: 30,
                              backgroundColor: "#fff !important",
                            }}
                          />
                          <Typography
                            className={styles.detailButton}
                            onClick={() => viewHistory("name")}
                            style={{
                              cursor: values?.nameChange ? "pointer" : "context-menu",
                              color: values?.nameChange ? "#fff" : "#fafafa",
                            }}
                          >
                            <b>Chi tiết</b>
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box position="relative">
                  <KPTextfield
                    id="researchTarget"
                    xs={12}
                    height={3}
                    label="Mục tiêu nghiên cứu"
                    value={values?.researchTarget || ""}
                    handleChangeForm={handleChangeForm}
                    required={true}
                    disabled={
                      !values?.researchTargetChange ||
                      props.open?.detail ||
                      props?.open?.reportDetail ||
                      props.open?.reportAccept
                    }
                  />
                  {(isReport || values?.researchTargetChange) && !disabled && (
                    <Box
                      top={2}
                      right={0}
                      height={32}
                      paddingLeft="24px"
                      position="absolute"
                      // bgcolor='#bcbcbc'
                      bgcolor={theme.palette.primary.main}
                      zIndex={1}
                      style={{
                        borderTopRightRadius: 4,
                        borderTopLeftRadius: 4,
                        clipPath: "polygon(5% 0%, 100% 0, 100% 100%, 0% 100%)",
                      }}
                      display="flex"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="default"
                            style={{ color: "#fff" }}
                            checked={values?.researchTargetChange || false}
                            disabled={props.open?.detail || props.open?.reportDetail || props.open?.reportAccept}
                            onClick={(e) => handleChangeForm("researchTargetChange", e.target.checked)}
                          />
                        }
                        style={{ marginTop: -6 }}
                        label={
                          <Typography
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              marginTop: -2,
                            }}
                          >
                            <b>Xin điều chỉnh</b>
                          </Typography>
                        }
                      />
                      {(props.open?.detail || props.open?.reportDetail || props.open?.reportAccept) && (
                        <>
                          <Divider
                            style={{
                              marginTop: 1,
                              width: 1,
                              height: 30,
                              backgroundColor: "#fff !important",
                            }}
                          />
                          <Typography
                            className={styles.detailButton}
                            onClick={() => viewHistory("researchTarget")}
                            style={{
                              cursor: values?.researchTargetChange ? "pointer" : "context-menu",
                              color: values?.researchTargetChange ? "#fff" : "#fafafa",
                            }}
                          >
                            <b>Chi tiết</b>
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box position="relative">
                  <KPTextfield
                    id="expectedResult"
                    xs={12}
                    height={3}
                    label="Dự kiến sản phẩm, kết quả"
                    value={values?.expectedResult || ""}
                    handleChangeForm={handleChangeForm}
                    required={true}
                    disabled={
                      !values?.expectedResultChange ||
                      props.open?.detail ||
                      props.open?.reportDetail ||
                      props.open?.reportAccept
                    }
                  />
                  {(isReport || values?.expectedResultChange) && !disabled && (
                    <Box
                      top={2}
                      right={0}
                      height={32}
                      paddingLeft="24px"
                      position="absolute"
                      // bgcolor='#bcbcbc'
                      bgcolor={theme.palette.primary.main}
                      zIndex={1}
                      style={{
                        borderTopRightRadius: 4,
                        borderTopLeftRadius: 4,
                        clipPath: "polygon(5% 0%, 100% 0, 100% 100%, 0% 100%)",
                      }}
                      display="flex"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="default"
                            style={{ color: "#fff" }}
                            checked={values?.expectedResultChange || false}
                            disabled={props.open?.detail || props.open?.reportDetail || props.open?.reportAccept}
                            onClick={(e) => handleChangeForm("expectedResultChange", e.target.checked)}
                          />
                        }
                        style={{ marginTop: -6 }}
                        label={
                          <Typography
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              marginTop: -2,
                            }}
                          >
                            <b>Xin điều chỉnh</b>
                          </Typography>
                        }
                      />
                      {(props.open?.detail || props.open?.reportDetail || props.open?.reportAccept) && (
                        <>
                          <Divider
                            style={{
                              marginTop: 1,
                              width: 1,
                              height: 30,
                              backgroundColor: "#fff !important",
                            }}
                          />
                          <Typography
                            className={styles.detailButton}
                            onClick={() => viewHistory("expectedResult")}
                            style={{
                              cursor: values?.expectedResultChange ? "pointer" : "context-menu",
                              color: values?.expectedResultChange ? "#fff" : "#fafafa",
                            }}
                          >
                            <b>Chi tiết</b>
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box position="relative">
                  <KPTextfield
                    id="expectedCompetition"
                    xs={12}
                    height={3}
                    label="Dự kiến tham gia giải thưởng sinh viên NCKH các cấp"
                    value={values?.expectedCompetition || ""}
                    handleChangeForm={handleChangeForm}
                    required={true}
                    disabled={
                      !values?.expectedCompetitionChange ||
                      props.open?.detail ||
                      props?.open?.reportDetail ||
                      props.open?.reportAccept
                    }
                  />
                  {(isReport || values?.expectedCompetitionChange) && !disabled && (
                    <Box
                      top={2}
                      right={0}
                      height={32}
                      paddingLeft="24px"
                      position="absolute"
                      // bgcolor='#bcbcbc'
                      bgcolor={theme.palette.primary.main}
                      zIndex={1}
                      style={{
                        borderTopRightRadius: 4,
                        borderTopLeftRadius: 4,
                        clipPath: "polygon(5% 0%, 100% 0, 100% 100%, 0% 100%)",
                      }}
                      display="flex"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="default"
                            style={{ color: "#fff" }}
                            checked={values?.expectedCompetitionChange || false}
                            disabled={props.open?.detail || props.open?.reportDetail || props.open?.reportAccept}
                            onClick={(e) => handleChangeForm("expectedCompetitionChange", e.target.checked)}
                          />
                        }
                        style={{ marginTop: -6 }}
                        label={
                          <Typography
                            style={{
                              color: "#fff",
                              fontSize: 14,
                              marginTop: -2,
                            }}
                          >
                            <b>Xin điều chỉnh</b>
                          </Typography>
                        }
                      />
                      {(props.open?.detail || props.open?.reportDetail || props.open?.reportAccept) && (
                        <>
                          <Divider
                            style={{
                              marginTop: 1,
                              width: 1,
                              height: 30,
                              backgroundColor: "#fff !important",
                            }}
                          />
                          <Typography
                            className={styles.detailButton}
                            onClick={() => viewHistory("expectedCompetition")}
                            style={{
                              cursor: values?.expectedCompetitionChange ? "pointer" : "context-menu",
                              color: values?.expectedCompetitionChange ? "#fff" : "#fafafa",
                            }}
                          >
                            <b>Chi tiết</b>
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ),
    },
    {
      key: "box33",
      xs: 12,
      type: "render",
      render: () => (
        <>
          {!disabled && (
            <Box border="solid 1px #bcbcbc" borderRadius={4}>
              <Box
                display="flex"
                bgcolor="#ececec"
                padding="8px 16px"
                alignItems="center"
                borderBottom="solid 1px #bcbcbc"
                style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
              >
                <OfflinePin
                  style={{
                    marginRight: 12,
                    marginTop: 2,
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
                  <b>BÁO CÁO TIẾN ĐỘ</b>
                </Typography>
              </Box>
              <Box padding={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <KPTextfield
                      id="finishedWork"
                      xs={12}
                      type="dragzone"
                      label={(color) => (
                        <Typography className="kpt-textfield-label" style={{ color }}>
                          {`Các công việc đã hoàn thành (Tải file mẫu `}
                          <a
                            href={fileCongViecHoanThanh}
                            style={{ color: "blue" }}
                            download={"Cac cong viec da hoan thanh.docx"}
                          >
                            tại đây
                          </a>
                          {`)`}
                          <span style={{ color: "#c90000" }}>*</span>
                          {`:`}
                        </Typography>
                      )}
                      value={values?.finishedWork || ""}
                      handleChangeForm={handleChangeForm}
                      required={true}
                      disabled={props?.open?.reportDetail}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </>
      ),
    },
    {
      key: "box2",
      xs: 12,
      type: "render",
      render: () => (
        <Box border="solid 1px #bcbcbc" borderRadius={4}>
          <Box
            display="flex"
            bgcolor="#ececec"
            padding="8px 16px"
            alignItems="center"
            borderBottom="solid 1px #bcbcbc"
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <School
              style={{
                marginRight: 12,
                marginTop: 2,
                color: theme.palette.primary.main,
              }}
            />
            <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
              <b>GIẢNG VIÊN HƯỚNG DẪN</b>
            </Typography>
          </Box>
          <Box padding={2}>
            {instructors?.map((instructor, index) => (
              <Box marginTop={parseInt(index !== 0 ? 1 : 0) * 2}>
                {instructor?.isNew && (
                  <Typography color="secondary" style={{ marginTop: 32 }}>
                    <b>
                      <i>Giảng viên hướng dẫn được thêm mới:</i>
                    </b>
                  </Typography>
                )}
                {instructor?.isStop && (
                  <Typography color="error" style={{ marginTop: 32 }}>
                    <b>
                      <i>Giảng viên xin dừng thực hiện đề tài:</i>
                    </b>
                  </Typography>
                )}
                <Box key={`instructor-${index}`} display="flex">
                  <Box width={152} minWidth={152} maxWidth={152} marginRight={1}>
                    <KPTextfield
                      disabled={!isReport || instructor.researchRole === "main" || disabled}
                      value={instructor.isPhenikaa}
                      id={`instructors.${index}.isPhenikaa`}
                      type="checkbox"
                      name="GV trong trường"
                      handleChangeForm={handleChangeForm}
                      style={{
                        marginTop: "25.5px",
                        borderColor: instructor?.isStop || instructor?.isNew ? getStateColor(instructor) : "#c4c4c4",
                        boxShadow:
                          (instructor?.isStop || instructor?.isNew) &&
                          `${getStateColor(instructor)}c7 0px 2px 4px -1px, ${getStateColor(
                            instructor
                          )}f2 0px 2px 4px -1px`,
                      }}
                    />
                  </Box>
                  <Grid container spacing={1} style={{ flexGrow: 1 }}>
                    <Grid item xs={4}>
                      <KPTextfield
                        className={styles[`textfield_${getStateColor(instructor).substring(1)}`]}
                        type={instructor.isPhenikaa ? "autoComplete" : null}
                        options={instructor.isPhenikaa ? profileList : null}
                        getOptionLabel={(option) => option.name}
                        required={true}
                        disabled={!isReport || instructor.researchRole === "main" || disabled}
                        value={
                          instructor.isPhenikaa
                            ? profileList.find((e) => e.id === instructor?.profileId)
                            : instructor?.name
                        }
                        label="Tên giảng viên"
                        id={`instructors.${index}${instructor.isPhenikaa ? null : ".name"}`}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <KPTextfield
                        className={styles[`textfield_${getStateColor(instructor).substring(1)}`]}
                        disabled={!isReport || instructor.researchRole === "main" || instructor.isPhenikaa || disabled}
                        required={true}
                        value={instructor.email}
                        label="Email"
                        id={`instructors.${index}.email`}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <KPTextfield
                        className={styles[`textfield_${getStateColor(instructor).substring(1)}`]}
                        type="select"
                        required={true}
                        disabled={
                          instructor.researchRole === "main" ||
                          !instructor.isPhenikaa ||
                          !instructor.name ||
                          !isReport ||
                          disabled
                        }
                        value={instructor.researchRole}
                        label="Vai trò"
                        id={`instructors.${index}.researchRole`}
                        options={researchRoleInstructorOptions}
                        handleChangeForm={handleChangeForm}
                        sx={{
                          "& .MuiInputBase-root.Mui-disabled": {
                            "& > fieldset": { borderColor: "#8cffcb" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  {isReport && (
                    <Button
                      className={!isReport || instructor.researchRole === "main" || disabled ? null : "error-button"}
                      color="primary"
                      variant="contained"
                      disabled={instructor.researchRole === "main" || disabled}
                      style={{ marginLeft: 8, marginTop: "25.5px", height: 35 }}
                      onClick={() => setInstructors(instructors.filter((e, id) => id !== index))}
                    >
                      Xóa
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
            {isReport && (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                disabled={instructors.length >= 2}
                onClick={() => {
                  setInstructors([...instructors, { isPhenikaa: false, researchRole: "other" }]);
                }}
                style={{ marginTop: 16, background: "white" }}
              >
                Thêm giảng viên hướng dẫn phụ
              </Button>
            )}
          </Box>
        </Box>
      ),
    },
  ];

  if (members?.length > 0) {
    textForm.push({
      key: "box4",
      xs: 12,
      type: "render",
      render: () => (
        <Box border="solid 1px #bcbcbc" borderRadius={4}>
          <Box
            display="flex"
            bgcolor="#ececec"
            padding="8px 16px"
            alignItems="center"
            borderBottom="solid 1px #bcbcbc"
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <Group
              style={{
                marginRight: 12,
                marginTop: 2,
                color: theme.palette.primary.main,
              }}
            />
            <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
              <b>THÀNH VIÊN THAM GIA</b>
            </Typography>
          </Box>
          <Box padding={2}>
            {members?.map((member, index) => (
              <Box
                key={`member-${index}`}
                padding={2}
                borderRadius={4}
                marginTop={index === 0 ? 0 : 2}
                display="flex"
                position="relative"
                alignItems="flex-end"
                border={`solid 1px ${getStateColor(member)}`}
                style={{
                  borderTopWidth: 4,
                  borderTopColor: getStateColor(member),
                  boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                }}
              >
                <Grid container spacing={1}>
                  {fieldList.map((field) => (
                    <Grid key={`member-${index}-${field.key}`} item xs={field.xs}>
                      <KPTextfield
                        // disabled={!isReport}
                        disabled={!isReport || !member.isNew}
                        {...field}
                        required={field.required === "only_main" ? member.researchRole === "main" : field.required}
                        value={
                          field?.key === "departmentId"
                            ? field?.options?.find((e) => e.id === member?.[field?.key])
                            : member?.[field?.key]
                        }
                        getOptionLabel={(option) => option?.name || ""}
                        id={`members.${index}.${field?.key}`}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={2}>
                    <KPTextfield
                      type="select"
                      required={true}
                      disabled={!isReport || member.isStop || member.researchRole === "main" || disabled}
                      label="Vai trò"
                      options={researchRoleMemberOptions}
                      value={member?.researchRole}
                      id={`members.${index}.researchRole`}
                      handleChangeForm={handleChangeForm}
                    />
                  </Grid>
                  {member?.researchRole === "main" && (
                    <>
                      <Grid item xs={3}>
                        <KPTextfield
                          disabled={!isReport || disabled}
                          // disabled={!isReport || !member?.isNew}
                          required={true}
                          label="Số CMND/CCCD"
                          value={member?.identityNumber}
                          id={`members.${index}.identityNumber`}
                          handleChangeForm={handleChangeForm}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <KPTextfield
                          disabled={!isReport || disabled}
                          // disabled={!isReport || !member?.isNew}
                          // required={true}
                          label="STK ngân hàng"
                          value={member?.bankAccNum}
                          id={`members.${index}.bankAccNum`}
                          handleChangeForm={handleChangeForm}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <KPTextfield
                          disabled={!isReport || disabled}
                          // disabled={!isReport || !member?.isNew}
                          // required={true}
                          label="Chi nhánh ngân hàng"
                          value={member?.bankBranch}
                          id={`members.${index}.bankBranch`}
                          handleChangeForm={handleChangeForm}
                        />
                      </Grid>
                    </>
                  )}
                  {member?.isStop && (
                    <>
                      <Grid item xs={12}>
                        <KPTextfield
                          required={true}
                          disabled={!isReport}
                          label="Lý do dừng thực hiện đề tài"
                          options={researchRoleMemberOptions}
                          value={member?.leaveReason}
                          id={`members.${index}.leaveReason`}
                          handleChangeForm={handleChangeForm}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <KPTextfield
                          type="dragzone"
                          required={true}
                          label={(color) => (
                            <Typography className="kpt-textfield-label" style={{ color }}>
                              {`File đơn xin dừng (Tải file mẫu `}
                              <a href={fileXinDung} style={{ color: "blue" }} download={"File xin dung.docx"}>
                                tại đây
                              </a>
                              {`)`}
                              <span style={{ color: "#c90000" }}>*</span>
                              {`:`}
                            </Typography>
                          )}
                          value={member?.leaveFileLink}
                          id={`members.${index}.leaveFileLink`}
                          handleChangeForm={handleChangeForm}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box
                  top={-1}
                  right={-1}
                  width={isReport && !disabled ? 110 : 48}
                  height={36}
                  // bgcolor='#bcbcbc'
                  bgcolor={getStateColor(member)}
                  color="#fff"
                  position="absolute"
                  justifyContent="flex-end"
                  display="flex"
                  alignItems="center"
                  style={{
                    borderTopRightRadius: 4,
                    clipPath: "polygon(0 1%, 100% 0, 100% 100%, 20% 100%)",
                  }}
                >
                  {member.isStop ? (
                    <HighlightOff style={{ color: "#fff", paddingRight: 10 }} />
                  ) : (
                    <b
                      style={{
                        paddingBottom: 3,
                        paddingRight: 16,
                        fontSize: 22,
                      }}
                    >
                      {index - members?.slice(0, Math.max(index, 0)).filter((e) => e?.isStop).length + 1}
                    </b>
                  )}
                  {isReport && !disabled && (
                    <>
                      <Divider orientation="vertical" style={{ height: 28, marginTop: 1 }} />
                      <Tooltip
                        title={
                          member.researchRole === "main"
                            ? "Chủ nhiệm đề tài không thể xin dừng"
                            : `${member.isStop ? "Huỷ xin" : "Xin"} dừng thực hiện đề tài`
                        }
                      >
                        <span>
                          {(() => {
                            return (
                              <IconButton
                                disabled={member.researchRole === "main"}
                                onClick={() => {
                                  if (member?.isNew) {
                                    setMembers([...members].filter((_, id) => index !== id));
                                  } else {
                                    handleChangeForm(`members.${index}.isStop`, !!!member.isStop);
                                  }
                                }}
                              >
                                {member.isStop ? (
                                  <Replay style={{ color: "#fff" }} />
                                ) : (
                                  <>
                                    {member.researchRole === "main" ? (
                                      <DeleteForever style={{ color: "#fff" }} />
                                    ) : (
                                      <Delete style={{ color: "#fff" }} />
                                    )}
                                  </>
                                )}
                              </IconButton>
                            );
                          })()}
                        </span>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Box>
            ))}
            {isReport && !disabled && (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                disabled={members.filter((e) => !e?.isStop)?.length >= 5}
                onClick={() => setMembers([...members, { researchRole: "other", isNew: true }])}
                style={{ marginTop: 16 }}
              >
                Thêm thành viên
              </Button>
            )}
          </Box>
        </Box>
      ),
    });
  }

  const updateReport = () => {
    dispatch(uploadMultiFile(profileDetail?.id, files)).then((res) => {
      if (res.success) {
        const files = res.data;
        dispatch(
          insertStudentResearch({
            ...values,
            id: undefined,
            parent: values.id,
            finishedWork: files.find((file) => file.includes("finishedWork")),
            instructors: instructors.map((instructor) => ({
              ...instructor,
              id: undefined,
              researchId: undefined,
            })),
            status: "report_submitted",
            researchMembers: members.map((member, memberId) => ({
              ...member,
              id: undefined,
              researchId: undefined,
              gpa: 0,
              departmentId: typeof member?.departmentId === "number" ? member?.departmentId : member?.departmentId?.id,
              leaveFileLink: files.find((file) => {
                const list = file?.split("][");
                const index = parseInt(list[0]?.split(".")[1]);
                return memberId === index;
              }),
            })),
          })
        ).then((res) => {
          if (res) handleReload();
        });
      }
    });
  };

  const changeMain = () => {
    dispatch(
      updateStudentResearch({
        ...props.detail,
        instructors,
        status: "report_default",
      })
    );
  };

  const ensureProject = () => dispatch(updateStudentResearchStatus(props.detail.id, "report_ensure"));

  const handleSubmit = () => {
    props.handleClose();
    switch (props.id) {
      case "report-form":
        if (disabled) {
          changeMain();
        } else {
          updateReport();
        }
        break;
      case "report-accept-form":
        ensureProject();
        break;
      default:
        break;
    }
  };

  if (!!!profileList) return null;

  return (
    <>
      <KPTextform id={props.id} textForm={textForm} values={values} setValues={setValues} handleSubmit={handleSubmit} />

      {history && (
        <Dialog
          maxWidth="xs"
          TransitionComponent={React.forwardRef((props, ref) => {
            return <Slide direction="up" ref={ref} {...props} />;
          })}
          fullWidth
          open={!!history}
          onClose={() => setHistory(null)}
        >
          <DialogTitle>
            <b>Lịch sử điều chỉnh</b>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ margin: "16px 0" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <KPTextfield
                  height={1}
                  fullWidth
                  size="small"
                  label="Ban đầu"
                  variant="outlined"
                  value={history?.before}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Box width="100%" display="flex" justifyContent="center">
                  <DoubleArrow
                    style={{
                      fontSize: 36,
                      padding: "0 8px",
                      transform: "rotate(90deg)",
                      color: "rgba(0, 0, 0, 0.30)",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <KPTextfield
                  height={1}
                  fullWidth
                  color="primary"
                  size="small"
                  label="Sau điều chỉnh"
                  variant="outlined"
                  value={history?.after}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default ReportForm;
