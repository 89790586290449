import React from 'react';
import { useDispatch } from 'react-redux';
import { HighlightOffRounded } from "@material-ui/icons";
import { Box, Grid, Button, Typography } from '@material-ui/core';

import { deleteStudentResearch } from '../../../../reducer/studentResearch/action';

function DeleteForm(props) {
  const dispatch = useDispatch();

  const { deleteId, handleClose } = props;

  const handleDelete = () => {
    handleClose();
    dispatch(deleteStudentResearch(deleteId));
  }

  return (
    <Box display='flex' alignItems='center' flexDirection='column' position='relative' >
      <HighlightOffRounded style={{ color: '#d32f2f', fontSize: 120 }} />
      <Typography variant='h5' style={{ marginBottom: 8 }}><b>Xác nhận xóa đề tài?</b></Typography>
      <Typography style={{ textAlign: 'center' }}>{props?.title}</Typography>
      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={6}>
          <Button fullWidth variant='outlined' onClick={handleClose} style={{ marginRight: 16 }}>Hủy</Button>
        </Grid>
        <Grid item xs={6}>
          <Button fullWidth className='error-button' color='primary' variant='contained' onClick={handleDelete}>Xóa</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DeleteForm;
