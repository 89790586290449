import {
  GetApp,
  Settings,
  VerifiedUser,
  SupervisorAccount,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Box, Grid, Divider, Tooltip, IconButton, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import select from "../../../utils/select";
import { rowRender } from "../utils/render";

import ActionButton from "../utils/ActionButton";

import { KPTable } from "../../../components/KPTComponents";
import InstitutionalProjectModal from "../InstitutionalProjectModal";
import { INSTITUTIONAL_PROJECT } from "../../../constants/selectOptions";
import { AcademicRankAbbrv, DegreeAbbrv } from "../../../utils/abbreviation";

const useStyles = makeStyles(() => ({
  tableRoot: {
    "& tbody tr": { height: 1 },
    "& tbody tr td": { height: "inherit" }
  },
}));

const sortFunc = (a, b) => {
  if (a.researchRole === "main") return -1;
  if (b.researchRole === "main") return 1;
  return a.id - b.id;
};

function InstitutionalProjectTable(props) {
  const styles = useStyles();

  const { type, year } = props;

  const [open, setOpen] = useState(null);
  const [detail, setDetail] = useState(null);
  const [datasource, setDatasource] = useState([]);

  const profileList = useSelector((state) => select(state, "profileReducer", "list")) || [];
  const list = useSelector((state) => select(state, "internalResearchReducer", "list")) || [];
  const isFetching = useSelector((state) => select(state, "internalResearchReducer", "isFetching"));

  useEffect(() => {
    const fullRows = list.map((e) => ({
      ...e,
      participants: e.participants.map((participant) => {
        const profile = profileList.find((p) => participant.profileId === p.id);
        if (!profile) return participant;
        return ({
          ...participant,
          degree: profile.degree,
          academicRank: profile.academicRank,
        });
      }),
      status: e?.status.split(",")
    }));
    const _datasource = fullRows.map((row) => {
      const { status, buttons } = rowRender(row.status, type, row.infoPassed);
      return { ...row, status, actions: buttons };
    });

    if (list) setDatasource(_datasource);
  }, [list]);

  return (
    <Grid item xs={12} style={{ position: "relative" }}>
      <KPTable
        tableProps={{
          classes: {
            root: styles.tableRoot
          }
        }}
        // totalData={rows.length}
        // rowsPerPage={50}
        border={true}
        loading={isFetching}
        filterObj={props.filterObj}
        maxHeight="calc(100vh - 209px)"
        columns={[
          {
            title: <b>Mã đề tài</b>,
            key: "researchCode",
            width: 100,
            searchable: true,
            onCell: (row) => ({ rowSpan: row.rowSpan }),
            render: (value) => value || "N/A",
          },
          {
            title: <b>Tên đề tài tiếng Việt</b>,
            key: "vietnameseName",
            width: "calc((100% - 730px) / 2)",
            searchable: true,
            onCell: (row) => ({ rowSpan: row.rowSpan }),
          },
          {
            title: <b>Tên đề tài tiếng Anh</b>,
            key: "englishName",
            width: "calc((100% - 730px) / 2)",
            searchable: true,
            onCell: (row) => ({ rowSpan: row.rowSpan }),
          },
          {
            title: <b>Cán bộ tham gia</b>,
            key: "participants",
            width: 200,
            render: (value, row) => {
              return (
                <Box
                  margin="-6px -16px"
                  width="calc(100% + 32px)"
                  height="calc(100% + 12px)"
                >
                  {value?.sort(sortFunc)?.map((e, id) => (
                    <>
                      <Box
                        key={`column-instructor-${id}`}
                        height={`calc(${100 / value?.length}% - ${
                          id === 0 ? 0 : 1
                        }px)`}
                        borderTop={id !== 0 ? "solid 1px #e0e0e0" : null}
                        display="flex"
                        alignItems="center"
                        padding="0 16px"
                        minHeight={28}
                        fontWeight={e.researchRole === "main" ? "bold" : "auto"}
                      >
                        {`${AcademicRankAbbrv(e?.academicRank)}
                        ${DegreeAbbrv(e?.degree)}
                        ${e?.name}`}
                      </Box>
                    </>
                  ))}
                </Box>
              );
            },
          },
          {
            title: <b>Vai trò</b>,
            key: "participants",
            align: "right",
            width: 100,
            render: (value) => {
              return (
                <Box
                  margin="-6px -16px"
                  width="calc(100% + 32px)"
                  height="calc(100% + 12px)"
                >
                  {value?.sort(sortFunc)?.map((e, id) => (
                    <>
                      <Box
                        key={`column-instructor-${id}`}
                        height={`calc(${100 / value?.length}% - ${
                          id === 0 ? 0 : 1
                        }px)`}
                        borderTop={id !== 0 ? "solid 1px #e0e0e0" : null}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        padding="0 16px"
                        minHeight={28}
                        width="calc(100% - 32px)"
                        fontWeight={e.researchRole === "main" ? "bold" : "auto"}
                      >
                        {
                          INSTITUTIONAL_PROJECT.roleOptions.find(
                            (elm) => elm.value === e.researchRole
                          )?.title
                        }
                      </Box>
                    </>
                  ))}
                </Box>
              );
            },
          },
          {
            title: <b>Trạng thái</b>,
            key: "status",
            align: "center",
            width: 226,
            render: (value, row) => (
              <>
                {value.map((e, index) => (
                  <Box
                    key={`status-${row.id}-${row.id}`}
                    bgcolor={`${e.bgcolor}16`}
                    border={`solid 1px ${e.bgcolor}`}
                    color={e.bgcolor}
                    fontSize={12}
                    borderRadius={16}
                    padding="2px 0"
                    width="calc(100% - 32px)"
                    margin="auto"
                    marginTop={index !== 0 ? 1 : 0}
                    textAlign="center"
                  >
                    {e.label}
                  </Box>
                ))}
              </>
            ),
            onCell: (row) => ({ rowSpan: row.rowSpan }),
          },
          {
            title: <b>Tác vụ</b>,
            key: "actions",
            align: "right",
            width: 104,
            onCell: (row) => ({ rowSpan: row.rowSpan }),
            render: (value, row) => (
              <ActionButton
                parent={row.parentResearch}
                row={row}
                actions={value}
                setOpen={setOpen}
                setDetail={setDetail}
              />
            ),
          },
        ]}
        rows={datasource}
      />

      {(props.type === "admin" || props.type === "mod") && (
        <Box bottom={6} marginLeft={0} position="absolute" display="flex">
          <IconButton onClick={() => setOpen({ export: true })}>
            <Tooltip title="Xuất file báo cáo">
              <GetApp />
            </Tooltip>
          </IconButton>
          {props.type === "mod" && (
            <>
              <IconButton>
                <Tooltip title="Hội đồng các tiểu ban">
                  <SupervisorAccount />
                </Tooltip>
              </IconButton>
              <Divider
                orientation="vertical"
                style={{ height: 32, margin: "8px" }}
              />
              <IconButton>
                <Tooltip title="Check trùng đề tài">
                  <VerifiedUser />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => setOpen({ setting: true })}>
                <Tooltip title="Cấu hình">
                  <Settings />
                </Tooltip>
              </IconButton>
            </>
          )}
        </Box>
      )}

      <InstitutionalProjectModal
        open={open}
        setOpen={setOpen}
        type={type}
        year={year}
        detail={detail}
      />
    </Grid>
  );
}

export default InstitutionalProjectTable;
