import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, LinearProgress, TextField, Typography } from "@material-ui/core";
import { KPTextform } from "../../../../components/KPTComponents";
import { Close } from "@material-ui/icons";

import styles from "./index.module.css";
import { apiCall } from "../../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { scientificCouncilAction } from "../../../../reducer/scientificCouncil/action";
import select from "../../../../utils/select";
import { uploadMultiFile } from "../../../../reducer/file/action";
import { Autocomplete } from "@material-ui/lab";

const ScientificCouncil = (props) => {
  const { open, handleClose } = props;
  const [values, setValues] = useState(null);

  const role = useSelector((state) => select(state, "userReducer", "role"));
  const departments = useSelector((state) => select(state, "departmentReducer", "departments"));
  const profileDetail = useSelector((state) => select(state, "profileReducer", "detail"));

  const TEXT_FORM = [
    {
      key: "title",
      xs: 12,
      disabled: props.viewValues,
      required: true,
      label: () => (
        <>
          <Typography className="kpt-textfield-label" color="primary">
            Tên đối tượng:
          </Typography>
          <Typography variant="body2" color="primary">
            (Đối tượng có thể là nhóm nghiên cứu, đề tài, giáo trình, hội thảo tùy thuộc vào Phân loại Hội đồng)
          </Typography>
        </>
      )
    },
    {
      xs: 12,
      key: "department",
      disabled: props.viewValues,
      required: true,
      label: "Khoa/Viện",
      type: "autoComplete",
      getOptionLabel: (option) => option.name,
      options: departments.filter((item) => item.id !== 0) ?? [],
    },
    { xs: 12, key: 'executionTime', disabled: props.viewValues, type: 'date', label: 'Thời điểm thực hiện', required: true },
    { 
      xs: 12, 
      key: 'type', 
      disabled: props.viewValues,
      type: 'select', 
      label: 'Phân loại hội đồng', 
      required: true,
      options: [
        ...[
          { title: "Hội đồng đánh giá Hồ sơ thành lập Nhóm nghiên cứu", value: "research_group" },
          { title: "Hội đồng đánh giá đề tài nghiên cứu cấp cơ sở", value: "internal_research_council" },
          { title: "Hội đồng nghiệm thu giáo trình", value: "textbook" },
          { title: "Tổ chức hội thảo, Tọa đàm khoa học cấp Trường", value: "uni_seminar" },
          { title: "Tổ chức Hội thảo, Tọa đàm khoa học cấp Khoa/Viện", value: "faculty_seminar" },
          { title: "Hội đồng khoa học đánh giá các đề tài tham gia Hội nghị sinh viên NCKH cấp trường", value: "student_research_reviewer" }
        ].map((d, i) => ({
          key: `opt${i}`,
          title: d.title,
          value: d.value
        }))
      ]
    },
    {
      xs: 6,
      key: 'info',
      disabled: props.viewValues,
      type: 'text',
      label: () => (
        <>
          <Typography className="kpt-textfield-label" color="primary">
            Thông tin đối tượng<span style={{ color: "#c90000" }}>*</span>:
          </Typography>
          <Typography variant="body2" color="primary">
            (Tên trưởng nhóm nghiên cứu/Mã số đề tài/Tên người soạn giáo trình…)
          </Typography>
        </>
      ),
      required: true
    },
    { 
      xs: 6, 
      key: 'role', 
      disabled: props.viewValues,
      type: 'select', 
      label: () => (
        <>
          <Typography variant="body2" color="primary">
            &nbsp;
          </Typography>
          <Typography className="kpt-textfield-label" color="primary">
            Vai trò<span style={{ color: "#c90000" }}>*</span>:
          </Typography>
        </>
      ),
      options: (() => {
        var listOption = [
          {title: "Chủ tịch HĐ", value: "chairman"},
          {title: "Thư kí HĐ", value: "secretary"},
        ];
        if (!(values?.type === "uni_seminar" || values?.type === "faculty_seminar")) {
          listOption = [
            ...listOption,
            {title: "Ủy viên", value: "commissioner"},
            {title: "Ủy viên phản biện", value: "reviewer"},
          ];
        }
        return listOption.map((d, i) => ({
          key: `opt${1}`,
          title: d.title,
          value: d.value
        }))
      })(),
      // options: [
      //   ...[
      //     {title: "Chủ tịch HĐ", value: "chairman"},
      //     {title: "Thư kí HĐ", value: "secretary"},
      //     {title: "Ủy viên", value: "commissioner"},
      //     {title: "Ủy viên phản biện", value: "reviewer"},
      //   ].map((d, i) => ({
      //     key: `opt${1}`,
      //     title: d.title,
      //     value: d.value
      //   }))
      // ],
      required: true 
    },
    { xs: 12, disabled: props.viewValues, key: 'fileUrl', type: 'dragzone', label: 'Minh chứng', required: true },
  ]

  const dispatch = useDispatch();

  const insert = () => {
    dispatch(uploadMultiFile(profileDetail?.id, { file: values?.fileUrl })).then((res) => {
      if (res.success) {
        const files = res.data;
  
        dispatch(scientificCouncilAction.createAllScientificCouncli({
          ...values,
          profileId: profileDetail.id,
          year: 2022,
          departmentId: values.department.id,
          fileUrl: files[0],
        })).then(() => {
          props.reload();
          setValues(null);
        });
      }
    });
  }

  const accept = (accepted) => dispatch(scientificCouncilAction.updateAllScientificCouncli({
    accepted,
    id: props.viewValues.id,
  })).then(() => props.reload());
  
  const handleSubmit = () => {
    if (!!props.viewValues) {
      if (role === 'sudo-admin') {
        accept(!props.viewValues.accepted);
      }
    } else {
      insert();
    }
    handleClose();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
    >
      {/* {isFetching && <LinearProgress />} */}
      <DialogTitle classes={{ root: styles["dialog-title-container"] }}>
        <Grid container>
          <Grid item xs={7} classes={{ root: styles["dialog-title"] }}>
            <Typography variant="h5">
              <b>Khai báo thành viên hội đồng khoa học</b>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <IconButton
              onClick={() => {
                handleClose();
                setValues(null);
              }}
              style={{ float: "right" }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <KPTextform
          id='scientificCouncil'
          textForm={TEXT_FORM}
          values={props.viewValues || values}
          setValues={!props.viewValues && setValues}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px" }}>
        <Button
          type="submit"
          form="scientificCouncil"
          variant="contained"
          color="primary"
          disabled={!props.viewValues && !values?.fileUrl}
        >
           {(() => {
            if (!!props.viewValues) {
              if (role === 'sudo-admin') {
                return props.viewValues.accepted ? "Huỷ duyệt" : "Duyệt";
              } else {
                return "Đóng";
              }
            } else {
              return "Thêm";
            }
          })()}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScientificCouncil;