import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import ResearchTimeYearInsertModal from '../ResearchTimeYearInsertModal';
import ResearchTimeYearDeleteModal from '../ResearchTimeYearDeleteModal';

import { filterYear, getTimeResearchInformation } from '../../../../reducer/timeResearchInformation/action';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxHeight: 300
    },
  })
);

function ResearchTimeButton(props) {
  const {
    year,
    filterYear,
    getTimeResearchInformation,
    timeResearchInformationList,
  } = props;

  const classes = useStyles();

  const [deletingYear, setDeletingYear] = useState(false);
  const [insertingYear, setInsertingYear] = useState(false);

  // Nếu không có năm 2021 thì đổi sang năm khác
  useEffect(() => {
    const checkIndex = props.timeResearchInformationList?.findIndex((e) => e.year === props.year);
    if (checkIndex < 0 && props.timeResearchInformationList.length > 0) {
      props.filterYear(props.timeResearchInformationList[0]?.year);
    }
  }, [props.timeResearchInformationList]);

  useEffect(() => {
    getTimeResearchInformation(0);
  }, []);

  const handleDeleteYear = () => setDeletingYear(true);
  const handleInsertYear = () => setInsertingYear(true);
  const handleReload = () => getTimeResearchInformation(0);

  const yearList = [];
  timeResearchInformationList.sort((a, b) => (b.year - a.year)).forEach((e) => yearList.push(e.year));

  return (
    <>
      <Grid container>
        <Grid item xs={3}><Typography variant="h5" style={{ lineHeight: '36px' }}>Bảng tính giờ nghiên cứu</Typography></Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                size='small'
                variant='outlined'
                value={year}
                label='Chọn năm học'
                onChange={(e) => {
                  filterYear(parseInt(e.target.value));
                  getTimeResearchInformation(0);
                }}
                SelectProps={{
                  MenuProps: {
                    classes: { list: classes.root },
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    transformOrigin: { vertical: 'top', horizontal: 'center' },
                    getContentAnchorEl: null,
                  } 
                }}
              >
                {yearList.map((e, index) => <MenuItem key={index} value={parseInt(e)}>{e} - {e+1}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" color="secondary" fullWidth onClick={handleDeleteYear} style={{ padding: '7px 0' }}>Xoá dữ liệu năm học</Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="outlined" color="primary" fullWidth onClick={handleInsertYear} style={{ padding: '7px 0' }}>Thêm dữ liệu năm học mới</Button>
            </Grid>
          </Grid>
        </Grid>
          
        <Grid item xs={3}>
          <div style={{ float: 'right' }}>
            <Button variant="outlined" startIcon={<Replay />} onClick={handleReload}>Tải lại dữ liệu</Button>
          </div>
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0' }} />

      <ResearchTimeYearDeleteModal deleting={deletingYear} setDeleting={setDeletingYear} handleReload={handleReload} />
      <ResearchTimeYearInsertModal inserting={insertingYear} setInserting={setInsertingYear} handleReload={handleReload} />
    </>
  );
}

const mapStateToProps = (state) => ({
  year: select(state, 'timeResearchInformationReducer', 'year'),
  timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
});

const mapDispatchToProps = (dispatch) => ({
  filterYear: (year) => dispatch(filterYear(year)),
  getTimeResearchInformation: (profileId) => dispatch(getTimeResearchInformation(profileId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ResearchTimeButton));
