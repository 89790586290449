import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  noti: null,
  history: null,
  isFetching: false,
  dataScientificResearch: [],
  workPages: [0, 0, 0, 0, 0],
});

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.HOME.GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_LOADING:
    case TYPE.AUTO_RANKING_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.HOME.GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_FAILURE:
    case TYPE.AUTO_RANKING_FAILURE:
      return state.merge({
        isFetching: false,
      });
      
    case TYPE.AUTO_RANKING_SUCCESS:
      return state.merge({
        journal: action.payload,
        isFetching: false,
      });
        
    case TYPE.HOME.GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_SUCCESS:
      return state.merge({
        dataScientificResearch: action.payload,
        isFetching: false,
      });

    case TYPE.HOME.CREATE_HISTORY:
      return state.merge({
        history: action.payload,
      });

    case 'CHANGE_PAGES': {
      const { id, newPage } = action;
      const tmp = [...state.get('workPages')];
      tmp.splice(id, 1, newPage);
      return state.merge({
        workPages: tmp,
      });
    }

    case 'RESET_PAGES': {
      return state.merge({
        workPages: [0, 0, 0, 0, 0],
      });
    }


    case TYPE.HOME.NOTIFICATION:
      return state.merge({
        noti: action.payload,
      });

    default:
      return state;
  }
}
