import React from 'react';
import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useField } from 'formik';

import GridCellExpand from './GridCellExpand';

const useStyles = makeStyles((theme) => {
  return {
    tableParent: {
      height: 400,
    },
    table: {
      '& .MuiDataGrid-colCellTitle': {
        fontWeight: 'bold',
      },
    },
    btnSelect: {
      margin: theme.spacing(0, 0, 0, 1),
    },
  };
});

function PublishmentModal(props) {
  const { open, handleClose, scientificResearchOthers } = props;

  const classes = useStyles();

  const [publishmentInput, , publishmentHelper] = useField('scientificResearchs.publishments');
  const [publishmentIds, setPublishmentIds] = useState([]);

  useEffect(() => {
    if (publishmentInput.value.length > 0 && publishmentIds.length === 0){
      setPublishmentIds(publishmentInput.value.map((element) => element.id))
    }
}, [publishmentInput]);

  /**
   * @type {import('@material-ui/data-grid').GridColDef[]}
   */
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      hide: true,
    },
    {
      field: 'title',
      headerName: 'Tiêu đề',
      width: 800,
      renderCell: (params) => {
        return <GridCellExpand value={params.value} width={params.colDef.width} />;
      },
    },
  ];

  const rows = scientificResearchOthers
    .map((element) => {
      const id = element.scientificResearchOther.id;
      const authors = (element.scientificResearchOther.listAuthor ?? []).join(', ');
      const paperTitle = element.scientificResearchOther.title;
      const year = element.scientificResearchOther.year;

      const title = `${authors} (${year}). ${paperTitle}.`;

      return { id, title };
    });

  /**
   *
   * @param {import('@material-ui/data-grid').GridSelectionModelChangeParams} params
   */
  const handleSelectionModelChange = (params) => {
    const selectedRows = rows.filter((rowElement) => {
      if (params.includes(rowElement.id)) {
        return true;
      }
      return false;
    });
    publishmentHelper.setValue(selectedRows);
    setPublishmentIds(params);
  };

  const handleSelect = () => {
    handleClose(false);
  };

  return (
    <Dialog maxWidth='md' fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Công bố quốc tế khác</DialogTitle>
      <DialogContent>
        <Box className={classes.tableParent}>
          <DataGrid
            className={classes.table}
            columns={columns}
            rows={rows}
            checkboxSelection={true}
            selectionModel={publishmentIds}
            onSelectionModelChange={handleSelectionModelChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='primary' onClick={() => handleClose(false)}>
          Hủy
        </Button>
        <Button variant='contained' color='primary' className={classes.btnSelect} onClick={handleSelect}>
          Chọn
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PublishmentModal;
