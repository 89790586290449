import React from "react";
import {
  Chip,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Replay } from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import ReactCountryFlag from "react-country-flag";

import toJs from "../../../hoc/toJS";
import {
  updateAllJournal,
  getFilteredJournal,
  importJournalByYear,
} from "../../../reducer/journal/action";
import select from "../../../utils/select";
import COUNTRY_CODE from "../../../constants/countryCode";

import { KPTable } from "../../../components/KPTComponents";

function JournalTable(props) {
  const [open, setOpen] = React.useState(false);
  const [uploadYear, setUploadYear] = React.useState(null);
  const [files, setFiles] = React.useState([]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleImport = () => {
    if (uploadYear && files.length !== 0) {
      props.importJournalByYear(uploadYear, files);
      handleClose();
    } else alert("Vui lòng nhập năm và chọn file để upload");
  };

  return (
    <>
      <KPTable
        rowsPerPage={10}
        maxHeight={props?.filterOpen
          ? "calc(100vh - 314px)"
          : "calc(100vh - 258px)"}
        columns={[
          {
            title: <b>Tên tạp chí</b>,
            key: "title",
            width: "17%",
            render: (value, row) => `${value} (${row.year})`,
          },
          { title: <b>Phân loại</b>, key: "type", width: "10%" },
          { title: <b>ISI/Scopus</b>, key: "coreCollection", width: "12%" },
          {
            title: <b>Ngành</b>,
            key: "areas",
            width: "15%",
            filterSearch: true,
          },
          { title: <b>Chuyên ngành</b>, key: "categories", width: "18%" },
          {
            title: <b>Rank Q</b>,
            key: "sjrBestQuartile",
            width: "6%",
            render: (value) => (
              <Chip
                label={value ? value : "Q1/Q2/Q3/Q4"}
                style={{ wordWrap: "break-word" }}
              />
            ),
          },
          {
            title: <b>Rank R</b>,
            key: "rank",
            width: "6%",
            render: (value) => (
              <Chip
                label={value ? value : "R1/R2/R3"}
                style={{ wordWrap: "break-word" }}
              />
            ),
          },
          {
            title: <b>Top Ngành</b>,
            key: "topRank",
            width: "8%",
            render: (value) => (
              <Chip
                label={value !== "none" ? value : "Không"}
                style={{ wordWrap: "break-word" }}
              />
            ),
          },
          {
            title: <b>Quốc gia</b>,
            key: "country",
            width: "13%",
            render: (value) => (
              <ReactCountryFlag
                svg
                countryCode={COUNTRY_CODE.find((e) => e.name === value)?.code}
                style={{ width: "2em", height: "2em" }}
              />
            ),
          },
        ]}
        rows={props.list}
        // onPageChange={props.getFilteredJournal}
        onPageChange={(newPage) => {
          props.getFilteredJournal(newPage + 1, 10, props.filterData);
        }}
        filterData={true}
        totalData={props.totalData}
      />

      {props.role === "sudo-admin" && (
        <>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Replay />}
            style={{ left: 16, bottom: 44 }}
            onClick={handleClickOpen}
          >
            Upload dữ liệu
          </Button>

          <Dialog onClose={handleClose} open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Upload dữ liệu tạp chí quốc tế mới
            </DialogTitle>
            <DialogContent>
              <Typography gutterBottom>
                Việc cập nhật dữ liệu sẽ tốn rất nhiều thời gian và yêu cầu
                đường truyền mạng ổn định do lượng dữ liệu lớn!
              </Typography>
              <Typography gutterBottom>
                Bạn có xác nhận việc cập nhật dữ liệu?
              </Typography>
              <div style={{ textAlign: "center", marginBottom: 10 }}>
                <div
                  style={{
                    display: "inline-block",
                    paddingTop: 8,
                    marginRight: 10,
                  }}
                >
                  Cập nhật dữ liệu cho năm:{" "}
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  style={{ width: 175, marginRight: 10 }}
                  label="Năm"
                  value={uploadYear}
                  onChange={(event) =>
                    setUploadYear(parseInt(event.target.value))
                  }
                />
              </div>
              <DropzoneArea
                onChange={(files) => setFiles(files)}
                showPreviews={true}
                maxFileSize={30000000}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: { spacing: 1, direction: "row" },
                }}
                previewText="File đã chọn:"
                filesLimit={1}
                defaultFiles={files}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={handleImport}
              >
                Xác nhận
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  list: select(state, "journalReducer", "listFiltered"),
  filterData: select(state, "journalReducer", "filterData"),
  totalData: select(state, "journalReducer", "totalFiltered"),
  role: select(state, "userReducer", "role"),
});

const mapDispatchToProps = (dispatch) => ({
  updateAllJournal: () => dispatch(updateAllJournal()),
  getFilteredJournal: (page, pageSize, payload) => dispatch(getFilteredJournal(page, pageSize, payload)),
  importJournalByYear: (year, file) => dispatch(importJournalByYear(year, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(JournalTable));
