import React from 'react';

import MonographTable from '../../containers/Monograph/MonographTable';
import MonographButton from '../../containers/Monograph/MonographButton';

function Monograph(props) {
  const list = window.location.href.split('/');
  const lastValueList = list[list.length - 1].split('=');
  const selectedProfileId = (lastValueList.length > 1) ? lastValueList[lastValueList.length - 1] : null;

  return (
    <div style={{ padding: 8, minHeight: 'calc(100vh - 178px)', position: 'relative' }}>
      <MonographButton type={props.type} department={props.department} selectedProfileId={selectedProfileId} handleReload={props.handleReload}/>
      <MonographTable adminTab={props.adminTab} type={props.type} department={props.department} selectedProfileId={selectedProfileId} handleReload={props.handleReload}/>
    </div>
  );
}

export default Monograph;
