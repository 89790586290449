import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Box, Grid, Backdrop, Typography, CircularProgress } from "@material-ui/core";

import "./AppLayout.css";

import select from "../../utils/select";
import ROUTER from "../../constants/router";
import Sidebar from "../../containers/AppLayout/Sidebar";
import AppHeader from "../../containers/AppLayout/AppHeader";

import Home from '../Home';
import User from '../User';
import Work from '../Work';
import Report from '../Report';
import Search from '../Search';
import Conference from '../Conference';
import Management from '../Management';
import Department from '../Department';
import AdminSetting from '../AdminSetting';
import Construction from '../Construction/Construction';
import HumanResource from '../HumanResource';
import StudentProject from '../StudentProject';
import EmploymentContract from '../EmploymentContract';
import NotificationScreen from '../NotificationScreen';
import InstitutionalProject from '../InstitutionalProject';
import StudentProjectCouncil from '../StudentProjectCouncil';

function StudentProjectRoute() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/personal`}>
        <StudentProject type="personal" />
      </Route>
      <Route exact path={`${path}/admin`}>
        <StudentProject type="admin" />
      </Route>
      <Route exact path={`${path}/mod`}>
        <StudentProject type="mod" />
      </Route>
      <Route exact path={`${path}/council`}>
        <StudentProjectCouncil />
      </Route>
      <Route exact path={`${path}/conference`}>
        <StudentProject type="conference" />
      </Route>
    </Switch>
  );
}

function InstitutionalProjectRoute() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/personal`}>
        <InstitutionalProject type="personal" />
      </Route>
      <Route exact path={`${path}/admin`}>
        <InstitutionalProject type="admin" />
      </Route>
      <Route exact path={`${path}/mod`}>
        <InstitutionalProject type="mod" />
      </Route>
    </Switch>
  );
}

function AppLayout() {
  const [animText, setAnimText] = useState(true);
  const [open, setOpen] = useState(localStorage.getItem("side-bar-open") === "true");

  const profileDetail = useSelector((state) => select(state, "profileReducer", "detail"));

  const url = window.location.href;
  const isGuestRoute = url.split("/").slice(-1)[0].toLowerCase().includes("guest");

  useEffect(() => localStorage.setItem("side-bar-open", open), [open]);

  return (
    <>
      <Sidebar open={open} animText={animText} setOpen={setOpen} style={{ position: "fixed" }} />
      <Grid container direction="row">
        <Box
          display={{ xs: "none", md: "flex" }}
          style={{ transition: "0.5s", width: isGuestRoute && !profileDetail ? 0 : open ? 250 : 80 }}
        />
        <Grid id="content" item>
          <AppHeader open={open} setOpen={setOpen} setAnimText={setAnimText} />
          <div style={{ marginTop: 12 }}>
            <Switch>
              <Route path={ROUTER.WORK} component={Work} />
              <Route path={ROUTER.SEARCH} component={Search} />
              <Route path={ROUTER.PHENIKAA_CONFERENCE} component={Construction} />
              <Route path={ROUTER.INTELLECTUAL_PROPERTY} component={Construction} />
              <Route path={ROUTER.CONFERENCE} component={Conference} />
              <Route path={ROUTER.MANAGEMENT} component={Management} />
              <Route path={ROUTER.DEPARTMENT} component={Department} />
              <Route path={ROUTER.NCKH_SINH_VIEN}>
                <StudentProjectRoute />
              </Route>
              <Route path={ROUTER.NCKH_CO_SO}>
                <InstitutionalProjectRoute />
              </Route>
              <Route path={ROUTER.USER} component={User} />
              <Route path={ROUTER.ADMIN_SETTING} component={AdminSetting} />
              <Route path={ROUTER.CONTRACT} component={EmploymentContract} />
              <Route path={ROUTER.REPORT} component={Report} />
              <Route path={ROUTER.NOTIFICATION} component={NotificationScreen} />
              <Route path={ROUTER.HUMANRESOURCE} component={HumanResource} />
              <Route exact path={ROUTER.GUEST} component={Home} />
              <Route exact path={ROUTER.ORLAB_GUEST} component={Home} />
              <Route path={ROUTER.HOME} component={Home} />
            </Switch>
          </div>
        </Grid>
      </Grid>

      <Backdrop in={!isGuestRoute && !profileDetail} style={{ zIndex: 999999 }}>
        <Box id="loading">
          <CircularProgress />
          <Typography variant="body1">
            <b>Đang tải...</b>
          </Typography>
        </Box>
      </Backdrop>
    </>
  );
}

export default AppLayout;
