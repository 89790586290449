import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
  DOI: {},
  list: [],
  listById: [],
  purposes: [],
  listFiltered: [],
  listDuplicate: [],
  filter: 'journalInternational',
  isFetching: false,
  isNeedReload: false,
  isFetchingDOI: false,
  isFetchingPurposes: false,
  isFetchingAccepted: false,
  isCheckingDuplicate: false,
});

export default function scientificResearchReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_LOADING:
    case TYPE.SCIENTIFIC_RESEARCH.GET_FILTERED_SCIENTIFIC_RESEARCH_LOADING:
    case TYPE.SCIENTIFIC_RESEARCH.INSERT_SCIENTIFIC_RESEARCH_LOADING:
    case TYPE.SCIENTIFIC_RESEARCH.UPDATE_SCIENTIFIC_RESEARCH_LOADING:
    case TYPE.SCIENTIFIC_RESEARCH.DELETE_SCIENTIFIC_RESEARCH_LOADING:
      return state.merge({
        isFetching: true,
      });
    case TYPE.SCIENTIFIC_RESEARCH.DOI_LOADING: {
      return state.merge({
        isFetchingDOI: true,
      });
    }
    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_LOADING:
      return state.merge({
        isFetching: true,
      });
    case TYPE.SCIENTIFIC_RESEARCH.ACCEPT_SCIENTIFIC_RESEARCH_LOADING:
      return state.merge({
        isFetchingAccepted: true,
      });
    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_PURPOSE_LOADING:
      return state.merge({ isFetchingPurposes: true });
    case TYPE.SCIENTIFIC_RESEARCH.GET_DUPLICATE_SCIENTIFIC_RESEARCH_LOADING:
      return state.merge({
        isCheckingDuplicate: true,
      });

    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_FAILURE:
    case TYPE.SCIENTIFIC_RESEARCH.GET_FILTERED_SCIENTIFIC_RESEARCH_FAILURE:
    case TYPE.SCIENTIFIC_RESEARCH.INSERT_SCIENTIFIC_RESEARCH_FAILURE:
    case TYPE.SCIENTIFIC_RESEARCH.UPDATE_SCIENTIFIC_RESEARCH_FAILURE:
    case TYPE.SCIENTIFIC_RESEARCH.DELETE_SCIENTIFIC_RESEARCH_FAILURE:
      return state.merge({
        isFetching: false,
      });
    case TYPE.SCIENTIFIC_RESEARCH.DOI_FAILURE: {
      return state.merge({
        isFetchingDOI: false,
        isNeedReload: !state.get('isNeedReload')
      });
    }
    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_FAILURE:
      return state.merge({
        isFetching: false,
      });
    case TYPE.SCIENTIFIC_RESEARCH.ACCEPT_SCIENTIFIC_RESEARCH_FAILURE:
      return state.merge({
        isFetchingAccepted: false,
      });
    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_PURPOSE_FAILURE:
      return state.merge({ isFetchingPurposes: false });
    case TYPE.SCIENTIFIC_RESEARCH.GET_DUPLICATE_SCIENTIFIC_RESEARCH_FAILURE:
      return state.merge({
        isCheckingDuplicate: false,
      });

    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_SUCCESS:
      return state.merge({
        list: action.payload || [],
        isFetching: false,
      });

    case TYPE.SCIENTIFIC_RESEARCH.GET_FILTERED_SCIENTIFIC_RESEARCH_SUCCESS:
      return state.merge({
        listFiltered: action.payload || [],
        isFetching: false,
      });

    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_SUCCESS:
      return state.merge({
        listById: action.payload || [],
        isFetching: false,
      });

    case TYPE.SCIENTIFIC_RESEARCH.INSERT_SCIENTIFIC_RESEARCH_SUCCESS:
      return state.merge({
        listById: action.payload? [...state.get('listById'), action.payload] : state.get('listById'),
        listFiltered: action.payload? [...state.get('listFiltered'), action.payload] : state.get('list'),
        DOI: {},
        isFetching: false,
      });

    case TYPE.SCIENTIFIC_RESEARCH.UPDATE_SCIENTIFIC_RESEARCH_SUCCESS: {
      const newList = state.get('listById');
      if (action.payload !== null){
        const index = newList.findIndex((e) => e.scientificResearch.id === action.payload.id);
        const newScientificResearch = { ...newList[index], scientificResearch: action.payload};
        newList.splice(index, 1, newScientificResearch);
      }

      const newList1 = state.get('listFiltered');
      if (action.payload !== null){
        const index1 = newList1.findIndex((e) => e.scientificResearch.id === action.payload.id);
        const newScientificResearch1 = { ...newList1[index1], scientificResearch: action.payload};
        newList1.splice(index1, 1, newScientificResearch1);
      }

      return state.merge({
        listById: [...newList],
        listFiltered: [...newList1],
        isFetching: false,
      });
    }

    case TYPE.SCIENTIFIC_RESEARCH.DELETE_SCIENTIFIC_RESEARCH_SUCCESS: {
      const newList = state.get('listById');
      const index = newList.findIndex((e) => e.scientificResearch.id === action.id);
      if (index !== -1)
        newList.splice(index, 1);

      const newList1 = state.get('listFiltered');
      const index1 = newList1.findIndex((e) => e.scientificResearch.id === action.id);
      if (index1 !== -1)
       newList1.splice(index1, 1);

      const dupList = state.get('listDuplicate');
      const newDupList = dupList.map(item => {
        return {
          duplicateScientificResearch: item.duplicateScientificResearch.filter(subItem => subItem.scientificResearch.id !== action.id)
        }
      })

      return state.merge({
        listById: [...newList],
        listFiltered: [...newList1],
        listDuplicate: newDupList ?? [],
        isFetching: false,
      });
    }

    case TYPE.SCIENTIFIC_RESEARCH.ACCEPT_SCIENTIFIC_RESEARCH_SUCCESS: {
      const newList = state.get('listById');
      const index = newList.findIndex((e) => e.scientificResearch.id === action.payload.id);
      const newScientificResearch = { ...newList[index], scientificResearch: action.payload};
      newList.splice(index, 1, newScientificResearch);

      const newList1 = state.get('listFiltered');
      const index1 = newList1.findIndex((e) => e.scientificResearch.id === action.payload.id);
      const newScientificResearch1 = { ...newList1[index1], scientificResearch: action.payload};
      newList1.splice(index1, 1, newScientificResearch1);

      return state.merge({
        listById: [...newList],
        listFiltered: [...newList1],
        isFetchingAccepted: false,
      });
    }

    case TYPE.SCIENTIFIC_RESEARCH.DOI_SUCCESS: {
      return state.merge({
        DOI: action.doi,
        isFetchingDOI: false,
        isNeedReload: !state.get('isNeedReload')
      });
    }

    case TYPE.SCIENTIFIC_RESEARCH.GET_ALL_PURPOSE_SUCCESS: {
      const tempPurposes = action.payload;
      if (tempPurposes[tempPurposes.length - 1].description === 'Tính giờ nghiên cứu') {
        tempPurposes.splice(2, 0, tempPurposes[tempPurposes.length - 1]);
        tempPurposes.splice(tempPurposes.length - 1, 1);
      }
      return state.merge({
        purposes: tempPurposes,
        isFetchingPurposes: false,
      });
    }
      

    case 'SCIENTIFIC_RESEARCH_SET_FILTER': 
      return state.merge({
        filter: action.id,
      })
    case 'RESET_DETAIL_ON_SR':
      return state.merge({
        DOI: {},
      })

    case TYPE.SCIENTIFIC_RESEARCH.GET_DUPLICATE_SCIENTIFIC_RESEARCH_SUCCESS:
      return state.merge({
        listDuplicate: action.payload ?? [],
        isCheckingDuplicate: false,
      });

    default: return state;
  }
}