import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import select from "../../../../utils/select";

import { STUDENT_RESEARCH } from '../../../../constants/selectOptions';
import { updateStudentResearch } from "../../../../reducer/studentResearch/action";
import { KPTextfield, KPTextform } from '../../../../components/KPTComponents';

function CouncilForm(props) {
  const dispatch = useDispatch();

  const { values, setValues } = props;

  const profileList = useSelector(state => select(state, 'profileReducer', 'list'));

  const handleChangeForm = (key, val, type) => {
    if (typeof(val) === 'object') {
      const roleKey = key.replace('Name', '');
      setValues({
        ...values,
        [`${roleKey}Name`]: val?.name,
        [`${roleKey}Degree`]: val?.degree,
        [`${roleKey}Pid`]: val?.id,
      });
    } else {
      setValues({ ...values, [key]: val });
    }
  }

  const handleSubmit = (e) => {
    props.handleClose();

    var statusList = props.detail.status.map((e) => e.value);
    statusList[1] = 'council_accepted';

    const councilMemberInfo = {}
    STUDENT_RESEARCH.councilFieldKey.forEach((e) => {
      const key = e.key;
      councilMemberInfo[`${key}Degree`] = values?.[`${key}Degree`];
      councilMemberInfo[`${key}Name`] = values?.[`${key}Name`];
      councilMemberInfo[`${key}Pid`] = values?.[`${key}Pid`];
    })

    dispatch(updateStudentResearch({
      ...props.detail,
      parentResearch: undefined,
      status: statusList.join(','),
      councilMemberInfo
    }))
  }

  return (
    <KPTextform
      id={props.id}
      textForm={[
        {key: 'box4', xs: 12, type: 'render', render: () => (
          <>
            <Grid
              container
              spacing={2}
              alignItems='center'
              style={{
                marginTop: 8,
                padding: '6px 0',
                backgroundColor: '#fafafa',
                borderBottom: 'solid 1px #e0e0e0'
              }}
            >
              <Grid item xs={7}>
                <Box display='flex'>
                  <Box width='170px' marginRight={2} />
                  <Box width='calc(100% - 170px)'>
                    <Typography variant='body1'><b>Tên thành viên</b></Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body1'><b>Học vị</b></Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1'><b>Vị trí</b></Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ padding: '12px 0', borderBottom: 'solid 1px #e0e0e0' }}>
              {STUDENT_RESEARCH.councilFieldKey.map((role) => {
                const { key, title, required } = role;
                const isPhenikaa = values?.[`${key}IsPhenikaa`] || values?.[`${key}Pid`];
                return (
                  <React.Fragment key={key}>
                    <Grid item xs={7}>
                      <Box display='flex'>
                        <Box width='170px' marginRight={2}>
                          <KPTextfield
                            value={isPhenikaa || false}
                            id={`${key}IsPhenikaa`}
                            type='checkbox'
                            name='GV trong trường'
                            // disabled={props.id === 'council-detail-form'}
                            handleChangeForm={handleChangeForm}
                          />
                        </Box>
                        <Box width='calc(100% - 170px)'>
                          <KPTextfield
                            type={isPhenikaa ? 'autoComplete' : null}
                            options={isPhenikaa ? profileList : null}
                            getOptionLabel={(option) => option.name}
                            value={
                              isPhenikaa
                                ? profileList.find((e) => e.id === values?.[`${key}Pid`])
                                : values?.[`${key}Name`]
                            }
                            id={`${key}Name`}
                            // disabled={props.id === 'council-detail-form'}
                            handleChangeForm={handleChangeForm}
                            required={required}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <KPTextfield
                        value={values?.[`${key}Degree`]}
                        disabled={isPhenikaa}
                        // disabled={isPhenikaa || props.id === 'council-detail-form'}
                        id={`${key}Degree`}
                        handleChangeForm={handleChangeForm}
                        required={required}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <KPTextfield value={title} disabled={true} />
                    </Grid>
                  </React.Fragment>
                )
              })}
            </Grid>
          </>
        )}
      ]}
      values={values}
      setValues={setValues}
      handleSubmit={handleSubmit}
    />
  );
}

export default CouncilForm;
