import { Popover, TextField, MenuItem, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => {
  return {
    roleSelectBox: {
      width: 200,
    },
    btnCancel: {
      margin: theme.spacing(0, 1, 0, 0),
    },
  };
});

function HumanResourceRoleModal(props) {
  const { roleAnchorEl, setRoleAnchorEl, currentRole, assignRole } = props;
  const classes = useStyles();

  const [role, setRole] = useState(currentRole || 'researcher');

  const handleAssignRole = () => {
    assignRole(role);
    setRoleAnchorEl(null);
  };

  return (
    <Popover
      open={roleAnchorEl !== null}
      anchorEl={roleAnchorEl}
      onClose={() => setRoleAnchorEl(null)}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Box p={2}>
        <Box pb={1}>
          <TextField
            select
            size='small'
            variant='outlined'
            className={classes.roleSelectBox}
            value={role}
            onChange={(event) => setRole(event.target.value)}
          >
            <MenuItem value='sudo-admin'>Admin</MenuItem>
            <MenuItem value='humanresource'>Quản lý nhân sự</MenuItem>
            <MenuItem value='researcher'>Nhà nghiên cứu</MenuItem>
          </TextField>
        </Box>
        <Box textAlign='right'>
          <Button variant='outlined' color='primary' className={classes.btnCancel} onClick={() => setRoleAnchorEl(null)}>
            Hủy
          </Button>
          <Button variant='contained' color='primary' onClick={handleAssignRole}>
            Chọn
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default HumanResourceRoleModal;
