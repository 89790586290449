export const rankConference = [
    { text: 'A', value: 'A'},
    { text: 'A*', value: 'A*'},
    { text: 'Australasian', value: 'Australasian'},
    { text: 'Australasian B', value: 'Australasian B'},
    { text: 'Australasian C', value: 'Australasian C'},
    { text: 'B', value: 'B'},
    { text: 'C', value: 'C'},
    { text: 'journal published', value: 'journal published'},
    { text: 'L', value: 'L'},
    { text: 'National', value: 'National'},
    { text: 'National: Austria', value: 'National: Austria'},
    { text: 'National: Bulgaria', value: 'National: Bulgaria'},
    { text: 'National: China', value: 'National: China'},
    { text: 'National: Croatia', value: 'National: Croatia'},
    { text: 'National: USA', value: 'National: USA'},
    { text: 'National: Spain', value: 'National: Spain'},
    { text: 'National: Italy', value: 'National: Italy'},
    { text: 'National: France', value: 'National: France'},
    { text: 'National: Russia', value: 'National: Russia'},
    { text: 'National: Germany', value: 'National: Germany'},
    { text: 'National: India', value: 'National: India'},
    { text: 'National: Romania', value: 'National: Romania'},
    { text: 'National: Poland', value: 'National: Poland'},
    { text: 'National: Pakistan', value: 'National: Pakistan'},
    { text: 'National: Vietnam', value: 'National: Vietnam'},
    { text: 'National: Korea', value: 'National: Korea'},
    { text: 'National: Malaysia', value: 'National: Malaysia'},
    { text: 'National: Czecholslovakia', value: 'National: Czecholslovakia'},
    { text: 'National: Iran', value: 'National: Iran'},
    { text: 'National: ireland', value: 'National: ireland'},
    { text: 'National: slovakia', value: 'National: slovakia'},
    { text: 'National: serbia', value: 'National: serbia'},
    { text: 'National: S. Korea', value: 'National: S. Korea'},
    { text: 'National: Japan', value: 'National: Japan'},
    { text: 'National: UK', value: 'National: UK'},
    { text: 'National: Spain', value: 'National: Spain'},
    { text: 'Unranked', value: 'Unranked'},
    { text: 'N/A', value: ''},
    { text: 'Regional', value: 'Regional'},
    { text: 'NEW', value: 'NEW'},
    { text: 'Regional - Baltic', value: 'Regional - Baltic'},
]

export const rankQ = [
  { text: 'Q1', value: 'Q1' },
  { text: 'Q2', value: 'Q2' },
  { text: 'Q3', value: 'Q3' },
  { text: 'Q4', value: 'Q4' }
]

export const rankR = [
  { text: 'R1', value: 'R1' },
  { text: 'R2', value: 'R2' },
  { text: 'R3', value: 'R3' },
  { text: 'R4', value: 'R4' },
  { text: 'R5', value: 'R5' },
  { text: 'R6', value: 'R6' },
  { text: 'R7', value: 'R7' },
  { text: 'R8', value: 'R8' },
  { text: 'R9', value: 'R9' },
  { text: 'R10', value: 'R10' },
  { text: 'TCN', value: 'TCN' }
]

export const topRank = [
  { text: 'Không', value: 'none' },
  { text: 'TN1', value: 'TN1' },
  { text: 'TN2', value: 'TN2' },
]

export const coreCollection = [
  { text: 'Arts & Humanities Citation Index', value: 'Arts & Humanities Citation Index', acronym: 'AHCI' },
  { text: 'Science Citation Index Expanded', value: 'Science Citation Index Expanded', acronym: 'SCIE' },
  { text: 'Social Sciences Citation Index', value: 'Social Sciences Citation Index', acronym: 'SSCI' },
  { text: 'Scopus', value: 'Scopus', acronym: 'Scopus' },
]

export const journalType = [
  { text: 'Book Series', value: 'book series' },
  { text: 'Conference and Proceedings', value: 'conference and proceedings' },
  { text: 'Journal', value: 'journal' },
  { text: 'Trade journal', value: 'trade journal' }
]

export const journalArea = [
    {
        text: 'Agricultural and Biological Sciences',
        value: 'Agricultural and Biological Sciences'
    },
    {
        text: 'Arts and Humanities',
        value: 'Arts and Humanities'
    },
    {
        text: 'Biochemistry, Genetics and Molecular Biology',
        value: 'Biochemistry, Genetics and Molecular Biology'
    },
    {
        text: 'Business, Management and Accounting',
        value: 'Business, Management and Accounting'
    },
    {
        text: 'Chemical Engineering',
        value: 'Chemical Engineering'
    },
    {
        text: 'Chemistry',
        value: 'Chemistry'
    },
    {
        text: 'Computer Science',
        value: 'Computer Science'
    },
    {
        text: 'Decision Sciences',
        value: 'Decision Sciences'
    },
    {
        text: 'Dentistry',
        value: 'Dentistry'
    },
    {
        text: 'Earth and Planetary Sciences',
        value: 'Earth and Planetary Sciences'
    },
    {
        text: 'Economics, Econometrics and Finance',
        value: 'Economics, Econometrics and Finance'
    },
    {
        text: 'Energy',
        value: 'Energy'
    },
    {
        text: 'Engineering',
        value: 'Engineering'
    },
    {
        text: 'Environmental Science',
        value: 'Environmental Science'
    },
    {
        text: 'Health Professions',
        value: 'Health Professions'
    },
    {
        text: 'Immunology and Microbiology',
        value: 'Immunology and Microbiology'
    },
    {
        text: 'Materials Science',
        value: 'Materials Science'
    },
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'Medicine',
        value: 'Medicine'
    },
    {
        text: 'Multidisciplinary',
        value: 'Multidisciplinary'
    },
    {
        text: 'Neuroscience',
        value: 'Neuroscience'
    },
    {
        text: 'Nursing',
        value: 'Nursing'
    },
    {
        text: 'Pharmacology, Toxicology and Pharmaceutics',
        value: 'Pharmacology, Toxicology and Pharmaceutics'
    },
    {
        text: 'Physics and Astronomy',
        value: 'Physics and Astronomy'
    },
    {
        text: 'Psychology',
        value: 'Psychology'
    },
    {
        text: 'Social Sciences',
        value: 'Social Sciences'
    },
    {
        text: 'Veterinary',
        value: 'Veterinary'
    },
]

export const journalCategory = [
  {
      text: "2002-2016",
      value: "2002-2016"
  },
  {
      text: "2008-2020",
      value: "2008-2020"
  },
  {
      text: "Accounting",
      value: "Accounting"
  },
  {
      text: "Acoustics and Ultrasonics",
      value: "Acoustics and Ultrasonics"
  },
  {
      text: "Advanced and Specialized Nursing",
      value: "Advanced and Specialized Nursing"
  },
  {
      text: "Aerospace Engineering",
      value: "Aerospace Engineering"
  },
  {
      text: "Aging",
      value: "Aging"
  },
  {
      text: "Agricultural and Biological Sciences (miscellaneous)",
      value: "Agricultural and Biological Sciences (miscellaneous)"
  },
  {
      text: "Agronomy and Crop Science",
      value: "Agronomy and Crop Science"
  },
  {
      text: "Algebra and Number Theory",
      value: "Algebra and Number Theory"
  },
  {
      text: "Analysis",
      value: "Analysis"
  },
  {
      text: "Analytical Chemistry",
      value: "Analytical Chemistry"
  },
  {
      text: "Anatomy",
      value: "Anatomy"
  },
  {
      text: "Anesthesiology and Pain Medicine",
      value: "Anesthesiology and Pain Medicine"
  },
  {
      text: "Animal Science and Zoology",
      value: "Animal Science and Zoology"
  },
  {
      text: "Anthropology",
      value: "Anthropology"
  },
  {
      text: "Applied Mathematics",
      value: "Applied Mathematics"
  },
  {
      text: "Applied Microbiology and Biotechnology",
      value: "Applied Microbiology and Biotechnology"
  },
  {
      text: "Applied Psychology",
      value: "Applied Psychology"
  },
  {
      text: "Aquatic Science",
      value: "Aquatic Science"
  },
  {
      text: "Archeology",
      value: "Archeology"
  },
  {
      text: "Archeology (arts and humanities)",
      value: "Archeology (arts and humanities)"
  },
  {
      text: "Architecture",
      value: "Architecture"
  },
  {
      text: "Artificial Intelligence",
      value: "Artificial Intelligence"
  },
  {
      text: "Arts and Humanities (miscellaneous)",
      value: "Arts and Humanities (miscellaneous)"
  },
  {
      text: "Assessment and Diagnosis",
      value: "Assessment and Diagnosis"
  },
  {
      text: "Astronomy and Astrophysics",
      value: "Astronomy and Astrophysics"
  },
  {
      text: "Atmospheric Science",
      value: "Atmospheric Science"
  },
  {
      text: "Atomic and Molecular Physics, and Optics",
      value: "Atomic and Molecular Physics, and Optics"
  },
  {
      text: "Automotive Engineering",
      value: "Automotive Engineering"
  },
  {
      text: "Behavioral Neuroscience",
      value: "Behavioral Neuroscience"
  },
  {
      text: "Biochemistry",
      value: "Biochemistry"
  },
  {
      text: "Biochemistry (medical)",
      value: "Biochemistry (medical)"
  },
  {
      text: "Biochemistry, Genetics and Molecular Biology (miscellaneous)",
      value: "Biochemistry, Genetics and Molecular Biology (miscellaneous)"
  },
  {
      text: "Bioengineering",
      value: "Bioengineering"
  },
  {
      text: "Biological Psychiatry",
      value: "Biological Psychiatry"
  },
  {
      text: "Biomaterials",
      value: "Biomaterials"
  },
  {
      text: "Biomedical Engineering",
      value: "Biomedical Engineering"
  },
  {
      text: "Biophysics",
      value: "Biophysics"
  },
  {
      text: "Biotechnology",
      value: "Biotechnology"
  },
  {
      text: "Building and Construction",
      value: "Building and Construction"
  },
  {
      text: "Business and International Management",
      value: "Business and International Management"
  },
  {
      text: "Business, Management and Accounting (miscellaneous)",
      value: "Business, Management and Accounting (miscellaneous)"
  },
  {
      text: "Cancer Research",
      value: "Cancer Research"
  },
  {
      text: "Cardiology and Cardiovascular Medicine",
      value: "Cardiology and Cardiovascular Medicine"
  },
  {
      text: "Care Planning",
      value: "Care Planning"
  },
  {
      text: "Catalysis",
      value: "Catalysis"
  },
  {
      text: "Cell Biology",
      value: "Cell Biology"
  },
  {
      text: "Cellular and Molecular Neuroscience",
      value: "Cellular and Molecular Neuroscience"
  },
  {
      text: "Ceramics and Composites",
      value: "Ceramics and Composites"
  },
  {
      text: "Chemical Engineering (miscellaneous)",
      value: "Chemical Engineering (miscellaneous)"
  },
  {
      text: "Chemical Health and Safety",
      value: "Chemical Health and Safety"
  },
  {
      text: "Chemistry (miscellaneous)",
      value: "Chemistry (miscellaneous)"
  },
  {
      text: "Chiropractics",
      value: "Chiropractics"
  },
  {
      text: "Civil and Structural Engineering",
      value: "Civil and Structural Engineering"
  },
  {
      text: "Classics",
      value: "Classics"
  },
  {
      text: "Clinical Biochemistry",
      value: "Clinical Biochemistry"
  },
  {
      text: "Clinical Psychology",
      value: "Clinical Psychology"
  },
  {
      text: "Cognitive Neuroscience",
      value: "Cognitive Neuroscience"
  },
  {
      text: "Colloid and Surface Chemistry",
      value: "Colloid and Surface Chemistry"
  },
  {
      text: "Communication",
      value: "Communication"
  },
  {
      text: "Community and Home Care",
      value: "Community and Home Care"
  },
  {
      text: "Complementary and Alternative Medicine",
      value: "Complementary and Alternative Medicine"
  },
  {
      text: "Complementary and Manual Therapy",
      value: "Complementary and Manual Therapy"
  },
  {
      text: "Computational Mathematics",
      value: "Computational Mathematics"
  },
  {
      text: "Computational Mechanics",
      value: "Computational Mechanics"
  },
  {
      text: "Computational Theory and Mathematics",
      value: "Computational Theory and Mathematics"
  },
  {
      text: "Computer Graphics and Computer-Aided Design",
      value: "Computer Graphics and Computer-Aided Design"
  },
  {
      text: "Computer Networks and Communications",
      value: "Computer Networks and Communications"
  },
  {
      text: "Computer Science (miscellaneous)",
      value: "Computer Science (miscellaneous)"
  },
  {
      text: "Computer Science Applications",
      value: "Computer Science Applications"
  },
  {
      text: "Computer Vision and Pattern Recognition",
      value: "Computer Vision and Pattern Recognition"
  },
  {
      text: "Computers in Earth Sciences",
      value: "Computers in Earth Sciences"
  },
  {
      text: "Condensed Matter Physics",
      value: "Condensed Matter Physics"
  },
  {
      text: "Conservation",
      value: "Conservation"
  },
  {
      text: "Control and Optimization",
      value: "Control and Optimization"
  },
  {
      text: "Control and Systems Engineering",
      value: "Control and Systems Engineering"
  },
  {
      text: "Critical Care Nursing",
      value: "Critical Care Nursing"
  },
  {
      text: "Critical Care and Intensive Care Medicine",
      value: "Critical Care and Intensive Care Medicine"
  },
  {
      text: "Cultural Studies",
      value: "Cultural Studies"
  },
  {
      text: "Decision Sciences (miscellaneous)",
      value: "Decision Sciences (miscellaneous)"
  },
  {
      text: "Demography",
      value: "Demography"
  },
  {
      text: "Dental Hygiene",
      value: "Dental Hygiene"
  },
  {
      text: "Dentistry (miscellaneous)",
      value: "Dentistry (miscellaneous)"
  },
  {
      text: "Dermatology",
      value: "Dermatology"
  },
  {
      text: "Development",
      value: "Development"
  },
  {
      text: "Developmental Biology",
      value: "Developmental Biology"
  },
  {
      text: "Developmental Neuroscience",
      value: "Developmental Neuroscience"
  },
  {
      text: "Developmental and Educational Psychology",
      value: "Developmental and Educational Psychology"
  },
  {
      text: "Discrete Mathematics and Combinatorics",
      value: "Discrete Mathematics and Combinatorics"
  },
  {
      text: "Drug Discovery",
      value: "Drug Discovery"
  },
  {
      text: "Drug Guides",
      value: "Drug Guides"
  },
  {
      text: "E-learning",
      value: "E-learning"
  },
  {
      text: "Earth and Planetary Sciences (miscellaneous)",
      value: "Earth and Planetary Sciences (miscellaneous)"
  },
  {
      text: "Earth-Surface Processes",
      value: "Earth-Surface Processes"
  },
  {
      text: "Ecological Modeling",
      value: "Ecological Modeling"
  },
  {
      text: "Ecology",
      value: "Ecology"
  },
  {
      text: "Ecology, Evolution, Behavior and Systematics",
      value: "Ecology, Evolution, Behavior and Systematics"
  },
  {
      text: "Economic Geology",
      value: "Economic Geology"
  },
  {
      text: "Economics and Econometrics",
      value: "Economics and Econometrics"
  },
  {
      text: "Economics, Econometrics and Finance (miscellaneous)",
      value: "Economics, Econometrics and Finance (miscellaneous)"
  },
  {
      text: "Education",
      value: "Education"
  },
  {
      text: "Electrical and Electronic Engineering",
      value: "Electrical and Electronic Engineering"
  },
  {
      text: "Electrochemistry",
      value: "Electrochemistry"
  },
  {
      text: "Electronic, Optical and Magnetic Materials",
      value: "Electronic, Optical and Magnetic Materials"
  },
  {
      text: "Embryology",
      value: "Embryology"
  },
  {
      text: "Emergency Medical Services",
      value: "Emergency Medical Services"
  },
  {
      text: "Emergency Medicine",
      value: "Emergency Medicine"
  },
  {
      text: "Emergency Nursing",
      value: "Emergency Nursing"
  },
  {
      text: "Endocrine and Autonomic Systems",
      value: "Endocrine and Autonomic Systems"
  },
  {
      text: "Endocrinology",
      value: "Endocrinology"
  },
  {
      text: "Endocrinology, Diabetes and Metabolism",
      value: "Endocrinology, Diabetes and Metabolism"
  },
  {
      text: "Energy (miscellaneous)",
      value: "Energy (miscellaneous)"
  },
  {
      text: "Energy Engineering and Power Technology",
      value: "Energy Engineering and Power Technology"
  },
  {
      text: "Engineering (miscellaneous)",
      value: "Engineering (miscellaneous)"
  },
  {
      text: "Environmental Chemistry",
      value: "Environmental Chemistry"
  },
  {
      text: "Environmental Engineering",
      value: "Environmental Engineering"
  },
  {
      text: "Environmental Science (miscellaneous)",
      value: "Environmental Science (miscellaneous)"
  },
  {
      text: "Epidemiology",
      value: "Epidemiology"
  },
  {
      text: "Equine",
      value: "Equine"
  },
  {
      text: "Experimental and Cognitive Psychology",
      value: "Experimental and Cognitive Psychology"
  },
  {
      text: "Family Practice",
      value: "Family Practice"
  },
  {
      text: "Filtration and Separation",
      value: "Filtration and Separation"
  },
  {
      text: "Finance",
      value: "Finance"
  },
  {
      text: "Fluid Flow and Transfer Processes",
      value: "Fluid Flow and Transfer Processes"
  },
  {
      text: "Food Animals",
      value: "Food Animals"
  },
  {
      text: "Food Science",
      value: "Food Science"
  },
  {
      text: "Forestry",
      value: "Forestry"
  },
  {
      text: "Fuel Technology",
      value: "Fuel Technology"
  },
  {
      text: "Fundamentals and Skills",
      value: "Fundamentals and Skills"
  },
  {
      text: "Gastroenterology",
      value: "Gastroenterology"
  },
  {
      text: "Gender Studies",
      value: "Gender Studies"
  },
  {
      text: "Genetics",
      value: "Genetics"
  },
  {
      text: "Genetics (clinical)",
      value: "Genetics (clinical)"
  },
  {
      text: "Geochemistry and Petrology",
      value: "Geochemistry and Petrology"
  },
  {
      text: "Geography, Planning and Development",
      value: "Geography, Planning and Development"
  },
  {
      text: "Geology",
      value: "Geology"
  },
  {
      text: "Geometry and Topology",
      value: "Geometry and Topology"
  },
  {
      text: "Geophysics",
      value: "Geophysics"
  },
  {
      text: "Geotechnical Engineering and Engineering Geology",
      value: "Geotechnical Engineering and Engineering Geology"
  },
  {
      text: "Geriatrics and Gerontology",
      value: "Geriatrics and Gerontology"
  },
  {
      text: "Gerontology",
      value: "Gerontology"
  },
  {
      text: "Global and Planetary Change",
      value: "Global and Planetary Change"
  },
  {
      text: "Hardware and Architecture",
      value: "Hardware and Architecture"
  },
  {
      text: "Health (social science)",
      value: "Health (social science)"
  },
  {
      text: "Health Informatics",
      value: "Health Informatics"
  },
  {
      text: "Health Information Management",
      value: "Health Information Management"
  },
  {
      text: "Health Policy",
      value: "Health Policy"
  },
  {
      text: "Health Professions (miscellaneous)",
      value: "Health Professions (miscellaneous)"
  },
  {
      text: "Health, Toxicology and Mutagenesis",
      value: "Health, Toxicology and Mutagenesis"
  },
  {
      text: "Hematology",
      value: "Hematology"
  },
  {
      text: "Hepatology",
      value: "Hepatology"
  },
  {
      text: "Histology",
      value: "Histology"
  },
  {
      text: "History",
      value: "History"
  },
  {
      text: "History and Philosophy of Science",
      value: "History and Philosophy of Science"
  },
  {
      text: "Horticulture",
      value: "Horticulture"
  },
  {
      text: "Human Factors and Ergonomics",
      value: "Human Factors and Ergonomics"
  },
  {
      text: "Human-Computer Interaction",
      value: "Human-Computer Interaction"
  },
  {
      text: "Immunology",
      value: "Immunology"
  },
  {
      text: "Immunology and Allergy",
      value: "Immunology and Allergy"
  },
  {
      text: "Immunology and Microbiology (miscellaneous)",
      value: "Immunology and Microbiology (miscellaneous)"
  },
  {
      text: "Industrial Relations",
      value: "Industrial Relations"
  },
  {
      text: "Industrial and Manufacturing Engineering",
      value: "Industrial and Manufacturing Engineering"
  },
  {
      text: "Infectious Diseases",
      value: "Infectious Diseases"
  },
  {
      text: "Information Systems",
      value: "Information Systems"
  },
  {
      text: "Information Systems and Management",
      value: "Information Systems and Management"
  },
  {
      text: "Inorganic Chemistry",
      value: "Inorganic Chemistry"
  },
  {
      text: "Insect Science",
      value: "Insect Science"
  },
  {
      text: "Instrumentation",
      value: "Instrumentation"
  },
  {
      text: "Internal Medicine",
      value: "Internal Medicine"
  },
  {
      text: "Issues, Ethics and Legal Aspects",
      value: "Issues, Ethics and Legal Aspects"
  },
  {
      text: "LPN and LVN",
      value: "LPN and LVN"
  },
  {
      text: "Language and Linguistics",
      value: "Language and Linguistics"
  },
  {
      text: "Law",
      value: "Law"
  },
  {
      text: "Leadership and Management",
      value: "Leadership and Management"
  },
  {
      text: "Library and Information Sciences",
      value: "Library and Information Sciences"
  },
  {
      text: "Life-span and Life-course Studies",
      value: "Life-span and Life-course Studies"
  },
  {
      text: "Linguistics and Language",
      value: "Linguistics and Language"
  },
  {
      text: "Literature and Literary Theory",
      value: "Literature and Literary Theory"
  },
  {
      text: "Logic",
      value: "Logic"
  },
  {
      text: "Management Information Systems",
      value: "Management Information Systems"
  },
  {
      text: "Management Science and Operations Research",
      value: "Management Science and Operations Research"
  },
  {
      text: "Management of Technology and Innovation",
      value: "Management of Technology and Innovation"
  },
  {
      text: "Management, Monitoring, Policy and Law",
      value: "Management, Monitoring, Policy and Law"
  },
  {
      text: "Marketing",
      value: "Marketing"
  },
  {
      text: "Materials Chemistry",
      value: "Materials Chemistry"
  },
  {
      text: "Materials Science (miscellaneous)",
      value: "Materials Science (miscellaneous)"
  },
  {
      text: "Maternity and Midwifery",
      value: "Maternity and Midwifery"
  },
  {
      text: "Mathematical Physics",
      value: "Mathematical Physics"
  },
  {
      text: "Mathematics (miscellaneous)",
      value: "Mathematics (miscellaneous)"
  },
  {
      text: "Mechanical Engineering",
      value: "Mechanical Engineering"
  },
  {
      text: "Mechanics of Materials",
      value: "Mechanics of Materials"
  },
  {
      text: "Media Technology",
      value: "Media Technology"
  },
  {
      text: "Medical Assisting and Transcription",
      value: "Medical Assisting and Transcription"
  },
  {
      text: "Medical Laboratory Technology",
      value: "Medical Laboratory Technology"
  },
  {
      text: "Medical Terminology",
      value: "Medical Terminology"
  },
  {
      text: "Medical and Surgical Nursing",
      value: "Medical and Surgical Nursing"
  },
  {
      text: "Medicine (miscellaneous)",
      value: "Medicine (miscellaneous)"
  },
  {
      text: "Metals and Alloys",
      value: "Metals and Alloys"
  },
  {
      text: "Microbiology",
      value: "Microbiology"
  },
  {
      text: "Microbiology (medical)",
      value: "Microbiology (medical)"
  },
  {
      text: "Modeling and Simulation",
      value: "Modeling and Simulation"
  },
  {
      text: "Molecular Biology",
      value: "Molecular Biology"
  },
  {
      text: "Molecular Medicine",
      value: "Molecular Medicine"
  },
  {
      text: "Multidisciplinary",
      value: "Multidisciplinary"
  },
  {
      text: "Museology",
      value: "Museology"
  },
  {
      text: "Music",
      value: "Music"
  },
  {
      text: "Nanoscience and Nanotechnology",
      value: "Nanoscience and Nanotechnology"
  },
  {
      text: "Nature and Landscape Conservation",
      value: "Nature and Landscape Conservation"
  },
  {
      text: "Nephrology",
      value: "Nephrology"
  },
  {
      text: "Neurology",
      value: "Neurology"
  },
  {
      text: "Neurology (clinical)",
      value: "Neurology (clinical)"
  },
  {
      text: "Neuropsychology and Physiological Psychology",
      value: "Neuropsychology and Physiological Psychology"
  },
  {
      text: "Neuroscience (miscellaneous)",
      value: "Neuroscience (miscellaneous)"
  },
  {
      text: "Nuclear Energy and Engineering",
      value: "Nuclear Energy and Engineering"
  },
  {
      text: "Nuclear and High Energy Physics",
      value: "Nuclear and High Energy Physics"
  },
  {
      text: "Numerical Analysis",
      value: "Numerical Analysis"
  },
  {
      text: "Nurse Assisting",
      value: "Nurse Assisting"
  },
  {
      text: "Nursing (miscellaneous)",
      value: "Nursing (miscellaneous)"
  },
  {
      text: "Nutrition and Dietetics",
      value: "Nutrition and Dietetics"
  },
  {
      text: "Obstetrics and Gynecology",
      value: "Obstetrics and Gynecology"
  },
  {
      text: "Occupational Therapy",
      value: "Occupational Therapy"
  },
  {
      text: "Ocean Engineering",
      value: "Ocean Engineering"
  },
  {
      text: "Oceanography",
      value: "Oceanography"
  },
  {
      text: "Oncology",
      value: "Oncology"
  },
  {
      text: "Oncology (nursing)",
      value: "Oncology (nursing)"
  },
  {
      text: "Ophthalmology",
      value: "Ophthalmology"
  },
  {
      text: "Optometry",
      value: "Optometry"
  },
  {
      text: "Oral Surgery",
      value: "Oral Surgery"
  },
  {
      text: "Organic Chemistry",
      value: "Organic Chemistry"
  },
  {
      text: "Organizational Behavior and Human Resource Management",
      value: "Organizational Behavior and Human Resource Management"
  },
  {
      text: "Orthodontics",
      value: "Orthodontics"
  },
  {
      text: "Orthopedics and Sports Medicine",
      value: "Orthopedics and Sports Medicine"
  },
  {
      text: "Otorhinolaryngology",
      value: "Otorhinolaryngology"
  },
  {
      text: "Paleontology",
      value: "Paleontology"
  },
  {
      text: "Parasitology",
      value: "Parasitology"
  },
  {
      text: "Pathology and Forensic Medicine",
      value: "Pathology and Forensic Medicine"
  },
  {
      text: "Pediatrics",
      value: "Pediatrics"
  },
  {
      text: "Pediatrics, Perinatology and Child Health",
      value: "Pediatrics, Perinatology and Child Health"
  },
  {
      text: "Periodontics",
      value: "Periodontics"
  },
  {
      text: "Pharmaceutical Science",
      value: "Pharmaceutical Science"
  },
  {
      text: "Pharmacology",
      value: "Pharmacology"
  },
  {
      text: "Pharmacology (medical)",
      value: "Pharmacology (medical)"
  },
  {
      text: "Pharmacology (nursing)",
      value: "Pharmacology (nursing)"
  },
  {
      text: "Pharmacology, Toxicology and Pharmaceutics (miscellaneous)",
      value: "Pharmacology, Toxicology and Pharmaceutics (miscellaneous)"
  },
  {
      text: "Pharmacy",
      value: "Pharmacy"
  },
  {
      text: "Philosophy",
      value: "Philosophy"
  },
  {
      text: "Physical Therapy, Sports Therapy and Rehabilitation",
      value: "Physical Therapy, Sports Therapy and Rehabilitation"
  },
  {
      text: "Physical and Theoretical Chemistry",
      value: "Physical and Theoretical Chemistry"
  },
  {
      text: "Physics and Astronomy (miscellaneous)",
      value: "Physics and Astronomy (miscellaneous)"
  },
  {
      text: "Physiology",
      value: "Physiology"
  },
  {
      text: "Physiology (medical)",
      value: "Physiology (medical)"
  },
  {
      text: "Plant Science",
      value: "Plant Science"
  },
  {
      text: "Podiatry",
      value: "Podiatry"
  },
  {
      text: "Political Science and International Relations",
      value: "Political Science and International Relations"
  },
  {
      text: "Pollution",
      value: "Pollution"
  },
  {
      text: "Polymers and Plastics",
      value: "Polymers and Plastics"
  },
  {
      text: "Process Chemistry and Technology",
      value: "Process Chemistry and Technology"
  },
  {
      text: "Psychiatric Mental Health",
      value: "Psychiatric Mental Health"
  },
  {
      text: "Psychiatry and Mental Health",
      value: "Psychiatry and Mental Health"
  },
  {
      text: "Psychology (miscellaneous)",
      value: "Psychology (miscellaneous)"
  },
  {
      text: "Public Administration",
      value: "Public Administration"
  },
  {
      text: "Public Health, Environmental and Occupational Health",
      value: "Public Health, Environmental and Occupational Health"
  },
  {
      text: "Pulmonary and Respiratory Medicine",
      value: "Pulmonary and Respiratory Medicine"
  },
  {
      text: "Radiation",
      value: "Radiation"
  },
  {
      text: "Radiological and Ultrasound Technology",
      value: "Radiological and Ultrasound Technology"
  },
  {
      text: "Radiology, Nuclear Medicine and Imaging",
      value: "Radiology, Nuclear Medicine and Imaging"
  },
  {
      text: "Rehabilitation",
      value: "Rehabilitation"
  },
  {
      text: "Religious Studies",
      value: "Religious Studies"
  },
  {
      text: "Renewable Energy, Sustainability and the Environment",
      value: "Renewable Energy, Sustainability and the Environment"
  },
  {
      text: "Reproductive Medicine",
      value: "Reproductive Medicine"
  },
  {
      text: "Research and Theory",
      value: "Research and Theory"
  },
  {
      text: "Review and Exam Preparation",
      value: "Review and Exam Preparation"
  },
  {
      text: "Reviews and References (medical)",
      value: "Reviews and References (medical)"
  },
  {
      text: "Rheumatology",
      value: "Rheumatology"
  },
  {
      text: "Safety Research",
      value: "Safety Research"
  },
  {
      text: "Safety, Risk, Reliability and Quality",
      value: "Safety, Risk, Reliability and Quality"
  },
  {
      text: "Sensory Systems",
      value: "Sensory Systems"
  },
  {
      text: "Signal Processing",
      value: "Signal Processing"
  },
  {
      text: "Small Animals",
      value: "Small Animals"
  },
  {
      text: "Social Psychology",
      value: "Social Psychology"
  },
  {
      text: "Social Sciences (miscellaneous)",
      value: "Social Sciences (miscellaneous)"
  },
  {
      text: "Social Work",
      value: "Social Work"
  },
  {
      text: "Sociology and Political Science",
      value: "Sociology and Political Science"
  },
  {
      text: "Software",
      value: "Software"
  },
  {
      text: "Soil Science",
      value: "Soil Science"
  },
  {
      text: "Space and Planetary Science",
      value: "Space and Planetary Science"
  },
  {
      text: "Spectroscopy",
      value: "Spectroscopy"
  },
  {
      text: "Speech and Hearing",
      value: "Speech and Hearing"
  },
  {
      text: "Sports Science",
      value: "Sports Science"
  },
  {
      text: "Statistical and Nonlinear Physics",
      value: "Statistical and Nonlinear Physics"
  },
  {
      text: "Statistics and Probability",
      value: "Statistics and Probability"
  },
  {
      text: "Statistics, Probability and Uncertainty",
      value: "Statistics, Probability and Uncertainty"
  },
  {
      text: "Strategy and Management",
      value: "Strategy and Management"
  },
  {
      text: "Stratigraphy",
      value: "Stratigraphy"
  },
  {
      text: "Structural Biology",
      value: "Structural Biology"
  },
  {
      text: "Surfaces and Interfaces",
      value: "Surfaces and Interfaces"
  },
  {
      text: "Surfaces, Coatings and Films",
      value: "Surfaces, Coatings and Films"
  },
  {
      text: "Surgery",
      value: "Surgery"
  },
  {
      text: "Taiwan Agricultural Engineers Society",
      value: "Taiwan Agricultural Engineers Society"
  },
  {
      text: "Theoretical Computer Science",
      value: "Theoretical Computer Science"
  },
  {
      text: "Tourism, Leisure and Hospitality Management",
      value: "Tourism, Leisure and Hospitality Management"
  },
  {
      text: "Toxicology",
      value: "Toxicology"
  },
  {
      text: "Transplantation",
      value: "Transplantation"
  },
  {
      text: "Transportation",
      value: "Transportation"
  },
  {
      text: "Urban Studies",
      value: "Urban Studies"
  },
  {
      text: "Urology",
      value: "Urology"
  },
  {
      text: "Veterinary (miscellaneous)",
      value: "Veterinary (miscellaneous)"
  },
  {
      text: "Virology",
      value: "Virology"
  },
  {
      text: "Visual Arts and Performing Arts",
      value: "Visual Arts and Performing Arts"
  },
  {
      text: "Waste Management and Disposal",
      value: "Waste Management and Disposal"
  },
  {
      text: "Water Science and Technology",
      value: "Water Science and Technology"
  }
]

