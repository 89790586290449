import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { valueFormat } from "../../../utils/valueFormat";

const columnCode = "ABCDEFGHIJKL";

export const exportExcel = async (_data, departmentName, date) => {
  console.log(_data);

  const data = _data.map((e, index) => ({
    ...e,
    index: index + 1,
    publicationTitle: e.publication?.title,
    publicationSjrBestQuartile: e.publication?.sjrBestQuartile,
    authors: e?.listAuthor?.join(", "),
    acceptedYear: new Date(e.acceptedDay).getFullYear(),
    domesticTitle: e.filter === "journalDomestic"
      ? e.publication?.title ? e.publication?.title : e.otherTitle
      : null
  }));

	const fileName = `Bái báo khoa học`;
  const Workbook = new ExcelJS.Workbook();
	const worksheet = Workbook.addWorksheet("Bài báo khoa học");

  const columns = [
    {
      title: "STT",
      key: "index",
      width: 8,
    },
    {
      title: "Tên bài báo khoa học",
      key: "title",
      width: 40,
    },
    {
      title: "Danh sách tác giả",
      key: "authors",
      width: 25,
    },
    {
      title: "Tên tạp chí",
      key: "publicationTitle",
      width: 20,
    },
    {
      title: "Tạp chí quốc tế",
      key: "publicationSjrBestQuartile",
      width: 20,
    },
    {
      title: "Tạp chí trong nước",
      key: "domesticTitle",
      width: 20,
    },
    {
      title: "Ghi chú đường dẫn bài báo",
      key: "doi",
      width: 27,
    },
    {
      title: "Tập số",
      key: "volume",
      width: 10,
    },
    {
      title: "Trang",
      key: "page",
      width: 10,
    },
    {
      title: "Năm công bố",
      key: "acceptedYear",
      width: 13,
    }
  ];

  worksheet.columns = columns;
  worksheet.mergeCells(`A1:${columnCode[columns.length - 1]}1`);
  worksheet.getCell("A1").value = "BÀI BÁO KHOA HỌC ĐÃ CÔNG BỐ";

  worksheet.addRow(columns.map((e) => e.title));
  data.forEach((row) => worksheet.addRow(columns.map((col) => row[col.key])));

  for (let i = 0; i < worksheet.columnCount; i++) {
    for (let j = 1; j <= worksheet.rowCount; j++) {
      worksheet.getCell(`${columnCode[i]}${j}`).style = {
        ...worksheet.getCell(`${columnCode[i]}${j}`).style, 
        alignment: { wrapText: true, vertical: 'middle' },
        border: {
          top: {style: "thin", color: {argb:'FF000000'}},
          left: {style: "thin", color: {argb:'FF000000'}},
          bottom: {style: "thin", color: {argb:'FF000000'}},
          right: {style: "thin", color: {argb:'FF000000'}}
        }
      }
    }
  }

  worksheet.getCell("A1").alignment = { horizontal: "center" };
  worksheet.getRow(2).alignment = { horizontal: "center" };
  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(2).font = { bold: true };

	const buf = await Workbook.xlsx.writeBuffer();
	saveAs(new Blob([buf]), `${fileName}.xlsx`);
}