import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  role: null,
  detail: null,
  userId: null,
  isFetching: false,
});

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.USER.LOGIN_LOADING:
    case TYPE.USER.LOGIN_WITH_GOOGLE_LOADING:
    case TYPE.USER.REFRESH_TOKEN_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.USER.LOGIN_FAILURE:
    case TYPE.USER.LOGIN_WITH_GOOGLE_FAILURE:
    case TYPE.USER.REFRESH_TOKEN_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.USER.LOGIN_SUCCESS:
    case TYPE.USER.LOGIN_WITH_GOOGLE_SUCCESS:
    case TYPE.USER.REFRESH_TOKEN_SUCCESS: {
      localStorage.setItem('access_token', action.payload.access_token);
      localStorage.setItem('refresh_token', action.payload.refresh_token);
      return state.merge({
        role: action.payload.role,
        userId: action.payload.userId,
        isFetching: false,
      });
    }

    default:
      return state;
  }
}
