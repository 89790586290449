import { connect } from "react-redux";
import { Grid, Typography, Divider, Box } from "@material-ui/core";

import select from "../../../utils/select";
import toJS from "../../../hoc/toJS";

import BasicInfo from "./BasicInfo";
import Education from "./Education";
import Business from "./Business";
import Language from "./Language";
import Research from "./Research";
import Experience from "./Experience";

function ScientificProfileForm(props) {
  const {
    researchProjects,
    scientificResearchs,
    scientificResearchOthers,
    patents,
    solutions,
    monographs,
  } = props;

  return (
    <Box overflow='auto' maxHeight='calc(100vh - 234px)' width='calc(100% + 16px)'>
      <Grid container spacing={2} style={{ width: '100%' }}>
        {/* <Grid item xs={12}>
          <Typography variant="h4">Lý lịch khoa học</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}
        <Grid item xs={12}>
          <BasicInfo />
          <Education />
          <Business />
          <Language />
          <Research
            researchProps={{
              researchProjects,
              scientificResearchs,
              scientificResearchOthers,
              patents,
              solutions,
              monographs,
            }}
          />
          <Experience />
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  contracts: select(state, "profileReducer", "contracts"),
  monographs: select(state, "profileReducer", "monographs"),
  patents: select(state, "profileReducer", "patents"),
  scientificResearchs: select(state, "profileReducer", "scientificResearchs"),
  scientificResearchOthers: select(
    state,
    "profileReducer",
    "scientificResearchOthers"
  ),
  detail: select(state, "profileReducer", "detail"),
  productContracts: select(state, "profileReducer", "productContracts"),
  researchProjects: select(state, "profileReducer", "researchProjects"),
  solutions: select(state, "profileReducer", "solutions"),
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ScientificProfileForm));
