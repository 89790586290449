const ROUTER = {
  HOME: '/',
  GUEST: '/guest',
  ORLAB_GUEST: '/orlab-guest',
  USER: '/user',
  WORK: '/work',
  LOGIN: '/login',
  PATENT: '/patent',
  INTELLECTUAL_PROPERTY: '/intellectual_property',
  SEARCH: '/search',
  REPORT: '/report',
  JOURNAL: '/journal',
  PROJECT: '/project',
  CONTRACT: '/contract',
  SOLUTION: '/solution',
  MONOGRAPH: '/monograph',
  MANAGEMENT: '/management',
  NOTIFICATION: '/notification',
  SCIENTIFIC_RESEARCH: '/scientific_research',
  HUMANRESOURCE: '/humanresource',

  CONFERENCE: '/conference',
  ADMIN_SETTING: '/admin_setting',
  DEPARTMENT: '/department',
  PHENIKAA_CONFERENCE: '/phenikaa_conference',

  NCKH_SINH_VIEN: '/nckh-sinh-vien',
  NCKH_CO_SO: '/nckh-co-so',

  HOI_NGHI: 'conference',
  SO_HUU_TRI_TUE: 'conference',
};

export default ROUTER;