import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import ProjectInsertModal from '../ProjectInsertModal';

import { getAllProfile } from '../../../reducer/profile/action';
import { getAllProject, getAllProjectByProfileId, getFilteredProject } from '../../../reducer/project/action';

function ProjectButton(props) {
  const { getAllProfile, getAllProject, getAllProjectByProfileId, getFilteredProject } = props;

  const [inserting, setInserting] = useState(false);

  let profileId = props.selectedProfileId;
  if (!profileId) profileId = props.profileDetail?.id;
  
  useEffect(() => {
    if (props.listProfile.length === 0) getAllProfile();
  }, [getAllProfile, props.listProfile.length]);

  const handleInsert = () => setInserting(true);

  useEffect(() => {
    if (props.type !== 'all' && profileId) getAllProjectByProfileId(profileId);
  }, [profileId]);

  useEffect(() => {
    if (props.type === 'all' && props.listFilteredProject.length === 0 && !props.isFetching) getFilteredProject(1, -1, {departmentId: props.department});
  }, []);

  useEffect(() => {
    if (props.type === 'all' && !props.isFetching && !props.isFetchingAccepted) getFilteredProject(1, -1, {departmentId: props.department})
  }, [props.department, getFilteredProject])

  const handleReload = () => {
    if (props.type !== 'all') {
      if (profileId) {
        getAllProjectByProfileId(profileId)
        props.handleReload();
      };
    } else {
      getAllProject();
      getFilteredProject(1, -1, {departmentId: props.department});
      props.handleReload();
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ lineHeight: '36px' }}>
            {/* Đề tài nghiên cứu (Đã được nghiệm thu) */}
            Đề tài nghiên cứu
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div style={{ float: 'right' }}>
            <Button variant="outlined" startIcon={<Replay />}  onClick={handleReload}>Tải lại</Button>
            {
              (props.type !== 'all') &&
              <Button variant="contained" color="primary" startIcon={<Add />} style={{ marginLeft: 8 }} onClick={handleInsert}>Thêm</Button>
            }
          </div>
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0' }} />
      <ProjectInsertModal inserting={inserting} setInserting={setInserting} />
    </>
  );
}

const mapStateToProps = (state) => ({
  isFetching: select(state, 'projectReducer', 'isFetching'),
  isFetchingAccepted: select(state, 'projectReducer', 'isFetchingAccepted'),

  listProfile: select(state, 'profileReducer', 'list'),
  listProject: select(state, 'projectReducer', 'list'),
  listFilteredProject: select(state, 'projectReducer', 'listFiltered'),
  profileDetail: select(state, 'profileReducer', 'detail'),
  listProjectById: select(state, 'projectReducer', 'listById'),
});

const mapDispatchToProps = (dispatch) => ({
  getAllProfile: () => dispatch(getAllProfile()),
  getAllProject: () => dispatch(getAllProject()),
  getFilteredProject: (page, pageSize, payload) => dispatch(getFilteredProject(page, pageSize, payload)),
  getAllProjectByProfileId: (id) => dispatch(getAllProjectByProfileId(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ProjectButton));
