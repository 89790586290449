import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Box,
  Button,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import { Pie } from 'react-chartjs-2'; 
import { Replay } from '@material-ui/icons';

import styles from './index.module.css';
import select from '../../../utils/select';
import { getProfileInfoByProfileId } from '../../../reducer/profile/action';

function Stats(props) {
  const dispatch = useDispatch();
  const isFetching = useSelector(state => select(state, 'profileReducer', 'isFetching'));
  const profile = useSelector(state => select(state, 'profileReducer', 'detail'));
  const contracts = useSelector(state => select(state, 'profileReducer', 'contracts'));
  const patents = useSelector(state => select(state, 'profileReducer', 'patents'));
  const productContracts = useSelector(state => select(state, 'profileReducer', 'productContracts'));
  const scientificResearchs = useSelector(state => select(state, 'profileReducer', 'scientificResearchs'));
  const solutions = useSelector(state => select(state, 'profileReducer', 'solutions'));
  const researchProjects = useSelector(state => select(state, 'profileReducer', 'researchProjects'));
  const monographs = useSelector(state => select(state, 'profileReducer', 'monographs'));

  const [scientifiResearchData, setScientifiResearchData] = useState({ Q1: 0, Q2: 0, Q3: 0, Q4: 0, Domestic: 0, Scopus: 0, Other: 0 });
  const { Q1, Q2, Q3, Q4, Scopus, Domestic, Other } = scientifiResearchData;

  useEffect(() => {
    let Q1, Q2, Q3, Q4, Scopus, Domestic, Other;
    Q1 = Q2 = Q3 = Q4 = Scopus = Domestic = Other = 0;
    if (Array.isArray(scientificResearchs)) {
      scientificResearchs.forEach((scientificResearchElement) => {
        if (scientificResearchElement?.scientificResearch?.accepted) {
          if (
            scientificResearchElement?.journal === null &&
            scientificResearchElement?.journalDomestic === null
          ) {
            Other++;
          } else {
            if (scientificResearchElement?.journal !== null) {
              if (scientificResearchElement?.journal?.coreCollection !== 'Scopus') {
                switch (scientificResearchElement?.journal?.sjrBestQuartile) {
                  case 'Q1':
                    Q1++;
                    break;
                  case 'Q2':
                    Q2++;
                    break;
                  case 'Q3':
                    Q3++;
                    break;
                  case 'Q4':
                    Q4++;
                    break;
                  default:
                    break;
                }
              } else {
                Scopus++;
              }
            } else if (scientificResearchElement?.journalDomestic !== null) {
              Domestic++;
            }
          }
        }
      });
    }

    setScientifiResearchData({ Q1, Q2, Q3, Q4, Scopus, Domestic, Other });
  }, [scientificResearchs]);

  const handleReloadProfileInfo = () => { dispatch(getProfileInfoByProfileId(profile.id)) };

  return (
    <>
      <Grid container spacing={2} alignItems='center' justifyContent='center'>
        <Grid item xs={12}>
          <Box display='flex' width='100%'>
            <Box className={styles.box} display='flex' width='calc(20% - 8px)'>
              <Box className={styles.text} flexGrow={1}>Bằng sáng chế</Box>
              <Box className={styles.num}>{patents?.length || 0}</Box>
            </Box>
            <Box className={styles.box} display='flex' width='calc(20% - 8px)' marginLeft='10px'>
              <Box className={styles.text} flexGrow={1}>Công trình khoa học</Box>
              <Box className={styles.num}>{scientificResearchs?.length || 0}</Box>
            </Box>
            <Box className={styles.box} display='flex' width='calc(20% - 8px)' marginLeft='10px'>
              <Box className={styles.text} flexGrow={1}>Giải pháp hữu ích</Box>
              <Box className={styles.num}>{solutions?.length || 0}</Box>
            </Box>
            <Box className={styles.box} display='flex' width='calc(20% - 8px)' marginLeft='10px'>
              <Box className={styles.text} flexGrow={1}>Đề tài nghiên cứu</Box>
              <Box className={styles.num}>{researchProjects?.length || 0}</Box>
            </Box>
            <Box className={styles.box} display='flex' width='calc(20% - 8px)' marginLeft='10px'>
              <Box className={styles.text} flexGrow={1}>Sách chuyên khảo</Box>
              <Box className={styles.num}>{monographs?.length || 0}</Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8} style={{ marginTop: 48 }}>
          <Pie
            options={{
              title: {
                text: 'Bài báo khoa học',
                fontSize: 20,
                display: true,
                position: 'bottom',
                fontColor: '#000000',
              },
              legend: { position: 'bottom' },
            }}
            data={{
              labels: ['ISI-Q1', 'ISI-Q2', 'ISI-Q3', 'ISI-Q4', 'Scopus', 'Trong nước', 'Others'],
              datasets: [
                {
                  data: [Q1, Q2, Q3, Q4, Scopus, Domestic, Other],
                  backgroundColor: [
                    '#D2443F',
                    '#F6932F',
                    '#EBCC4A',
                    '#24A89D',
                    'dodgerblue',
                    '#0F4078',
                    '#AD84D9',
                  ],
                },
              ],
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Stats;
