import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  typeFilter: 1,
  isFetching: false,
});

export default function rankTableReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.RANK_TABLE.GET_RANK_TABLE_LOADING:
    case TYPE.RANK_TABLE.UPDATE_RANK_TABLE_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.RANK_TABLE.GET_RANK_TABLE_FAILURE:
    case TYPE.RANK_TABLE.UPDATE_RANK_TABLE_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.RANK_TABLE.GET_RANK_TABLE_SUCCESS:
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });

    case TYPE.RANK_TABLE.UPDATE_RANK_TABLE_SUCCESS: {
      const temp = state.get('list');
      const index = temp.find((e) => e.id === action.id);
      temp.splice(index, 1, action.payload);
      return state.merge({
        list: [...temp],
        isFetching: false,
      });
    }

    case 'UPDATE_TYPE_FILTER':
      return state.merge({
        typeFilter: action.payload,
      });

    default:
      return state;
  }
}
