import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { insertTimeResearchInformation } from '../../../../reducer/timeResearchInformation/action';
import { filterYear } from '../../../../reducer/timeResearchInformation/action';

function ResearchTimeYearInsertModal(props) {
  const { inserting, setInserting, insertTimeResearchInformation } = props;
  const [values, setValues] = useState({ initYear: new Date().getFullYear() });

  // Nếu không có năm 2021 thì đổi sang năm khác
  useEffect(() => {
    const checkIndex = props.timeResearchInformationList?.findIndex((e) => e.year === values.initYear);
    if (checkIndex < 0 && props.timeResearchInformationList.length > 0) {
      setValues({ initYear: props.timeResearchInformationList[0]?.year });
    }
  }, [props.timeResearchInformationList]);


  const handleChangeForm = (name, value) => setValues({ ...values, [name]: value });
  const handleSubmit = (e) => {
    e.preventDefault();
    insertTimeResearchInformation({
      ...props.timeResearchInformationList.find((e) => e.year === values.initYear),
      year: values.year,
    }).then(() => {
      props.handleReload();
      props.filterYear(values.year);
    })
    setValues({ initYear: new Date().getFullYear() });
    setInserting(false);
  }

  const yearList = [];
  props.timeResearchInformationList.sort((a, b) => (b.year - a.year)).forEach((e) => {
    yearList.push(e.year);
  });

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth='md'
      open={inserting}
      onClose={() => setInserting(false)}
    >
      <form onSubmit={handleSubmit}>
      <DialogTitle>Thêm dữ liệu để tính giờ nghiên cứu</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container spacing={2} style={{marginBottom: 10}}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              size='small'
              type='number'
              variant='outlined'
              label='Chọn năm bắt đầu'
              value={values?.year || ''}
              onChange={(event) => handleChangeForm('year', parseInt(event.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              size='small'
              variant='outlined'
              value={values?.initYear || ''}
              label='Dựa vào dữ liệu của năm học'
              onChange={(event) => handleChangeForm('initYear', parseInt(event.target.value))}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
            >
              {yearList.map((e, index) => <option key={index} value={parseInt(e)}>{e} - {e+1}</option>)}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 8px 24px' }}>
        <div style={{ float: 'right' }}>
          <Button variant="outlined" color="secondary" onClick={() => setInserting(false)} style={{ marginRight: 4 }}>Huỷ</Button>
          <Button variant="contained" color="primary" type='submit'>Xác nhận</Button>
        </div>
      </DialogActions>
      </form>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
});

const mapDispatchToProps = (dispatch) => ({
  filterYear: (year) => dispatch(filterYear(year)),
  insertTimeResearchInformation: (payload) => dispatch(insertTimeResearchInformation(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ResearchTimeYearInsertModal));
