import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { getDOI } from '../../../../reducer/scientificResearch/action';
import { getJournalByIdOnScientificResearch, getSearchFilteredJournalAfterDOI } from '../../../../reducer/journal/action';

function Chapter(props) {
  const { values, setValues, handleChangeForm } = props;

  useEffect(() => {
    if (!(Object.keys(props.DOI).length === 0 && props.DOI.constructor === Object)){
      props.getSearchFilteredJournalAfterDOI(1, 100, {
        issn: (props.DOI?.ISSN?.[0] || props.DOI?.ISBN?.[0])?.replace('-', ''),
        title: props.DOI?.['container-title'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      }, props.DOI)
    }
  }, [props.isNeedReload]);


    useEffect(() => {
    if (props.listSearchAfterDOI && props.listSearchAfterDOI.length !== 0 && props.infoFromDoiLink && props.infoFromDoiLink.length !== 0){
      let tempListAuthor = '';
      (props.infoFromDoiLink?.author || [])?.forEach((author) => { 
        const tempAuthor = `${author.given} ${author.family}`;
        if (tempListAuthor === '') tempListAuthor += tempAuthor; else tempListAuthor += `, ${tempAuthor}`; 
      });

      setValues({
        ...values,
        doi: `https://doi.org/${props.infoFromDoiLink.DOI}`,
        title: props.infoFromDoiLink.title,
        issn: props.infoFromDoiLink?.ISSN?.[0] || props.infoFromDoiLink?.ISBN?.[0],
        issue: props.infoFromDoiLink.issue === undefined? '' : props.infoFromDoiLink.issue,
        // listAuthor: props.infoFromDoiLink.author?.map(author => `${author.given} ${author.family}`),
        listAuthor: tempListAuthor,
        page: props.infoFromDoiLink.page === undefined? '' : props.infoFromDoiLink.page,
        publisher: props.infoFromDoiLink.publisher === undefined? '' : props.infoFromDoiLink.publisher,
        volume: props.infoFromDoiLink.volume === undefined? '' : props.infoFromDoiLink.volume,
        numOfMember: props.infoFromDoiLink.author?.length,
        journalId: props.listSearchAfterDOI[0].id,
        year: parseInt(props.infoFromDoiLink.issued['date-parts']),
      })
    }
  }, [props.isFetchingAfterDOI]);

  useEffect(() => {
    const { journalDetail } = props;
    if (values?.journalId !== 0)
      setValues({
        ...values,
        sjrBestQuartile: journalDetail?.sjrBestQuartile,
        rank: journalDetail?.rank,
        topRank: journalDetail?.topRank,
        coreCollection: journalDetail?.coreCollection,
        impactFactor: journalDetail?.impactFactor,
        impactFactorFiveYear: journalDetail?.impactFactorFiveYear,
        submissionToFirstDecision: journalDetail?.submissionToFirstDecision,
        submissionToAcceptance: journalDetail?.submissionToAcceptance,
      })
  }, [props.isFetchingJournalDetail]);

  // const handleGetDataFromDOI = () => props.getDOI(values?.doi);
  const handleGetDataFromDOI = () => {
    const type1 = 'https://doi.org/';
    const type2 = 'doi.org/';
    if (values?.doi?.substr(0, type1.length) === type1) {
      props.getDOI(values?.doi);
    } else if (values?.doi?.substr(0, type2.length) === type2) {
      props.getDOI(`https://${values?.doi}`);
    } else {
      props.getDOI(`https://doi.org/${values?.doi}`);
    }
  }

  return (
    <>
      <Grid item xs={9}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Link DOI'
          variant='outlined'
          value={values?.doi || ''}
          onChange={(event) => handleChangeForm('doi', event.target.value)}
          helperText={!values?.doi? <div style={{ color: 'red' }}>Vui lòng upload file minh chứng nếu công bố không có link DOI</div> : null}
        />
      </Grid>
      <Grid item xs={3}> 
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleGetDataFromDOI()}
          disabled={props.disabled || props.isFetchingDOI || props.isFetchingAfterDOI || (values?.locked === 'kpi' || values?.locked === 'reward') || values?.lockedForTimeResearch}
        >
          {(props.isFetchingDOI || props.isFetchingAfterDOI) ? (
            <CircularProgress size={24} />
          ) : (
            'Nhập từ link DOI'
          )}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={props.disabled}
          size='small'
          label='Tên công bố'
          variant='outlined'
          value={values?.title || ''}
          style={{ width: '100%' }}
          onChange={(event) => handleChangeForm('title', event.target.value)}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          disabled={props.disabled}
          size='small'
          label='Tên sách'
          variant='outlined'
          value={values?.bookTitle || ''}
          style={{ width: '100%' }}
          onChange={(event) => handleChangeForm('bookTitle', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          select
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Ngôn ngữ'
          variant='outlined'
          SelectProps={{ native: true }}
          value={values?.language}
          onChange={(event) => handleChangeForm('language', event.target.value)}
        >
          <option value={null} />
          <option key='english' value='english'>Tiếng Anh</option>
          <option key='other' value='other'>Khác</option>
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          size='small'
          label='Trang'
          variant='outlined'
          value={values?.page || ''}
          style={{ width: '100%' }}
          onChange={(event) => handleChangeForm('page', parseInt(event.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={props.disabled}
          rows={2}
          multiline 
          size='small'
          label='Editors'
          variant='outlined'
          value={values?.editors || ''}
          style={{ width: '100%' }}
          onChange={(event) => handleChangeForm('editors', event.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Nhà xuất bản'
          variant='outlined'
          value={values?.publisher || ''}
          onChange={(event) => handleChangeForm('publisher', event.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="ISSN/ISBN"
          variant="outlined"
          value={values?.issn || ''}
          onChange={(event) => handleChangeForm('issn', event.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="Số lượng tác giả"
          variant="outlined"
          type="numOfMember"
          value={values?.numOfMember || ''}
          onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value), 10)}
          error={!(values?.numOfMember > 0)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Số lượng tác giả chính'
          variant='outlined'
          type='number'
          value={values?.numberMainAuthor || ''}
          onChange={(event) => handleChangeForm('numberMainAuthor', parseInt(event.target.value, 10))}
          error={!(values?.numberMainAuthor > 0)}
        />
      </Grid>
      {/* <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="Năm"
          variant="outlined"
          type="number"
          value={values?.year || ''}
          onChange={(event) => handleChangeForm('year', parseInt(event.target.value), 10)}
        />
      </Grid> */}
      <Grid item xs={12}>
        <TextField
          rows={4}
          multiline
          fullWidth
          disabled={props.disabled} 
          size='small'
          label='Tác giả'
          variant='outlined'
          value={values?.listAuthor || ''}
          onChange={(event) => handleChangeForm('listAuthor', event.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Issue'
          variant='outlined'
          value={values?.issue || ''}
          onChange={(event) => handleChangeForm('issue', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Volume'
          variant='outlined'
          value={values?.volume || ''}
          onChange={(event) => handleChangeForm('volume', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Page'
          variant='outlined'
          value={values?.page || ''}
          onChange={(event) => handleChangeForm('page', event.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          type='number'
          label='Điểm GS-PGS'
          variant='outlined'
          value={values?.score?.toString() || ''}
          onChange={(event) => handleChangeForm('score', parseFloat(event.target.value))}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Năm nộp'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1900, max: 3000 } }}
          value={new Date(values?.submissionDay).getFullYear() || ''}
          onChange={(event) => {
            let newDate = values?.submissionDay;
            let newYear = parseInt(event.target.value);
            if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
            newDate.setFullYear(newYear);
            handleChangeForm('submissionDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Năm chấp nhận'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1900, max: 3000 } }}
          value={new Date(values?.acceptedDay).getFullYear() || ''}
          onChange={(event) => {
            let newDate = values?.acceptedDay;
            let newYear = parseInt(event.target.value);
            if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
            newDate.setFullYear(newYear);
            handleChangeForm('acceptedDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          disabled={props.disabled || (values?.locked === 'kpi' || values?.locked === 'reward')}
          select
          size='small'
          label='Mục đích'
          variant='outlined'
          value={values?.purposeId ?? 'none'}
          SelectProps={{ native: true }}
          onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value, 10))}
        >
          {props.purposes.filter((e) => e.name !== 'kpi').map((e) => (
            <option key={e.id} value={e.id}>{e.description}</option>
          ))}
        </TextField>
      </Grid>
      {/* <Grid item xs={12}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Tên tạp chí/hội thảo khác (trong trường hợp hệ thống không có tạp chí/hội thảo cần tìm)'
          variant='outlined'
          value={values?.otherTitle || ''}
          onChange={(event) => handleChangeForm('otherTitle', event.target.value)}
        />
      </Grid> */}
   
    </>
  );
}
         
const mapStateToProps = (state) => ({
  DOI: select(state, 'scientificResearchReducer', 'DOI'),
  purposes: select(state, 'scientificResearchReducer', 'purposes'),
  journalDetail: select(state, 'journalReducer', 'detailOnScientificResearch'),
  infoFromDoiLink: select(state, 'journalReducer', 'infoFromDoiLink'),
  listSearchAfterDOI: select(state, 'journalReducer', 'listSearchAfterDOI'),

  isNeedReload: select(state, 'scientificResearchReducer', 'isNeedReload'),
  isFetchingDOI: select(state, 'scientificResearchReducer', 'isFetchingDOI'),
  isFetchingAfterDOI: select(state, 'journalReducer', 'isFetchingAfterDOI'),
  isFetchingJournalDetail: select(state, 'journalReducer', 'isFetchingDetailOnScientificResearch'),
});

const mapDispatchToProps = (dispatch) => ({
  getDOI: (url) => dispatch(getDOI(url)),
  getJournalByIdOnScientificResearch: (id) => dispatch(getJournalByIdOnScientificResearch(id)),
  getSearchFilteredJournalAfterDOI: (page, pageSize, payload, doiInfo) => dispatch(getSearchFilteredJournalAfterDOI(page, pageSize, payload, doiInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(Chapter));
