import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { matchSorter } from 'match-sorter';
import dayjs from 'dayjs';
import moment from 'moment';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTimePicker from '../../../components/KPTimePicker';
import { updateConference } from '../../../reducer/conference/action';

const dateFormat = (value) => {
  const dateForm = value?.replaceAll('/', '');
  const date = dateForm?.substr(0, 2);
  const month = dateForm?.substr(2, 2);
  const year = dateForm?.substr(-4);
  return(value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ssZ') : null);
}

function ConferenceUpdateModal(props) {
  const { updating, setUpdating, updateConference } = props;
  const [disabled, setDisabled] = useState(true);

  const [values, setValues] = useState(null);

  useEffect(() => {
    if (updating) setValues({
      ...updating
    });
  }, [updating]);

  const handleChangeForm = (name, value) => {
    setValues({ ...values, [name]: value })
  };

  const handleClose = () => {
    if (!disabled) {
      setDisabled(true);
    } else {
      setUpdating(null);
      setDisabled(true);
    }
  }

  const handleSubmit = () => {
    if (disabled) {
      setDisabled(false);
    } else {
      updateConference(values.id, { 
        ...values,
        organizeAt: dateFormat(values.organizeAt),
        year: dateFormat(values.organizeAt)? parseInt(dateFormat(values.organizeAt).split('-')[0]) : 1999,
      });
      setUpdating(null);
      setDisabled(true);
    }
  }

  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {keys: ['title']}).slice(0,100);

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth='md'
      open={updating !== null}
      onClose={() => { setUpdating(null); setDisabled(true);}}
    >
      <DialogTitle>{disabled ? 'Chi tiết' : 'Cập nhật'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{marginBottom: 10}}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              disabled={disabled}
              label='Tên hội thảo'
              value={values?.title || ''}
              style={{ paddingBottom: 0 }}
              onChange={(event) => handleChangeForm('title', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              disabled={disabled}
              label='Điểm GS-PGS'
              value={values?.type || ''}
              onChange={(event) => handleChangeForm('type', event.target.value)}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              disabled={disabled}
              label='Nguồn'
              value={values?.source || ''}
              onChange={(event) => handleChangeForm('source', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              disabled={disabled}
              label='Xếp hạng'
              value={values?.rank || ''}
              onChange={(event) => handleChangeForm('rank', event.target.value)}
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              fullWidth
              type='date'
              size='small'
              disabled={disabled}
              variant='outlined'
              label='Thời gian tổ chức'
              value={values?.organizeAt || '1900-01-01'}
              onChange={(event) => handleChangeForm('organizeAt', event.target.value)}
            />
          </Grid> */}
          <Grid item xs={4}>
            <KPTimePicker
              disabled={disabled}
              fullWidth
              label='Thời gian tổ chức'
              value={values?.organizeAt? moment(values?.organizeAt).format('DD/MM/YYYY') : 'dd/mm/yyyy'}
              setValue={(newValue) => handleChangeForm('organizeAt', newValue)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              disabled={disabled}
              label='Đơn vị tổ chức'
              value={values?.organizeBy || ''}
              onChange={(event) => handleChangeForm('organizeBy', event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 8px 24px' }}>
        <div style={{ float: 'right' }}>
          <Button variant="outlined" color="secondary" onClick={handleClose} style={{ marginRight: 4 }}>Huỷ</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>{disabled ? 'Chỉnh sửa' : 'Xác nhận'}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  updateConference: (id, payload) => dispatch(updateConference(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ConferenceUpdateModal));
