import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllPatent = () => async (dispatch) => {
  const api = API_URLS.PATENT.getAllPatent();
  dispatch({
    type: TYPE.PATENT.GET_ALL_PATENT_LOADING,
    meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PATENT.GET_ALL_PATENT_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PATENT.GET_ALL_PATENT_FAILURE,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getFilteredPatent = (page, pageSize, payload) => async (dispatch) => {
  const api = API_URLS.PATENT.getFilteredPatent(page, pageSize);
  dispatch({
    type: TYPE.PATENT.GET_FILTERED_PATENT_LOADING,
    meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PATENT.GET_FILTERED_PATENT_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PATENT.GET_FILTERED_PATENT_FAILURE,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getPatentById = (exist, id) => async (dispatch) => {
  if (exist) {
    dispatch({
      type: TYPE.PATENT.GET_PATENT_BY_ID_SUCCESS,
      payload: id,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
  } else {
    const api = API_URLS.PATENT.getPatentById(id);
    dispatch({
      type: TYPE.PATENT.GET_PATENT_BY_ID_LOADING,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
    });
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success) {
      dispatch({
        type: TYPE.PATENT.GET_PATENT_BY_ID_SUCCESS,
        payload: response.data.data,
        meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
      });
    } else {
      dispatch({
        type: TYPE.PATENT.GET_PATENT_BY_ID_FAILURE,
        meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
      });
    }
  }
};

export const getAllPatentByProfileId = (id) => async (dispatch) => {
  const api = API_URLS.PATENT.getAllPatentByProfileId(id);
  dispatch({
    type: TYPE.PATENT.GET_ALL_PATENT_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PATENT.GET_ALL_PATENT_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PATENT.GET_ALL_PATENT_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const insertPatent = (profileId, payload) => async (dispatch) => {
  const api = API_URLS.PATENT.insertPatent();
  dispatch({
    type: TYPE.PATENT.INSERT_PATENT_LOADING,
    meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PATENT.INSERT_PATENT_SUCCESS,
      id: profileId,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thêm dữ liệu thành công!'
    }));
    return({success: true, data: response.data.data});
  } else {
    dispatch({
      type: TYPE.PATENT.INSERT_PATENT_FAILURE,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thêm dữ liệu thất bại!'
    }));
    return({success: false, data: null});
  }
};

export const updatePatent = (id, payload) => async (dispatch) => {
  const api = API_URLS.PATENT.updatePatent(id);
  dispatch({
    type: TYPE.PATENT.UPDATE_PATENT_LOADING,
    meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PATENT.UPDATE_PATENT_SUCCESS,
      id,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Cập nhật dữ liệu thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.PATENT.UPDATE_PATENT_FAILURE,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Cập nhật dữ liệu thất bại!'
    }));
    return(false);
  }
};

export const deletePatent = (id) => async (dispatch) => {
  const api = API_URLS.PATENT.deletePatent(id);
  dispatch({
    type: TYPE.PATENT.DELETE_PATENT_LOADING,
    meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PATENT.DELETE_PATENT_SUCCESS,
      id,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xoá dữ liệu thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.PATENT.DELETE_PATENT_FAILURE,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xoá dữ liệu thất bại!'
    }));
    return(false);
  }
};

export const acceptPatent = (id, accept) => async (dispatch) => {
  const api = API_URLS.PATENT.acceptPatent(id, accept);
  dispatch({
    type: TYPE.PATENT.ACCEPT_PATENT_LOADING,
    meta: { prefix: [PREFIX.PATENT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PATENT.ACCEPT_PATENT_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xác nhận thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.PATENT.ACCEPT_PATENT_FAILURE,
      meta: { prefix: [PREFIX.PATENT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xác nhận thất bại!'
    }));
    return(false);
  }
};
