import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function FilePreviewModal(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      style={{ maxWidth: '1920!important' }}
    >
      <DialogTitle>{"File minh chứng"}</DialogTitle>
      {props.url? (
        <DialogContent style={{ minWidth: 990,  minHeight: 700}}>
          <iframe style={{ width: '99%', minHeight: 700 }} src={`${process.env.REACT_APP_DOMAIN}${props.url}`} title={'preview'}/>
        </DialogContent>
      ) : (
        <Alert severity="warning" style={{ margin: 10 }}>Không tìm thấy file minh chứng cho công bố này!</Alert>
      )}
      <DialogActions>
      <Button
          target='_blank'
          rel='noreferrer'
          href={props.url ? `${process.env.REACT_APP_DOMAIN}${props.url}` : null}
          color='secondary'
          variant='outlined'
        >
          Mở bản gốc
        </Button>
        <Button onClick={props.handleClose} color='secondary' variant='contained'>Đóng</Button>
      </DialogActions>
    </Dialog>
  );
}