import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
  isFetching: false,
});

export default function monographReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.NOTIFICATION.SEND_TIMER_NOTIFICATION_LOADING:
    case TYPE.NOTIFICATION.SEND_EMAIL_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.NOTIFICATION.SEND_TIMER_NOTIFICATION_SUCCESS:
    case TYPE.NOTIFICATION.SEND_EMAIL_SUCCESS:
      return state.merge({
        isFetching: false,
      });

    case TYPE.NOTIFICATION.SEND_TIMER_NOTIFICATION_FAILURE:
    case TYPE.NOTIFICATION.SEND_EMAIL_FAILURE:
      return state.merge({
        isFetching: false,
      });
    
    default: return state;
  }
}