import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, Chip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";

import toJs from "../../../hoc/toJS";
import select from "../../../utils/select";
import ROUTER from "../../../constants/router";

import KPTable from "../../../components/KPTable";
import EmploymentContractUpdateModal2 from "../EmploymentContractUpdateModal2";
import EmploymentContractDeleteModal from "../EmploymentContractDeleteModal";
import {
  activateEmploymentContract,
  getAllEmploymentContract,
} from "../../../reducer/employmentContract/action";
import { AllInclusive } from "@material-ui/icons";

const useStyles = makeStyles((_theme) =>
  createStyles({
    btnAction: {
      cursor: "pointer",
    },
  })
);

function EmploymentContractTable(props) {
  const { role, activateEmploymentContract, getAllEmploymentContract } = props;

  const classes = useStyles();

  const [updating, setUpdating] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    getAllEmploymentContract();
  }, [getAllEmploymentContract]);

  const handleUpdate = (e) => {
    setUpdating(e);
  };

  const handleDelete = (event, e) => {
    setDeleting(e.contract.id);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <KPTable
        shadow="none"
        rowsPerPage={10}
        maxHeight="calc(100vh - 235px)"
        columns={[
          {
            title: <b>Tên</b>,
            key: "name",
            width: "20%",
            searchable: true,
            render: (value, row) => (
              <a
                href={`${ROUTER.WORK}/profile-id=${row?.profile?.id}`}
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography>
                  <b>{value}</b>
                </Typography>
              </a>
            ),
          },
          {
            title: <b>Ngành</b>,
            key: "major",
            width: "20%",
            searchable: true,
            render: (value, row) => (
              <Typography>{row.profile?.major}</Typography>
            ),
          },
          {
            title: <b>Vị trí</b>,
            key: "occupation",
            width: "20%",
            filters: [
              {
                text: "Nhà nghiên cứu",
                value: "researcher",
              },
              {
                text: "Quản lý nhân sự",
                value: "humanresource",
              },
              {
                text: "Postdoc",
                value: "postdoc",
              },
              {
                text: "Trợ lý nghiên cứu",
                value: "tlnc",
              },
            ],
            onFilter: (value, row) => row.filter((e) => e?.profile?.user?.occupation === value),
            render: (_, row) => {
              switch (row?.profile?.user?.occupation) {
                case "researcher":
                  return "Nhà nghiên cứu";
                case "humanresource":
                  return "Quản lý nhân sự";
                case "postdoc":
                  return "Postdoc";
                case "tlnc":
                  return "Trợ lý nghiên cứu";
                default:
                  return "";
              }
            },
          },
          {
            title: <b>Tình trạng</b>,
            key: "contract.active",
            width: "18%",
            filters: [
              {
                text: "Đang có hiệu lực",
                value: true,
              },
              {
                text: "Hết hiệu lực",
                value: false,
              },
            ],
            onFilter: (value, row) =>
              row.filter((e) => e.contract.active === value),
            render: (value, row) => {
              if (row.contract?.active) {
                return (
                  <Chip
                    label="Đang có hiệu lực"
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                );
              }
              return (
                <Chip
                  label="Hết hiệu lực"
                  size="small"
                  variant="outlined"
                  color="secondary"
                />
              );
            },
          },
          {
            title: <b>Hoàn thành KPI</b>,
            key: "contract.kpiCompleted",
            width: "18%",
            filters: [
              {
                text: "Đã hoàn thành",
                value: true,
              },
              {
                text: "Chưa hoàn thành",
                value: false,
              },
            ],
            onFilter: (value, row) =>
              row.filter((e) => {
                if (value) {
                  if (
                    e?.contract?.listKpi.filter(
                      (item) => item?.completed !== value
                    ).length <= 0
                  )
                    return e;
                } else {
                  if (
                    e?.contract?.listKpi.filter(
                      (item) => item?.completed === value
                    ).length > 0
                  )
                    return e;
                }
              }),
            render: (value, row) => {
              if (
                row?.contract?.listKpi?.filter(
                  (item) => item?.completed !== true
                ).length <= 0
              ) {
                return (
                  <Chip
                    label="Hoàn thành"
                    size="small"
                    variant="outlined"
                    color="primary"
                  />
                );
              }
              return (
                <Chip
                  label="Chưa hoàn thành"
                  size="small"
                  variant="outlined"
                  color="secondary"
                />
              );
            },
          },
          {
            title: <b>Thời hạn</b>,
            key: "contract.duration",
            width: "15%",
            sorter: (a, b) => a.contract?.duration - b.contract?.duration,
            render: (value, row) => {
              if (row.contract?.duration === 1000) {
                return <Typography>Vô thời hạn</Typography>;
              }
              return <Typography>{row.contract?.duration} năm</Typography>;
            },
          },
          {
            title: <b>Bắt đầu</b>,
            key: "contract.startAt",
            width: "15%",
            sorter: (a, b) =>
              dayjs(a.contract?.startAt).diff(b.contract?.startAt),
            render: (value, row) => (
              <Typography>
                {dayjs(row.contract?.startAt ?? "").format("DD/MM/YYYY")}
              </Typography>
            ),
          },
          {
            title: <b>Kết thúc</b>,
            key: "contract.finishAt",
            width: "15%",
            sorter: (a, b) =>
              dayjs(a.contract?.startAt).diff(b.contract?.startAt),
            render: (value, row) => (
              <Typography>
                {row.contract?.duration === 1000 ? (
                  <AllInclusive style={{ marginLeft: 30 }} />
                ) : (
                  dayjs(row.contract?.finishAt ?? "").format("DD/MM/YYYY")
                )}
              </Typography>
            ),
          },
          {
            title: <b>Tác vụ</b>,
            key: "action",
            width: "20%",
            render: (value, row) => {
              if (role !== "humanresource" && role !== "sudo-admin") {
                return (
                  <Typography
                    className={classes.btnAction}
                    variant="caption"
                    color="primary"
                    onClick={() => handleUpdate(row)}
                  >
                    Chi tiết
                  </Typography>
                );
              }
              return (
                <>
                  <Typography
                    className="table-action-buton"
                    variant="caption"
                    color="primary"
                    onClick={() => handleUpdate(row)}
                  >
                    Chi tiết
                  </Typography>
                  <Typography
                    className="table-action-buton"
                    variant="caption"
                    color="secondary"
                    onClick={(event) => handleDelete(event, row)}
                  >
                    Xoá
                  </Typography>
                  <Typography
                    className="table-action-buton"
                    variant="caption"
                    color="initial"
                    onClick={() =>
                      activateEmploymentContract(
                        row.contract?.id,
                        !row.contract?.active
                      )
                    }
                  >
                    {row.contract?.active ? "Hủy kích hoạt" : "Kích hoạt"}
                  </Typography>
                </>
              );
            },
          },
        ]}
        rows={props.listEmploymentContract.map((item) => {
          return {
            ...item,
            name: item?.profile?.name ?? "",
            major: item?.profile?.major ?? "",
          };
        })}
        loading={props.isFetching}
      />
      {updating !== null && (
        <EmploymentContractUpdateModal2
          updating={updating}
          setUpdating={setUpdating}
        />
      )}
      <EmploymentContractDeleteModal
        deleting={deleting}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, "userReducer", "role"),
  allUsers: select(state, "humanResourceReducer", "allUsers"),
  listEmploymentContract: select(state, "employmentContractReducer", "list"),
  isFetching: select(state, "employmentContractReducer", "isFetching"),
});

const mapDispatchToProps = (dispatch) => ({
  activateEmploymentContract: (id, active) =>
    dispatch(activateEmploymentContract(id, active)),
  getAllEmploymentContract: () => dispatch(getAllEmploymentContract()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJs(EmploymentContractTable));
