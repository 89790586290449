import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { notification } from '../home/action';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllJournalDomestic = () => async (dispatch) => {
  const api = API_URLS.JOURNAL_DOMESTIC.getAllJournalDomestic();
  dispatch({
    type: TYPE.JOURNAL_DOMESTIC.GET_ALL_JOURNAL_DOMESTIC_LOADING,
    meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.GET_ALL_JOURNAL_DOMESTIC_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.GET_ALL_JOURNAL_DOMESTIC_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_FAILURE] },
    });
  }
};

export const insertJournalDomestic = (payload) => async (dispatch) => {
  const api = API_URLS.JOURNAL_DOMESTIC.insertJournalDomestic();
  dispatch({
    type: TYPE.JOURNAL_DOMESTIC.INSERT_JOURNAL_DOMESTIC_LOADING,
    meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.INSERT_JOURNAL_DOMESTIC_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thêm dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.INSERT_JOURNAL_DOMESTIC_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thêm dữ liệu thất bại!'
    }));
  }
};

export const updateJournalDomestic = (id, payload) => async (dispatch) => {
  const api = API_URLS.JOURNAL_DOMESTIC.updateJournalDomestic(id);
  dispatch({
    type: TYPE.JOURNAL_DOMESTIC.UPDATE_JOURNAL_DOMESTIC_LOADING,
    meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.UPDATE_JOURNAL_DOMESTIC_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Cập nhật dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.UPDATE_JOURNAL_DOMESTIC_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Cập nhật dữ liệu thất bại!'
    }));
  }
};

export const deleteJournalDomestic = (id) => async (dispatch) => {
  const api = API_URLS.JOURNAL_DOMESTIC.deleteJournalDomestic(id);
  dispatch({
    type: TYPE.JOURNAL_DOMESTIC.DELETE_JOURNAL_DOMESTIC_LOADING,
    meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.DELETE_JOURNAL_DOMESTIC_SUCCESS,
      id,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xoá dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.JOURNAL_DOMESTIC.DELETE_JOURNAL_DOMESTIC_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL_DOMESTIC, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xoá dữ liệu thất bại!'
    }));
  }
};
