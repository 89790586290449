import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';
import ROUTER from '../../../../constants/router';

import KPTable from '../../../../components/KPTable';

import { updateTimeResearchInformation } from '../../../../reducer/timeResearchInformation/action';

function ResearchTimeTable(props) {
  const [open, setOpen] = React.useState(false);
  const [editting, setEditting] = useState(null);

  useEffect(() => {
    if (props.history !== null && props.role !== 'sudo-admin') props.history.push(ROUTER.HOME);
  }, []);

  useEffect(() => {
    setEditting(null);
  }, [props.timeResearchInformationList]);

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const handleSumit = (e) => {
    e.preventDefault();

    const index = props.timeResearchInformationList.find((e) => e.year === props.year).id;
    
    const temp = props.timeResearchInformationList.find((e) => e.year === props.year)?.timeResearchDetail;
    const id = temp.findIndex((e) => e.id === editting.id);
    temp.splice(id, 1, editting);

    props.updateTimeResearchInformation(
      index,
      {
        ...props.timeResearchInformationList.find((e) => e.year === props.year),
        timeResearchDetail: temp,
      }
    );

    setEditting(null);
  }

  const handleChange = (value) => setEditting({
    ...editting,
    value: value,
  });

  const list = props.timeResearchInformationList.find((e) => e.year === props.year)?.timeResearchDetail || [];

  return (
    <>
      <KPTable
        shadow="none"
        rowsPerPage={20}
        maxHeight='calc(100vh - 300px)'
        columns={[
          {
            title: <b>STT</b>,
            key: 'stt',
            width: '5%',
          },
          {
            title: <b>Nhiệm vụ</b>,
            key: 'description',
            width: '60%',
          },
          {
            title: <b>Số giờ NCKH</b>,
            key: 'value',
            width: '15%',
            sorter: (a, b) => a.value - b.value,
            render: (value, row) => {
              if (editting?.id === row.id) {
                return (
                  <form onSubmit={(event) => handleSumit(event)}>
                    <TextField
                      autoFocus
                      fullWidth
                      size='small'
                      type='number'
                      variant='outlined'
                      value={editting.value}
                      onChange={(event) => handleChange(parseInt(event.target.value))}
                    />
                  </form>
                )
              }
              return (value)
            }
          },
          {
            title: <b>Tác vụ</b>,
            key: 'action',
            width: '20%',
            render: (value, row) => (
              <>
                {(editting?.id !== row.id) ? (
                  <Typography className='table-action-buton' variant='caption' color='primary' onClick={() => setEditting(row)}>Sửa</Typography>
                ) : (
                  <>
                    <Typography className='table-action-buton' variant='caption' color='primary' onClick={handleSumit}>Lưu</Typography>
                    <Typography className='table-action-buton' variant='caption' color='secondary' onClick={() => setEditting(null)}>Huỷ</Typography>
                  </>
                )}
              </>
            )
          }
        ]}
        rows={list.sort((a, b) => a.stt - b.stt)}
      />

      <Button
        color='primary'
        variant='contained'
        style={{ left: 16, bottom: 44 }}
        onClick={handleClickOpen}
      >
        Chú thích
      </Button>
    
      <Dialog maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Chú thích
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Đối với các đề tài, dự án nghiên cứu: Chủ nhiệm đề tài được hưởng 1/3 số giờ NCKH quy đổi, 2/3 còn lại chia đều cho các thành viên bao gồm cả Chủ nhiệm. Các thành viên tham gia để tài (không phải Chủ nhiệm) sẽ được hưởng số giờ bằng 2/3 số giờ quy đổi của đề tài, dự án chia cho tổng số thành viên tham gia bao gồm cả Chủ nhiệm đề tài.
          </Typography>
          <br />
          <Typography gutterBottom>
            Đối với Sách/Giáo trình có chủ biên: Chủ biên được hưởng 1/3 số giờ quy đổi, 2/3 số giờ còn lại được chia đều cho các tác giả bao gồm cả chủ biên; với Sách/Giáo trình không có chủ biên thì số giờ sẽ được chia đều cho các tác giả.
          </Typography>
          <br />
          <Typography gutterBottom>
            Đối với bài báo khoa học, kỷ yếu hội nghị, Tác giả chính được hưởng 1/3 số giờ chuẩn quy đổi của bài báo khoa học hay kỷ yếu hội nghị đó, 2/3 số giờ còn lại được chia đều cho các tác giả bao gồm cả tác giả chính.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant='contained' onClick={handleClose}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, 'userReducer', 'role'),
  history: select(state, 'homeReducer', 'history'),
  year: select(state, 'timeResearchInformationReducer', 'year'),
  yearFilter: select(state, 'timeResearchInformationReducer', 'yearFilter'),
  timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
});

const mapDispatchToProps = (dispatch) => ({
  updateTimeResearchInformation: (id, payload) => dispatch(updateTimeResearchInformation(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ResearchTimeTable));
