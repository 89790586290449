import { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs, Tab } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import toJS from '../../hoc/toJS';
import select from '../../utils/select';

import { DepartmentProvider } from './DepartmentContext';

import DepartmentButton from './DepartmentButton';
import DepartmentTable from './DepartmentTable';

import DepartmentMemberButton from './DepartmentMemberButton';
import DepartmentMemberTable from './DepartmentMemberTable';

const useStyles = makeStyles((theme) =>
  createStyles({
    departmentCard: {
      padding: theme.spacing(2),
      minHeight: 'calc(100vh - 124px)',
      maxHeight: 'calc(100vh - 124px)',
    },
    departmentTabBar: {
      padding: theme.spacing(0, 0, 2, 0),
    },
  })
);

function DepartmentRoot(props) {
  const classes = useStyles();

  const { role } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        {...other}
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (children)}
      </div>
    );
  }

  return (
    <DepartmentProvider>
      <Card className={classes.departmentCard}>
        <Tabs
          className={classes.departmentTabBar}
          value={value}
          onChange={handleChange}
          indicatorColor={"secondary"}
        >
          {(role === 'sudo-admin') && <Tab label="Đơn vị" />}
          <Tab label="Thành viên" />
        </Tabs>
        {
          (role === 'sudo-admin') && 
          <TabPanel value={value} index={0}>
            <DepartmentButton />
            <DepartmentTable />
          </TabPanel>
        }
        <TabPanel value={value} index={role === 'sudo-admin' ? 1 : 0}>
          <DepartmentMemberButton />
          <DepartmentMemberTable />
        </TabPanel>
      </Card>
    </DepartmentProvider>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, 'userReducer', 'role'),
});

export default connect(mapStateToProps)(toJS(DepartmentRoot));
