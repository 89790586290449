import { Save, Edit, Check, Block, SkipNext } from "@material-ui/icons";
import { Step, Stepper, StepLabel } from "@material-ui/core";
import { DefaultForm, MemberForm, CouncilForm, StopForm, ReportForm, ReportTable, SettingForm, DeleteForm, SubmitForm, DocumentForm, ExportForm } from "../StudentProjectForms";

const color = {
  error: "#e00000",
  primary: "#243474",
  success: "#60ae7d",
  secondary: "#f46423",
};

const modalPropertiesDict = {
  insert: {
    title: "Thêm đề tài NCKH cấp sinh viên",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="insert-form" />,
    actionButtons: (_props) => [
      { closeButton: true },
      {
        props: {
          form: "insert-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Thêm đề tài",
      },
    ],
  },
  detail: {
    title: "Chi tiết đề tài",
    detail: true,
    // paperProps: { style: { maxWidth: 1020 } },
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="detail-form" />,
    actionButtons: () => [{ closeButton: true }],
  },
  detailOnlyInfo: {
    title: "Chi tiết đề tài",
    detail: true,
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="detail-only-info-form" />,
    actionButtons: () => [{ closeButton: true }],
  },
  detailOnlyMembers: {
    title: "Chi tiết đề tài",
    detail: true,
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="detail-only-members-form" />,
    actionButtons: () => [{ closeButton: true }],
  },
  edit: {
    title: "Chỉnh sửa đề tài",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="edit-form" />,
    actionButtons: (_props) => [
      { closeButton: true },
      {
        // disabledText:
        //   !_props.files?.presentationFile &&
        //   !_props.values?.presentationFileLink
        //     ? "Không thể thêm vì thiếu File thuyết minh"
        //     : !_props.files?.statisticPlanFile &&
        //       !_props.values?.statisticPlanFileLink
        //     ? "Không thể thêm vì thiếu File thông tin trích ngang"
        //     : null,
        props: {
          form: "edit-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Chỉnh sửa đề tài",
      },
    ],
  },
  acceptInfo: {
    title: "Duyệt thông tin đề tài",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="accept-info-form" />,
    actionButtons: (props) => [
      { closeButton: true },
      {
        disabledText: !props.values?.leaveReason ? 'Không thể hủy do chưa điền "Lý do huỷ đề tài"' : null,
        props: {
          form: "accept-info-form",
          className: props.values?.leaveReason ? "error-button" : null,
          type: "submit",
          name: "rejected",
          color: "primary",
          variant: "contained",
          startIcon: <Block />,
        },
        content: "Huỷ đề tài",
      },
      {
        props: {
          form: "accept-info-form",
          type: "submit",
          name: "info_accepted",
          color: "primary",
          variant: "contained",
          startIcon: <Check />,
        },
        content: "Duyệt thông tin",
      },
    ],
  },
  insertMember: {
    title: "Thêm thành viên",
    style: { padding: "16px 24px" },
    form: (props) => <MemberForm {...props} id="member-form" />,
    actionButtons: (props) => [
      { closeButton: true },
      {
        props: {
          form: "member-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Lưu danh sách thành viên",
      },
    ],
  },
  editMember: {
    title: "Chỉnh sửa thành viên",
    style: { padding: "16px 24px" },
    form: (props) => <MemberForm {...props} id="edit-member" />,
    actionButtons: (_props) => [
      { closeButton: true },
      {
        props: {
          form: "edit-member",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Chỉnh sửa thành viên",
      },
    ],
  },
  acceptMember: {
    title: "Thêm thành viên",
    style: { padding: "16px 24px" },
    form: (props) => <MemberForm {...props} id="accept-member-form" />,
    actionButtons: (props) => [
      { closeButton: true },
      {
        props: {
          form: "accept-member-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Check />,
        },
        content: "Duyệt danh sách thành viên",
      },
    ],
  },
  acceptAll: {
    title: "Duyệt thực hiện đề tài",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="accept_info-form" />,
    actionButtons: () => [
      { closeButton: true },
      {
        props: {
          form: "accept_info-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Check />,
        },
        content: "Duyệt thực hiện",
      },
    ],
  },
  insertCouncil: {
    title: "Thêm hội đồng nghiệm thu cấp Khoa",
    form: (props) => <CouncilForm {...props} id="council-form" />,
    actionButtons: () => [
      { closeButton: true },
      {
        props: {
          form: "council-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Lưu hội đồng nghiệm thu",
      },
    ],
  },
  councilDetail: {
    title: "Xem lại hội đồng nghiệm thu cấp Khoa",
    form: (props) => <CouncilForm {...props} values={props.values.councilMemberInfo} id="council-detail-form" />,
    actionButtons: () => [{ closeButton: true }],
  },
  detailCouncil: {
    title: "Chi tiết đề tài",
    size: "xl",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <DefaultForm {...props} id="detail-form" big={true} />,
    actionButtons: () => [{ closeButton: true }],
  },
  submit: {
    title: "Nộp báo cáo tổng kết",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <SubmitForm {...props} id="submit-form" />,
    actionButtons: () => [
      { closeButton: true },
      {
        props: {
          form: "submit-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Nộp báo cáo",
      },
    ],
  },
  submitDetail: {
    title: "Xem lại báo cáo tổng kết",
    size: "xl",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <SubmitForm {...props} id="submit-again-form" big={true} />,
    actionButtons: () => [{ closeButton: true }],
  },
  submitDetailEdit: {
    title: "Chỉnh sửa báo cáo tổng kết",
    size: "xl",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <SubmitForm {...props} id="submit-edit-form" big={true} />,
    actionButtons: () => [
      { closeButton: true },
      {
        props: {
          form: "submit-edit-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Nộp báo cáo",
      },
    ],
  },
  defaultDetail: {
    title: "Chi tiết báo cáo tổng kết",
    size: "xl",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <SubmitForm {...props} id="default-detail-form" big={true} />,
    actionButtons: () => [{ closeButton: true }],
  },
  defaultDetailNotCouncil: {
    title: "Chi tiết báo cáo tổng kết",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <SubmitForm {...props} id="default-detail-form" />,
    actionButtons: () => [{ closeButton: true }],
  },
  submitAccept: {
    title: "Nghiệm thu đề tài",
    size: "xl",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <SubmitForm {...props} id="submit-accept-form" big={true} />,
    actionButtons: (_props) => [
      { closeButton: true },
      {
        disabledText: !_props.values?.editRequest ? "Chưa điền Yêu cầu chỉnh sửa, bổ sung" : null,
        props: {
          form: "submit-accept-form",
          name: "edit",
          type: "submit",
          color: "secondary",
          variant: "contained",
          startIcon: <Edit />,
        },
        content: "Yêu cầu chỉnh sửa, bổ sung",
      },
      {
        disabledText: _props.values?.editRequest ? "Đã điền Yêu cầu chỉnh sửa, bổ sung" : null,
        props: {
          form: "submit-accept-form",
          name: "accept",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Nghiệm thu đề tài",
      },
    ],
  },
  submitAcceptAgain: {
    title: "Nghiệm thu đề tài",
    size: "xl",
    style: { padding: 0, overflow: "hidden" },
    form: (props) => <SubmitForm {...props} id="submit-accept-again-form" big={true} />,
    actionButtons: (_props) => [
      { closeButton: true },
      {
        disabledText: !_props.values?.editRequest ? "Chưa điền Yêu cầu chỉnh sửa, bổ sung" : null,
        props: {
          form: "submit-accept-again-form",
          name: "edit",
          type: "submit",
          color: "secondary",
          variant: "contained",
          startIcon: <Edit />,
        },
        content: "Yêu cầu chỉnh sửa, bổ sung",
      },
      {
        disabledText: _props.values?.editRequest ? "Đã điền Yêu cầu chỉnh sửa, bổ sung" : null,
        props: {
          form: "submit-accept-again-form",
          name: "accept",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Nghiệm thu đề tài",
      },
    ],
  },
  report: {
    title: "Báo cáo tiến độ",
    form: (props) => {
      if (props.step === 0) return <StopForm {...props} id="stop-form" />;
      return <ReportForm {...props} setStep={props.setStep} id="report-form" />;
    },
    actionButtons: (_props) => [
      {
        props: {
          color: "secondary",
          variant: "contained",
          disabled: _props.step === 2,
          startIcon: (
            <SkipNext
              style={{
                transition: "0.4s",
                transform: `rotate(${_props.step === 0 ? 0 : 180}deg)`,
              }}
            />
          ),
          onClick: () => _props.setStep(1 - _props.step),
          style: { width: 116, justifyContent: "space-between" },
        },
        content: _props.step === 0 ? "Bỏ qua" : "Quay lại",
      },
      { props: { disabled: true, component: "div", style: { flexGrow: 1 } } },
      { closeButton: true },
      {
        disabledText:
          _props.step === 1 && !_props.files?.finishedWork
            ? "Không thể báo cáo vì thiếu File Các công việc đã hoàn thành"
            : _props.members.filter((e) => e.researchRole === "main").length < 1
            ? "Chưa có chủ nhiệm đề tài"
            : _props.members.filter((e) => e.researchRole === "main").length > 1
            ? "Chỉ được có 1 chủ nhiệm đề tài"
            : _props.members.find((e, id) => e.isStop && !_props.files?.[`members.${id}.leaveFileLink`])
            ? "Thiếu File đơn xin dừng"
            : null,
        props: {
          form: _props.step === 0 ? "stop-form" : "report-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: _props.step === 0 ? "Xin dừng thực hiện" : _props.step === 1 ? "Gửi báo cáo" : "Đổi giảng viên hướng dẫn chính",
      },
    ],
    reportHeader: (step) => (
      <Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
        <Step key={0}>
          <StepLabel error={step !== 0}>
            <b>Xin dừng thực hiện</b>
            <br />
            {step === 0 ? "(Đang tiến hành)" : " (Đã bỏ qua)"}
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>
            <b>Báo cáo tiến độ</b>
            <br />
            {step === 0 ? "(Không thực hiện)" : " (Đang tiến hành)"}
          </StepLabel>
        </Step>
      </Stepper>
    ),
  },
  stopDetail: {
    title: "Chi tiết đơn xin dừng",
    form: (props) => {
      if (props.step === 0) return <StopForm {...props} id="stop-detail-form" disabled={true} />;
      return <ReportTable {...props} id="report-detail-form" disabled={true} />;
    },
    actionButtons: (_props) => [{ closeButton: true }],
    reportHeader: (step) => (
      <Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
        <Step key={0}>
          <StepLabel error={step === 1}>
            <b>Xin dừng thực hiện</b>
            <br />
            {step === 0 ? "(Đang tiến hành)" : " (Đã bỏ qua)"}
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>
            <b>Báo cáo tiến độ</b>
            <br />
            {step === 1 ? "(Đang tiến hành)" : " (Không thực hiện)"}
          </StepLabel>
        </Step>
      </Stepper>
    ),
  },
  reportDetail: {
    title: "Xem lại báo cáo tiến độ",
    // size: 'lg',
    form: (props) => {
      if (props.step === 0) return <StopForm {...props} id="stop-detail-form" disabled={true} />;
      return <ReportForm {...props} id="report-detail-form" disabled={true} />;
    },
    actionButtons: (_props) => [{ closeButton: true }],
    reportHeader: (step) => (
      <Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
        <Step key={0}>
          <StepLabel error={step === 1}>
            <b>Xin dừng thực hiện</b>
            <br />
            {step === 0 ? "(Đang tiến hành)" : " (Đã bỏ qua)"}
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>
            <b>Báo cáo tiến độ</b>
            <br />
            {step === 1 ? "(Đang tiến hành)" : " (Không thực hiện)"}
          </StepLabel>
        </Step>
      </Stepper>
    ),
  },
  reportStop: {
    title: "Xem lại đơn xin dừng",
    form: (props) => {
      if (props.step === 0) return <StopForm {...props} id="stop-detail-form" disabled={true} />;
      return <ReportForm {...props} id="report-detail-form" disabled={true} />;
    },
    actionButtons: (_props) => [{ closeButton: true }],
    reportHeader: (step) => (
      <Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
        <Step key={0}>
          <StepLabel error={step === 1}>
            <b>Xin dừng thực hiện</b>
            <br />
            {step === 0 ? "(Đang tiến hành)" : " (Đã bỏ qua)"}
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>
            <b>Báo cáo tiến độ</b>
            <br />
            {step === 1 ? "(Đang tiến hành)" : " (Không thực hiện)"}
          </StepLabel>
        </Step>
      </Stepper>
    ),
  },
  stopAccept: {
    title: "Duyệt đơn xin dừng",
    form: (props) => {
      if (props.step === 0) return <StopForm {...props} id="stop-accept-form" disabled={true} />;
      return <ReportForm {...props} id="report-accept-form" disabled={true} />;
    },
    actionButtons: (_props) => [
      { closeButton: true },
      {
        props: {
          form: _props.step === 0 ? "stop-accept-form" : "report-accept-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Check />,
        },
        content: _props.step === 0 ? "Duyệt dừng thực hiện" : "Duyệt đảm bảo tiến độ",
      },
    ],
    reportHeader: (step) => (
      <Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
        <Step key={0}>
          <StepLabel error={step === 1}>
            <b>Xin dừng thực hiện</b>
            <br />
            {step === 0 ? "(Đang tiến hành)" : " (Đã bỏ qua)"}
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>
            <b>Báo cáo tiến độ</b>
            <br />
            {step === 1 ? "(Đang tiến hành)" : " (Không thực hiện)"}
          </StepLabel>
        </Step>
      </Stepper>
    ),
  },
  reportAccept: {
    title: "Duyệt báo cáo tiến độ",
    form: (props) => {
      if (props.step === 0) return <StopForm {...props} id="stop-accept-form" disabled={true} />;
      return <ReportForm {...props} id="report-accept-form" disabled={true} />;
    },
    actionButtons: (_props) => [
      { closeButton: true },
      {
        props: {
          form: _props.step === 0 ? "stop-accept-form" : "report-accept-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Check />,
        },
        content: _props.step === 0 ? "Duyệt dừng thực hiện" : "Duyệt đảm bảo tiến độ",
      },
    ],
    reportHeader: (step) => (
      <Stepper className="step-modal" activeStep={step} style={{ flexGrow: 1, padding: 0 }}>
        <Step key={0}>
          <StepLabel error={step === 1}>
            <b>Xin dừng thực hiện</b>
            <br />
            {step === 0 ? "(Đang tiến hành)" : " (Đã bỏ qua)"}
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>
            <b>Báo cáo tiến độ</b>
            <br />
            {step === 1 ? "(Đang tiến hành)" : " (Không thực hiện)"}
          </StepLabel>
        </Step>
      </Stepper>
    ),
  },
  delete: {
    title: "Xóa đề tài",
    size: "xs",
    form: (props) => <DeleteForm handleClose={props.handleClose} title={props.detail.name} deleteId={props.detail.id} id="report-accept-form" />,
    actionButtons: () => [],
  },
  setting: {
    title: "Cấu hình hệ thống",
    size: "sm",
    form: (props) => <SettingForm handleClose={props.handleClose} year={props.year} id="setting-form" />,
    actionButtons: () => [
      { closeButton: true },
      {
        props: {
          form: "setting-form",
          type: "submit",
          color: "primary",
          variant: "contained",
          startIcon: <Save />,
        },
        content: "Lưu cấu hình",
      },
    ],
  },
  document: {
    title: "Thư viện",
    form: (props) => <DocumentForm {...props} id="document-form" />,
    actionButtons: () => [{ closeButton: true }],
  },
  export: {
    title: "Xuất file báo cáo",
    form: (props) => <ExportForm {...props} id="export-form" />,
    actionButtons: () => [{ closeButton: true }],
  },
};

const rowPropertiesDict = {
  info_default: {
    status: [
      {
        value: "info_default",
        label: "Đã gửi hồ sơ",
        bgcolor: color.primary,
      },
    ],
    buttons: {
      personal: ["detail", ["edit", "delete"]],
      admin: ["acceptInfo", "delete"],
      mod: ["acceptInfo", "delete"],
    },
  },
  info_accepted: {
    status: [
      {
        value: "info_default",
        label: "Đã duyệt thông tin sơ bộ",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "insertMember"],
      admin: ["detail", "disabled"],
      mod: ["detail", "insertMember"],
    },
  },
  member_default: {
    status: [
      {
        value: "member_default",
        label: "Chưa được duyệt thành viên",
        bgcolor: color.primary,
      },
    ],
    buttons: {
      personal: ["detail", ["editMember", "delete"]],
      admin: ["detail", "acceptMember"],
      mod: ["detail", "disabled"],
    },
  },
  member_accepted: {
    status: [
      {
        value: "member_accepted",
        label: "Đã được duyệt thành viên",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "disabled"],
      admin: ["detail", "disabled"],
      mod: ["acceptAll", "disabled"],
    },
  },
  info_adjusted: {
    status: [
      {
        value: "info_adjusted",
        label: "Đã gửi chỉnh sửa, bổ sung",
        bgcolor: color.secondary,
      },
    ],
    buttons: {
      personal: ["detail", "disabled"],
      admin: ["acceptInfoAgain", "disabled"],
      mod: ["acceptInfoAgain", "disabled"],
    },
  },
  info_rejected: {
    status: [
      {
        value: "info_rejected",
        label: "Đề tài bị hủy",
        bgcolor: color.error,
      },
    ],
    buttons: {
      personal: ["detail", "disabled"],
      admin: ["detail", "disabled"],
      mod: ["detail", "disabled"],
    },
  },
  info_council: {
    status: [
      {
        value: "info_default",
        label: "Đã duyệt thông tin sơ bộ",
        bgcolor: color.success,
      },
      {
        value: "info_council",
        label: "Đã được thành lập hội đồng xét duyệt",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detailCouncil", "disabled"],
      admin: ["detailCouncil", "disabled"],
      mod: ["detailCouncil", "disabled"],
    },
  },
  conducted: {
    status: [
      {
        value: "conducted",
        label: "Được duyệt thực hiện",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "disabled"],
      admin: ["detail", "disabled"],
      mod: ["detail", "disabled"],
    },
  },
  report_default: {
    status: [
      {
        value: "report_default",
        label: "Chưa gửi báo cáo tiến độ",
        bgcolor: color.primary,
      },
    ],
    buttons: {
      personal: ["detail", "report"],
      admin: ["detail", "disabled"],
      mod: ["detail", "report"],
    },
  },
  report_submitted: {
    status: [
      {
        value: "report_submitted",
        label: "Đã gửi báo cáo tiến độ",
        bgcolor: color.secondary,
      },
    ],
    buttons: {
      personal: ["detail", "reportDetail"],
      admin: ["detail", "reportAccept"],
      mod: ["detail", "reportDetail"],
    },
  },
  report_ensure: {
    status: [
      {
        value: "report_ensure",
        label: "Đảm bảo tiến độ",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "reportDetail"],
      admin: ["detail", "reportDetail"],
      mod: ["detail", "reportDetail"],
    },
  },
  report_stop_default: {
    status: [
      {
        value: "report_stop_default",
        label: "Đã gửi đơn xin dừng",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "stopDetail"],
      admin: ["detail", "stopAccept"],
      mod: ["detail", "stopDetail"],
    },
  },
  report_stop_accepted: {
    status: [
      {
        value: "report_stop_accepted",
        label: "Đã duyệt đơn xin dừng",
        bgcolor: color.error,
      },
    ],
    buttons: {
      personal: ["detail", "reportStop"],
      admin: ["detail", "reportStop"],
      mod: ["detail", "reportStop"],
    },
  },
  default_council_default: {
    status: [
      {
        value: "default",
        label: "Chưa nộp báo cáo",
        bgcolor: color.primary,
      },
      {
        value: "council_default",
        label: "Chưa gán hội đồng",
        bgcolor: color.primary,
      },
    ],
    buttons: {
      personal: ["detail", "submit"],
      admin: ["detail", "insertCouncil"],
      mod: ["detail", "submit"],
    },
  },
  default_council_accepted: {
    status: [
      {
        value: "default",
        label: "Chưa nộp báo cáo",
        bgcolor: color.primary,
      },
      {
        value: "council_accepted",
        label: "Đã gán hội đồng",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "submit"],
      admin: ["detail", "councilDetail"],
      mod: ["detail", "submit"],
    },
  },
  submitted_council_default: {
    status: [
      {
        value: "submitted",
        label: "Đã nộp báo cáo",
        bgcolor: color.success,
      },
      {
        value: "council_default",
        label: "Chưa gán hội đồng",
        bgcolor: color.primary,
      },
    ],
    buttons: {
      personal: ["detail", "defaultDetailNotCouncil"],
      admin: ["detail", "insertCouncil"],
      mod: ["detail", "defaultDetailNotCouncil"],
    },
  },
  submitted_council_accepted: {
    status: [
      {
        value: "submitted",
        label: "Đã nộp báo cáo",
        bgcolor: color.success,
      },
      {
        value: "council_accepted",
        label: "Đã gán hội đồng",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "defaultDetail"],
      admin: ["detail", "submitAccept"],
      mod: ["detail", "defaultDetail"],
    },
  },
  submitted: {
    status: [
      {
        value: "submitted",
        label: "Đã nộp báo cáo",
        bgcolor: color.success,
      },
      {
        value: "council_accepted",
        label: "Đã gán hội đồng",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "defaultDetail"],
      admin: ["detail", "submitAcceptAgain"],
      mod: ["detail", "defaultDetail"],
    },
  },
  accepted_eligible: {
    status: [
      {
        value: "accepted",
        label: "Đã nghiệm thu cấp khoa",
        bgcolor: color.success,
      },
      {
        value: "eligible",
        label: "Tham gia hội nghị NCKH cấp trường",
        bgcolor: color.success,
      },
    ],
    buttons: {
      personal: ["detail", "submitDetail"],
      admin: ["detail", "submitDetail"],
      mod: ["detail", "submitDetail"],
      conference: ["detail", "submitDetail"],
    },
  },
  accepted_not_eligible: {
    status: [
      {
        value: "accepted",
        label: "Đã nghiệm thu cấp khoa",
        bgcolor: color.success,
      },
      {
        value: "not_eligible",
        label: "Không tham gia hội nghị NCKH cấp trường",
        bgcolor: color.error,
      },
    ],
    buttons: {
      personal: ["detail", "submitDetail"],
      admin: ["detail", "submitDetail"],
      mod: ["detail", "submitDetail"],
    },
  },
  adjust_request: {
    status: [
      {
        value: "adjust_request",
        label: "Yêu cầu chỉnh sửa, bổ sung",
        bgcolor: color.secondary,
      },
    ],
    buttons: {
      personal: ["detail", "submitDetailEdit"],
      admin: ["detail", "disabled"],
      mod: ["detail", "submitDetailEdit"],
    },
  },
};

export const render = (open) => {
  const properties = [];
  for (const property in open) properties.push(property);
  const res = modalPropertiesDict[properties[0]];
  return res;
};

export const rowRender = (status, type) => {
  var _status = status;
  if (status?.[1] === "info_council") _status = status?.[1];
  if (status?.[0] === "default") _status = `${status?.[0]}_${status?.[status.length - 1]}`;
  if (status?.[0] === "submitted" && status.length > 1) _status = `${status?.[0]}_${status?.[status.length - 1]}`;
  if (status?.[0] === "accepted") _status = `${status?.[0]}_${status?.[status.length - 1]}`;
  return {
    status: rowPropertiesDict[_status]?.status,
    buttons: rowPropertiesDict[_status]?.buttons[type],
  };
};
