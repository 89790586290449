import {
  AlignmentType,
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { AcademicRankAbbrv, DegreeAbbrv } from "../abbreviation";
import { commonBorders } from "./helpers/commonNoneBorders";

export class StudentProjectAdjustListDocx {
  create = (data) => {
    const renderTableList = [];
    data.forEach((item, index) =>
      item.researchMembers.forEach((member, memberId) => {
        // Nếu row là chủ nhiệm của đề tài thì render cái này ra
        const isMain = member.researchRole === "main";
        var renderMain = [];
        if (isMain) {
          renderMain.push(
            new Paragraph({
              alignment: AlignmentType.CENTER,
              spacing: { after: 60 },
              children: [new TextRun({ text: "Chủ nhiệm đề tài", size: 11 * 2 })],
            })
          );
        }
        //
        var children = [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                verticalAlign: VerticalAlign.CENTER,
                spacing: { after: 60, before: 60 },
                children: [new TextRun({ text: `${index + 1}`, size: 11 * 2 })],
              }),
            ],
            rowSpan: item.researchMembers.length,
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                verticalAlign: VerticalAlign.CENTER,
                spacing: { after: 60, before: 60 },
                children: [new TextRun({ text: item.name, size: 11 * 2 })],
              }),
            ],
            rowSpan: item.researchMembers.length,
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                spacing: { after: 60, before: 60 },
                children: [new TextRun({ text: `${member.name} - ${member.academicYear} - ${member.class}`, size: 11 * 2 })],
              }),
              ...renderMain,
            ],
          }),
          new TableCell({
            rowSpan: item.researchMembers.length,
            spacing: { after: 60, before: 60 },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                verticalAlign: VerticalAlign.CENTER,
                children: [
                  new TextRun({
                    text: item.instructors
                      .map((e) => `${AcademicRankAbbrv(e?.academicRank)}${DegreeAbbrv(e?.degree)}${e?.name}`)
                      .join(", "),
                    size: 11 * 2,
                  }),
                ],
              }),
            ],
          }),
        ];

        if (memberId !== 0) children = [children[2]];

        renderTableList.push(new TableRow({ children }));
      })
    );

    const document = new Document({
      sections: [
        {
          children: [
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 276 },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "TRƯỜNG ĐẠI HỌC PHENIKAA", size: 12 * 2 })],
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "KHOA………………", size: 12 * 2, bold: true })],
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [new TextRun({ text: "---------------------------", size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({ children: [new TextRun({ size: 13 * 2 })], alignment: AlignmentType.CENTER }),
                      ],
                      width: { size: 40, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                      borders: commonBorders,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 276 },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({ text: "CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM", bold: true, size: 11.5 * 2 }),
                          ],
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [new TextRun({ text: "Độc lập - Tự do - Hạnh phúc", bold: true, size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [new TextRun({ text: "------------------------------", size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [
                            new TextRun({
                              text: "Hà Nội, ngày       tháng        năm 2023",
                              italics: true,
                              size: 11.5 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                      borders: commonBorders,
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({ spacing: { after: 200 }, children: [new TextRun({ size: 13 * 2 })] }),
            new Paragraph({ children: [new TextRun({ size: 13 * 2 })] }),
            new Paragraph({
              children: [new TextRun({ text: "DANH MỤC CÁC ĐỀ TÀI SINH VIÊN NCKH KHOA…", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "TRƯỜNG ĐẠI HỌC PHENIKAA NĂM HỌC 2022 - 2023", size: 13 * 2, bold: true }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              spacing: { after: 240 },
              children: [new TextRun({ text: "SAU KHI ĐƯỢC BỔ SUNG, ĐIỀU CHỈNH", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 312 },
                          children: [new TextRun({ text: "TT", bold: true, size: 11 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 5, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 312 },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Tên đề tài", bold: true, size: 11 * 2 })],
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { before: 60, line: 312 },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Sinh viên thực hiện", bold: true, size: 11 * 2 })],
                        }),
                        new Paragraph({
                          spacing: { line: 312 },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Đơn vị", bold: true, size: 11 * 2 })],
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 312 },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Cán bộ hướng dẫn", bold: true, size: 11 * 2 })],
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                  ],
                  tableHeader: true,
                }),
                ...renderTableList,
              ],
            }),
            new Paragraph({
              spacing: { before: 60 },
              children: [
                new TextRun({ text: `(Ấn định danh sách ${data.length} đề tài)`, size: 12 * 2, italics: true }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({ children: [new TextRun({ size: 13 * 2 })] }),
            new Paragraph({ children: [new TextRun({ size: 13 * 2 })] }),
            new Paragraph({ children: [new TextRun({ size: 13 * 2 })] }),
            new Paragraph({ children: [new TextRun({ size: 13 * 2 })] }),
            new Paragraph({ children: [new TextRun({ size: 13 * 2 })] }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [],
                      width: { size: 70, type: WidthType.PERCENTAGE },
                      borders: commonBorders,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [new TextRun({ text: "TRƯỞNG ĐƠN VỊ", bold: true, size: 13 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 30, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                      borders: commonBorders,
                    }),
                  ],
                }),
              ],
            }),
          ],
          properties: { page: { margin: { left: "3cm", right: "2cm", top: "2cm", bottom: "2cm" } } },
        },
      ],
    });

    return document;
  };
}
