import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  Select,
  Divider,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Chip,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import dayjs from "dayjs";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";

import toJs from "../../../hoc/toJS";
import select from "../../../utils/select";
import ROUTER from "../../../constants/router";

import FileUploadModal from "../../File/FileUploadModal";
import FilePreviewModal from "../../File/FilePreviewModal";

import { uploadFile } from "../../../reducer/file/action";
import { updateMonograph } from "../../../reducer/monograph/action";
import { statisticUnaccepted } from "../../../reducer/profile/action";
import {
  getEmploymentContractById,
  getAllEmploymentContractByProfileId,
} from "../../../reducer/employmentContract/action";
import {
  getTimeResearchInformation,
  getTimeResearchInformationById,
} from "../../../reducer/timeResearchInformation/action";

import EmploymentContractTable from "../../../components/EmployContractModal/EmploymentContractTable";
import EmploymentContractDetailTable from "../../../components/EmployContractModal/EmploymentContractDetailTable";

function MonographUpdateModal(props) {
  const { updating, setUpdating } = props;
  const [disabled, setDisabled] = useState(true);

  const [values, setValues] = useState(null);
  const [profileList, setProfileList] = useState([]);
  const [detail, setDetail] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [timeResearchYear, setTimeResearchYear] = useState(2023);

  useEffect(() => {
    if (updating) resetValues();
  }, [updating]);

  useEffect(() => {
    if (values?.profileIdForKpi >= 0)
      props.getTimeResearchInformation(0, timeResearchYear);
  }, [values?.profileIdForKpi, timeResearchYear]);

  useEffect(() => {
    const timeResearchDetailId = parseInt(
      values?.timeResearchDetailCode?.split("-").slice(0, 1).shift()
    );
    if (timeResearchDetailId)
      props.getTimeResearchInformationById(timeResearchDetailId);
  }, [values?.timeResearchDetailCode]);

  useEffect(() => {
    if (values?.profileIdForKpi)
      props.getAllEmploymentContractByProfileId(values?.profileIdForKpi);
  }, [values?.profileIdForKpi]);

  console.log(timeResearchYear)

  useEffect(() => {
    if (props.employmentContractDetail)
      setValues({
        ...values,
        profileIdForKpi: props.employmentContractDetail?.profile?.id,
      });
  }, [props.employmentContractDetail]);

  useEffect(() => {
    // setDetail(props?.employmentContractListById?.find((e) => e?.contract?.id === updating?.contractIdForKpi)?.contract);
    const contract = props?.employmentContractListById?.find(
      (e) => e?.contract?.id === updating?.contractIdForKpi
    )?.contract;
    setDetail(contract?.listKpi?.find((e) => e.id === updating.kpiIdForKpi));
  }, [props?.employmentContractListById, updating?.contractIdForKpi]);

  useEffect(() => {
    if (values?.timeResearchDetailCode) {
      const selectedYear = props.timeResearchInformationList
        ?.find((info) => (
          !!info.timeResearchDetail.find((detail) => detail.code === values?.timeResearchDetailCode)
        ))?.year

      setTimeResearchYear(selectedYear);
    }
  }, [values?.timeResearchDetailCode]);

  useEffect(() => {
    if (values && props.isUploading === false && props.fileUrl !== "") {
      setValues({
        ...values,
        fileUrl: props.fileUrl,
      });
      updateMonograph(values.id, {
        ...values,
        monographProfile: profileList,
        fileUrl: props.fileUrl,
        dashboardYear: values?.year,
      });
    }
  }, [props.isUploading]);

  const resetValues = () => {
    setValues({
      ...updating,
      issueAt: dayjs(updating.issueAt).format("YYYY-MM-DD"),
      acceptedDay: dayjs(updating.acceptedDay).format("YYYY-MM-DD"),
      submissionDay: dayjs(updating.submissionDay).format("YYYY-MM-DD"),
      listMember: updating.listMember?.join(", "),
    });
    setProfileList([...updating.monographProfile] || []);
    if (!props.isFetchingEmploymentContract)
      props.getEmploymentContractById(updating.contractIdForKpi);
    // setDetail(props.employmentContractListById?.find((e) => e.contract.id === updating.contractIdForKpi)?.contract);
    const contract = props?.employmentContractListById?.find(
      (e) => e?.contract?.id === updating?.contractIdForKpi
    )?.contract;
    setDetail(contract?.listKpi?.find((e) => e.id === updating.kpiIdForKpi));

    const timeResearchYearId = parseInt(
      updating.timeResearchDetailCode?.split("-")[0]
    );
    const year =
      props.timeResearchInformationList?.find(
        (e) => e.id === timeResearchYearId
      )?.year || 2022;
    setTimeResearchYear(year);
  };

  const handleChangeForm = (name, value) => {
    if (name === "timeResearchDetailCode") {
      setValues({
        ...values,
        [name]: value === "null" ? null : value,
      });
    } else setValues({ ...values, [name]: value });
  };

  const handleChangeProfileList = (index, name, value) => {
    const temp = profileList;
    const tempElm = profileList[index];
    temp.splice(index, 1, { ...tempElm, [name]: value });
    setProfileList([...temp]);
  };

  const handleInsert = () => {
    const temp = profileList;
    temp.push({
      profileId: null,
      roleInMonograph: "main",
    });
    setProfileList([...temp]);
  };

  const handleRemove = (index) => {
    if (profileList[index].profileId === values?.profileIdForKpi)
      handleChangeForm("profileIdForKpi", null);
    const temp = profileList;
    temp.splice(index, 1);
    setProfileList([...temp]);
  };

  const handleClose = () => {
    if (!disabled) {
      resetValues();
      setDisabled(true);
    } else {
      setUpdating(null);
      setDisabled(true);
    }
  };

  const updateMonograph = async () => {
    const tempListMember = values?.listMember
      ?.split(",")
      .map((author) => author.trimStart());
    let result = await props.updateMonograph(values.id, {
      ...values,
      listMember: tempListMember,
      dashboardYear: values?.year,
      monographProfile: profileList,
    });
    return result;
  };

  const handleSubmit = () => {
    if (disabled) {
      setDisabled(false);
    } else {
      updateMonograph().then((success) => {
        if (success) {
          props.statisticUnaccepted({
            departmentId: 0,
            profileId: props.profile?.id,
          });
          if (props.role === "sudo-admin")
            props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
        }
      });
      setUpdating(null);
      setDisabled(true);
    }
  };

  const handleOpenUploadModal = () => setUploadModalOpen(true);
  const handleCloseUploadModal = () => setUploadModalOpen(false);
  const handleOpenPreviewModal = () => setPreviewModalOpen(true);
  const handleClosePreviewModal = () => setPreviewModalOpen(false);

  const handleUpload = (file) => {
    props
      .uploadFile(values.id, "solution", file)
      .then((success) => {
        if (success) setUploadModalOpen(false);
      })
      .catch();
  };

  const contractList = [];
  props?.employmentContractListById.forEach((e) =>
    contractList.push(e.contract)
  );

  var timeResearchDetailList = props.timeResearchInformationList
    ?.find((e) => e.year === timeResearchYear)
    ?.timeResearchDetail || [];

  timeResearchDetailList = timeResearchDetailList.filter((e) => !e.allowedUpdateNum && e.type === "monograph");
  console.log(timeResearchDetailList)

  const yearList = [];
  props.timeResearchInformationList
    .sort((a, b) => b.year - a.year)
    .forEach((e) => {
      yearList.push(e.year);
    });

  const renderLockButton = () => {
    if (!values?.locked) return null;
    return (
      <Chip
        variant="outlined"
        label={`Đã khóa tính ${
          (values?.locked === "kpi" && "KPI") ||
          (values?.locked === "reward" && "thưởng") ||
          (values?.locked === "timeResearch" && "giờ nghiên cứu")
        }`}
        color="primary"
        style={{ fontSize: 15, float: "right", marginRight: 16 }}
      />
    );
  };

  const handleSendFeedback = () => {
    props.history.push({
      pathname: ROUTER.NOTIFICATION,
      state: {
        sendTo: "personal",
        title: "Thông báo về sai sót thông tin trong CTKH",
        chosenProfiles: profileList.reduce((accumulator, currentValue) => {
          accumulator.push(currentValue.profileId);
          return accumulator;
        }, []),
        data: updating,
        dataType: "monograph",
      },
    });
  };

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth={values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? "xl" : "md"}
      open={updating !== null}
      onClose={() => {
        setUpdating(null);
        setDisabled(true);
      }}
    >
      <DialogTitle>
        {disabled ? "Chi tiết" : "Cập nhật"}
        {renderLockButton()}
      </DialogTitle>
      <DialogContent style={{ display: "flex", overflowX: "hidden" }}>
        <div
          style={{
            display: "flex",
            paddingRight: 16,
            alignItems: "flex-start",
            minWidth: values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? "50%" : "100%",
            maxWidth: values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 960 : "100%",
          }}
        >
          <Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 10 }}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                disabled={disabled}
                size="small"
                label="Tên sách/ Tiêu đề"
                variant="outlined"
                value={values?.title?.toString() || ""}
                onChange={(event) => handleChangeForm("title", event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={disabled}
                size="small"
                type="number"
                variant="outlined"
                label="Số lượng tác giả"
                value={values?.numOfMember || ""}
                onChange={(event) => handleChangeForm("numOfMember", parseInt(event.target.value))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={disabled}
                size="small"
                variant="outlined"
                label="Nhà xuất bản"
                value={values?.publisher || ""}
                onChange={(event) => handleChangeForm("publisher", event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                disabled={disabled}
                label="Tác giả"
                variant="outlined"
                value={values?.listMember || ""}
                multiline
                rows={4}
                onChange={(event) => handleChangeForm("listMember", event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={disabled}
                size="small"
                type="number"
                variant="outlined"
                label="Năm xuất bản"
                value={values?.year || ""}
                onChange={(event) => handleChangeForm("year", parseInt(event.target.value))}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={disabled}
                size="small"
                type="number"
                label="Số trang"
                variant="outlined"
                value={values?.pageNumber || ""}
                onChange={(event) => handleChangeForm("pageNumber", parseInt(event.target.value))}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={disabled}
                size="small"
                label="Số ISBN"
                variant="outlined"
                value={values?.isbn || ""}
                onChange={(event) => handleChangeForm("isbn", event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={disabled}
                size="small"
                type="number"
                label="Điểm GS-PGS"
                variant="outlined"
                value={values?.score || ""}
                onChange={(event) => handleChangeForm("score", parseFloat(event.target.value))}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth disabled={disabled} variant="outlined" size="small">
                <InputLabel style={{ padding: "0 8px", backgroundColor: "white" }}>Phân loại</InputLabel>
                <Select
                  native
                  fullWidth
                  disabled={disabled}
                  value={values?.type || ""}
                  onChange={(event) => handleChangeForm("type", event.target.value)}
                >
                  <option value={null} />
                  <option value="chapter">Chương sách</option>
                  <option value="monograph">Chuyên khảo</option>
                  <option value="reference">Tham khảo</option>
                  <option value="curriculum">Giáo trình</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth disabled={disabled} variant="outlined" size="small">
                <InputLabel style={{ padding: "0 8px", backgroundColor: "white" }}>Ngôn ngữ</InputLabel>
                <Select
                  native
                  fullWidth
                  disabled={disabled}
                  value={values?.language || ""}
                  onChange={(event) => handleChangeForm("language", event.target.value)}
                >
                  <option value={null} />
                  <option value="english">Tiếng Anh</option>
                  <option value="other">Khác</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="purpose-label" style={{ padding: "0 8px", backgroundColor: "white" }}>
                  Mục đích
                </InputLabel>
                <Select
                  native
                  fullWidth
                  disabled={disabled}
                  labelId="purpose-label"
                  value={values?.purposeId ?? "none"}
                  onChange={(event) => handleChangeForm("purposeId", parseInt(event.target.value))}
                >
                  {props.purposes.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.description}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {values?.purposeId === 5 && (
              <>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    value={timeResearchYear}
                    disabled={disabled}
                    SelectProps={{ native: true }}
                    label="Năm học tính giờ nghiên cứu"
                    variant="outlined"
                    onChange={(event) => setTimeResearchYear(parseInt(event.target.value))}
                  >
                    {yearList.map((e) => (
                      <option key={`${e} - ${e + 1}`} value={parseInt(e)}>
                        {e} - {e + 1}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    disabled={disabled}
                    label="Nhiệm vụ tính giờ nghiên cứu"
                    variant="outlined"
                    value={values?.timeResearchDetailCode || null}
                    onChange={(event) => handleChangeForm("timeResearchDetailCode", event.target.value)}
                    InputLabelProps={{
                      shrink: !(
                        values?.timeResearchDetailCode === undefined || values?.timeResearchDetailCode === null
                      ),
                    }}
                    error={!values?.timeResearchDetailCode}
                  >
                    {timeResearchDetailList?.map((e) => (
                      <MenuItem key={e.code} value={e.code}>{`${e.description} - ${e.value}(h)`}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Ghi chú"
                variant="outlined"
                value={values?.note || ""}
                multiline
                rows={3}
                disabled={disabled || values?.locked === "kpi" || values?.locked === "reward"}
                onChange={(event) => handleChangeForm("note", event.target.value)}
              />
            </Grid>

            <Grid item xs={5} style={{ margin: "auto" }}>
              <Divider />{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.30)" }}>Thành viên</Typography>
            </Grid>
            <Grid item xs={5} style={{ margin: "auto" }}>
              <Divider />{" "}
            </Grid>

            <Grid item xs={12}>
              {profileList.map((e, index) => (
                <Grid key={`list-${index}`} container spacing={2}>
                  <Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 5 : 4}>
                    <Autocomplete
                      disableClearable
                      disabled={disabled}
                      options={props.profileList}
                      getOptionLabel={(option) => option.name}
                      value={props.profileList?.find((profile) => profile.id === e.profileId)}
                      onChange={(event, profile) => handleChangeProfileList(index, "profileId", parseInt(profile?.id))}
                      renderInput={(params) => (
                        <TextField {...params} size="small" label="Thành viên" variant="outlined" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 5 : 3}>
                    <FormControl fullWidth disabled={disabled} variant="outlined" size="small">
                      <InputLabel style={{ padding: "0 8px", backgroundColor: "white" }}>Vai trò</InputLabel>
                      <Select
                        native
                        fullWidth
                        value={e?.roleInMonograph || "main"}
                        onChange={(event) => handleChangeProfileList(index, "roleInMonograph", event.target.value)}
                      >
                        <option value="main">Chủ biên</option>
                        <option value="member">Đồng tác giả</option>
                        <option value="mainOther">Chủ biên tính hộ</option>
                        <option value="memberOther">Đồng tác giả tính hộ</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  {values?.purposeId === 1 && (
                    <Grid item xs={3}>
                      <FormControl required fullWidth error={!values?.profileIdForKpi}>
                        <Button
                          fullWidth
                          color="primary"
                          variant="outlined"
                          disabled={disabled}
                          style={{ height: 40 }}
                          startIcon={
                            typeof values?.profileIdForKpi === "number" && values?.profileIdForKpi === e.profileId ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )
                          }
                          onClick={() => {
                            setDetail(null);
                            props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
                            handleChangeForm(
                              "profileIdForKpi",
                              values?.profileIdForKpi !== e.profileId ? parseInt(e.profileId) : null
                            );
                          }}
                        >
                          Tính KPI
                        </Button>
                        {!values?.profileIdForKpi && <FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>}
                      </FormControl>
                    </Grid>
                  )}
                  {values?.purposeId === 99999999 && (
                    <Grid item xs={3}>
                      <FormControl required fullWidth error={!values?.profileIdForReward}>
                        <Button
                          fullWidth
                          disabled={disabled}
                          color="primary"
                          variant="outlined"
                          style={{ height: 40 }}
                          startIcon={
                            typeof values?.profileIdForReward === "number" &&
                            values?.profileIdForReward === e.profileId ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )
                          }
                          onClick={() => {
                            setDetail(null);
                            handleChangeForm(
                              "profileIdForReward",
                              values?.profileIdForReward !== e.profileId ? parseInt(e.profileId) : null
                            );
                          }}
                        >
                          Tính thưởng
                        </Button>
                        {!values?.profileIdForReward && <FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      disabled={disabled}
                      color="secondary"
                      variant="contained"
                      style={{ height: 40 }}
                      onClick={() => handleRemove(index)}
                    >
                      Xoá
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Button fullWidth disabled={disabled} variant="outlined" onClick={handleInsert}>
                Thêm thành viên
              </Button>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            flexDirection: "column",
            display: values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? "block" : "none",
          }}
        >
          <EmploymentContractTable
            contract={detail}
            contractList={contractList}
            kpiIdForKpi={values?.kpiIdForKpi}
            contractIdForKpi={values?.contractIdForKpi}
            setDetail={setDetail}
            values={values}
            setValues={setValues}
            handleChangeForm={handleChangeForm}
          />
          <EmploymentContractDetailTable kpi={detail} />
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "0 24px 8px 24px" }}>
        <Grid container justifyContent={"space-between"}>
          <div>
            <Button
              disabled={disabled}
              variant="outlined"
              color="primary"
              onClick={handleOpenUploadModal}
              style={{ marginRight: 4 }}
            >
              Upload file minh chứng
            </Button>
            <Button variant="contained" color="primary" onClick={handleOpenPreviewModal}>
              Preview file minh chứng
            </Button>
          </div>
          <div>
            {props.adminTab && (
              <Button variant="outlined" onClick={handleSendFeedback}>
                Gửi feedback
              </Button>
            )}
            <Button variant="outlined" color="secondary" onClick={handleClose} style={{ margin: "0 4px" }}>
              Huỷ
            </Button>
            <Tooltip
              disableHoverListener={props.role === "sudo-admin"}
              title="Liên hệ phòng KHCN để đề xuất chỉnh sửa CTKH"
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    (!disabled &&
                      ((values?.purposeId === 1 && !(values?.profileIdForKpi && values?.contractIdForKpi)) ||
                        (values?.purposeId === 99999999 && !values?.profileIdForReward) ||
                        (values?.purposeId === 5 && !values?.timeResearchDetailCode))) ||
                    values?.locked ||
                    (values?.accepted && props.role !== "sudo-admin")
                  }
                >
                  {disabled ? "Chỉnh sửa" : "Xác nhận"}
                </Button>
              </span>
            </Tooltip>
          </div>
        </Grid>
      </DialogActions>
      <FileUploadModal open={uploadModalOpen} handleClose={handleCloseUploadModal} handleUpload={handleUpload} />
      <FilePreviewModal open={previewModalOpen} handleClose={handleClosePreviewModal} url={values?.fileUrl} />
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, "userReducer", "role"),
  history: select(state, "homeReducer", "history"),
  fileUrl: select(state, "fileReducer", "fileUrl"),
  profile: select(state, "profileReducer", "detail"),
  profileList: select(state, "profileReducer", "list"),
  isUploading: select(state, "fileReducer", "isFetching"),
  purposes: select(state, "scientificResearchReducer", "purposes"),
  employmentContractDetail: select(
    state,
    "employmentContractReducer",
    "detail"
  ),
  employmentContractListById: select(
    state,
    "employmentContractReducer",
    "listById"
  ),
  timeResearchInformationList: select(
    state,
    "timeResearchInformationReducer",
    "list"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
  updateMonograph: (id, payload) => dispatch(updateMonograph(id, payload)),
  statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
  getTimeResearchInformationById: (id) =>
    dispatch(getTimeResearchInformationById(id)),
  getAllEmploymentContractByProfileId: (id) =>
    dispatch(getAllEmploymentContractByProfileId(id)),
  getTimeResearchInformation: (profileId, year) =>
    dispatch(getTimeResearchInformation(profileId, year)),
  getEmploymentContractById: (id) => dispatch(getEmploymentContractById(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJs(MonographUpdateModal));
