import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import dayjs from "dayjs";

import { API_URLS } from "../../constants/api";
import toJS from "../../hoc/toJS";
import { apiCall } from "../../utils/api";
import { notification } from "../../reducer/home/action";

/**
 * @description
 * ProfileInfo component, required `profileId` props
 */
export function ProfileInfo(props) {
  const { profileId } = props;
  const [profileInfo, setProfileInfo] = useState({});

  useEffect(() => {
    (async () => {
      const apiProfileInfo =
        API_URLS.PROFILE.getProfileInfoByProfileId(profileId);
      const { response: responseProfileInfo, error: errorProfileInfo } =
        await apiCall({ ...apiProfileInfo });
      if (
        !errorProfileInfo &&
        responseProfileInfo.status === 200 &&
        responseProfileInfo.data.success
      ) {
        const { profile: resProfileInfo } = responseProfileInfo.data.data;

        setProfileInfo((prevInitialValues) => {
          return {
            ...prevInitialValues,
            basicInfo: {
              ...prevInitialValues.basicInfo,
              address: resProfileInfo?.address,
              birth: dayjs(resProfileInfo?.birth).format("YYYY-MM-DD"),
              degree: resProfileInfo?.degree,
              phone: resProfileInfo?.phone,
              email: resProfileInfo?.mail,
              name: resProfileInfo?.name,
              nationality: resProfileInfo?.nationality ?? "",
              position: resProfileInfo?.cvInfo?.position ?? "",
              unit: resProfileInfo?.cvInfo?.department ?? "",
              code: resProfileInfo?.code,
              identityNumber: resProfileInfo?.identityNumber,
              avatar: resProfileInfo?.avatar,
            },
            educations: (
              resProfileInfo?.cvInfo?.trainingProcessArray ?? []
            ).map((element) => {
              return { ...element, unit: element.university };
            }),
            businesses: (resProfileInfo?.cvInfo?.workProcessArray ?? []).map(
              (element) => {
                return { ...element, role: element.job };
              }
            ),
            languages: resProfileInfo?.cvInfo?.foreignLanguageArray ?? [],
            scientificResearchs: {
              ...prevInitialValues.scientificResearchs,
              researchAreas:
                resProfileInfo?.cvInfo?.mainResearchFieldArray ?? [],
            },
            experiences: {
              doctors: (
                resProfileInfo?.cvInfo?.doctoralTrainingArray ?? []
              ).map((element) => {
                return {
                  name: element.student,
                  unit: element.university,
                  year: element.year,
                  role: element.advisor,
                };
              }),
              masters: (resProfileInfo?.cvInfo?.masterTrainingArray ?? []).map(
                (element) => {
                  return {
                    name: element.student,
                    unit: element.university,
                    year: element.year,
                    role: element.advisor,
                  };
                }
              ),
            },
          };
        });
      } else {
        notification({
          type: "error",
          message: "Không lấy được hồ sơ",
        });
      }
    })();
  }, [profileId]);

  /**
   * @TODO
   * display profile info here
   */
  return (
    <Box>
      <Typography variant='h5'>Thông tin cơ bản</Typography>
      <Box>
        <Typography>Họ tên: {profileInfo.basicInfo?.name}</Typography>
        <Typography>
          Ngày sinh: {dayjs(profileInfo.basicInfo?.birth).format("DD/MM/YYYY")}
        </Typography>
        <Typography>Quốc tịch: {profileInfo.basicInfo?.nationality}</Typography>
        <Typography>
          Học hàm/học vị cao nhất: {profileInfo.basicInfo?.degree}
        </Typography>
        <Typography>Đơn vị công tác: {profileInfo.basicInfo?.unit}</Typography>
        <Typography>Chức vụ: {profileInfo.basicInfo?.position}</Typography>
        <Typography>Địa chỉ: {profileInfo.basicInfo?.address}</Typography>
        <Typography>Email: {profileInfo.basicInfo?.email}</Typography>
        <Typography>Số điện thoại: {profileInfo.basicInfo?.phone}</Typography>
      </Box>

      <Box marginY={2} />
      <Typography variant="h5">Quá trình đào tạo</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Thời gian</TableCell>
            <TableCell>Học vị</TableCell>
            <TableCell>Chuyên ngành</TableCell>
            <TableCell>Cơ sở đào tạo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(profileInfo?.educations ?? []).map((element, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{element.duration}</TableCell>
                <TableCell>{element.degree}</TableCell>
                <TableCell>{element.major}</TableCell>
                <TableCell>{element.unit}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Box marginY={2} />
      <Typography variant="h5">Quá trình công tác</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Thời gian</TableCell>
            <TableCell>Đơn vị công tác</TableCell>
            <TableCell>Công việc đảm nhiệm</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(profileInfo?.businesses ?? []).map((element, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{element.duration}</TableCell>
                <TableCell>{element.unit}</TableCell>
                <TableCell>{element.job}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Box marginY={2} />
      <Typography variant="h5">Ngoại ngữ</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Ngôn ngữ</TableCell>
            <TableCell>Kỹ năng đọc</TableCell>
            <TableCell>Kỹ năng viết</TableCell>
            <TableCell>Kỹ năng nói</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(profileInfo?.languages ?? []).map((element, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{element.language}</TableCell>
                <TableCell>{element.reading}</TableCell>
                <TableCell>{element.writing}</TableCell>
                <TableCell>{element.speaking}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Box marginY={2} />
      <Typography variant="h5">Quá trình nghiên cứu khoa học</Typography>
      <Typography variant="h6">Hướng nghiên cứu chính</Typography>
      <ul>
        {(profileInfo?.scientificResearchs?.researchAreas ?? []).map(
          (element, index) => {
            return <li>{element}</li>;
          }
        )}
      </ul>
      <Typography variant="h6">Các đề tài, dự án nghiên cứu khoa học</Typography>

      <Typography variant="h6">Công bố ISI/Scopus</Typography>

      <Typography variant="h6">Công bố quốc tế khác</Typography>

      <Typography variant="h6">Công bố trên các tạp chí khoa học quốc gia</Typography>

      <Typography variant="h6">Bằng sáng chế/Giải pháp hữu ích/Sách chuyên khảo</Typography>


      <Box marginY={2} />
      <Typography variant="h5">Kinh nghiệm trong đào tạo</Typography>
      <Typography variant="h6">Đào tạo Tiến sĩ</Typography>

      <Typography variant="h6">
        Đào tạo Thạc sĩ (trong 5 năm gần nhất)
      </Typography>
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  notification: (payload) => dispatch(notification(payload)),
});

export default connect(null, mapDispatchToProps)(toJS(ProfileInfo));
