import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Tab,
  Card,
  Grid,
  Tabs,
  Menu,
  Button,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ROUTER from '../../constants/router';

import ConferenceTable from '../../containers/Conference/ConferenceTable';
import ConferenceButton from '../../containers/Conference/ConferenceButton';
import ConferenceSearchTab from '../../containers/Conference/ConferenceSearchTab';

function Conference() {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chosenYear, setChosenYear] = React.useState(0)

  const handleChange = (event, newValue) => setValue(newValue);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: anchorEl?.offsetWidth,
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (children)}
      </div>
    );
  }

  const handleChangeYear = (year) => {
    setChosenYear(year)
  }

  return (
    <Card style={{ padding: 8, minHeight: 'calc(100vh - 108px)' }}>
      <Grid container style={{ marginBottom: 8}}>
        <Grid item xs={7}>
          <Tabs
            value={value}
            onChange={handleChange}
            style={{ marginBottom: 8 }}
          >
            <Tab label="Tra cứu hội thảo" />
            <Tab label="Hội thảo quốc tế" />
            <Tab label="Hội thảo trong nước" />
          </Tabs>
        </Grid>
        <Grid item xs={5}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleClick}
              style={{ minWidth: 180, float: 'right' }}
            >
              Tra cứu hội thảo
              <ExpandMoreIcon />
            </Button>
          <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <Link to={ROUTER.SEARCH} style={{ color: 'black', textDecoration:'none' }}>
              <MenuItem>Tra cứu tạp chí</MenuItem>
            </Link>
            <MenuItem onClick={handleClose}>Tra cứu hội thảo</MenuItem>
          </StyledMenu>
        </Grid>
      </Grid>

        <TabPanel value={value} index={0}>
          <ConferenceSearchTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ConferenceButton level='international' year={chosenYear} handleChangeYear={handleChangeYear} />
          <ConferenceTable level='international' year={chosenYear} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConferenceButton level='domestic' year={chosenYear} handleChangeYear={handleChangeYear} />
          <ConferenceTable level='domestic' year={chosenYear} />
        </TabPanel>
    </Card>
  );
}

export default Conference;
