import React, { useEffect, useState } from 'react';
import { Typography, Chip, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';

import KPTable from '../../../components/KPTable';

import { getRewardContractStatistic, updateKpiCompleted, getContractRewardByYear } from '../../../reducer/statistic/action';
import EmploymentContractUpdateModal2 from '../../EmploymentContract/EmploymentContractUpdateModal2';
import { EXPORT_CONTRACT_REWARD_TO_EXCEL } from '../../../reducer/statistic/action';
import { notification } from '../../../reducer/home/action';

function ContractRewardTable(props) {
  const [updating, setUpdating] = useState(null);

  const handleExportExcel = () => {
    props.getContractRewardByYear(props.year)
      .then((response) => {
        if (response.success) {
          EXPORT_CONTRACT_REWARD_TO_EXCEL(props.statistic, response.data, props.year)
          props.notification({
            type: 'success',
            message: 'Export dữ liệu thành công!'
          })
        } else {
          props.notification({
            type: 'error',
            message: 'Export dữ liệu thất bại!'  
          })
        }
      })
  }

  const columns=[
    {
      title: <b>Tên</b>,
      key: 'name',
      width: '10%',
      searchable: true,
      sorter: (a, b) => a - b,
      render: (value, row) => (
        <a href={`${ROUTER.WORK}/profile-id=${row.profile.id}`} style={{ color: 'black', textDecoration: 'none' }}>
          <Typography><b>{value}</b></Typography>
        </a>
      )
    },
    {
      title: <b>Thời hạn hợp đồng</b>,
      key: 'contract',
      width: '10%',
      sorter: (a, b) => a - b,
      render: (value) => value.duration !== 1000 ? `${value.duration} năm` : 'Vô hạn',
    },
    {
      title: <b>Thời gian bắt đầu hợp đồng</b>,
      key: 'contract',
      width: '10%',
      sorter: (a, b) => a - b,
      render: (value => moment(value.startAt).format('DD/MM/YYYY'))
    },
    {
      title: <b>Trạng thái hoàn thành của KPI</b>,
      key: 'contract',
      width: '10%',
      render: (value => value?.listKpi[0]?.completed?  <Chip label="Đã hoàn thành" variant="outlined" color="primary"/> : <Chip label="Chưa hoàn thành" variant="outlined" color="secondary"/>),
      filters: [
        {
          text: 'Đã hoàn thành',
          value: true,
        },
        {
          text: 'Chưa hoàn thành',
          value: false,
        }
      ],
      onFilter: (value, row) => row.filter((e) => e?.contract?.listKpi[0]?.completed === value),
    },
    {
      title: <b>Tác vụ</b>,
      key: 'action',
      width: '10%',
      render: (value, row) => (
        <Typography
          className='table-action-buton'
          variant='caption'
          color='primary'
          onClick={() => setUpdating(row)}
        >
          Chi tiết
        </Typography>
      ),
    }
  ]

  useEffect(() => {
    props.getRewardContractStatistic(1, -1, { 
      departmentId: props.department,
      kpiYear: props.year,
      active: true,
    })
  }, [props.year, props.department]);

  const handleUpdateKpiCompleted = (completed) => {
    props.updateKpiCompleted(props.year, completed, {
      page: 1, 
      pageSize: -1, 
      payload:{ 
        departmentId: props.department,
        kpiYear: props.year,
        active: true,
      }
    })
  }

  return(
    <div>
      <KPTable
        shadow="none"
        rowsPerPage={10}
        maxHeight='calc(100vh - 214px)'
        columns={columns}
        rows={props.statistic.map((item) => { return({...item, name: item.profile.name}) })}
        loading={props.isFetching}
      />
      {updating !== null && <EmploymentContractUpdateModal2 updating={updating} setUpdating={setUpdating} chosenYear={props.year} />}

      <Button
        color='primary'
        variant='contained'
        style={{ left: 16, bottom: 44, marginRight: 10 }}
        onClick={() => handleUpdateKpiCompleted(true)}
      >
        Chốt KPI tính thưởng năm {props.year}
      </Button>

      <Button variant='outlined' onClick={() => handleExportExcel()} style={{ left: 16, bottom: 44 }}>
        Export excel
      </Button>
    </div>
  )  
}

const mapStateToProps = (state) => ({
  statistic: select(state, 'statisticReducer', 'rewardContractStatistic'),
  isFetching: select(state, 'statisticReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
  getRewardContractStatistic: (page, pageSize, payload) => dispatch(getRewardContractStatistic(page, pageSize, payload)),
  updateKpiCompleted: (year, completed, reloadPayload) => dispatch(updateKpiCompleted(year, completed, reloadPayload)),
  notification: (payload) => dispatch(notification(payload)),
  getContractRewardByYear: (year) => dispatch(getContractRewardByYear(year)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ContractRewardTable));