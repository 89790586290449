import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  Add,
  Delete,
  DoubleArrow,
  FileCopy,
  GetApp,
  Group,
  Info,
  InfoOutlined,
  Person,
  RateReview,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { KPTextfield, KPTextform } from "../../../../components/KPTComponents";
import { INSTITUTIONAL_PROJECT } from "../../../../constants/selectOptions";

import { uploadMultiFile } from "../../../../reducer/file/action";
import { getAllDepartments, getAllFaculties } from '../../../../reducer/department/action';

import {
  insertInternalResearch,
  updateInternalResearch,
} from "../../../../reducer/internalResearch/action";
import { valueFormat } from "../../../../utils/valueFormat";

import fileLyLich from "../../../../assets/files/DeTaiCoSo/BMĐTCS-05 TKTTCNĐT.docx";
import fileThuyetMinh from "../../../../assets/files/DeTaiCoSo/File Thuyet Minh.rar";

import theme from "../../../../theme";
import styles from "./index.module.css";
import select from "../../../../utils/select";
import axios from "axios";

const getHocHamHocVi = (academicRank, degree) => {
  const res = [];
  if (academicRank) res.push(academicRank);
  if (degree === "Tiến sĩ" || degree === "Thạc sĩ") res.push(degree);
  return res.join("/");
}


const downloadAs = (url, name) => {
  axios.get(url, {
    headers: {
      "Content-Type": "application/octet-stream"
    },
    responseType: "blob"
  })
    .then(response => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch(err => {
      // console.log("error", err);
    });
};

function DefaultForm(props) {
  const dispatch = useDispatch();

  const [openInfo, setOpenInfo] = useState(null);

  const {
    type,
    year,
    big,
    bigger,
    files,
    setFiles,
    values,
    setValues,
    council,
    setCouncil,
    councilApp,
    participants,
    setParticipants,
  } = props;

  const profileList = useSelector((state) =>
    select(state, "profileReducer", "list")
  );
  const profileDetail = useSelector((state) =>
    select(state, "profileReducer", "detail")
  );
  const profileDepartments = useSelector((state) =>
    select(state, "profileReducer", "departments")
  );

  const handleChangeForm = (key, val, type) => {
    if (key?.includes("participants")) type = "participantField";
    // if (key?.includes("ReviewerNote")) type = "councilField";
    if (key?.includes("ReviewerNote") || key?.includes("councilNote")) type = "councilField";

    if (type === "participantField") {
      const keyList = key.split(".");
      const index = parseInt(keyList[1]);
      const fieldKey = keyList?.[2] || "all";
      const value = [...participants];

      var newParticipant = {
        ...value[index],
        ...val,
        id: undefined,
        degree: getHocHamHocVi(val?.academicRank, val?.degree),
        profileId: val?.id,
        email: val?.mail,
        department: val?.departmentProfile?.find((e) => e.department?.isFaculty)
          ?.department?.name,
      };

      if (fieldKey === "all") value.splice(index, 1, newParticipant);
      else if (fieldKey === "isPhenikaa") {
        value.splice(index, 1, {
          ...value[index],
          [fieldKey]: val,
          name: undefined,
          degree: undefined,
          profileId: undefined,
          department: undefined,
        });
        if (!val) {
          // Nếu không phải GV trong trường thì nhắc khéo
          setOpenInfo(index - 1);  // Bỏ chủ nhiệm đề tài
          setTimeout(() => setOpenInfo(null), 2500);
        } else setOpenInfo(null);
      } else value.splice(index, 1, { ...value[index], [fieldKey]: val });

      setParticipants(value);
    } else if (type === "file") {
      if (val?.[0]) {
        setFiles({ ...files, [key]: val?.[0] });
      } else {
        if (values?.[`${key}Link`]) {
          setFiles({ ...files, [`${key}Link`]: val?.[0] });
          setValues({ ...values, [`${key}Link`]: val?.[0] });
        }
      }
    } else if (type === "councilField") {
      setCouncil({ ...council, [key]: val });
    } else {
      setValues({ ...values, [key]: val });
    }
  };

  console.log('profileDepartments', profileDepartments)

  const insertProject = () => {
    const faculty = profileDepartments.find((department) => {
      // const matchProfile = department.departmentProfile?.find(
      //   (item) => item.profileId === profileDetail.id
      // );
      if (
        department?.isFaculty || department?.isInstitute
        // matchProfile.roleInDepartment.toLowerCase() === "leader"
      )
        return true;
      return false;
    });

    dispatch(uploadMultiFile(profileDetail?.id, files)).then((res) => {
      if (res.success) {
        const files = res.data;
        dispatch(
          insertInternalResearch({
            ...values,
            participants: participants.map((e, id) => ({
              ...e,
              llkh: files.find((e) => e.includes(`participants.${id}.llkh`)),
            })),
            status: "info_default",
            schoolYear: year,
            departmentId: faculty?.id,
            cost: parseInt(values.cost.replaceAll(".", "")),
            endTime: valueFormat.stringToDate(values.endTime),
            startTime: valueFormat.stringToDate(values.startTime),
            presentationFileLink: files.find((e) => e.includes("presentationFile")),
            statisticPlanFileLink: files.find((e) => e.includes("statisticPlanFile")),
          })
        );
      }
    });
  };

  const requestAdjustInfo = () => {
    dispatch(
      updateInternalResearch({
        ...props.detail,
        status: "info_adjust_request",
        editRequest: values?.editRequest,
        researchCode: values?.researchCode,
      })
    );
  };

  const rejectInfo = () => {
    dispatch(
      updateInternalResearch({
        ...props.detail,
        status: "info_rejected",
        editRequest: values?.editRequest,
      })
    );
  };

  const passInfo = () => {
    dispatch(
      updateInternalResearch({
        ...props.detail,
        status: "info_accepted",
        editRequest: null,
        researchCode: values?.researchCode,
        infoPassed: true,
      })
    );
  };

  const acceptInfo = () => {
    dispatch(
      updateInternalResearch({
        ...props.detail,
        status: "info_accepted_v2",
        editRequest: null,
        researchCode: values?.researchCode,
      })
    );
  };

  const updateReview = (status) => {
    if (files?.presentationFile || files?.statisticPlanFile || files?.councilFile) {
      dispatch(uploadMultiFile(profileDetail?.id, files)).then((res) => {
        if (res.success) {
          const files = res.data;
          dispatch(
            updateInternalResearch({
              ...values,
              status,
              participants,
              internalCouncilInfos: [{
                ...council,
                councilFileLink: files.find((e) => e.includes("councilFile")) || values.councilFileLink,
              }],
              endTime: valueFormat.stringToDate(values.endTime),
              startTime: valueFormat.stringToDate(values.startTime),
              cost: parseInt(values.cost.toString().replaceAll(".", "")),
              presentationFileLink: files.find((e) => e.includes("presentationFile")) || values.presentationFileLink,
              statisticPlanFileLink: files.find((e) => e.includes("statisticPlanFile")) || values.statisticPlanFileLink,
            })
          );
        }
      });
    } else {
      dispatch(
        updateInternalResearch({
          ...values,
          status,
          participants,
          internalCouncilInfos: [{ ...council }],
          endTime: valueFormat.stringToDate(values.endTime),
          startTime: valueFormat.stringToDate(values.startTime),
          cost: parseInt(values.cost.toString().replaceAll(".", "")),
        })
      );
    }
  };

  const handleSubmit = (e) => {
    props.handleClose();
    switch (props.id) {
      case "insert-form":
        insertProject();
        break;
      case "accept-info-form":
        var eventName = e.nativeEvent.submitter.name;
        if (eventName === "info_adjust_request") requestAdjustInfo();
        else passInfo();
        break;
      case "accept-info-again-form":
        eventName = e.nativeEvent.submitter.name;
        if (eventName === "info_rejected") rejectInfo();
        else passInfo();
        break;
      case "accept-info-v2-form":
        eventName = e.nativeEvent.submitter.name;
        if (eventName === "info_adjust_request") requestAdjustInfo();
        else acceptInfo();
        break;
      case "accept-info-again-v2-form":
        eventName = e.nativeEvent.submitter.name;
        if (eventName === "info_rejected") rejectInfo();
        else acceptInfo();
        break;
      case "detail-council-form":
        eventName = e.nativeEvent.submitter.name;
        if (eventName === "adjust_council")
          updateReview("content_adjust_request");
        else updateReview("conducted");
        break;
      case "edit-form":
        updateReview("info_adjusted");
        break;
      case "edit-basic":
        updateReview("info_default");
        break;
      case "edit-commiss-form":
        updateReview("content_adjusted");
        break;
      default:
        break;
    }
  };

  const disabled =
    !props?.open?.insert &&
    !props?.open?.detailAdjust &&
    !values?.status?.find((e) => e?.value?.includes("info_adjust_request"));

  const editRequestDisabled =
    values?.status?.findIndex((e) => e.value.includes("info_adjust")) >= 0;

  const textForm = [
    {
      key: "box1",
      xs: 12,
      type: "render",
      render: () => (
        <Box display="flex" width="100%">
          <Box
            maxHeight="calc(100vh - 216px)"
            width={`calc(${big || bigger ? 6 : 10}0% - 72px)`}
            padding="16px 24px"
            margin="8px 0"
            style={{ overflow: "auto" }}
          >
            <Box border="solid 1px #bcbcbc" borderRadius={4}>
              <Box
                display="flex"
                bgcolor="#ececec"
                padding="8px 16px"
                alignItems="center"
                borderBottom="solid 1px #bcbcbc"
                style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
              >
                <Info
                  style={{
                    marginRight: 12,
                    marginTop: 2,
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography
                  variant="h6"
                  style={{ color: theme.palette.primary.main }}
                >
                  <b>THÔNG TIN CƠ BẢN</b>
                </Typography>
              </Box>
              <Box padding={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <KPTextfield
                      id="vietnameseName"
                      xs={12}
                      height={1}
                      disabled={disabled && props.id !== "edit-basic"}
                      value={values?.vietnameseName}
                      label="Tên đề tài tiếng Việt"
                      handleChangeForm={handleChangeForm}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KPTextfield
                      id="englishName"
                      xs={12}
                      height={1}
                      disabled={disabled && props.id !== "edit-basic"}
                      value={values?.englishName}
                      label="Tên đề tài tiếng Anh"
                      handleChangeForm={handleChangeForm}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield
                      id="researchType"
                      type="select"
                      xs={12}
                      height={1}
                      disabled={disabled && props.id !== "edit-basic"}
                      value={values?.researchType}
                      label="Loại đề tài"
                      handleChangeForm={handleChangeForm}
                      required={true}
                      options={INSTITUTIONAL_PROJECT.type}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield
                      id="cost"
                      xs={12}
                      height={1}
                      value={values?.cost}
                      label="Dự toán kinh phí"
                      handleChangeForm={handleChangeForm}
                      required={true}
                      type="render"
                      render={() => (
                        <TextField
                          required
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={disabled && props.id !== "edit-basic"}
                          value={valueFormat.seperatorPoint(
                            values?.cost?.toString() || ""
                          )}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                VND
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ style: { padding: 8 } }}
                          onChange={(event) =>
                            handleChangeForm("cost", event.target.value)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <Box width="calc((100% - 48px) / 2)">
                        <KPTextfield
                          id="startTime"
                          type="date"
                          disabled={disabled && props.id !== "edit-basic"}
                          value={values?.startTime}
                          label="Thời gian bắt đầu"
                          handleChangeForm={handleChangeForm}
                          required={true}
                        />
                      </Box>
                      <Box width={48}>
                        <DoubleArrow
                          style={{
                            fontSize: 28,
                            marginTop: 32,
                            padding: "0 10px",
                            color: "rgba(0, 0, 0, 0.30)",
                          }}
                        />
                      </Box>
                      <Box width="calc((100% - 48px) / 2)">
                        <KPTextfield
                          id="endTime"
                          type="date"
                          disabled={disabled && props.id !== "edit-basic"}
                          value={values?.endTime}
                          label="Thời gian kết thúc"
                          handleChangeForm={handleChangeForm}
                          required={true}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <KPTextfield
                      id="presentationFile"
                      type="dragzone"
                      disabled={disabled && props.id !== "edit-basic"}
                      required={true}
                      value={values?.presentationFileLink}
                      handleChangeForm={handleChangeForm}
                      label={(color) => (
                        <Typography
                          className="kpt-textfield-label"
                          style={{ color }}
                        >
                          {`File thuyết minh (Tải file mẫu `}
                          <a
                            href={fileThuyetMinh}
                            style={{ color: "blue" }}
                            download={"File thuyet minh.rar"}
                          >
                            tại đây
                          </a>
                          {`)`}
                          <span style={{ color: "#c90000" }}>*</span>
                          {`:`}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KPTextfield
                      id="statisticPlanFile"
                      type="dragzone"
                      disabled={disabled && props.id !== "edit-basic"}
                      required={true}
                      value={values?.statisticPlanFileLink}
                      handleChangeForm={handleChangeForm}
                      label={(color) => (
                        <Typography
                          className="kpt-textfield-label"
                          style={{ color }}
                        >
                          {`File thông tin trích ngang (Tải file mẫu `}
                          <a
                            href={fileLyLich}
                            style={{ color: "blue" }}
                            download={"Thong tin trich ngang.docx"}
                          >
                            tại đây
                          </a>
                          {`)`}
                          <span style={{ color: "#c90000" }}>*</span>
                          {`:`}
                        </Typography>
                      )}
                    />
                  </Grid>
                  {values?.finalFileLink && (
                    <Grid item xs={12}>
                      <KPTextfield
                        id="finalFileLink"
                        type="dragzone"
                        disabled={disabled && props.id !== "edit-basic"}
                        required={true}
                        value={values?.finalFileLink}
                        handleChangeForm={handleChangeForm}
                        label={(color) => (
                          <Typography
                            className="kpt-textfield-label"
                            style={{ color }}
                          >
                            File báo cáo tổng kết
                            <span style={{ color: "#c90000" }}>*</span>:
                          </Typography>
                        )}
                      />
                    </Grid>
                  )}
                  {values?.estimateFileLink && (
                    <Grid item xs={12}>
                      <KPTextfield
                        id="estimateFileLink"
                        type="dragzone"
                        disabled={disabled && props.id !== "edit-basic"}
                        required={true}
                        value={values?.estimateFileLink}
                        handleChangeForm={handleChangeForm}
                        label={(color) => (
                          <Typography
                            className="kpt-textfield-label"
                            style={{ color }}
                          >
                            File Phiếu đánh giá nghiệm thu
                            <span style={{ color: "#c90000" }}>*</span>:
                          </Typography>
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
            <Box border="solid 1px #bcbcbc" marginTop={4} borderRadius={4}>
              <Box
                display="flex"
                bgcolor="#ececec"
                padding="8px 16px"
                alignItems="center"
                borderBottom="solid 1px #bcbcbc"
                style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
              >
                <Person
                  style={{
                    marginRight: 12,
                    marginTop: 2,
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography
                  variant="h6"
                  style={{ color: theme.palette.primary.main }}
                >
                  <b>CHỦ NHIỆM ĐỀ TÀI</b>
                </Typography>
              </Box>
              <Box padding={2}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    {profileList && (
                      <KPTextfield
                        type="autoComplete"
                        options={profileList}
                        getOptionLabel={(option) => option.name}
                        required={true}
                        disabled={props.type === "personal" || !props?.open?.insert}
                        value={profileList.find(
                          (e) => e.id === participants[0]?.profileId
                        )}
                        label="Tên giảng viên"
                        id="participants.0."
                        handleChangeForm={handleChangeForm}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield
                      id="participants.0.degree"
                      label="Học hàm, học vị"
                      handleChangeForm={handleChangeForm}
                      value={participants?.[0]?.degree}
                      required={true}
                      disabled={true}
                    />
                  </Grid>
                  <Grid className={styles.files} item xs={4}>
                    {participants?.[0]?.llkh ? (() => {
                      const { fullname, name, type, size } = valueFormat.pathToInfo(participants?.[0]?.llkh);

                      return (
                        <KPTextfield
                          id="participants.0.llkh"
                          label="Lý lịch khoa học"
                          type="render"
                          required={true}
                          render={() => (
                            <OutlinedInput
                              fullWidth
                              disabled
                              size="small"
                              value={name}
                              inputProps={{ style: { padding: 8, borderRadius: 4 } }}
                              style={{ padding: 0, backgroundColor: "white", zIndex: 2 }}
                              endAdornment={
                                <Tooltip title='Tải xuống'>
                                  <IconButton
                                    size="small"
                                    onClick={() => downloadAs(
                                      `${process.env.REACT_APP_DOMAIN}${participants?.[0]?.llkh}`,
                                      fullname
                                    )}
                                    // style={{ width: 48, height: 48, paddingTop: 14 }}
                                  >
                                    {/* <a href={`${process.env.REACT_APP_DOMAIN}${value}`} download='renamed'> */}
                                      {/* ok */}
                                      <GetApp />
                                    {/* </a> */}
                                  </IconButton>
                              </Tooltip>
                              }
                            />
                          )}
                        />
                      )
                    })() : (
                      <KPTextfield
                        id="participants.0.llkh"
                        label="Lý lịch khoa học"
                        type="render"
                        required={true}
                        render={() => (
                          <OutlinedInput
                            fullWidth
                            required
                            size="small"
                            type="file"
                            disabled={disabled && props.id !== "edit-basic"}
                            inputProps={{ style: { padding: '5.5px 8px 10.5px', borderRadius: 4 } }}
                            style={{ padding: 0, backgroundColor: "white", zIndex: 2 }}
                            onChange={(e) => setFiles({
                              ...files,
                              "participants.0.llkh": e.target.files[0]
                            })}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield
                      id="participants.0.department"
                      label="Đơn vị công tác"
                      handleChangeForm={handleChangeForm}
                      value={participants?.[0]?.department}
                      required={true}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield
                      id="participants.0.phone"
                      label="Số điện thoại"
                      handleChangeForm={handleChangeForm}
                      value={participants?.[0]?.phone}
                      required={true}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield
                      id="participants.0.email"
                      label="Email"
                      handleChangeForm={handleChangeForm}
                      value={participants?.[0]?.email}
                      required={true}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {(props.open?.insert
              || props.open?.editBasic
              || props.open?.detailAdjust
              || participants.filter((e) => e.researchRole === "other").length > 0
            ) && (
              <Box border="solid 1px #bcbcbc" marginTop={4} borderRadius={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <Group
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>CÁN BỘ THAM GIA</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  {participants
                    .filter((e) => e.researchRole === "other")
                    ?.map((member, index) => (
                      <Box key={`member-${index + 1}`} mt={index ? 1 : 0} display="flex">
                        <Box width={220} marginRight={1}>
                          <KPTextfield
                            value={member.isPhenikaa}
                            id={`participants.${index + 1}.isPhenikaa`}
                            type="checkbox"
                            name="GV trong trường"
                            disabled={disabled && props.id !== "edit-basic"}
                            handleChangeForm={handleChangeForm}
                            style={{ marginTop: "25.5px" }}
                          />
                        </Box>
                        <Grid container spacing={1} style={{ flexGrow: 1 }}>
                          <Grid item xs={4}>
                            <KPTextfield
                              type={member.isPhenikaa ? "autoComplete" : null}
                              options={member.isPhenikaa ? profileList : null}
                              getOptionLabel={(option) => option.name}
                              required={true}
                              disabled={disabled && props.id !== "edit-basic"}
                              value={
                                member.isPhenikaa
                                  ? profileList.find(
                                      (e) => e.id === member?.profileId
                                    )
                                  : member?.name
                              }
                              label="Tên giảng viên"
                              id={`participants.${index + 1}${
                                member.isPhenikaa ? null : ".name"
                              }`}
                              handleChangeForm={handleChangeForm}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KPTextfield
                              required={true}
                              value={member.degree}
                              disabled={
                                (disabled || member?.isPhenikaa) &&
                                props.id !== "edit-basic"
                              }
                              label={(color) => (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography className="kpt-textfield-label" style={{ color }}>
                                    Học hàm, học vị
                                    <span style={{ color: '#c90000' }}>*</span>
                                    :
                                  </Typography>
                                  {!member.isPhenikaa && (
                                    <Tooltip
                                      arrow
                                      title={<>
                                        <Typography variant="body2"><b>Lưu ý</b></Typography>
                                        <Typography variant="caption2">
                                          Với Giảng viên không thuộc trường ĐH Phinekaa, đề nghị viết tắt Học hàm, học vị!
                                        </Typography>
                                        <br />
                                        <Typography variant="caption2">
                                          VD: "Phó giáo sư/Tiến sĩ" sẽ điền là "PGS. TS."
                                        </Typography>
                                      </>}
                                      open={openInfo === index}
                                    >
                                      <InfoOutlined
                                        onMouseEnter={() => setOpenInfo(index)}
                                        onMouseLeave={() => setOpenInfo(null)}
                                        style={{ fontSize: 18, color }}
                                      />
                                    </Tooltip>
                                  )}
                                </Box>
                              )}
                              id={`participants.${index + 1}.degree`}
                              handleChangeForm={handleChangeForm}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KPTextfield
                              required={true}
                              disabled={
                                (disabled || member?.isPhenikaa) &&
                                props.id !== "edit-basic"
                              }
                              value={member.department}
                              label="Đơn vị"
                              id={`participants.${index + 1}.department`}
                              handleChangeForm={handleChangeForm}
                            />
                          </Grid>
                        </Grid>
                        <ButtonGroup
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "25.5px",
                          }}
                        >
                          {(() => {
                            const fullname = valueFormat.pathToInfo(
                              participants?.[index + 1]?.llkh
                            )?.fullname || files?.[`participants.${index + 1}.llkh`]?.name;

                            const isFile = files?.[`participants.${index + 1}.llkh`]?.name;

                            return (
                              <Tooltip title={fullname ? fullname : "Chưa upload file Lý lịch khoa học"}>
                                <Button
                                  color={fullname ? "primary" : "inherit"}
                                  variant={fullname ? "contained" : "outlined"}
                                  style={{
                                    height: 35,
                                    minWidth: 35,
                                    padding: 0,
                                    marginLeft: 16,
                                    // zIndex: -1,
                                  }}
                                  onClick={() => {
                                    if (fullname) downloadAs(
                                      `${process.env.REACT_APP_DOMAIN}${participants?.[index + 1]?.llkh}`,
                                      fullname
                                    );
                                  }}
                                >
                                  <label
                                    // for={fullname ? `participants.${index + 1}.llkh` : null}
                                    for={isFile ? null : `participants.${index + 1}.llkh`}
                                    style={{
                                      width: 35,
                                      height: 35,
                                      // marginTop: "25.5px",
                                      cursor: "pointer",
                                      zIndex: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FileCopy fontSize="small" />
                                  </label>
                                </Button>
                              </Tooltip>
                            )
                          })()}
                          <input
                            type="file"
                            name="photo"
                            id={`participants.${index + 1}.llkh`}
                            onChange={(e) => {
                              setFiles({
                                ...files,
                                [`participants.${index + 1}.llkh`]: e.target.files[0]
                              });
                            }}
                            style={{ display: "none" }}
                          />
                          {(props.open?.insert || props.open?.editBasic || props.open?.detailAdjust) && (
                            <Tooltip title="Xóa">
                              <Button
                                className="error-button"
                                color="primary"
                                variant="contained"
                                style={{
                                  height: 35,
                                  minWidth: 35,
                                  padding: 0,
                                }}
                                onClick={() => {
                                  const _participants = [...participants];
                                  _participants.splice(index + 1, 1);
                                  setParticipants(_participants);
                                }}
                              >
                                <Delete fontSize="small" />
                                {/* Xóa */}
                              </Button>
                            </Tooltip>
                          )}
                        </ButtonGroup>
                      </Box>
                    ))}
                  {(props.open?.insert || props.open?.editBasic || props.open?.detailAdjust) && (
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      startIcon={<Add />}
                      style={{
                        height: 35,
                        marginTop: 16,
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        setParticipants([
                          ...participants,
                          { isPhenikaa: true, researchRole: "other" },
                        ]);
                      }}
                    >
                      Thêm thành viên
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {(props.open?.acceptInfo ||
              props.open?.acceptInfoV2 ||
              editRequestDisabled) && (
              <Box border="solid 1px #bcbcbc" marginTop={4} borderRadius={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <RateReview
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>
                      NHẬN XÉT CỦA{" "}
                      {!values.infoPassed ? "TRƯỞNG KHOA" : "PHÒNG KHCN"}
                    </b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  {values.infoPassed && (
                    <KPTextfield
                      id="researchCode"
                      xs={12}
                      label="Mã đề tài"
                      required={true}
                      value={values?.researchCode || ""}
                      disabled={editRequestDisabled}
                      handleChangeForm={handleChangeForm}
                    />
                  )}
                  <KPTextfield
                    id="editRequest"
                    xs={12}
                    height={4}
                    // label='Nhận xét của phòng KHCN'
                    label={`Nhận xét của ${
                      !values.infoPassed ? "trưởng khoa" : "phòng KHCN"
                    }`}
                    value={values?.editRequest || ""}
                    disabled={editRequestDisabled}
                    handleChangeForm={handleChangeForm}
                  />
                </Box>
              </Box>
            )}
            {bigger && (
              <Box border="solid 1px #bcbcbc" borderRadius={4} marginTop={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <Info
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>NGHIỆM THU ĐỀ TÀI</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <KPTextfield
                        id="point"
                        value={values?.point}
                        label="Tổng điểm"
                        type="number"
                        step={0.01}
                        min={0}
                        max={100}
                        required={true}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <KPTextfield
                        id="rank"
                        value={values?.rank}
                        label="Xếp loại"
                        type="select"
                        options={[
                          { key: "ok", value: "ok", title: "Đạt" },
                          { key: "notOk", value: "notOk", title: "Không đạt" },
                        ]}
                        required={true}
                        handleChangeForm={handleChangeForm}
                        style={{ marginTop: "25.5px" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <KPTextfield
                        id="review"
                        value={values?.review}
                        label="Nhận xét"
                        // required={true}
                        disabled={values?.rank !== "notOk"}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <KPTextfield
                        id="estimate_file_link"
                        value={values?.estimate_file_link}
                        label="File Phiếu đánh giá nghiệm thu"
                        type="dragzone"
                        required={true}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
          {big && (
            <Box
              maxHeight="calc(100vh - 216px)"
              width="calc(40% - 48px)"
              padding="16px 24px"
              margin="8px 0"
              style={{ overflow: "auto" }}
            >
              <Box border="solid 1px #bcbcbc" borderRadius={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <Group
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>DANH SÁCH HỘI ĐỒNG XÉT CHỌN</b>
                  </Typography>
                </Box>
                <Box padding={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        style={{
                          padding: "12px 0",
                          backgroundColor: "#fafafa",
                          borderBottom: "solid 1px #e0e0e0",
                        }}
                      >
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <b>Tên thành viên</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Học hàm, học vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Đơn vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body1">
                            <b>Vai trò</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="chairmanName"
                            disabled={true}
                            value={council?.chairmanName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="chairmanDegree"
                            disabled={true}
                            value={council?.chairmanDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="chairmanUnit"
                            disabled={true}
                            value={council?.chairmanUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Chủ tịch"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="secretaryName"
                            disabled={true}
                            value={council?.secretaryName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="secretaryDegree"
                            disabled={true}
                            value={council?.secretaryDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="secretaryUnit"
                            disabled={true}
                            value={council?.secretaryUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Thư ký"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="financeCommissionerName"
                            disabled={true}
                            value={council?.financeCommissionerName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="financeCommissionerDegree"
                            disabled={true}
                            value={council?.financeCommissionerDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="financeCommissionerUnit"
                            disabled={true}
                            value={council?.financeCommissionerUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Uỷ viên thẩm định tài chính"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        {/* Ủy viên 01 */}
                        <>
                          <Grid item xs={4}>
                            <KPTextfield
                              id="firstCommissionerName"
                              disabled={true}
                              value={council?.firstCommissionerName}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="firstCommissionerDegree"
                              disabled={true}
                              value={council?.firstCommissionerDegree}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="firstCommissionerUnit"
                              disabled={true}
                              value={council?.firstCommissionerUnit}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield
                              value="Uỷ viên 1"
                              disabled={true}
                              required={true}
                            />
                          </Grid>
                        </>
                        {/* Ủy viên 02 */}
                        <>
                          <Grid item xs={4}>
                            <KPTextfield
                              id="secondCommissionerName"
                              disabled={true}
                              value={council?.secondCommissionerName}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="secondCommissionerDegree"
                              disabled={true}
                              value={council?.secondCommissionerDegree}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="secondCommissionerUnit"
                              disabled={true}
                              value={council?.secondCommissionerUnit}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield
                              value="Uỷ viên 2"
                              disabled={true}
                              required={true}
                            />
                          </Grid>
                        </>
                        {/* Ủy viên 03 */}
                        <>
                          <Grid item xs={4}>
                            <KPTextfield
                              id="thirdCommissionerName"
                              disabled={true}
                              value={council?.thirdCommissionerName}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="thirdCommissionerDegree"
                              disabled={true}
                              value={council?.thirdCommissionerDegree}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="thirdCommissionerUnit"
                              disabled={true}
                              value={council?.thirdCommissionerUnit}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield
                              value="Uỷ viên 3"
                              disabled={true}
                              required={true}
                            />
                          </Grid>
                        </>
                        {/* Ủy viên 04 */}
                        <>
                          <Grid item xs={4}>
                            <KPTextfield
                              id="fourthCommissionerName"
                              disabled={true}
                              value={council?.fourthCommissionerName}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="fourthCommissionerDegree"
                              disabled={true}
                              value={council?.fourthCommissionerDegree}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="fourthCommissionerUnit"
                              disabled={true}
                              value={council?.fourthCommissionerUnit}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield
                              value="Uỷ viên 4"
                              disabled={true}
                              required={true}
                            />
                          </Grid>
                        </>
                        {/* Ủy viên 05 */}
                        <>
                          <Grid item xs={4}>
                            <KPTextfield
                              id="fifthCommissionerName"
                              disabled={true}
                              value={council?.fifthCommissionerName}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="fifthCommissionerDegree"
                              disabled={true}
                              value={council?.fifthCommissionerDegree}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="fifthCommissionerUnit"
                              disabled={true}
                              value={council?.fifthCommissionerUnit}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield
                              value="Uỷ viên 5"
                              disabled={true}
                              required={true}
                            />
                          </Grid>
                        </>
                        {/* Ủy viên 06 */}
                        <>
                          <Grid item xs={4}>
                            <KPTextfield
                              id="sixthCommissionerName"
                              disabled={true}
                              value={council?.sixthCommissionerName}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="sixthCommissionerDegree"
                              disabled={true}
                              value={council?.sixthCommissionerDegree}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="sixthCommissionerUnit"
                              disabled={true}
                              value={council?.sixthCommissionerUnit}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield
                              value="Uỷ viên 6"
                              disabled={true}
                              required={true}
                            />
                          </Grid>
                        </>
                        {/* Ủy viên 07 */}
                        <>
                          <Grid item xs={4}>
                            <KPTextfield
                              id="seventhCommissionerName"
                              disabled={true}
                              value={council?.seventhCommissionerName}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="seventhCommissionerDegree"
                              disabled={true}
                              value={council?.seventhCommissionerDegree}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              id="seventhCommissionerUnit"
                              disabled={true}
                              value={council?.seventhCommissionerUnit}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield
                              value="Uỷ viên 7"
                              disabled={true}
                              required={true}
                            />
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box border="solid 1px #bcbcbc" marginTop={4} borderRadius={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <RateReview
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>DANH SÁCH PHẢN BIỆN</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          padding: "12px 0",
                          backgroundColor: "#fafafa",
                          borderBottom: "solid 1px #e0e0e0",
                        }}
                      >
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <b>Tên thành viên</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Học hàm, học vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Đơn vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body1">
                            <b>Vai trò</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="firstReviewerName"
                            disabled={true}
                            value={council?.firstReviewerName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="firstReviewerDegree"
                            disabled={true}
                            value={council?.firstReviewerDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="firstReviewerUnit"
                            disabled={true}
                            value={council?.firstReviewerUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Phản biện 1"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        {council?.secondReviewerName !== "" &&
                          council?.secondReviewerDegree && (
                            <>
                              <Grid item xs={4}>
                                <KPTextfield
                                  id="secondReviewerName"
                                  disabled={true}
                                  value={council?.secondReviewerName}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <KPTextfield
                                  id="secondReviewerDegree"
                                  disabled={true}
                                  value={council?.secondReviewerDegree}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <KPTextfield
                                  id="secondReviewerUnit"
                                  disabled={true}
                                  value={council?.secondReviewerUnit}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <KPTextfield
                                  value="Phản biện 2"
                                  disabled={true}
                                  required={true}
                                />
                              </Grid>
                            </>
                          )}
                        {council?.thirdReviewerName !== "" &&
                          council?.thirdReviewerDegree && (
                            <>
                              <Grid item xs={4}>
                                <KPTextfield
                                  id="thirdReviewerName"
                                  disabled={true}
                                  value={council?.thirdReviewerName}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <KPTextfield
                                  id="thirdReviewerDegree"
                                  disabled={true}
                                  value={council?.thirdReviewerDegree}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <KPTextfield
                                  id="thirdReviewerUnit"
                                  disabled={true}
                                  value={council?.thirdReviewerUnit}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <KPTextfield
                                  value="Phản biện 3"
                                  disabled={true}
                                  required={true}
                                />
                              </Grid>
                            </>
                          )}
                        {(council?.firstReviewerNote || type !== "personal") && (
                          <Grid item xs={12}>
                            <KPTextfield
                              id="firstReviewerNote"
                              value={council?.firstReviewerNote}
                              handleChangeForm={handleChangeForm}
                              label="Nhận xét của Phản biện 1"
                              height={4}
                              // required={true}
                              disabled={
                                type === "personal" ||
                                props.open?.detailAdjust ||
                                props.open?.detailAdjusted
                              }
                            />
                          </Grid>
                        )}
                        {council?.secondReviewerName !== "" &&
                          council?.secondReviewerDegree &&
                          (council?.secondReviewerNote || type !== "personal") && (
                            <Grid item xs={12}>
                              <KPTextfield
                                id="secondReviewerNote"
                                value={council?.secondReviewerNote}
                                handleChangeForm={handleChangeForm}
                                label="Nhận xét của Phản biện 2"
                                height={4}
                                // required={true}
                                disabled={
                                  type === "personal" ||
                                  props.open?.detailAdjust ||
                                  props.open?.detailAdjusted
                                }
                              />
                            </Grid>
                          )}
                        {council?.thirdReviewerName !== ""
                          && council?.thirdReviewerDegree
                          && (council?.thirdReviewerNote || type !== "personal") && (
                            <Grid item xs={12}>
                              <KPTextfield
                                id="thirdReviewerNote"
                                value={council?.thirdReviewerNote}
                                handleChangeForm={handleChangeForm}
                                label="Nhận xét của Phản biện 3"
                                height={4}
                                // required={true}
                                disabled={
                                  type === "personal" ||
                                  props.open?.detailAdjust ||
                                  props.open?.detailAdjusted
                                }
                              />
                            </Grid>
                        )}
                        {council?.thirdReviewerName !== ""
                          && council?.thirdReviewerDegree
                          && (council?.fourthReviewerNote || type !== "personal") && (
                            <Grid item xs={12}>
                              <KPTextfield
                                id="fourthReviewerNote"
                                value={council?.fourthReviewerNote}
                                handleChangeForm={handleChangeForm}
                                label="Nhận xét của Phản biện 3"
                                height={4}
                                // required={true}
                                disabled={
                                  type === "personal" ||
                                  props.open?.detailAdjust ||
                                  props.open?.detailAdjusted
                                }
                              />
                            </Grid>
                        )}
                        {council?.thirdReviewerName !== ""
                          && council?.thirdReviewerDegree
                          && (council?.fifthReviewerNote || type !== "personal") && (
                            <Grid item xs={12}>
                              <KPTextfield
                                id="fifthReviewerNote"
                                value={council?.fifthReviewerNote}
                                handleChangeForm={handleChangeForm}
                                label="Nhận xét của Phản biện 3"
                                height={4}
                                // required={true}
                                disabled={
                                  type === "personal" ||
                                  props.open?.detailAdjust ||
                                  props.open?.detailAdjusted
                                }
                              />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                          <KPTextfield
                            id="councilNote"
                            value={council?.councilNote}
                            handleChangeForm={handleChangeForm}
                            label="Kết luận của Hội đồng"
                            height={4}
                            // required={true}
                            disabled={
                              type === "personal" ||
                              props.open?.detailAdjust ||
                              props.open?.detailAdjusted
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <KPTextfield
                            id="councilFile"
                            type="dragzone"
                            value={council?.councilFileLink}
                            handleChangeForm={handleChangeForm}
                            label="Biên bản họp Hội đồng"
                            disabled={
                              type === "personal" ||
                              props.open?.detailAdjust ||
                              props.open?.detailAdjusted
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
          {bigger && (
            <Box
              maxHeight="calc(100vh - 216px)"
              width="calc(40% - 48px)"
              padding="16px 24px"
              margin="8px 0"
              style={{ overflow: "auto" }}
            >
              <Box border="solid 1px #bcbcbc" borderRadius={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <Group
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>{`DANH SÁCH HỘI ĐỒNG XÉT CHỌN ${
                      values?.researchType === "applicational" ? "SẢN PHẨM" : ""
                    }`}</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        style={{
                          padding: "12px 0",
                          backgroundColor: "#fafafa",
                          borderBottom: "solid 1px #e0e0e0",
                        }}
                      >
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <b>Tên thành viên</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Học hàm, học vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Đơn vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body1">
                            <b>Vai trò</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="council.chairmanName"
                            disabled={true}
                            value={council?.chairmanName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.chairmanDegree"
                            disabled={true}
                            value={council?.chairmanDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.chairmanUnit"
                            disabled={true}
                            value={council?.chairmanUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Chủ tịch"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="council.secretaryName"
                            disabled={true}
                            value={council?.secretaryName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.secretaryDegree"
                            disabled={true}
                            value={council?.secretaryDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.secretaryUnit"
                            disabled={true}
                            value={council?.secretaryUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Thư ký"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="council.commissionerName"
                            disabled={true}
                            value={council?.commissionerName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.commissionerDegree"
                            disabled={true}
                            value={council?.commissionerDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.commissionerUnit"
                            disabled={true}
                            value={council?.commissionerUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Uỷ viên"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box border="solid 1px #bcbcbc" marginTop={4} borderRadius={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <RateReview
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>{`DANH SÁCH PHẢN BIỆN ${
                      values?.researchType === "applicational"
                        ? "(HĐNT SẢN PHẨM)"
                        : ""
                    }`}</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          padding: "12px 0",
                          backgroundColor: "#fafafa",
                          borderBottom: "solid 1px #e0e0e0",
                        }}
                      >
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <b>Tên thành viên</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Học hàm, học vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body1">
                            <b>Đơn vị</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body1">
                            <b>Vai trò</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="council.firstCommissionerName"
                            disabled={true}
                            value={council?.firstCommissionerName}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.firstCommissionerDegree"
                            disabled={true}
                            value={council?.firstCommissionerDegree}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.firstCommissionerUnit"
                            disabled={true}
                            value={council?.firstCommissionerUnit}
                            handleChangeForm={handleChangeForm}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield
                            value="Phản biện 1"
                            disabled={true}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="council.secondCommissionerName"
                            disabled={true}
                            value={council?.secondCommissionerName}
                            handleChangeForm={handleChangeForm}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.secondCommissionerDegree"
                            disabled={true}
                            value={council?.secondCommissionerDegree}
                            handleChangeForm={handleChangeForm}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.secondCommissionerUnit"
                            disabled={true}
                            value={council?.secondCommissionerUnit}
                            handleChangeForm={handleChangeForm}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield value="Phản biện 2" disabled={true} />
                        </Grid>
                        <Grid item xs={4}>
                          <KPTextfield
                            id="council.thirdCommissionerName"
                            disabled={true}
                            value={council?.thirdCommissionerName}
                            handleChangeForm={handleChangeForm}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.thirdCommissionerDegree"
                            disabled={true}
                            value={council?.thirdCommissionerDegree}
                            handleChangeForm={handleChangeForm}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <KPTextfield
                            id="council.thirdCommissionerUnit"
                            disabled={true}
                            value={council?.thirdCommissionerUnit}
                            handleChangeForm={handleChangeForm}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <KPTextfield value="Phản biện 3" disabled={true} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {values?.researchType === "applicational" && (
                <>
                  <Box
                    border="solid 1px #bcbcbc"
                    borderRadius={4}
                    marginTop={4}
                  >
                    <Box
                      display="flex"
                      bgcolor="#ececec"
                      padding="8px 16px"
                      alignItems="center"
                      borderBottom="solid 1px #bcbcbc"
                      style={{
                        borderTopRightRadius: 4,
                        borderTopLeftRadius: 4,
                      }}
                    >
                      <Group
                        style={{
                          marginRight: 12,
                          marginTop: 2,
                          color: theme.palette.primary.main,
                        }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: theme.palette.primary.main }}
                      >
                        <b>DANH SÁCH HỘI ĐỒNG XÉT CHỌN ĐỀ TÀI</b>
                      </Typography>
                    </Box>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            style={{
                              padding: "12px 0",
                              backgroundColor: "#fafafa",
                              borderBottom: "solid 1px #e0e0e0",
                            }}
                          >
                            <Grid item xs={4}>
                              <Typography variant="body1">
                                <b>Tên thành viên</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body1">
                                <b>Học hàm, học vị</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body1">
                                <b>Đơn vị</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="body1">
                                <b>Vai trò</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <KPTextfield
                                id="councilApp.chairmanName"
                                value={councilApp?.chairmanName}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.chairmanDegree"
                                value={councilApp?.chairmanDegree}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.chairmanUnit"
                                value={councilApp?.chairmanUnit}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <KPTextfield
                                value="Chủ tịch"
                                disabled={true}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KPTextfield
                                id="councilApp.secretaryName"
                                value={councilApp?.secretaryName}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.secretaryDegree"
                                value={councilApp?.secretaryDegree}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.secretaryUnit"
                                value={councilApp?.secretaryUnit}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <KPTextfield
                                value="Thư ký"
                                disabled={true}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KPTextfield
                                id="councilApp.commissionerName"
                                value={councilApp?.commissionerName}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.commissionerDegree"
                                value={councilApp?.commissionerDegree}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.commissionerUnit"
                                value={councilApp?.commissionerUnit}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <KPTextfield
                                value="Uỷ viên"
                                disabled={true}
                                required={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <Box
                    border="solid 1px #bcbcbc"
                    marginTop={4}
                    borderRadius={4}
                  >
                    <Box
                      display="flex"
                      bgcolor="#ececec"
                      padding="8px 16px"
                      alignItems="center"
                      borderBottom="solid 1px #bcbcbc"
                      style={{
                        borderTopRightRadius: 4,
                        borderTopLeftRadius: 4,
                      }}
                    >
                      <RateReview
                        style={{
                          marginRight: 12,
                          marginTop: 2,
                          color: theme.palette.primary.main,
                        }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: theme.palette.primary.main }}
                      >
                        <b>DANH SÁCH PHẢN BIỆN (HỘI ĐỒNG NGHIỆM THU ĐỀ TÀI)</b>
                      </Typography>
                    </Box>
                    <Box padding={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              padding: "12px 0",
                              backgroundColor: "#fafafa",
                              borderBottom: "solid 1px #e0e0e0",
                            }}
                          >
                            <Grid item xs={4}>
                              <Typography variant="body1">
                                <b>Tên thành viên</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body1">
                                <b>Học hàm, học vị</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body1">
                                <b>Đơn vị</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="body1">
                                <b>Vai trò</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <KPTextfield
                                id="councilApp.firstCommissionerName"
                                value={councilApp?.firstCommissionerName}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.firstCommissionerDegree"
                                value={councilApp?.firstCommissionerDegree}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.firstCommissionerUnit"
                                value={councilApp?.firstCommissionerUnit}
                                handleChangeForm={handleChangeForm}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <KPTextfield
                                value="Phản biện 1"
                                disabled={true}
                                required={true}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KPTextfield
                                id="councilApp.secondCommissionerName"
                                value={councilApp?.secondCommissionerName}
                                handleChangeForm={handleChangeForm}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.secondCommissionerDegree"
                                value={councilApp?.secondCommissionerDegree}
                                handleChangeForm={handleChangeForm}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.secondCommissionerUnit"
                                value={councilApp?.secondCommissionerUnit}
                                handleChangeForm={handleChangeForm}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <KPTextfield
                                value="Phản biện 2"
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <KPTextfield
                                id="councilApp.thirdCommissionerName"
                                value={councilApp?.thirdCommissionerName}
                                handleChangeForm={handleChangeForm}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.thirdCommissionerDegree"
                                value={councilApp?.thirdCommissionerDegree}
                                handleChangeForm={handleChangeForm}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <KPTextfield
                                id="councilApp.thirdCommissionerUnit"
                                value={councilApp?.thirdCommissionerUnit}
                                handleChangeForm={handleChangeForm}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <KPTextfield
                                value="Phản biện 3"
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (profileDetail && participants.length === 0 && !props.detail) {
      const temp = [...participants];
      if (props.type === "personal") {
        temp.push({
          isPhenikaa: true,
          name: profileDetail?.name,
          email: profileDetail?.mail,
          phone: profileDetail?.phone,
          profileId: profileDetail?.id,
          degree: profileDetail?.degree,
          department: profileDetail?.departmentProfile.find(
            (e) => e.department?.isFaculty
          )?.department?.name,
          researchRole: "main",
        });
      } else {
        temp.push({
          isPhenikaa: true,
          researchRole: "main",
        });
      }
      setParticipants(temp);
    }
  }, [profileDetail]);

  if (participants?.length < 2) textForm.splice(2, 1);

  return (
    <Box margin="-16px 0" width="calc(100% + 24px)" height="calc(100% + 100px)">
      <KPTextform
        id={props.id}
        textForm={textForm}
        values={values}
        setValues={setValues}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
}

export default DefaultForm;
