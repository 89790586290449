import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { matchSorter } from 'match-sorter';
import { Autocomplete } from '@material-ui/lab';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { getDOI } from '../../../../reducer/scientificResearch/action';
import { getAllConference } from '../../../../reducer/conference/action';
import { getJournalByIdOnScientificResearch, getSearchFilteredJournalAfterDOI } from '../../../../reducer/journal/action';

function Conference(props) {
  const { values, setValues, handleChangeForm } = props;

  useEffect(() => {
    if (props.listConference.length === 0 && !props.isFetchingJournal) {
      props.getAllConference();
    }
  }, []);

  useEffect(() => {
    if (!(Object.keys(props.DOI).length === 0 && props.DOI.constructor === Object)){
      props.getSearchFilteredJournalAfterDOI(1, 100, {
        title: props.DOI?.['container-title'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''),
        issn: (props.DOI?.ISSN?.[0] || props.DOI?.ISBN?.[0])?.replace('-', ''),
      }, props.DOI)
    }
  }, [props.isNeedReload]);


  useEffect(() => {
    if (props.listSearchAfterDOI && props.listSearchAfterDOI.length !== 0 && props.infoFromDoiLink && props.infoFromDoiLink.length !== 0){
      let tempListAuthor = '';
      (props.infoFromDoiLink?.author || [])?.forEach((author) => { 
        const tempAuthor = `${author.given} ${author.family}`;
        if (tempListAuthor === '') tempListAuthor += tempAuthor; else tempListAuthor += `, ${tempAuthor}`; 
      });

      setValues({
        ...values,
        doi: `https://doi.org/${props.infoFromDoiLink.DOI}`,
        title: props.infoFromDoiLink.title,
        issn: props.infoFromDoiLink?.ISSN?.[0] || props.infoFromDoiLink?.ISBN?.[0],
        issue: props.infoFromDoiLink.issue === undefined? '' : props.infoFromDoiLink.issue,
        // listAuthor: props.infoFromDoiLink.author?.map(author => `${author.given} ${author.family}`),
        listAuthor: tempListAuthor,
        page: props.infoFromDoiLink.page === undefined? '' : props.infoFromDoiLink.page,
        publisher: props.infoFromDoiLink.publisher === undefined? '' : props.infoFromDoiLink.publisher,
        volume: props.infoFromDoiLink.volume === undefined? '' : props.infoFromDoiLink.volume,
        otherTitle: props.infoFromDoiLink['container-title'] ?? '',
        numOfMember: props.infoFromDoiLink.author?.length,
        year: parseInt(props.infoFromDoiLink.issued['date-parts']),
      })
    }
  }, [props.isFetchingAfterDOI]);

  // const handleGetDataFromDOI = () => props.getDOI(values?.doi);
  const handleGetDataFromDOI = () => {
    const type1 = 'https://doi.org/';
    const type2 = 'doi.org/';
    if (values?.doi?.substr(0, type1.length) === type1) {
      props.getDOI(values?.doi);
    } else if (values?.doi?.substr(0, type2.length) === type2) {
      props.getDOI(`https://${values?.doi}`);
    } else {
      props.getDOI(`https://doi.org/${values?.doi}`);
    }
  }

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {keys: ['search']}).slice(0,100);
  }

  return (
    <>
      <Grid item xs={9}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Link DOI'
          variant='outlined'
          value={values?.doi || ''}
          onChange={(event) => handleChangeForm('doi', event.target.value)}
          helperText={!values?.doi? <div style={{ color: 'red' }}>Vui lòng upload file minh chứng nếu công bố không có link DOI</div> : null}
        />
      </Grid>
      <Grid item xs={3}> 
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleGetDataFromDOI()}
          disabled={props.disabled || props.isFetchingDOI || props.isFetchingAfterDOI || (values?.locked === 'kpi' || values?.locked === 'reward') || values?.lockedForTimeResearch}
        >
          {(props.isFetchingDOI || props.isFetchingAfterDOI) ? (
            <CircularProgress size={24} />
          ) : (
            'Nhập từ link DOI'
          )}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Tên công bố'
          variant='outlined'
          value={values?.title || ''}
          onChange={(event) => handleChangeForm('title', event.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Nhà xuất bản'
          variant='outlined'
          value={values?.publisher || ''}
          onChange={(event) => handleChangeForm('publisher', event.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="ISSN/ISBN"
          variant="outlined"
          value={values?.issn || ''}
          onChange={(event) => handleChangeForm('issn', event.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="Số lượng tác giả"
          variant="outlined"
          type="numOfMember"
          value={values?.numOfMember || ''}
          onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value), 10)}
          error={!(values?.numOfMember > 0)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={props.disabled}
          type='number'
          size='small'
          label='Số lượng tác giả chính'
          variant='outlined'
          value={values?.numberMainAuthor || ''}
          onChange={(event) => handleChangeForm('numberMainAuthor', parseInt(event.target.value))}
          error={!(values?.numberMainAuthor > 0)}
        />
      </Grid>
      {/* <Grid item xs={2}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="Năm"
          variant="outlined"
          type="number"
          value={values?.year || ''}
          onChange={(event) => handleChangeForm('year', parseInt(event.target.value), 10)}
        />
      </Grid> */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={props.disabled} 
          size='small'
          label='Tác giả'
          variant='outlined'
          value={values?.listAuthor || ''}
          multiline
          rows={4}
          onChange={(event) => handleChangeForm('listAuthor', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Tháng nộp'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1, max: 12 } }}
          value={values?.submissionMonth || ''}
          error={!(values?.submissionMonth > 0)}
          onChange={(event) => {
            handleChangeForm('submissionMonth', parseInt(event.target.value, 10));
            // let newDate = values?.submissionDay;
            // let newMonth = Math.max(parseInt(event.target.value) - 1, 0);
            // newMonth = Math.min(parseInt(event.target.value) - 1, 11);
            // if (!isValidDate(newDate)) newDate = new Date(2021, 0, 1);
            // newDate.setMonth(newMonth);
            // handleChangeForm('submissionDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Năm nộp'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1900, max: 3000 } }}
          value={new Date(values?.submissionDay).getFullYear() || ''}
          onChange={(event) => {
            let newDate = values?.submissionDay;
            let newYear = parseInt(event.target.value);
            if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
            newDate.setFullYear(newYear);
            handleChangeForm('submissionDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Tháng chấp nhận'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1, max: 12 } }}
          error={!(values?.acceptedMonth > 0)}
          value={values?.acceptedMonth || ''}
          // value={(() => {
          //   const month = new Date(values?.acceptedDay).getMonth();
          //   if (isNaN(month)) return '';
          //   return month + 1;
          // })()}
          onChange={(event) => {
            handleChangeForm('acceptedMonth', parseInt(event.target.value));
            // let newDate = values?.acceptedDay;
            // let newMonth = Math.max(parseInt(event.target.value) - 1, 0);
            // newMonth = Math.min(parseInt(event.target.value) - 1, 11);
            // if (!isValidDate(newDate)) newDate = new Date(2021, 0, 1);
            // newDate.setMonth(newMonth);
            // handleChangeForm('acceptedDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Năm chấp nhận'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1900, max: 3000 } }}
          value={new Date(values?.acceptedDay).getFullYear() || ''}
          onChange={(event) => {
            let newDate = values?.acceptedDay;
            let newYear = parseInt(event.target.value);
            if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
            newDate.setFullYear(newYear);
            handleChangeForm('acceptedDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          disabled={props.disabled || (values?.locked === 'kpi' || values?.locked === 'reward')}
          select
          size='small'
          label='Mục đích'
          variant='outlined'
          value={values?.purposeId ?? 'none'}
          SelectProps={{ native: true }}
          onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value, 10))}
        >
          {props.purposes.filter((e) => e.name !== 'kpi').map((e) => (
            <option key={e.id} value={e.id}>{e.description}</option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={10}>
        <Grid item xs={12}>
          <Autocomplete
            size='small'
            disableClearable
            options={props.listConference?.filter(e => e.level === 'domestic').map(e =>({...e, search: e.acronym + e.title}))}
            getOptionLabel={(option) => option?.title}
            filterOptions={filterOptions}
            onChange={(event, val) => handleChangeForm('conferenceDomesticId', val.id)}
            value={props.listConference?.find((e) => e.id === values?.conferenceDomesticId) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tên hội thảo trong nước"
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            )}
            style={{marginBottom: -10, marginTop: -16}}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          type='number'
          label='Điểm GS-PGS'
          variant='outlined'
          value={values?.score?.toString() || ''}
          onChange={(event) => handleChangeForm('score', parseFloat(event.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Tên hội thảo khác (trong trường hợp hệ thống không có hội thảo cần tìm)'
          variant='outlined'
          value={values?.otherTitle || ''}
          onChange={(event) => handleChangeForm('otherTitle', event.target.value)}
        />
      </Grid>
    </>
  );
}
         
const mapStateToProps = (state) => ({
  DOI: select(state, 'scientificResearchReducer', 'DOI'),
  purposes: select(state, 'scientificResearchReducer', 'purposes'),
  listConference: select(state, 'conferenceReducer', 'list'),
  infoFromDoiLink: select(state, 'journalReducer', 'infoFromDoiLink'),
  listSearchAfterDOI: select(state, 'journalReducer', 'listSearchAfterDOI'),

  isNeedReload: select(state, 'scientificResearchReducer', 'isNeedReload'),
  isFetchingDOI: select(state, 'scientificResearchReducer', 'isFetchingDOI'),
  isFetchingJournal: select(state, 'journalReducer', 'isFetching'),
  isFetchingAfterDOI: select(state, 'journalReducer', 'isFetchingAfterDOI'),
  isFetchingJournalDetail: select(state, 'journalReducer', 'isFetchingDetailOnScientificResearch'),
});

const mapDispatchToProps = (dispatch) => ({
  getDOI: (url) => dispatch(getDOI(url)),
  getAllConference: () => dispatch(getAllConference()),
  getJournalByIdOnScientificResearch: (id) => dispatch(getJournalByIdOnScientificResearch(id)),
  getSearchFilteredJournalAfterDOI: (page, pageSize, payload, doiInfo) => dispatch(getSearchFilteredJournalAfterDOI(page, pageSize, payload, doiInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(Conference));
