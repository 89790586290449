import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
  list: [],
  listV2: [],
  listFiltered: [],
  listSearch: [],
  listSearchAfterDOI: [],
  detail: {},
  infoFromDoiLink: [],
  isFetching: false,
  isFetchingDetail: false,
  isFetchingAfterDOI: false,
  isFetchingSearchFilter: false,
  filterData: {},
  searchFilterText: null,
  totalFiltered: 0,
});

export default function journalReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.JOURNAL.GET_ALL_JOURNAL_LOADING:
    case TYPE.JOURNAL.GET_ALL_JOURNAL_V2_LOADING:
    case TYPE.JOURNAL.GET_FILTERED_JOURNAL_LOADING:
      return state.merge({
        isFetching: true,
      });
    case TYPE.JOURNAL.UPDATE_ALL_JOURNAL_LOADING:
      return state.merge({
        isFetching: true,
      });
    case TYPE.SCIENTIFIC_RESEARCH.DOI_LOADING:
      return state.merge({
        infoFromDoiLink:[]
      })
    case TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_LOADING: 
      return state.merge({
        isFetchingAfterDOI: true,
        listSearchAfterDOI: [],
        infoFromDoiLink: [],
        listSearch: []
      })
    case TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_LOADING:
      return state.merge({
        isFetchingSearchFilter: true,
      });
    case TYPE.JOURNAL.GET_JOURNAL_BY_ID_LOADING: 
      return state.merge({
        isFetchingDetail: true,
      });
    case TYPE.JOURNAL.GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_LOADING: 
      return state.merge({
        isFetchingDetailOnScientificResearch: true,
      });

    case TYPE.JOURNAL.GET_ALL_JOURNAL_FAILURE:
    case TYPE.JOURNAL.GET_ALL_JOURNAL_V2_FAILURE:
    case TYPE.JOURNAL.GET_FILTERED_JOURNAL_FAILURE:
      return state.merge({
        isFetching: false,
      });
    case TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_FAILURE:
      return state.merge({
        isFetchingAfterDOI: false,
        listSearchAfterDOI: [],
        infoFromDoiLink: [],
        listSearch: []
      });
    case TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_FAILURE:
      return state.merge({
        isFetchingSearchFilter: false,
      });
    case TYPE.JOURNAL.UPDATE_ALL_JOURNAL_FAILURE:
      return state.merge({
        isFetching: false,
      });
    case TYPE.JOURNAL.GET_JOURNAL_BY_ID_FAILURE:
      return state.merge({
        isFetchingDetail: false,
      });
    case TYPE.JOURNAL.GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_FAILURE: 
      return state.merge({
        isFetchingDetailOnScientificResearch: false,
      });

    case TYPE.JOURNAL.GET_ALL_JOURNAL_SUCCESS:
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });

    case TYPE.JOURNAL.GET_ALL_JOURNAL_V2_SUCCESS:
      return state.merge({
        listV2: [...action.payload],
        isFetching: false,
      });

    case TYPE.JOURNAL.UPDATE_ALL_JOURNAL_SUCCESS: {
      return state.merge({
        isFetching: false,
      });
    }

    case TYPE.JOURNAL.GET_FILTERED_JOURNAL_SUCCESS: 
      return state.merge({
        listFiltered: action.payload.data || [],
        isFetching: false,
        totalFiltered: action.payload.metaData.total,
      })

    case TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_SUCCESS:
      return state.merge({
        searchFilterText: action.id,
        listSearch: action.payload || [],
        isFetchingSearchFilter: false,
      })

    case TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_SUCCESS:
      return state.merge({
        listSearch: action.payload || [],
        listSearchAfterDOI: action.payload || [],
        isFetchingAfterDOI: false,
        infoFromDoiLink: action.doiInfo,
      })

    case TYPE.JOURNAL.GET_JOURNAL_BY_ID_SUCCESS: 
      return state.merge({
        detail: action.payload,
        isFetchingDetail: false,
      })

    case TYPE.JOURNAL.GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_SUCCESS: 
      return state.merge({
        detailOnScientificResearch: action.payload,
        isFetchingDetailOnScientificResearch: false,
      });

    case TYPE.JOURNAL.CHANGE_FILTER_DATA:
      return state.merge({
        filterData: action.payload,
      })
    case 'RESET_DETAIL_ON_SR':
      return state.merge({
        detailOnScientificResearch: {},
        infoFromDoiLink: []
      })

    default: return state;
  }
}