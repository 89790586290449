import { apiCall } from '../../utils/api';

import { notification } from '../home/action';
import { getProfileFromUserId, getProfileInfoByProfileId } from '../profile/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const login = (payload) => async (dispatch) => {
  const api = API_URLS.USER.login();
  dispatch({
    type: TYPE.USER.LOGIN_LOADING,
    meta: { prefix: [PREFIX.USER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload: {
    username: payload.username,
    password: payload.password,
  } });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.USER.LOGIN_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.USER, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Đăng nhập thành công!'
    }));
    dispatch(getProfileFromUserId(response.data.data.userId))
      .then(response => {if (response.success) dispatch(getProfileInfoByProfileId(response.profileId))})
      .catch()
    return true;
  } else {
    dispatch({
      type: TYPE.USER.LOGIN_FAILURE,
      meta: { prefix: [PREFIX.USER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Đăng nhập thất bại!'
    }));
    return false;
  }
};

export const loginWithGoogle = (payload) => async (dispatch) => {
  const api = API_URLS.USER.loginWithGoogle();
  dispatch({
    type: TYPE.USER.LOGIN_WITH_GOOGLE_LOADING,
    meta: { prefix: [PREFIX.USER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.USER.LOGIN_WITH_GOOGLE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.USER, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Đăng nhập bằng Google thành công!'
    }));
    dispatch(getProfileFromUserId(response.data.data.userId))
      .then(response => {if (response.success) dispatch(getProfileInfoByProfileId(response.profileId))})
      .catch()
    return true;
  } else {
    dispatch({
      type: TYPE.USER.LOGIN_WITH_GOOGLE_FAILURE,
      meta: { prefix: [PREFIX.USER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Đăng nhập bằng Google thất bại!'
    }));
    return false;
  }
};

export const logout = () => async (dispatch) => {
  const api = API_URLS.USER.logout();
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  dispatch({
    type: TYPE.USER.LOGOUT,
    meta: { prefix: [PREFIX.HOME, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api});
  if (!error && response.status === 200 && response.data.success) {
    return true;
  } else {
    return false;
  }
}

export const refreshToken = (payload) => async (dispatch) => {
  const api = API_URLS.USER.refreshToken();
  dispatch({
    type: TYPE.USER.REFRESH_TOKEN_LOADING,
    meta: { prefix: [PREFIX.USER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.USER.REFRESH_TOKEN_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.USER, PREFIX.API_SUCCESS] },
    });
    dispatch(getProfileFromUserId(response.data.data.userId))
      .then(response => {
        if (response.success) dispatch(getProfileInfoByProfileId(response.profileId))
      })
      .catch()
    return true;
  } else {
    dispatch({
      type: TYPE.USER.REFRESH_TOKEN_FAILURE,
      meta: { prefix: [PREFIX.USER, PREFIX.API_FAILURE] },
    });
    return false;
  }
};

export const updatePassword = (payload) => async (dispatch) => {
  const api = API_URLS.USER.updatePassword();
  dispatch({
    type: TYPE.USER.UPDATE_PASSWORD_LOADING,
    meta: { prefix: [PREFIX.USER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.USER.UPDATE_PASSWORD_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.USER, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Đổi mật khẩu thành công!'
    }));
    dispatch(getProfileFromUserId(response.data.data.userId))
    .then(response => {if (response.success) dispatch(getProfileInfoByProfileId(response.profileId))})
    .catch()
    return true;
  } else {
    dispatch({
      type: TYPE.USER.UPDATE_PASSWORD_FAILURE,
      meta: { prefix: [PREFIX.USER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Đổi mật khẩu thất bại!'
    }));
    return false;
  }
};
