import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Chip, Tooltip, TextField, Typography, InputAdornment, Menu, Box, Button } from "@material-ui/core";
import { Clear } from "@material-ui/icons";

import toJs from "../../../hoc/toJS";
import styles from "./index.module.css";
import select from "../../../utils/select";

import KPTable from "../../../components/KPTable";
import ScientificResearchUpdateModal from "../ScientificResearchUpdateModal";
import ScientificResearchDeleteModal from "../ScientificResearchDeleteModal";

import { statisticUnaccepted } from "../../../reducer/profile/action";
import { acceptScientificResearch, updateScientificResearch } from "../../../reducer/scientificResearch/action";
import {
  acceptScientificResearchOther,
  updateScientificResearchOther,
} from "../../../reducer/scientificResearchOther/action";
import { changePages } from "../../../reducer/home/action";
import { KPTextform } from "../../../components/KPTComponents";
import { exportExcel } from "../utils/exportExcel";

const listFilter = [
  {
    value: "journalInternational",
    type: "scientificResearchJournal",
    title: "Tạp chí quốc tế",
    checkFunction: (e) => e.journal && !e.scientificResearch.accepted,
  },
  {
    value: "journalDomestic",
    type: "scientificResearchJournalDomestic",
    title: "Tạp chí trong nước",
    checkFunction: (e) => e.journalDomestic && !e.scientificResearch.accepted,
  },
  {
    value: "conference",
    type: "scientificResearchConference",
    title: "Hội thảo quốc tế",
    checkFunction: (e) => e.conference?.level === "international" && !e.scientificResearch.accepted,
  },
  {
    value: "conferenceDomestic",
    type: "scientificResearchConferenceDomestic",
    title: "Hội thảo trong nước",
    checkFunction: (e) => e.conference?.level === "domestic" && !e.scientificResearch.accepted,
  },
  {
    value: "chapter",
    type: "chapterJournal",
    title: "Chương sách",
    checkFunction: (e) => e.scientificResearch.type === "book" && !e.scientificResearch.accepted,
  },
  {
    value: "workingPaper",
    type: "working",
    title: "Working Paper",
    checkFunction: (e) => false,
  },
  {
    value: "other",
    type: "scientificResearchOther",
    title: "Tạp chí khác",
    checkFunction: (e) => false,
  },
];

function ScientificResearchTable(props) {
  const [editting, setEditting] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleUpdate = (e) => setUpdating(e);
  const handleEditYear = (e) => setEditting(e);

  useEffect(() => {
    // sessionStorage.setItem('ScientificResearchPage', parseInt(sessionStorage.getItem('ScientificResearchPage') || 0));
    sessionStorage.setItem("ScientificResearchPage", 0);
  }, []);

  const handleDelete = (event, e) => {
    setDeleting(e.id);
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (value) => {
    setEditting({
      ...editting,
      dashboardYear: value,
    });
  };

  const handleChangePurpose = (value, editting) => {
    // // console.log(value, editting);
    props.updateScientificResearch(editting.id, {
      ...editting,
      purposeId: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // change scientific other
    // if (props.filter !== 'other') {
    props.updateScientificResearch(editting.id, editting);
    // } else {
    //   props.updateScientificResearchOther(editting.id, editting);
    // }
    setEditting(null);
  };

  const handleClear = () => {
    // change scientific other
    // if (props.filter !== 'other') {
    props.updateScientificResearch(editting.id, {
      ...editting,
      dashboardYear: null,
    });
    // } else {
    //   props.updateScientificResearchOther(editting.id, {...editting, dashboardYear: null});
    // }
    setEditting(null);
  };

  const handleAccept = (e) => {
    // change scientific other
    // if (props.filter !== 'other') {
    props.acceptScientificResearch(e.id, !e.accepted, props.filter).then((success) => {
      if (success) {
        props.statisticUnaccepted({
          departmentId: 0,
          profileId: props.profile?.id,
        });
        if (props.role === "sudo-admin") props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
      }
    });
    // } else {
    //   props.acceptScientificResearchOther(e.id, !e.accepted);
    // }
  };

  const getPublicationTitle = (item) => {
    switch (props.filter) {
      case "journalDomestic":
        return (
          (item.publication?.title
            ? `${item.publication?.title} (${item.publication?.publisher})`
            : item?.otherTitle) ?? "N/A"
        );
      case "conference":
        return (
          (item.publication?.title
            ? `${item.publication?.title} (Hội thảo rank ${item.publication?.rank})`
            : item?.otherTitle
            ? item?.otherTitle
            : item?.journalTitle) ?? "N/A"
        );
      default:
        return (
          (item.publication?.title
            ? `${item.publication?.title} (${item.publication?.coreCollection},  ${item.publication?.sjrBestQuartile}-${item.publication?.rank})`
            : item?.otherTitle
            ? item?.otherTitle
            : item?.journalTitle) ?? "N/A"
        );
    }
    // (item.publication?.title? (`${item.publication?.title} (${item.publication?.coreCollection},  ${item.publication?.sjrBestQuartile}-${item.publication?.rank})`) : (item?.otherTitle? item?.otherTitle : item?.journalTitle))
  };

  const list = [];
  if (props.type === "all") {
    switch (props.filter) {
      case "journalInternational": {
        props.listScientificResearch.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchJournal" && e?.scientificResearch?.purposeId !== 4)
            list.push({
              ...e.scientificResearch,
              publication: e.journal,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "journalDomestic": {
        props.listScientificResearch.forEach((e) => {
          if (
            e?.scientificResearch?.type === "scientificResearchJournalDomestic" &&
            e?.scientificResearch?.purposeId !== 4
          )
            list.push({
              ...e.scientificResearch,
              publication: e.journalDomestic,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "conference": {
        props.listScientificResearch.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchConference" && e?.scientificResearch?.purposeId !== 4)
            list.push({
              ...e.scientificResearch,
              publication: e.conference,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "conferenceDomestic": {
        props.listScientificResearch.forEach((e) => {
          if (
            e?.scientificResearch?.type === "scientificResearchConferenceDomestic" &&
            e?.scientificResearch?.purposeId !== 4
          )
            list.push({
              ...e.scientificResearch,
              publication: e.conference,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "chapter": {
        props.listScientificResearch.forEach((e) => {
          if (e?.scientificResearch?.type === "chapterJournal" && e?.scientificResearch?.purposeId !== 4) {
            list.push({
              ...e.scientificResearch,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
          }
        });
        break;
      }
      case "workingPaper": {
        props.listScientificResearch.forEach((e) => {
          if (e?.scientificResearch?.type === "working" && e?.scientificResearch?.purposeId !== 4) {
            list.push({
              ...e.scientificResearch,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
          }
        });
        break;
      }
      // change scientific other
      case "other": {
        props.listScientificResearch.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchOther") {
            list.push({
              ...e.scientificResearch,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
          }
        });
        break;
      }
      // case 'other': {
      //   props.listScientificResearchOther.forEach((e) => {
      //     list.push({
      //       ...e.scientificResearchOther,
      //       filter: props.filter,
      //       submissionYear:  new Date(e.scientificResearch.submissionDay).getFullYear(),
      //     })
      //   })
      //   break;
      // }
      default: {
        break;
      }
    }
  } else {
    switch (props.filter) {
      case "journalInternational": {
        props.listScientificResearchById.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchJournal")
            list.push({
              ...e.scientificResearch,
              publication: e.journal,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "journalDomestic": {
        props.listScientificResearchById.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchJournalDomestic")
            list.push({
              ...e.scientificResearch,
              publication: e.journalDomestic,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "conference": {
        // // console.log(props.listScientificResearchById);
        props.listScientificResearchById.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchConference")
            list.push({
              ...e.scientificResearch,
              publication: e.conference,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "conferenceDomestic": {
        props.listScientificResearchById.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchConferenceDomestic")
            list.push({
              ...e.scientificResearch,
              publication: e.conference,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
        });
        break;
      }
      case "chapter": {
        props.listScientificResearchById.forEach((e) => {
          if (e?.scientificResearch?.type === "chapterJournal") {
            list.push({
              ...e.scientificResearch,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
          }
        });
        break;
      }
      case "workingPaper": {
        props.listScientificResearchById.forEach((e) => {
          if (e?.scientificResearch?.type === "working") {
            list.push({
              ...e.scientificResearch,
              filter: props.filter,
              // submissionYear:  new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
          }
        });
        break;
      }
      // change scientific other
      case "other": {
        props.listScientificResearchById.forEach((e) => {
          if (e?.scientificResearch?.type === "scientificResearchOther") {
            list.push({
              ...e.scientificResearch,
              filter: props.filter,
              submissionYear: new Date(e.scientificResearch.submissionDay).getFullYear(),
            });
          }
        });
        break;
      }
      // case 'other': {
      //   props.listScientificResearchOtherById.forEach((e) => {
      //     list.push({
      //       ...e.scientificResearchOther,
      //       filter: props.filter,
      //       submissionYear:  new Date(e.scientificResearchOther.submissionDay).getFullYear(),
      //     })
      //   })
      //   break;
      // }
      default: {
        break;
      }
    }
  }

  const columns = [
    {
      title: <b>Tên công bố</b>,
      key: "title",
      width: props.role === "sudo-admin" ? "25%" : "35%",
      sortable: true,
      searchable: true,
      render: (value, row) => <div onClick={() => handleUpdate(row)}>{value}</div>,
    },
    {
      title: <b>Tác giả</b>,
      key: "listAuthorString",
      width: "18%",
      searchable: true,
    },
    {
      title: <b>Năm nộp</b>,
      key: "submissionYear",
      width: "12%",
      searchable: true,
      sorter: (a, b) => parseInt(a.submissionYear) - parseInt(b.submissionYear),
      // render: (value, row) => new Date(value).getFullYear()
    },
    {
      title: <b>Mục đích</b>,
      key: "purposeId",
      width: "15%",
      type: "include",
      filters: props.purposes.map((e) => ({
        value: e.id,
        text: e.description,
      })),
      onFilter: (value, row) => row.filter((e) => value.includes(e.purposeId)),
      render: (value) => props.purposes.find((e) => e.id === value)?.description,
    },
    props.role === "sudo-admin" && props.type === "all"
      ? {
          title: <b>Năm hiển thị</b>,
          key: "dashboardYear",
          width: "14%",
          searchable: true,
          render: (value, row) =>
            row?.id !== editting?.id ? (
              <Tooltip title="Click để chỉnh sửa năm">
                <div style={{ cursor: "pointer" }} onClick={() => handleEditYear(row)}>
                  {value > 0 ? value : "Chưa có năm hiển thị"}
                </div>
              </Tooltip>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  size="small"
                  type="tel"
                  variant="outlined"
                  value={editting?.dashboardYear || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={handleClear}>
                        <Clear style={{ fontSize: 18 }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => handleChange(parseInt(e.target.value))}
                />
              </form>
            ),
        }
      : {
          key: "dashboardYear",
          width: "0%",
          render: () => null,
        },
    {
      title: <b>Tác vụ</b>,
      key: "action",
      width: "12%",
      render: (value, row) =>
        row?.id !== editting?.id ? (
          <>
            <Typography
              className="table-action-buton"
              variant="caption"
              color="primary"
              onClick={() => handleUpdate(row)}
            >
              Chi tiết
            </Typography>
            {row.locked ? (
              <Typography className="table-action-buton" variant="caption" color="textPrimary">
                {`Đã khóa ${
                  (row.locked === "kpi" && "KPI") ||
                  (row.locked === "reward" && "thưởng") ||
                  (row.locked === "timeResearch" && "giờ nghiên cứu")
                }`}
              </Typography>
            ) : (
              <>
                {((props.type !== "all" && (!props.selectedProfileId || props.role === "sudo-admin")) ||
                  (props.type === "all" && props.role === "sudo-admin")) && (
                  <Typography
                    className="table-action-buton"
                    variant="caption"
                    color="secondary"
                    onClick={(event) => handleDelete(event, row)}
                  >
                    Xoá
                  </Typography>
                )}
                {props.role === "sudo-admin" && props.filter !== "workingPaper" && row.purposeId !== 4 && (
                  <>
                    <Typography
                      className="table-action-buton"
                      variant="caption"
                      color="primary"
                      onClick={(event) => handleAccept(row)}
                    >
                      {row.accepted ? "Hủy xác nhận" : "Xác nhận"}
                    </Typography>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Typography className="table-action-buton" variant="caption" color="primary" onClick={handleSubmit}>
              Lưu
            </Typography>
            <Typography
              className="table-action-buton"
              variant="caption"
              color="secondary"
              onClick={() => setEditting(null)}
            >
              Huỷ
            </Typography>
          </>
        ),
    },
  ];

  if (props.filter !== "workingPaper")
    columns.splice(3, 0, {
      title: <b>Trạng thái</b>,
      key: "accepted",
      width: "12%",
      render: (value, row) => {
        if (row.purposeId === 4) {
          return <Chip size="small" color="primary" label="Công bố cũ" variant="outlined" />;
        }
        return (
          <Chip
            size="small"
            color={value ? "primary" : "default"}
            label={value ? "Đã được duyệt" : "Chưa được duyệt"}
            variant="outlined"
          />
        );
      },
      type: "include",
      filters: [
        {
          text: "Đã được duyệt",
          value: true,
        },
        {
          text: "Chưa được duyệt",
          value: false,
        },
      ],
      onFilter: (value, row) => row.filter((e) => value.includes(e.accepted)),
    });

  if (props.filter !== "workingPaper" && props.filter !== "chapter")
    columns.splice(2, 0, {
      title: <b>Tên tạp chí / hội thảo</b>,
      key: "publicationTitle",
      width: "20%",
      sortable: true,
      searchable: true,
    });

  console.log(list);

  return (
    <>
      <KPTable
        shadow="none"
        pageName="ScientificResearchPage"
        rowsPerPage={10}
        maxHeight="calc(100vh - 259px)"
        columns={columns}
        rows={list
          .sort((a) => (!a.accepted ? -1 : 1))
          .map((item) => {
            return {
              ...item,
              listAuthorString: item?.listAuthor?.join(", "),
              publicationTitle: getPublicationTitle(item),
            };
          })}
        loading={props.isFetching}
      />
      <Menu
        open={!!props.reportAnchorEl}
        anchorEl={props.reportAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => props.setReportAnchorEl(null)}
        classes={{ paper: styles.paper }}
      >
        <Box width={300} padding={1}>
          <KPTextform
            textForm={[
              { xs: 12, key: "startAt", type: "date", label: "Ngày bắt đầu" },
              { xs: 12, key: "finishAt", type: "date", label: "Ngày kết thúc" },
              {
                xs: 12,
                key: "export",
                type: "render",
                render: () => (
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    style={{ height: 35 }}
                    onClick={() =>
                      exportExcel(
                        list.filter((e) => {
                          const startDate = new Date(props.date.startAt);
                          const finishDate = new Date(props.date.finishAt);
                          const startMonth = startDate.getMonth() + 1;
                          const startYear = startDate.getFullYear();
                          const finishMonth = finishDate.getMonth() + 1;
                          const finishYear = finishDate.getFullYear();
                          const _date = new Date(e.acceptedDay);
                          const month = e.acceptedMonth || 1;
                          const year = _date.getFullYear();

                          return (
                            (startYear - 1) * 12 + startMonth <= (year - 1) * 12 + month &&
                            (year - 1) * 12 + month <= (finishYear - 1) * 12 + finishMonth
                          );
                        })
                      )
                    }
                  >
                    Xuất file Excel
                  </Button>
                ),
              },
            ]}
            values={props.dateString}
            setValues={props.setDateString}
          />
        </Box>
      </Menu>
      <ScientificResearchUpdateModal
        adminTab={props.adminTab}
        filter={props.filter}
        updating={updating}
        setUpdating={setUpdating}
        other={props.other}
        type={listFilter.find((e) => e.value === props.filter)?.type}
      />
      <ScientificResearchDeleteModal
        filter={props.filter}
        deleting={deleting}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        other={props.other}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, "userReducer", "role"),
  profile: select(state, "profileReducer", "detail"),
  workPages: select(state, "homeReducer", "workPages"),
  listJournal: select(state, "journalReducer", "listV2"),
  department: select(state, "profileReducer", "department"),
  filter: select(state, "scientificResearchReducer", "filter"),
  purposes: select(state, "scientificResearchReducer", "purposes"),
  isFetching: select(state, "scientificResearchReducer", "isFetching"),
  listScientificResearch: select(state, "scientificResearchReducer", "listFiltered"),
  listScientificResearchById: select(state, "scientificResearchReducer", "listById"),
  listScientificResearchOther: select(state, "scientificResearchOtherReducer", "list"),
  listScientificResearchOtherById: select(state, "scientificResearchOtherReducer", "listById"),
});

const mapDispatchToProps = (dispatch) => ({
  statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
  updateScientificResearch: (id, payload) => dispatch(updateScientificResearch(id, payload)),
  acceptScientificResearchOther: (id, accept) => dispatch(acceptScientificResearchOther(id, accept)),
  updateScientificResearchOther: (id, payload) => dispatch(updateScientificResearchOther(id, payload)),
  acceptScientificResearch: (id, accept, filter) => dispatch(acceptScientificResearch(id, accept, filter)),
  changePages: (id, newPage) => dispatch(changePages(id, newPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ScientificResearchTable));
