import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import MonographInsertModal from '../MonographInsertModal';

import { getAllProject } from '../../../reducer/project/action';
import { getAllMonograph, getAllMonographByProfileId, getFilteredMonograph } from '../../../reducer/monograph/action';

function MonographButton(props) {
  const { getAllMonograph, getAllMonographByProfileId, getFilteredMonograph } = props;

  const [inserting, setInserting] = useState(false);

  let profileId = props.selectedProfileId;
  if (!profileId) profileId = props.profileDetail?.id;

  useEffect(() => {
    if (props.type !== 'all' && profileId) getAllMonographByProfileId(profileId);
    return false;
  }, []);

  useEffect(() => {
    if (props.type === 'all' && props.listFilteredMonograph.length === 0 && !props.isFetching) getFilteredMonograph(1, -1, {departmentId: props.department});
  }, []);

  useEffect(() => {
    if (props.type === 'all' && !props.isFetching && !props.isFetchingAccepted) getFilteredMonograph(1, -1, {departmentId: props.department})
  }, [props.department, getFilteredMonograph])

  const handleReload = () => {
    if (props.type !== 'all') {
      if (profileId) {
        getAllMonographByProfileId(profileId)
        props.handleReload();
      };
    } else {
      getFilteredMonograph(1, -1, {departmentId: props.department})
      props.handleReload();
    }
  }
  const handleInsert = () => setInserting(true);

  return (
    <>
      <Grid container>
        <Grid item xs={6}><Typography variant="h5" style={{ lineHeight: '36px' }}>Sách chuyên khảo/tham khảo/giáo trình</Typography></Grid>
        <Grid item xs={6}>
          <div style={{ float: 'right' }}>
            <Button variant="outlined" startIcon={<Replay />}  onClick={handleReload}>Tải lại</Button>
            {
              (props.type !== 'all') &&
              <Button variant="contained" color="primary" startIcon={<Add />} style={{ marginLeft: 8 }} onClick={handleInsert}>Thêm</Button>
            }
          </div>
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0' }} />
      <MonographInsertModal inserting={inserting} setInserting={setInserting} />
    </>
  );
}

const mapStateToProps = (state) => ({
  // isFetching: select(state, 'projectReducer', 'isFetching'),
  // isFetchingAccepted: select(state, 'projectReducer', 'isFetchingAccepted'),

  profileDetail: select(state, 'profileReducer', 'detail'),
  listMonographById: select(state, 'monographReducer', 'listById'),
  listFilteredMonograph: select(state, 'monographReducer', 'listFiltered'),
});

const mapDispatchToProps = (dispatch) => ({
  getAllMonographByProfileId: (id) => dispatch(getAllMonographByProfileId(id)),
  getFilteredMonograph: (page, pageSize, payload) => dispatch(getFilteredMonograph(page, pageSize, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(MonographButton));
