import TYPE from "../../constants/type";
import { apiCall } from "../../utils/api";
import { API_URLS } from "../../constants/api";
import { PREFIX } from "../../constants/enums";
import { notification } from "../home/action";

export const getAllEmploymentContract = () => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.getAllEmploymentContract();
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Lấy dữ liệu thất bại!",
      })
    );
  }
};

export const getEmploymentContractById = (id) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.getEmploymentContractById(id);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_ID_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_ID_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
  }
};

export const getEmploymentContractByLeaderProfileId = (profileId) => async (
  dispatch
) => {
  dispatch({
    type:
      TYPE.EMPLOYMENT_CONTRACT
        .GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });

  const apiProfileInfo = API_URLS.PROFILE.getProfileInfoByProfileId(profileId);
  const { response, error } = await apiCall({ ...apiProfileInfo });

  if (!error && response.status === 200 && response.data.success) {
    /**
     * @type {Array}
     */
    const listDepartments = response.data.data.departments.filter(
      (departmentElement) => {
        return departmentElement.departmentProfile.some(
          (departmentProfileElement) => {
            return (
              departmentProfileElement.profileId === profileId &&
              departmentProfileElement.roleInDepartment === "leader"
            );
          }
        );
      }
    );

    if (listDepartments.length) {
      /**
       * the `results` variable looks like this
       * [
       *   {
       *     error: null,
       *     response: {
       *       data: {
       *         data: [
       *           { id: 0, profileId: 0, duration: "...", startAt: "...", finishAt: "...", profile: { ... } }, // employmentContract
       *           { id: 0, profileId: 0, duration: "...", startAt: "...", finishAt: "...", profile: { ... } }, // employmentContract
       *           { id: 0, profileId: 0, duration: "...", startAt: "...", finishAt: "...", profile: { ... } }, // employmentContract
       *         ]
       *       }
       *     }
       *   }
       * ]
       */
      const results = await Promise.all(
        listDepartments.map((departmentElement) => {
          const apiEmploymentContract = API_URLS.EMPLOYMENT_CONTRACT.filterEmploymentContract();
          return apiCall({
            ...apiEmploymentContract,
            payload: {
              active: true,
              departmentId: departmentElement.id,
              profileId: 0,
            },
          });
        })
      );

      const extractedEmploymentContracts = results.reduce(
        (accumulation, resultElement) => {
          return accumulation.concat(resultElement.response.data.data);
        },
        []
      );

      const payload = extractedEmploymentContracts.map(
        (extractedEmploymentContractElement) => {
          const { profile, ...contract } = extractedEmploymentContractElement;
          return {
            contract: contract.contract,
            profile: profile,
          };
        }
      );

      dispatch({
        type:
          TYPE.EMPLOYMENT_CONTRACT
            .GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_SUCCESS,
        payload: payload,
        meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
      });
    } else {
      dispatch({
        type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS,
        payload: [],
        meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
      });
    }
  } else {
    dispatch({
      type:
        TYPE.EMPLOYMENT_CONTRACT
          .GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({ type: "error", message: "Lấy dữ liệu thất bại" }));
  }
};

export const getAllEmploymentContractByProfileId = (id) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.getAllEmploymentContractByProfileId(id);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    return response.data.data;
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
  }
};

export const insertEmploymentContract = (payload) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.insertEmploymentContract();
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.INSERT_EMPLOYMENT_CONTRACT_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({
    ...api,
    payload: payload.contract,
  });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.INSERT_EMPLOYMENT_CONTRACT_SUCCESS,
      payload: {
        profile: payload.profile,
        contract: response.data.data,
      },
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Thêm dữ liệu thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.INSERT_EMPLOYMENT_CONTRACT_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Thêm dữ liệu thất bại!",
      })
    );
  }
};

/**
 *
 * @description
 * Dependency of updateEmploymentContract
 */
const _updateKpiScientificResearch = async (payload) => {
  //
  // 1. the `kpiScientificResearch` array is splitted into 3 smaller arrays (new, will be deleted, will be updated)
  // 1.1 create new kpiScientificResearch
  // 1.2 delete kpiScientificResearch
  // 1.3 update kpiScientificResearch
  // 2. update kpi

  const kpiScientificResearch =
    payload.contract.kpi.kpiScientificResearch ?? [];

  // step 1
  // the `kpiScientificResearch` array is splitted into 3 smaller arrays
  const newKpiScientificResearch = kpiScientificResearch.filter(
    (element) => !element.id
  );
  const willBeDeletedKpiScientificResearch = kpiScientificResearch.filter(
    (element) => !!element.deleted
  );
  const willBeUpdatedKpiScientificResearch = kpiScientificResearch.filter(
    (element) => !!element.id && !element.deleted
  );

  const apiCreateKpiScientificResearchList = API_URLS.KPI_SCIENTIFIC_RESEARCH.createListKpiScientificResearch();

  // step 1.1
  await apiCall({
    ...apiCreateKpiScientificResearchList,
    payload: { listKpiScientificResearch: newKpiScientificResearch },
  });

  // step 1.2
  if (willBeDeletedKpiScientificResearch.length) {
    await Promise.all(
      willBeDeletedKpiScientificResearch.map((element) => {
        const deleteKpiScientificResearch = API_URLS.KPI_SCIENTIFIC_RESEARCH.deleteKpiScientificResearchById(
          element.id
        );
        return apiCall({ ...deleteKpiScientificResearch });
      })
    );
  }

  // step 1.3
  await Promise.all(
    willBeUpdatedKpiScientificResearch.map((element) => {
      const updateKpiScientificResearch = API_URLS.KPI_SCIENTIFIC_RESEARCH.updateKpiScientificResearchById(
        element.id
      );
      return apiCall({ ...updateKpiScientificResearch, payload: element });
    })
  );
};

/**
 *
 * @description
 *  Dependency of updateEmploymentContract
 */
const _updateKpiPatent = async (payload) => {
  // similar to _updateKpiScientificResearch

  const kpiPatent = payload.contract.kpi.kpiPatent ?? [];

  // step 1
  // the `kpiPatent` array is splitted into 3 smaller arrays
  const newKpiPatent = kpiPatent.filter((element) => !element.id);
  const willBeDeletedKpiPatent = kpiPatent.filter(
    (element) => !!element.deleted
  );
  const willBeUpdatedKpiPatent = kpiPatent.filter(
    (element) => !!element.id && !element.deleted
  );

  const apiCreateKpiPatentList = API_URLS.KPI_PATENT.createListKpiPatent();

  // step 1.1
  await apiCall({
    ...apiCreateKpiPatentList,
    payload: { listKpiPatent: newKpiPatent },
  });

  // step 1.2
  if (willBeDeletedKpiPatent.length) {
    await Promise.all(
      willBeDeletedKpiPatent.map((element) => {
        const deleteKpiPatent = API_URLS.KPI_PATENT.deleteKpiPatentResearchById(
          element.id
        );
        return apiCall({ ...deleteKpiPatent });
      })
    );
  }

  // step 1.3
  await Promise.all(
    willBeUpdatedKpiPatent.map((element) => {
      const updateKpiPatent = API_URLS.KPI_PATENT.updateKpiPatentById(
        element.id
      );
      return apiCall({ ...updateKpiPatent, payload: element });
    })
  );
};

/**
 *
 * @description
 *  Dependency of updateEmploymentContract
 */
const _updateKpiProductContract = async (payload) => {
  // similar to _updateKpiScientificResearch
  const kpiProductContract = payload.contract.kpi.kpiProductContract ?? [];

  // step 1
  // the `kpiProductContract` array is splitted into 3 smaller arrays
  const newKpiProductContract = kpiProductContract.filter(
    (element) => !element.id
  );
  const willBeDeletedKpiProductContract = kpiProductContract.filter(
    (element) => !!element.deleted
  );
  const willBeUpdatedKpiProductContract = kpiProductContract.filter(
    (element) => !!element.id && !element.deleted
  );

  const apiCreateKpiProductContractList = API_URLS.KPI_PRODUCT_CONTRACT.createListKpiProductContract();

  // step 1.1
  await apiCall({
    ...apiCreateKpiProductContractList,
    payload: { listKpiProductContract: newKpiProductContract },
  });

  // step 1.2
  if (willBeDeletedKpiProductContract.length) {
    await Promise.all(
      willBeDeletedKpiProductContract.map((element) => {
        const deleteKpiProductContract = API_URLS.KPI_PRODUCT_CONTRACT.deleteKpiProductContractById(
          element.id
        );
        return apiCall({ ...deleteKpiProductContract });
      })
    );
  }

  // step 1.3
  await Promise.all(
    willBeUpdatedKpiProductContract.map((element) => {
      const updateKpiProductContract = API_URLS.KPI_PRODUCT_CONTRACT.updateKpiProductContractById(
        element.id
      );
      return apiCall({ ...updateKpiProductContract, payload: element });
    })
  );
};

const _updateKpiSolution = async (payload) => {
  const kpiSolution = payload.contract.kpi.kpiSolution ?? [];

  // the `kpiSolution` array is splitted into 3 smaller arrays
  const newKpiSolution = kpiSolution.filter((element) => !element.id);
  const willBeDeletedKpiSolution = kpiSolution.filter(
    (element) => !!element.deleted
  );
  const willBeUpdatedKpiSolution = kpiSolution.filter(
    (element) => !!element.id && !element.deleted
  );

  const apiCreateKpiSolutionList = API_URLS.KPI_SOLUTION.createListKpiSolution();

  if (newKpiSolution.length) {
    await apiCall({
      ...apiCreateKpiSolutionList,
      payload: { listKpiSolution: newKpiSolution },
    });
  }

  if (willBeDeletedKpiSolution.length) {
    await Promise.all(
      willBeDeletedKpiSolution.map((element) => {
        const apiDeleteKpiSolution = API_URLS.KPI_SOLUTION.deleteKpiSolutionById(
          element.id
        );
        return apiCall({ ...apiDeleteKpiSolution });
      })
    );
  }

  if (willBeUpdatedKpiSolution.length) {
    await Promise.all(
      willBeUpdatedKpiSolution.map((element) => {
        const apiUpdateKpiSolution = API_URLS.KPI_SOLUTION.updateKpiSolutionById(
          element.id
        );
        return apiCall({ ...apiUpdateKpiSolution, payload: element });
      })
    );
  }
};

export const updateEmploymentContract = (id, payload, cleanUp) => async (
  dispatch
) => {
  dispatch({
    type: TYPE.KPI.UPDATE_KPI_BY_ID_LOADING,
    meta: { prefix: [PREFIX.KPI, PREFIX.API_CALLING] },
  });
  await _updateKpiScientificResearch(payload);
  await _updateKpiPatent(payload);
  await _updateKpiProductContract(payload);
  await _updateKpiSolution(payload);

  const apiUpdateKpi = API_URLS.KPI.updateKpi(payload.contract.kpi.id);
  const apiUpdateEmploymentContract = API_URLS.EMPLOYMENT_CONTRACT.updateEmploymentContract(
    id
  );

  const { response: updateKpiResponse, error: updateKpiError } = await apiCall({
    ...apiUpdateKpi,
    payload: payload.contract.kpi,
  });

  const {
    response: updateEmploymentContractResponse,
    error: updateEmploymentContractError,
  } = await apiCall({
    ...apiUpdateEmploymentContract,
    payload: payload.contract,
  });
  if (
    !updateKpiError &&
    updateKpiResponse.status === 200 &&
    updateKpiResponse.data.success &&
    !updateEmploymentContractError &&
    updateEmploymentContractResponse.status === 200 &&
    updateEmploymentContractResponse.data.success
  ) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_SUCCESS,
      payload: {
        contract: updateEmploymentContractResponse.data.data,
        profile: payload.profile,
      },
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật hợp đồng nhân sự thành công!",
      })
    );
  } else {
    if (!payload.contract.active) {
      dispatch({
        type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_FAILURE,
        meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
      });
      dispatch(
        notification({
          type: "error",
          message: "Hợp đồng nhân sự đã hết hiệu lực",
        })
      );
    } else {
      dispatch({
        type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_FAILURE,
        meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
      });
      dispatch(
        notification({
          type: "error",
          message: "Không cập nhật được hợp đồng nhân sự",
        })
      );
    }
  }

  if (typeof cleanUp === "function") {
    cleanUp();
  }
};

export const updateEmploymentContractKpiCompleted = (
  id,
  completed,
  callbackOnSuccess,
  callbackOnError
) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.updateKpiCompletedContract(
    id,
    completed
  );
  dispatch({
    type:
      TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type:
        TYPE.EMPLOYMENT_CONTRACT
          .UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_SUCCESS,
      payload: {
        id: id,
        completed: completed,
      },
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: `Đã đánh dấu ${
          completed ? "hoàn thành" : "không hoàn thành"
        } hợp đồng nhân sự`,
      })
    );
    if (typeof callbackOnSuccess === "function") {
      callbackOnSuccess();
    }
  } else {
    dispatch({
      type:
        TYPE.EMPLOYMENT_CONTRACT
          .UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: `Đã xảy ra lỗi khi đánh dấu ${
          completed ? "hoàn thành" : "không hoàn thành"
        } hợp đồng nhân sự`,
      })
    );
    if (typeof callbackOnError === "function") {
      callbackOnError();
    }
  }
};

export const deleteEmploymentContract = (id) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.deleteEmploymentContract(id);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.DELETE_EMPLOYMENT_CONTRACT_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.DELETE_EMPLOYMENT_CONTRACT_SUCCESS,
      payload: id,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Xoá dữ liệu thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.DELETE_EMPLOYMENT_CONTRACT_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Xoá dữ liệu thất bại!",
      })
    );
  }
};

export const updateEmploymentContractV2 = (id, payload) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.updateEmploymentContract(id);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_V2_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật dữ liệu thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_EMPLOYMENT_CONTRACT_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Cập nhật dữ liệu thất bại!",
      })
    );
  }
};

export const activateEmploymentContract = (id, active) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.activateEmploymentContract(
    id,
    active
  );
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.ACTIVATE_EMPLOYMENT_CONTRACT_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.ACTIVATE_EMPLOYMENT_CONTRACT_SUCCESS,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
      payload: response.data.data,
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật dữ liệu thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.ACTIVATE_EMPLOYMENT_CONTRACT_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Cập nhật dữ liệu thất bại!",
      })
    );
  }
};

export const updateReceiveNotiEmploymentContract = (id, receiveNoti) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.updateReceiveNotiEmploymentContract(
    id,
    receiveNoti
  );
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_SUCCESS,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
      payload: response.data.data,
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật dữ liệu thành công!",
      })
    );
    window.location.reload();
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Cập nhật dữ liệu thất bại!",
      })
    );
  }
};

export const getEmploymentContractInfoById = (id) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.getEmploymentContractInfoById(id);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Lấy dữ liệu thất bại!",
      })
    );
  }
};

// API --------------------------------------------------------------------------------------------------
// for --------------------------------------------------------------------------------------------------
// KPI --------------------------------------------------------------------------------------------------

export const updateKPI = (id, payload) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.updateKPI(id);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật KPI thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Cập nhật KPI thất bại!",
      })
    );
  }
};

export const updateKPICompleted = (id, completed) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.updateKPICompleted(id, completed);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_SUCCESS,
      id,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật hoàn thành KPI thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.UPDATE_KPI_COMPLETED_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Cập nhật hoàn thành KPI thất bại!",
      })
    );
  }
};

export const createKPI = (payload) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.createKPI();
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.CREATE_KPI_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.CREATE_KPI_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Tạo KPI thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.CREATE_KPI_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Tạo KPI thất bại!",
      })
    );
  }
};

export const deleteKPI = (id) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.deleteKPI(id);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.DELETE_KPI_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.DELETE_KPI_SUCCESS,
      id,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Xóa KPI thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.DELETE_KPI_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Xóa KPI thất bại!",
      })
    );
  }
};

export const getKpiRewardInfo = (kpiId) => async (dispatch) => {
  const api = API_URLS.EMPLOYMENT_CONTRACT.getKpiRewardInfo(kpiId);
  dispatch({
    type: TYPE.EMPLOYMENT_CONTRACT.GET_KPI_REWARD_INFO_LOADING,
    meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200) {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_KPI_REWARD_INFO_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.EMPLOYMENT_CONTRACT.GET_KPI_REWARD_INFO_FAILURE,
      meta: { prefix: [PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_FAILURE] },
    });
  }
};