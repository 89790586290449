import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  listById: [],
  listFiltered: [],
  detail: null,
  isFetching: false,
  isFetchingAccepted: false,
});

export default function patentReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.PATENT.GET_ALL_PATENT_LOADING:
    case TYPE.PATENT.GET_FILTERED_PATENT_LOADING:
    case TYPE.PATENT.GET_ALL_PATENT_BY_PROFILE_ID_LOADING:
    case TYPE.PATENT.INSERT_PATENT_LOADING:
    case TYPE.PATENT.UPDATE_PATENT_LOADING:
    case TYPE.PATENT.DELETE_PATENT_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.PATENT.ACCEPT_PATENT_LOADING:
      return state.merge({
        isFetchingAccepted: true,
      });

    case TYPE.PATENT.GET_ALL_PATENT_FAILURE:
    case TYPE.PATENT.GET_FILTERED_PATENT_FAILURE:
    case TYPE.PATENT.GET_ALL_PATENT_BY_PROFILE_ID_FAILURE:
    case TYPE.PATENT.INSERT_PATENT_FAILURE:
    case TYPE.PATENT.UPDATE_PATENT_FAILURE:
    case TYPE.PATENT.DELETE_PATENT_FAILURE:
      return state.merge({
        isFetching: false,
      });
    
    case TYPE.PATENT.ACCEPT_PATENT_FAILURE:
      return state.merge({
        isFetchingAccepted: true,
      });

    case TYPE.PATENT.GET_ALL_PATENT_SUCCESS:
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });

    case TYPE.PATENT.GET_FILTERED_PATENT_SUCCESS:
      return state.merge({
        listFiltered: action.payload || [],
        isFetching: false,
      });

    case TYPE.PATENT.GET_ALL_PATENT_BY_PROFILE_ID_SUCCESS:
      return state.merge({
        listById: [...action.payload],
        isFetching: false,
      });

    case TYPE.PATENT.GET_PATENT_BY_ID_SUCCESS:
      return state.merge({
        detail: {...action.payload},
        isFetching: false,
      });

    case TYPE.PATENT.INSERT_PATENT_SUCCESS: {
      const newlistById = state.get('listById');
      const index = action.payload.patentProfile.findIndex((e) => e.profileId === action.id);
      if (index >= 0) newlistById.push({ patent: action.payload });
      return state.merge({
        listById: [...newlistById],
        listFiltered: [...state.get('listFiltered'), { patent: action.payload }],
        isFetching: false,
      });
    }

    case TYPE.PATENT.UPDATE_PATENT_SUCCESS: {
      const newList = state.get('listFiltered');
      const index = newList.findIndex((e) => e.patent.id === action.id);
      newList.splice(index, 1, { patent: action.payload });

      const newList1 = state.get('listById');
      const index1 = newList1.findIndex((e) => e.patent.id === action.id);
      newList1.splice(index1, 1, { patent: action.payload });

      return state.merge({
        listFiltered: [...newList],
        listById: [...newList1],
        isFetching: false,
      });
    }

    case TYPE.PATENT.DELETE_PATENT_SUCCESS: {
      const newList = state.get('listFiltered');
      const index = newList.findIndex((e) => e.patent.id === action.id);
      if (index !== -1) newList.splice(index, 1);

      const newList1 = state.get('listById');
      const index1 = newList1.findIndex((e) => e.patent.id === action.id);
      if (index1 !== -1) newList1.splice(index1, 1);

      return state.merge({
        listFiltered: [...newList],
        listById: [...newList1],
        isFetching: false,
      });
    }

    case TYPE.PATENT.ACCEPT_PATENT_SUCCESS: {
      const newList = state.get('listById');
      const index = newList.findIndex((e) => e.patent.id === action.payload.id);
      const newScientificResearch = { ...newList[index], patent: action.payload};
      newList.splice(index, 1, newScientificResearch);

      const newList1 = state.get('listFiltered');
      const index1 = newList1.findIndex((e) => e.patent.id === action.payload.id);
      const newScientificResearch1 = { ...newList1[index1], patent: action.payload};
      newList1.splice(index1, 1, newScientificResearch1);

      return state.merge({
        listFiltered: [...newList1],
        listById: [...newList],
        isFetchingAccepted: false,
      });
    }
  
    default:
      return state;
  }
}
