import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { getDOI } from '../../../../reducer/scientificResearch/action';
import { getJournalByIdOnScientificResearch, getSearchFilteredJournalAfterDOI } from '../../../../reducer/journal/action';

function WorkingPaper(props) {
  const { values, setValues, handleChangeForm } = props;

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Tên công bố'
          variant='outlined'
          value={values?.title || ''}
          onChange={(event) => handleChangeForm('title', event.target.value)}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Nhà xuất bản'
          variant='outlined'
          value={values?.publisher || ''}
          onChange={(event) => handleChangeForm('publisher', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="ISSN/ISBN"
          variant="outlined"
          value={values?.issn || ''}
          onChange={(event) => handleChangeForm('issn', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="Năm"
          variant="outlined"
          type="number"
          value={values?.year || ''}
          onChange={(event) => handleChangeForm('year', parseInt(event.target.value), 10)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={props.disabled} 
          size='small'
          label='Tác giả'
          variant='outlined'
          value={values?.listAuthor || ''}
          multiline
          rows={4}
          onChange={(event) => handleChangeForm('listAuthor', event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label="Số lượng tác giả"
          variant="outlined"
          type="numOfMember"
          value={values?.numOfMember || ''}
          onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value), 10)}
          // error={!(values?.numOfMember > 0)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled={props.disabled}
          size='small'
          label='Số lượng tác giả chính'
          variant='outlined'
          type='number'
          value={values?.numberMainAuthor || ''}
          onChange={(event) => handleChangeForm('numberMainAuthor', parseInt(event.target.value, 10))}
          // error={!(values?.numberMainAuthor > 0)}
        />
      </Grid>
    </>
  );
}
         
const mapStateToProps = (state) => ({
  purposes: select(state, 'scientificResearchReducer', 'purposes'),
});

const mapDispatchToProps = (dispatch) => ({
  getJournalByIdOnScientificResearch: (id) => dispatch(getJournalByIdOnScientificResearch(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(WorkingPaper));
