import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  role: null,
  detail: null,
  userId: null,
  isFetching: false,
});

export default function conferenceReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.CONFERENCE.GET_ALL_CONFERENCE_LOADING:
    case TYPE.CONFERENCE.INSERT_CONFERENCE_LOADING:
    case TYPE.CONFERENCE.UPDATE_CONFERENCE_LOADING:
    case TYPE.CONFERENCE.DELETE_CONFERENCE_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.CONFERENCE.GET_ALL_CONFERENCE_FAILURE:
    case TYPE.CONFERENCE.INSERT_CONFERENCE_FAILURE:
    case TYPE.CONFERENCE.UPDATE_CONFERENCE_FAILURE:
    case TYPE.CONFERENCE.DELETE_CONFERENCE_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.CONFERENCE.GET_ALL_CONFERENCE_SUCCESS:
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });

    case TYPE.CONFERENCE.GET_CONFERENCE_BY_ID_SUCCESS:
      return state.merge({
        detail: {...action.payload},
        isFetching: false,
      });

    case TYPE.CONFERENCE.INSERT_CONFERENCE_SUCCESS:
      return state.merge({
        list: [...state.get('list'), action.payload],
        isFetching: false,
      });

    case TYPE.CONFERENCE.UPDATE_CONFERENCE_SUCCESS: {
      const newList = state.get('list');
      const index = newList.findIndex((e) => e.id === action.id);
      newList.splice(index, 1, action.payload);
      return state.merge({
        list: [...newList],
        isFetching: false,
      });
    }

    case TYPE.CONFERENCE.DELETE_CONFERENCE_SUCCESS: {
      const newList = state.get('list');
      const index = newList.findIndex((e) => e.id === action.id);
      newList.splice(index, 1);
      return state.merge({
        list: [...newList],
        isFetching: false,
      });
    }

    default:
      return state;
  }
}
