import { fromJS } from "immutable";

import TYPE from "../../constants/type";

/**
 * @type {import('immutable').Map}
 */
const initialState = fromJS({
  list: [],
  detail: null,
  isFetching: false,
  timeResearch: null,
  listTimeResearch: [],
  contracts: [],
  patents: [],
  productContracts: [],
  scientificResearchs: [],
  scientificResearchOthers: [],
  solutions: [],
  monographs: [],
  researchProjects: [],
  contractProfile: [],
  department: 0,
  statisticUnacceptedOther: null,
  statisticUnacceptedDetail: null,
  statisticUnacceptedDetailFilter: null,
  isFetchingAccepted: false,
  defaultDepartment: 0,
  isFetchingProfScore: false,
  profScore: 0,
});

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.PROFILE.GET_ALL_PROFILE_LOADING:
    case TYPE.PROFILE.GET_PROFILE_FROM_USER_ID_LOADING:
    case TYPE.PROFILE.UPDATE_PROFILE_LOADING:
    case TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_LOADING:
    case TYPE.PROFILE.GET_PROFILE_INFO_BY_PROFILE_ID_LOADING:
    case TYPE.PROFILE.GET_CONTRACT_BY_PROFILE_ID_LOADING:
    case TYPE.PROFILE.UPDATE_TIME_RESEARCH_INFORMATION_LOADING:
    case TYPE.PROFILE.FILTER_TIME_RESEARCH_LOADING:
      return state.merge({
        isFetching: true,
      });
    case TYPE.PROFILE.GET_PROF_SCORE_LOADING:
      return state.merge({
        isFetchingProfScore: true,
      });

    case TYPE.PROFILE.STATISTIC_UNACCEPTED_LOADING:
      return state.merge({
        isFetchingAccepted: true,
      });

    case TYPE.PROFILE.GET_ALL_PROFILE_FAILURE:
    case TYPE.PROFILE.GET_PROFILE_FROM_USER_ID_FAILURE:
    case TYPE.PROFILE.UPDATE_PROFILE_FAILURE:
    case TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_FAILURE:
    case TYPE.PROFILE.GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE:
    case TYPE.PROFILE.GET_CONTRACT_BY_PROFILE_ID_FAILURE:
    case TYPE.PROFILE.UPDATE_TIME_RESEARCH_INFORMATION_FAILURE:
    case TYPE.PROFILE.FILTER_TIME_RESEARCH_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.PROFILE.STATISTIC_UNACCEPTED_FAILURE:
      return state.merge({
        isFetchingAccepted: false,
      });

    case TYPE.PROFILE.GET_PROF_SCORE_FAILURE:
      return state.merge({
        isFetchingProfScore: false,
      });

    case TYPE.PROFILE.GET_ALL_PROFILE_SUCCESS: {
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });
    }

    case TYPE.PROFILE.GET_PROFILE_FROM_USER_ID_SUCCESS: {
      return state.merge({
        detail: action.payload,
        isFetching: false,
      });
    }

    case TYPE.PROFILE.UPDATE_PROFILE_SUCCESS:
      return state.merge({
        detail: action.payload,
        isFetching: false,
      });

    case TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_SUCCESS:
      return state.merge({
        timeResearch: action.payload,
        isFetching: false,
      });

    case TYPE.PROFILE.FILTER_TIME_RESEARCH_SUCCESS:
      return state.merge({
        listTimeResearch: action.payload,
        isFetching: false,
      });

    // case TYPE.PROFILE.UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS: {
    //   const newList = state.get('timeResearch');
    //   if (action.payload !== null){
    //     const index = newList?.findIndex((e) => e.id === action.payload.id);
    //     newList.splice(index, 1, action.payload);
    //   }

    //   return state.merge({
    //     timeResearch: newList,
    //     isFetching: false,
    //   });
    // }

    case TYPE.PROFILE.GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS:
      console.log('action.payload', action.payload)
      return state.merge({
        departments: action.payload.departments ?? [],
        contracts: action.payload.contracts ?? [],
        patents: action.payload.patents ?? [],
        productContracts: action.payload.productContracts ?? [],
        scientificResearchs: action.payload.scientificResearchs ?? [],
        scientificResearchOthers: action.payload.scientificResearchOthers ?? [],
        solutions: action.payload.solutions ?? [],
        monographs: action.payload.monographs ?? [],
        researchProjects: action.payload.researchProjects ?? [],
        isFetching: false,
      });

    case TYPE.PROFILE.GET_CONTRACT_BY_PROFILE_ID_SUCCESS:
      return state.merge({
        contractProfile: action.payload,
        isFetching: false,
      });

    case TYPE.PROFILE.STATISTIC_UNACCEPTED_SUCCESS:
      if (action.id === "other"){
        return state.merge({
          statisticUnacceptedOther:
            action.id === "other"
              ? { ...action.payload }
              : { ...state.get("statisticUnacceptedOther") },
          isFetchingAccepted: false,
        })
      }
      if (action.id === "all"){
        return state.merge({
          statisticUnacceptedDetail:
            action.id === "all"
            ? { ...action.payload }
            : { ...state.get("statisticUnacceptedDetail") },
          isFetchingAccepted: false,
        })
      }
      if (action.id === "filter"){
        return state.merge({
          statisticUnacceptedDetailFilter:
            action.id === "filter"
              ? { ...action.payload }
              : { ...state.get("statisticUnacceptedDetailFilter") },
          isFetchingAccepted: false,
        });
      }
      return state.merge({
        isFetchingAccepted: false,
      })

    case "SET_DEPARTMENT":
      return state.merge({
        department: action.id,
      });

    case "SET_STATISTIC_UNACCEPTED": {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      newStatisticUnacceptedDetail = {
        ...newStatisticUnacceptedDetail,
        [action.id]:
          newStatisticUnacceptedDetail[action.id] + (action.payload ? -1 : 1),
      };

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      newStatisticUnacceptedDetailFilter = {
        ...newStatisticUnacceptedDetailFilter,
        [action.id]:
          newStatisticUnacceptedDetailFilter[action.id] +
          (action.payload ? -1 : 1),
      };

      let newStatisticUnacceptedOther = state.get("statisticUnacceptedOther");
      newStatisticUnacceptedOther = {
        ...newStatisticUnacceptedOther,
        [action.id]:
          newStatisticUnacceptedOther[action.id] + (action.payload ? -1 : 1),
      };
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
        statisticUnacceptedOther: newStatisticUnacceptedOther,
      });
    }
    // delete
    case TYPE.MONOGRAPH.DELETE_MONOGRAPH_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numMonograph: newStatisticUnacceptedDetail["numMonograph"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numMonograph: newStatisticUnacceptedDetailFilter["numMonograph"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.JOURNAL.DELETE_JOURNAL_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numJounal: newStatisticUnacceptedDetail["numJounal"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numJounal: newStatisticUnacceptedDetailFilter["numJounal"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.JOURNAL_DOMESTIC.UPDATE_JOURNAL_DOMESTIC_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numDomesticJournal:
            newStatisticUnacceptedDetail["numDomesticJournal"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numDomesticJournal:
            newStatisticUnacceptedDetailFilter["numDomesticJournal"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.CONFERENCE.DELETE_CONFERENCE_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numConference: newStatisticUnacceptedDetail["numConference"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numConference: newStatisticUnacceptedDetailFilter["numConference"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.SOLUTION.DELETE_SOLUTION_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numSolution: newStatisticUnacceptedDetail["numSolution"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numSolution: newStatisticUnacceptedDetailFilter["numSolution"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.PATENT.DELETE_PATENT_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numPatent: newStatisticUnacceptedDetail["numPatent"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numPatent: newStatisticUnacceptedDetailFilter["numPatent"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.CONTRACT.DELETE_CONTRACT_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numProductContract:
            newStatisticUnacceptedDetail["numProductContract"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if (newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numProductContract:
            newStatisticUnacceptedDetailFilter["numProductContract"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.PROJECT.DELETE_PROJECT_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numResearchProject:
            newStatisticUnacceptedDetail["numResearchProject"] - 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numResearchProject:
            newStatisticUnacceptedDetailFilter["numResearchProject"] - 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }

    // insert
    case TYPE.MONOGRAPH.INSERT_MONOGRAPH_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numMonograph: newStatisticUnacceptedDetail["numMonograph"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numMonograph: newStatisticUnacceptedDetailFilter["numMonograph"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.JOURNAL.INSERT_JOURNAL_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numJounal: newStatisticUnacceptedDetail["numJounal"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numJounal: newStatisticUnacceptedDetailFilter["numJounal"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.JOURNAL_DOMESTIC.INSERT_JOURNAL_DOMESTIC_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numDomesticJournal:
            newStatisticUnacceptedDetail["numDomesticJournal"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numDomesticJournal:
            newStatisticUnacceptedDetailFilter["numDomesticJournal"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.CONFERENCE.INSERT_CONFERENCE_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numConference: newStatisticUnacceptedDetail["numConference"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numConference: newStatisticUnacceptedDetailFilter["numConference"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.SOLUTION.INSERT_SOLUTION_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numSolution: newStatisticUnacceptedDetail["numSolution"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){

        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numSolution: newStatisticUnacceptedDetailFilter["numSolution"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.PATENT.INSERT_PATENT_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numPatent: newStatisticUnacceptedDetail["numPatent"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numPatent: newStatisticUnacceptedDetailFilter["numPatent"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.CONTRACT.INSERT_CONTRACT_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numProductContract:
            newStatisticUnacceptedDetail["numProductContract"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numProductContract:
            newStatisticUnacceptedDetailFilter["numProductContract"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }
    case TYPE.PROJECT.INSERT_PROJECT_SUCCESS: {
      let newStatisticUnacceptedDetail = state.get("statisticUnacceptedDetail");
      if(newStatisticUnacceptedDetail){
        newStatisticUnacceptedDetail = {
          ...newStatisticUnacceptedDetail,
          numResearchProject:
            newStatisticUnacceptedDetail["numResearchProject"] + 1,
        };
      }

      let newStatisticUnacceptedDetailFilter = state.get(
        "statisticUnacceptedDetailFilter"
      );
      if(newStatisticUnacceptedDetailFilter){
        newStatisticUnacceptedDetailFilter = {
          ...newStatisticUnacceptedDetailFilter,
          numResearchProject:
            newStatisticUnacceptedDetailFilter["numResearchProject"] + 1,
        };
      }
      return state.merge({
        statisticUnacceptedDetail: newStatisticUnacceptedDetail,
        statisticUnacceptedDetailFilter: newStatisticUnacceptedDetailFilter,
      });
    }

    case TYPE.PROFILE.GET_PROF_SCORE_SUCCESS:
      return state.merge({
        isFetchingProfScore: false,
        profScore: action.payload,
      });

    case TYPE.EMPLOYMENT_CONTRACT.UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_SUCCESS: {
      return state.merge({
        contracts: state.get('contracts').map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              receiveNoti: action.payload.receiveNoti,
            };
          }
          return item;
        }),
      });
    }

    default:
      return state;
  }
}
