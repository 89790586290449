import React from 'react'
import {
  Card,
} from '@material-ui/core'
import SendNotificationCard from '../../containers/Notification/SendNotificationCard/SendNotificationCard';

export default function NotificationScreen(props) {
  return(
    <Card style={{ padding: 10, minHeight: 'calc(100vh - 112px)', position: 'relative' }}>
      <SendNotificationCard />
    </Card>
  )
}