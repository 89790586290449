import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';
import { notification } from '../home/action';

export const uploadFile = (id, fileType, files) => async (dispatch) => {
  const api = API_URLS.FILE.uploadFile(id, fileType);
  dispatch({
    type: TYPE.FILE.UPLOAD_FILE_LOADING,
    meta: { prefix: [PREFIX.FILE, PREFIX.API_CALLING] },
  });
  var formData = new FormData();
  formData.append("file", files[0]);
  const { response, error } = await apiCall({ ...api, payload: formData });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.FILE.UPLOAD_FILE_SUCCESS,
      payload: response.data.data,
      fileType,
      meta: { prefix: [PREFIX.FILE, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: 'success',
        message: 'Upload dữ liệu thành công!',
      })
    );
    return ({success: true, data: response.data.data, fileType});
  } else {
    dispatch({
      type: TYPE.FILE.UPLOAD_FILE_FAILURE,
      meta: { prefix: [PREFIX.FILE, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: 'error',
        message: 'Upload dữ liệu thất bại!',
      })
    );
    return ({success: false, data: null});
  }
};

export const uploadMultiFile = (id, files) => async (dispatch) => {
  const api = API_URLS.FILE.uploadMultiFile(id);
  dispatch({
    type: TYPE.FILE.UPLOAD_MULTIPLE_FILE_LOADING,
    meta: { prefix: [PREFIX.FILE, PREFIX.API_CALLING] },
  });

  const formData = new FormData();
  for (const property in files) {
    if (files[property]) {
      const timestamp = new Date().getTime();
      const name = files[property].name;
      const size = files[property].size;
      formData.append(
        'file',
        files[property],
        '[' + property + '][' + timestamp + '][' + size + ']' + name
      );
    }
  }

  const { response, error } = await apiCall({ ...api, payload: formData });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.FILE.UPLOAD_MULTIPLE_FILE_SUCCESS,
      meta: { prefix: [PREFIX.FILE, PREFIX.API_SUCCESS] },
    });
    return ({ success: true, data: response.data.data });
  } else {
    dispatch({
      type: TYPE.FILE.UPLOAD_MULTIPLE_FILE_FAILURE,
      meta: { prefix: [PREFIX.FILE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({ type: 'error', message: 'Upload dữ liệu thất bại!' }));
    return ({ success: false, data: null});
  }
};
