import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Tab,
  Grid,
  Card,
  Tabs,
  Divider
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Stats from '../../containers/User/Stats';
import Profile from '../../containers/User/Profile';
import TimeResearch from '../../containers/User/TimeResearch';
import ContractProfile from '../../containers/User/ContractProfile';
import ScientificProfile from '../../containers/User/ScientificProfile';

const useStyles = makeStyles((_theme) => {
  return createStyles({
    statsRoot: {
      position: 'relative',
      minHeight: 'calc(100vh - 92px)',
      maxHeight: 'calc(100vh - 92px)',
      // padding: '8px 16px',
    },
    tabBar: {
      top: 0,
      zIndex: 2,
      opacity: 1,
      width: '100%',
      position: 'sticky',
      backgroundColor: 'white',
    },
    tabPanel: {
      padding: 8,
    }
  })
});

function User() {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (location.hash !== "") alert("Vui lòng cập nhật hồ sơ trước khi sử dụng hệ thống");
  }, [location]);

  const handleChange = (event, newValue) => setValue(newValue);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        {...other}
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (children)}
      </div>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Profile />
      </Grid>
      <Grid item xs={12} md={9}>
        <Card className={classes.statsRoot}>
          <Tabs value={value} onChange={handleChange} indicatorColor={"secondary"}>
            <Tab label="Thống kê" />
            <Tab label="Thời gian nghiên cứu" />
            <Tab label="Hợp đồng cá nhân" />
            <Tab label="Lý lịch khoa học" />
          </Tabs>
          <Divider />
          <Box padding={1}>
            <TabPanel className={classes.tabPanel} value={value} index={0}><Stats /></TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={1}><TimeResearch /></TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={2}><ContractProfile /></TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={3}><ScientificProfile /></TabPanel>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default User;
