import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { uploadMultiFile } from "../../../../reducer/file/action";
import {
  updateStudentResearch,
  updateStudentResearchStatus,
} from "../../../../reducer/studentResearch/action";

import select from "../../../../utils/select";
import { KPTextform } from "../../../../components/KPTComponents";
import { textFormsToDisplay } from "./utils/textFormsToDisplay";

function DocumentForm(props) {
  const dispatch = useDispatch();

  const { values, setValues, giaiDoan } = props;

  const profileDetail = useSelector((state) =>
    select(state, "profileReducer", "detail")
  );

  const textForm = [
    {
      fieldOptions: { width: "calc(100% - 32px)" },
      key: "leaveFileLink",
      value: values?.leaveFileLink,
      type: "dragzone",
      label: "Quyết định phê duyệt thực hiện",
      xs: 6,
      disabled: props.disabled,
    },
    {
      fieldOptions: { width: "calc(100% - 32px)" },
      key: "leaveFileLink",
      value: values?.leaveFileLink,
      type: "dragzone",
      label: "Quyết định dừng thực hiện",
      xs: 6,
      disabled: props.disabled,
    },
    {
      fieldOptions: { width: "calc(100% - 32px)" },
      key: "leaveFileLink",
      value: values?.leaveFileLink,
      type: "dragzone",
      label: "Quyết định được duyệt thay đổi",
      xs: 6,
      disabled: props.disabled,
    },
    {
      fieldOptions: { width: "calc(100% - 32px)" },
      key: "leaveFileLink",
      value: values?.leaveFileLink,
      type: "dragzone",
      label: "Quyết định thành lập Hội đồng nghiệm thu",
      xs: 6,
      disabled: props.disabled,
    },
    {
      fieldOptions: { width: "calc(100% - 32px)" },
      key: "leaveFileLink",
      value: values?.leaveFileLink,
      type: "dragzone",
      label: "Biên bản họp Hội đồng nghiệm thu",
      xs: 6,
      disabled: props.disabled,
    },
    {
      fieldOptions: { width: "calc(100% - 32px)" },
      key: "leaveFileLink",
      value: values?.leaveFileLink,
      type: "dragzone",
      label: "Phiếu đánh giá nghiệm thu",
      xs: 6,
      disabled: props.disabled,
    },
    {
      fieldOptions: { width: "calc(100% - 32px)" },
      key: "leaveFileLink",
      value: values?.leaveFileLink,
      type: "dragzone",
      label: "Quyết định công nhận kết quả",
      xs: 6,
      disabled: props.disabled,
    },
  ];

  const stopProject = () => {
    dispatch(
      uploadMultiFile(profileDetail?.id, { leaveFile: values?.leaveFileLink })
    ).then((res) => {
      if (res.success) {
        const files = res.data;
        dispatch(
          updateStudentResearch({
            ...props.detail,
            leaveFileLink: files[0],
            status: "report_stop_default",
            leaveReason: values?.leaveReason,
          })
        );
      }
    });
  };

  const acceptStopProject = () =>
    dispatch(
      updateStudentResearchStatus(props.detail.id, "report_stop_accepted")
    );

  const handleSubmit = () => {
    props.handleClose();
    switch (props.id) {
      case "stop-form":
        stopProject();
        break;
      case "stop-accept-form":
        acceptStopProject();
        break;
      default:
        break;
    }
  };

  const displayedForms = textFormsToDisplay(textForm, props.detail.status);

  return (
    <KPTextform
      id={props.id}
      textForm={displayedForms}
      values={values}
      setValues={setValues}
      handleSubmit={handleSubmit}
    />
  );
}

export default DocumentForm;
